import { GET, POST, DELETE } from 'utils/Constants';
import { action, createRequestTypes } from 'utils/ReduxHelpers';

import { ISignupForm, IUser } from 'core/models/Models';

interface FetchUserSuccessPayload {
  user: IUser;
}

interface UpdateCognitoUserDataRequestPayload {
  email?: string;
  name?: string;
  family_name?: string;
  given_name?: string;
}

interface FetchUserFailurePayload {
  error: string;
}
export const CURRENT_AUTH_USER = createRequestTypes('CURRENT_AUTH_USER', [GET]);
export const USER = createRequestTypes('COGNITO_USER', [GET, POST]);
export const COGNITO_USER_EMAIL_VERIFICATION = createRequestTypes('COGNITO_USER_EMAIL_VERIFICATION', [POST]);
export const SIGN_IN = createRequestTypes('SIGN_IN', [POST]);
export const FORUM_SIGN_IN = createRequestTypes('FORUM_SIGN_IN', [POST]);
export const SIGN_UP = createRequestTypes('SIGN_UP', [GET, POST]);
export const SIGN_OUT = createRequestTypes('SIGN_OUT', [POST]);
export const CONFIRM_SIGN_UP = createRequestTypes('CONFIRM_SIGN_UP', [POST]);
export const RESEND_CONFIRMATION_CODE = createRequestTypes('RESEND_CONFIRMATION_CODE', [POST]);
export const RESEND_EMAIL_CONFIRMATION_CODE = createRequestTypes('RESEND_EMAIL_CONFIRMATION_CODE', [POST]);
export const CHANGE_COGNITO_PASSWORD = createRequestTypes('CHANGE_COGNITO_PASSWORD', [POST]);
export const FORGOT_PASSWORD = createRequestTypes('FORGOT_PASSWORD', [POST]);
export const FORGOT_PASSWORD_CONFIRMATION = createRequestTypes('FORGOT_PASSWORD_CONFIRMATION', [POST]);
export const VERIFY_TOKEN = createRequestTypes('VERIFY_TOKEN', [POST]);
export const SEND_INVITATION = createRequestTypes('SEND_INVITATION', [POST]);
export const ACCEPT_INVITATION = createRequestTypes('ACCEPT_INVITATION', [POST]);
export const DEACTIVATE_USERS_PROFILE = createRequestTypes('DEACTIVATE_USERS_PROFILE', [POST]);
export const DELETE_USER_PROFILE = createRequestTypes('DELETE_USER_PROFILE', [DELETE]);
export const UPDATE_SIGNUP_DATA = 'UPDATE_SIGNUP_DATA';
export const RESET_FORGOT_EMAIL_MESSAGE = 'RESET_FORGOT_EMAIL_MESSGE';

export const updateSignupData = (data: ISignupForm) => action(UPDATE_SIGNUP_DATA, data);
export const resetForgotEmailMessage = () => action(RESET_FORGOT_EMAIL_MESSAGE);

export const getCurrentAuthUser = {
  request: () => action(CURRENT_AUTH_USER.GET.REQUEST),
  success: (payload: FetchUserSuccessPayload) => action(CURRENT_AUTH_USER.GET.SUCCESS, payload),
  failure: (error: any) => action(CURRENT_AUTH_USER.GET.FAILURE, error)
};

export const getUser = {
  request: () => action(USER.GET.REQUEST),
  success: (payload: FetchUserSuccessPayload) => action(USER.GET.SUCCESS, payload),
  failure: (error: FetchUserFailurePayload) => action(USER.GET.FAILURE, error)
};

export const updateCognitoUser = {
  request: (data: UpdateCognitoUserDataRequestPayload) => action(USER.POST.REQUEST, data),
  success: (payload: any) => action(USER.POST.SUCCESS, payload),
  failure: (error: any) => action(USER.POST.FAILURE, error)
};

export const verifyUserEmail = {
  request: (previousEmail: string, email: string, code: string, isSignup = true) =>
    action(COGNITO_USER_EMAIL_VERIFICATION.POST.REQUEST, { previousEmail, email, code, isSignup }),
  success: (payload: any) => action(COGNITO_USER_EMAIL_VERIFICATION.POST.SUCCESS, payload),
  failure: (error: any) => action(COGNITO_USER_EMAIL_VERIFICATION.POST.FAILURE, error)
};

export const signInUser = {
  request: (email: string, password: string, sso: string | null, sig: string | null) =>
    action(SIGN_IN.POST.REQUEST, { email, password, sso, sig }),
  success: (payload: any) => action(SIGN_IN.POST.SUCCESS, payload),
  failure: (error: any) => action(SIGN_IN.POST.FAILURE, error)
};

export const signInForumUser = {
  request: (email: string, cognitoId: string, sso: string, sig: string) =>
    action(FORUM_SIGN_IN.POST.REQUEST, { email, cognitoId, sso, sig }),
  success: () => action(FORUM_SIGN_IN.POST.SUCCESS),
  failure: (error: any) => action(FORUM_SIGN_IN.POST.FAILURE, error)
};

export const loadSignUpDataFromIDB = {
  request: () => action(SIGN_UP.GET.REQUEST),
  success: (payload: any) => action(SIGN_UP.GET.SUCCESS, payload),
  failure: (error: any) => action(SIGN_UP.GET.FAILURE, error)
};

export const signUpUser = {
  request: (data: ISignupForm) => action(SIGN_UP.POST.REQUEST, data),
  success: (payload: any) => action(SIGN_UP.POST.SUCCESS, payload),
  failure: (error: any) => action(SIGN_UP.POST.FAILURE, error)
};

export const signOutUser = {
  request: (discourseId: string) => action(SIGN_OUT.POST.REQUEST, { discourseId }),
  success: (payload: any) => action(SIGN_OUT.POST.SUCCESS, payload),
  failure: (error: any) => action(SIGN_OUT.POST.FAILURE, error)
};

export const confirmSignup = {
  request: (username: string, authCode: string, signupForm: ISignupForm) =>
    action(CONFIRM_SIGN_UP.POST.REQUEST, { username, authCode, signupForm }),
  success: (payload: any) => action(CONFIRM_SIGN_UP.POST.SUCCESS, payload),
  failure: (error: any) => action(CONFIRM_SIGN_UP.POST.FAILURE, error)
};

export const resendConfirmationCode = {
  request: (username: string) => action(RESEND_CONFIRMATION_CODE.POST.REQUEST, { username }),
  success: (payload: any) => action(RESEND_CONFIRMATION_CODE.POST.SUCCESS, payload),
  failure: (error: any) => action(RESEND_CONFIRMATION_CODE.POST.FAILURE, error)
};

export const resendEmailConfirmationCode = {
  request: (email: string) => action(RESEND_EMAIL_CONFIRMATION_CODE.POST.REQUEST, { email }),
  success: (payload: any) => action(RESEND_EMAIL_CONFIRMATION_CODE.POST.SUCCESS, payload),
  failure: (error: any) => action(RESEND_EMAIL_CONFIRMATION_CODE.POST.FAILURE, error)
};

export const forgotPassword = {
  request: (email: string) => action(FORGOT_PASSWORD.POST.REQUEST, { email }),
  success: (payload: any) => action(FORGOT_PASSWORD.POST.SUCCESS, payload),
  failure: (error: any) => action(FORGOT_PASSWORD.POST.FAILURE, error)
};

export const forgotPasswordConfirmation = {
  request: (username: string, code: string, newPassword: string) =>
    action(FORGOT_PASSWORD_CONFIRMATION.POST.REQUEST, { username, code, newPassword }),
  success: (payload: any) => action(FORGOT_PASSWORD_CONFIRMATION.POST.SUCCESS, payload),
  failure: (error: any) => action(FORGOT_PASSWORD_CONFIRMATION.POST.FAILURE, error)
};

export const changeCognitoPassword = {
  request: (oldPassword: string, newPassword: string) =>
    action(CHANGE_COGNITO_PASSWORD.POST.REQUEST, { oldPassword, newPassword }),
  success: (payload: any) => action(CHANGE_COGNITO_PASSWORD.POST.SUCCESS, payload),
  failure: (error: any) => action(CHANGE_COGNITO_PASSWORD.POST.FAILURE, error)
};

export const verifyToken = {
  request: (token: string) => action(VERIFY_TOKEN.POST.REQUEST, { token }),
  success: (payload: any) => action(VERIFY_TOKEN.POST.SUCCESS, payload),
  failure: (error: any) => action(VERIFY_TOKEN.POST.FAILURE, error)
};

export const sendInvitation = {
  request: (email: string, userId: string) => action(SEND_INVITATION.POST.REQUEST, { email, userId }),
  success: (payload: any) => action(SEND_INVITATION.POST.SUCCESS, payload),
  failure: (error: any) => action(SEND_INVITATION.POST.FAILURE, error)
};

export const acceptInvitation = {
  request: (id: string) => action(ACCEPT_INVITATION.POST.REQUEST, { id }),
  success: (payload: any) => action(ACCEPT_INVITATION.POST.SUCCESS, payload),
  failure: (error: any) => action(ACCEPT_INVITATION.POST.FAILURE, error)
};

export const deactivateUsersProfile = {
  request: (discourseId: string) => action(DEACTIVATE_USERS_PROFILE.POST.REQUEST, { discourseId }),
  success: (payload: any) => action(DEACTIVATE_USERS_PROFILE.POST.SUCCESS, payload),
  failure: (error: any) => action(DEACTIVATE_USERS_PROFILE.POST.FAILURE, error)
};

export const deleteUserProfile = {
  request: (discourseId: string) => action(DELETE_USER_PROFILE.DELETE.REQUEST, { discourseId }),
  success: () => action(DELETE_USER_PROFILE.DELETE.SUCCESS),
  failure: (error: any) => action(DELETE_USER_PROFILE.DELETE.FAILURE, error)
};
