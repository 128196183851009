import { stringify, escapeSingleAndDoubleQuotes, stringToList } from './utils';
import { expertiseResourceData, linkResourceData, toolResourceData, knowledgeResourceData } from './queries';

export function createExpertiseResourceMutation(resource, cognitoId) {
  const imageUrl = resource?.attributes?.imageUrl != undefined ? `imageUrl: "${resource?.attributes?.imageUrl}"` : '';
  const creatorOrganizationId =
    resource?.attributes?.creatorOrganizationId != undefined
      ? `creatorOrganizationId: ${resource?.attributes?.creatorOrganizationId}`
      : '';
  const tagIds = stringify(resource.tagIds);
  const projects = stringify(resource.projects);

  return `mutation {
      createExpertiseResource(
        
        resourceData: {
          ${imageUrl}
          ${creatorOrganizationId}
          expertiseArea: "${escapeSingleAndDoubleQuotes(resource.attributes.expertiseArea)}"
          expertiseLocation: [${stringToList(resource.attributes.expertiseLocation)}]
          description: [${stringToList(resource.attributes.description)}]
          name: "${escapeSingleAndDoubleQuotes(resource.attributes.name)}"
          countryId: ${resource.attributes.countryId}
          city: "${escapeSingleAndDoubleQuotes(resource.attributes.city)}"
          email: "${escapeSingleAndDoubleQuotes(resource.attributes.email)}"
          tagIds: ${tagIds}
          projects: ${projects}
        }
        cognitoId: "${cognitoId}"
      ){
        resource {
          ${expertiseResourceData}
        }
        isRequestSuccessful
      }
    }`;
}

export function updateExpertiseResourceMutation(resource, cognitoId) {
  const imageUrl = resource?.imageUrl != undefined ? `imageUrl: "${resource.imageUrl}"` : '';
  const expertiseArea =
    resource?.expertiseArea != undefined
      ? `expertiseArea: "${escapeSingleAndDoubleQuotes(resource.expertiseArea)}"`
      : '';
  const expertiseLocation =
    resource?.expertiseLocation != undefined ? `expertiseLocation: [${stringToList(resource.expertiseLocation)}]` : '';
  const description = resource?.description != undefined ? `description: [${stringToList(resource.description)}]` : '';
  const name = resource?.name != undefined ? `name: "${escapeSingleAndDoubleQuotes(resource.name)}"` : '';
  const countryId = resource?.countryId != undefined ? `countryId: ${resource.countryId}` : '';
  const city = resource?.city != undefined ? `city: "${escapeSingleAndDoubleQuotes(resource.city)}"` : '';
  const email = resource?.email != undefined ? `email: "${escapeSingleAndDoubleQuotes(resource.email)}"` : '';

  return `mutation {
    updateExpertiseResource  (
      expertiseResource:{
        id: ${resource.pId}
        ${imageUrl}
        ${expertiseArea}
        ${expertiseLocation}
        ${description}
        ${name}
        ${countryId}
        ${city}
        ${email}
      }
      cognitoId: "${cognitoId}"
    ){
       isRequestSuccessful
       resource {
        ${expertiseResourceData}
      }
    }
  }`;
}

export function deleteExpertiseResourceMutation(pId, cognitoId) {
  return `mutation {
    deleteExpertiseResource (
        id: ${pId}
        cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
    }
  }`;
}

export function createOrUpdateExpertiseResourceTagsMutation(resourceId, tagIds, cognitoId) {
  const ids = stringify(tagIds);
  return `mutation {
    createOrUpdateExpertiseResourceTags (
      id: ${resourceId}
      tagIds: ${ids}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      tags {
        name
        id
        pId
        type
        url
      }
      resourceId
    }
  }`;
}

export function createExpertiseProjectMutation(project, cognitoId) {
  const organizationId = project?.organizationId != undefined ? `organizationId: ${project.organizationId}` : '';
  const linkId = project?.linkId != undefined && project?.linkId != -1 ? `linkId: ${project.linkId}` : '';
  const knowledgeId =
    project?.knowledgeId != undefined && project?.knowledgeId != -1 ? `knowledgeId: ${project.knowledgeId}` : '';
  const workplace =
    project.workplace != (undefined || null) ? `workplace: "${escapeSingleAndDoubleQuotes(project.workplace)}"` : '';
  const description =
    project?.description != undefined
      ? `description: [${stringToList(escapeSingleAndDoubleQuotes(project.description))}]`
      : '';
  return `mutation {
    createExpertiseProject (
      projectData: {
        ${organizationId}
        ${workplace}
        ${linkId}
        ${knowledgeId}
        project: "${escapeSingleAndDoubleQuotes(project.project)}"
        resourceId: ${project.resourceId}
        ${description}
      }
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      project {
        id
        pId
        project
        organizationId
        workplace
        resourceId
        description
        linkId
        knowledgeId
      }

    }
  }`;
}

export function updateExpertiseProjectMutation(project, cognitoId) {
  const organizationId = project?.organizationId != undefined ? `organizationId: ${project.organizationId}` : '';
  const linkId = project?.linkId != undefined && project?.linkId != -1 ? `linkId: ${project.linkId}` : '';
  const knowledgeId =
    project?.knowledgeId != undefined && project?.knowledgeId != -1 ? `knowledgeId: ${project.knowledgeId}` : '';
  const workplace =
    project.workplace != (undefined || null) ? `workplace: "${escapeSingleAndDoubleQuotes(project.workplace)}"` : '';
  const projectStr =
    project.project != (undefined || null) ? `project: "${escapeSingleAndDoubleQuotes(project.project)}"` : '';
  const description =
    project?.description != undefined
      ? `description: [${stringToList(escapeSingleAndDoubleQuotes(project.description))}]`
      : '';

  return `mutation {
    updateExpertiseProject (
      projectData: {
        id: ${project.pId}
        ${organizationId}
        ${workplace}
        ${projectStr}
        ${description}
        ${linkId}
        ${knowledgeId}
      }
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      project {
        id
        pId
        project
        organizationId
        workplace
        resourceId
        description
        linkId
        knowledgeId
      }
    }
  }`;
}

export function deleteExpertiseProjectMutation(pId, cognitoId) {
  return `mutation {
    deleteExpertiseProject (
        id: ${pId}
        cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
    }
  }`;
}

export function createKnowledgeResourceMutation(knowledge, cognitoId) {
  const imageUrl = knowledge?.imageUrl != undefined ? `imageUrl: "${knowledge?.imageUrl}"` : '';
  const contentUrl = knowledge?.contentUrl != undefined ? `contentUrl: "${knowledge?.contentUrl}"` : '';
  const creatorOrganizationId =
    knowledge?.creatorOrganizationId != undefined ? `creatorOrganizationId: ${knowledge?.creatorOrganizationId}` : '';
  const tagIds = stringify(knowledge.tagIds);
  const description =
    knowledge?.description != undefined
      ? `description: [${stringToList(escapeSingleAndDoubleQuotes(knowledge.description))}]`
      : '';

  return `mutation {
    createKnowledgeResource(
      resourceData: {
        ${contentUrl}
        ${description}
        ${creatorOrganizationId}
        name: "${escapeSingleAndDoubleQuotes(knowledge.name)}"
        documentType: "${knowledge.documentType}"
        authorRights: "${knowledge.authorRights}"
        ${imageUrl}
        tagIds: ${tagIds}
      }
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      resource {
       ${knowledgeResourceData}
      }
    }
  }`;
}

export function updateKnowledgeResourceMutation(knowledgeAttributes, cognitoId) {
  const imageUrl = knowledgeAttributes?.imageUrl != undefined ? `imageUrl: "${knowledgeAttributes.imageUrl}"` : '';
  const contentUrl =
    knowledgeAttributes?.contentUrl != undefined ? `contentUrl: "${knowledgeAttributes.contentUrl}"` : '';
  const linkUrl = knowledgeAttributes?.linkUrl != undefined ? `linkUrl: "${knowledgeAttributes.linkUrl}"` : '';
  const name =
    knowledgeAttributes?.name != undefined ? `name: "${escapeSingleAndDoubleQuotes(knowledgeAttributes.name)}"` : '';
  const description =
    knowledgeAttributes?.description != undefined
      ? `description: [${stringToList(escapeSingleAndDoubleQuotes(knowledgeAttributes.description))}]`
      : '';
  const documentType =
    knowledgeAttributes?.documentType != undefined ? `documentType: "${knowledgeAttributes.documentType}"` : '';
  const authorRights =
    knowledgeAttributes?.authorRights != undefined ? `authorRights: "${knowledgeAttributes.authorRights}"` : '';

  return `mutation {
  updateKnowledgeResource  (
      knowledgeResource:{
        id: ${knowledgeAttributes.pId}
        ${imageUrl}
        ${contentUrl}
        ${linkUrl}
        ${name}
        ${description}
        ${documentType}
        ${authorRights}
    }
    cognitoId: "${cognitoId}"
  ){
     isRequestSuccessful
     knowledgeResource {
      ${knowledgeResourceData}
    }
  }
}`;
}

export function deleteKnowledgeResourceMutation(pId, cognitoId) {
  return `mutation {
  deleteKnowledgeResource (
      id: ${pId}
      cognitoId: "${cognitoId}"
  ){
    isRequestSuccessful
  }
}`;
}

export function createOrUpdateKnowledgeResourceTagsMutation(knowledgeId, tagIds, cognitoId) {
  return `mutation {
  createOrUpdateKnowledgeResourceTags (
    id: ${knowledgeId}
    tagIds: ${tagIds}
    cognitoId: "${cognitoId}"
  ){
    isRequestSuccessful
    tags {
      name
      id
      pId
      type
      url
    }
    knowledgeResourceId
  }
}`;
}

export function createLinkResourceMutation(link, cognitoId) {
  const imageUrl = link?.attributes?.imageUrl != undefined ? `imageUrl: "${link?.attributes?.imageUrl}"` : '';
  const tagIds = stringify(link.tagIds);
  const creatorOrganizationId =
    link?.attributes?.creatorOrganizationId != undefined
      ? `creatorOrganizationId: ${link?.attributes?.creatorOrganizationId}`
      : '';

  return `mutation {
      createLinkResource(
        resourceData: {
          link: "${link.attributes.link}"
          ${creatorOrganizationId}
          name: "${escapeSingleAndDoubleQuotes(link.attributes.name)}"
          description: [${stringToList(link.attributes.description)}]
          ${imageUrl}
          tagIds: ${tagIds}
        }
        cognitoId: "${cognitoId}"
      ){
      resource {
        ${linkResourceData}
      }
      isRequestSuccessful
    }
  }`;
}

export function updateLinkResourceMutation(link, cognitoId) {
  const imageUrl = link?.imageUrl != undefined ? `imageUrl: "${link.imageUrl}"` : '';
  const linkStr = link?.link != undefined ? `link: "${link.link}"` : '';
  const name = link?.name != undefined ? `name: "${escapeSingleAndDoubleQuotes(link.name)}"` : '';
  const description = link?.description != undefined ? `description: [${stringToList(link.description)}]` : '';

  return `mutation {
    updateLinkResource  (
      linkResource:{
        id: ${link.pId}
        ${imageUrl}
        ${linkStr}
        ${name}
        ${description}
      }
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      resource {
        ${linkResourceData}
      }
    }
  }`;
}

export function deleteLinkResourceMutation(pId, cognitoId) {
  return `mutation {
    deleteLinkResource (
        id: ${pId}
        cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
    }
  }`;
}

export function createOrUpdateLinkResourceTagsMutation(linkId, tagIds, cognitoId) {
  const ids = stringify(tagIds);
  return `mutation {
    createOrUpdateLinkResourceTags (
      id: ${linkId}
      tagIds: ${ids}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      tags {
        name
        id
        pId
        type
        url
      }
      resourceId
    }
  }`;
}

export function addLinkResourcesViewMutation(resourceId, cognitoId) {
  return `mutation {
    addLinkResourcesView (
      id: ${resourceId}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      resource {
        ${linkResourceData}
      }
    }
  }`;
}

export function addExpertiseResourcesViewMutation(resourceId, cognitoId) {
  return `mutation {
    addExpertiseResourcesView (
      id: ${resourceId}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      resource {
        ${expertiseResourceData}
      }
    }
  }`;
}

export function createToolResourceMutation(tool, cognitoId) {
  const tagIds = stringify(tool.tagIds);
  const imageUrl =
    tool?.attributes?.imagerl != undefined ? `imageUrl: "${tool?.attributes?.imageUrl}"` : `imageUrl: ""`;
  const knowledgeIds = stringify(tool.knowledgeIds);
  const linkIds = stringify(tool.linkIds);
  const expertiseIds = stringify(tool.expertiseIds);
  const creatorOrganizationId =
    tool?.attributes?.creatorOrganizationId != undefined
      ? `creatorOrganizationId: ${tool?.attributes?.creatorOrganizationId}`
      : '';

  return `mutation {
    createToolResource(
      resourceData: {
        ${creatorOrganizationId}
        name: "${escapeSingleAndDoubleQuotes(tool.attributes.name)}"
        description: [${stringToList(tool.attributes.description)}]
        tagIds: ${tagIds}
        ${imageUrl}
        knowledgeIds: ${knowledgeIds}
        linkIds: ${linkIds}
        expertiseIds: ${expertiseIds}
      }
      cognitoId: "${cognitoId}"
    ){
      resource {
        ${toolResourceData}
      }
      isRequestSuccessful
  }
}`;
}

export function updateToolResourceMutation(tool, cognitoId) {
  const imageUrl = tool?.imageUrl != undefined ? `imageUrl: "${tool.imageUrl}"` : '';
  const name = tool?.name != undefined ? `name: "${escapeSingleAndDoubleQuotes(tool.name)}"` : '';
  const description = tool?.description != undefined ? `description: [${stringToList(tool.description)}]` : '';

  return `mutation {
  updateToolResource  (
    toolResource:{
      id: ${tool.pId}
      ${imageUrl}
      ${name}
      ${description}
    }
    cognitoId: "${cognitoId}"
  ){
    isRequestSuccessful
    resource {
      ${toolResourceData}
    }
  }
}`;
}

export function deleteToolResourceMutation(pId, cognitoId) {
  return `mutation {
  deleteToolResource (
      id: ${pId}
      cognitoId: "${cognitoId}"
  ){
    isRequestSuccessful
  }
}`;
}

export function createOrUpdateToolResourceTagsMutation(linkId, tagIds, cognitoId) {
  const ids = stringify(tagIds);
  return `mutation {
  createOrUpdateToolResourceTags (
    id: ${linkId}
    tagIds: ${ids}
    cognitoId: "${cognitoId}"
  ){
    isRequestSuccessful
    tags {
      name
      id
      pId
      type
      url
    }
    resourceId
  }
}`;
}

export function addToolResourcesViewMutation(resourceId, cognitoId) {
  return `mutation {
    addToolResourceView (
      id: ${resourceId}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      resource {
        ${toolResourceData}
      }
    }
  }`;
}

export function addKnowledgeResourcesViewMutation(resourceId, cognitoId) {
  return `mutation {
    addKnowledgeResourceView (
      id: ${resourceId}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      resource {
        ${knowledgeResourceData}
      }
    }
  }`;
}

export function createOrUpdateToolResourceResourcesMutation(toolId, knowledgeIds, linkIds, expertiseIds, cognitoId) {
  const knowledgeIdsStr = stringify(knowledgeIds);
  const linkIdsStr = stringify(linkIds);
  const expertiseIdsStr = stringify(expertiseIds);

  return `mutation {
  createOrUpdateToolResourceResources (
    resourcesData: {
      id: ${toolId}
      knowledgeIds: ${knowledgeIdsStr}
      linkIds: ${linkIdsStr}
      expertiseIds: ${expertiseIdsStr}
    }
    cognitoId: "${cognitoId}"
  ){
    isRequestSuccessful
    resource {
      ${toolResourceData}
    }
  }
}`;
}

export function addToolResourcesDocument(elementId, url, title, cognitoId) {
  const titleStr = title != undefined ? `title: "${escapeSingleAndDoubleQuotes(title)}"` : '';
  return `mutation {
    addToolResourcesDocument (
      elementId: ${elementId}
      url: "${url}"
      ${titleStr}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      document {
        pId
        title
        url
        elementId
      }
    }
  }`;
}

export function updateToolResourcesDocument(id, title, cognitoId) {
  const titleStr = title != undefined ? `title: "${escapeSingleAndDoubleQuotes(title)}"` : '';
  return `mutation {
    updateToolResourcesDocument (
      id: ${id}
      ${titleStr}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      document {
        pId
        title
        url
        elementId
      }
    }
  }`;
}

export function deleteToolResourcesDocument(id, cognitoId) {
  return `mutation {
    deleteToolResourcesDocument (
      id: ${id}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
    }
  }`;
}

export function addToolResourceDocumentsAndImage(resourceId, documents, imageUrl, cognitoId) {
  const documentsStr = documents != undefined ? `documents: ${documents}` : '';
  const imageUrlStr = imageUrl != undefined ? `imageUrl: "${imageUrl}"` : '';
  return `mutation {
    addNewToolResourcesDocumentsAndImage (
      newResourceInputData: {
        ${documentsStr}
        ${imageUrlStr}
        id: ${resourceId}
      }
    cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      resource {
        ${toolResourceData}
      }
    }
  }`;
}
