import Dexie, { Table } from 'dexie';

import { ISignupState } from 'core/models/Models';

interface ISignupData {
  id?: string;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  position: string;
  organizationId?: number;
  organizationName: string;
  imageUrl: string;
  tagIds: number[];
  invitationId: string;
  confirmedEmail: boolean;
  state: ISignupState;
}

const PERSISTED_ID = '3b308007-7033-c7b4-f9e8-f7e98ee17f5e';

class ZdruzeneIDB extends Dexie {
  public signupData!: Table<ISignupData, string>;

  public constructor() {
    super('ZdruzeneIDB');
    this.version(1).stores({
      signupData: '&id'
    });
  }
}

const idb = new ZdruzeneIDB();
// idb.open().catch((err) => console.warn('Failed to open db: ', err));

export const getSignupData = async () => {
  if (!idb.isOpen()) {
    await idb.open();
  }
  return idb
    .transaction('r', 'signupData', async () => await idb.signupData.toArray())
    .catch((err) => {
      console.warn(err);
    });
};

export const upsertSignupData = async (data: ISignupData) => {
  if (!idb.isOpen()) {
    await idb.open();
  }
  return idb
    .transaction('rw', 'signupData', async () => {
      const idbData = await idb.signupData.toArray();
      if (idbData.length === 0) {
        return await idb.signupData.add(
          {
            id: PERSISTED_ID,
            ...data
          },
          PERSISTED_ID
        );
      }
      return await idb.signupData.put(
        {
          ...idbData[0],
          ...data
        },
        idbData[0].id
      );
    })
    .catch((err) => {
      console.warn('Fail to put into idb', err);
    });
};

export const resetDb = () => {
  return idb.transaction('rw', idb.signupData, async () => {
    await Promise.all(idb.tables.map((table) => table.clear()));
  });
};
