import { action, createRequestTypes } from 'utils/ReduxHelpers';

import { GET, POST, PUT, DELETE } from 'utils/Constants';
import { IComment, IContest, IDocument, ITag } from 'core/models/Models';

export const CONTEST = createRequestTypes('CONTEST', [GET, POST, PUT, DELETE]);
export const CONTESTS = createRequestTypes('CONTESTS', [GET]);
export const SEARCH_CONTEST = createRequestTypes('SEARCH_CONTEST', [GET]);
export const CONTEST_DOCUMENT = createRequestTypes('CONTEST_DOCUMENT', [POST, PUT, DELETE]);
export const CONTEST_TAGS = createRequestTypes('CONTEST_TAGS', [POST]);
export const CONTEST_VISIBILITY = createRequestTypes('CONTEST_VISIBILITY', [POST]);
export const CONTEST_COMMENTS = createRequestTypes('CONTEST_COMMENTS', [DELETE, POST, PUT]);
export const CONTEST_LIKE = createRequestTypes('CONTEST_LIKE', [POST]);
export const CONTEST_COMMENT_LIKE = createRequestTypes('CONTEST_COMMENT_LIKE', [POST]);
export const CONTEST_VIEW = createRequestTypes('CONTEST_VIEW', [POST]);
export const CONTEST_APPLICATION_LIKE = createRequestTypes('CONTEST_APPLICATION_LIKE', [POST]);
export const CONTEST_ACTION_LIKE = createRequestTypes('CONTEST_ACTION_LIKE', [POST]);
export const CONTEST_CONTEST_APPLICATION_VOTE = createRequestTypes('CONTEST_CONTEST_APPLICATION_VOTE', [POST]);
export const CONTEST_ACTION_VOTE = createRequestTypes('CONTEST_ACTION_COTE', [POST]);
export const ELIGIBLE_CONTESTS = createRequestTypes('ELIGIBLE_CONTESTS', [GET]);

export const searchContests = {
  request: (name: string, tagIds?: Array<number>) => action(SEARCH_CONTEST.GET.REQUEST, { name, tagIds }),
  success: (payload: any) => action(SEARCH_CONTEST.GET.SUCCESS, payload),
  failure: (error: any) => action(SEARCH_CONTEST.GET.FAILURE, error)
};

export const getContests = {
  request: () => action(CONTESTS.GET.REQUEST),
  success: (payload: IContest[]) => action(CONTESTS.GET.SUCCESS, payload),
  failure: (error: string) => action(CONTESTS.GET.FAILURE, error)
};

export const getContest = {
  request: (id: number) => action(CONTEST.GET.REQUEST, { id }),
  success: (payload: IContest) => action(CONTEST.GET.SUCCESS, payload),
  failure: (error: string) => action(CONTEST.GET.FAILURE, error)
};

export const getEligibleContests = {
  request: (actionId: number) => action(ELIGIBLE_CONTESTS.GET.REQUEST, { actionId }),
  success: (payload: IContest) => action(ELIGIBLE_CONTESTS.GET.SUCCESS, payload),
  failure: (error: string) => action(ELIGIBLE_CONTESTS.GET.FAILURE, error)
};

export const createContest = {
  request: (contestData: any) => action(CONTEST.POST.REQUEST, { contestData }),
  success: (payload: IContest) => action(CONTEST.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST.POST.FAILURE, error)
};

export const updateContest = {
  request: (data: any) => action(CONTEST.PUT.REQUEST, { data }),
  success: (payload: IContest) => action(CONTEST.PUT.SUCCESS, payload),
  failure: (error: any) => action(CONTEST.PUT.FAILURE, error)
};

export const deleteContest = {
  request: (id: number) => action(CONTEST.DELETE.REQUEST, { id }),
  success: (id: number) => action(CONTEST.DELETE.SUCCESS, { id }),
  failure: (error: any) => action(CONTEST.DELETE.FAILURE, error)
};

export const addDocument = {
  request: (document: any) => action(CONTEST_DOCUMENT.POST.REQUEST, { document }),
  success: (payload: IContest) => action(CONTEST_DOCUMENT.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_DOCUMENT.POST.FAILURE, error)
};

export const updateDocument = {
  request: (document: IDocument) => action(CONTEST_DOCUMENT.PUT.REQUEST, { document }),
  success: (payload: any) => action(CONTEST_DOCUMENT.PUT.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_DOCUMENT.PUT.FAILURE, error)
};

export const removeDocument = {
  request: (document: IDocument) => action(CONTEST_DOCUMENT.DELETE.REQUEST, { document }),
  success: (payload: any) => action(CONTEST_DOCUMENT.DELETE.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_DOCUMENT.DELETE.FAILURE, error)
};

export const updateContestTags = {
  request: (id: number, tags: Array<ITag>) => action(CONTEST_TAGS.POST.REQUEST, { id, tags }),
  success: (payload: any) => action(CONTEST_TAGS.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_TAGS.POST.FAILURE, error)
};

export const updateContestVisibility = {
  request: (data: any) => action(CONTEST_VISIBILITY.POST.REQUEST, { data }),
  success: (payload: any) => action(CONTEST_VISIBILITY.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_VISIBILITY.POST.FAILURE, error)
};

export const addContestComment = {
  request: (comment: IComment) => action(CONTEST_COMMENTS.POST.REQUEST, { comment }),
  success: (payload: any) => action(CONTEST_COMMENTS.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_COMMENTS.POST.FAILURE, error)
};

export const updateContestComment = {
  request: (comment: IComment) => action(CONTEST_COMMENTS.PUT.REQUEST, { comment }),
  success: (payload: any) => action(CONTEST_COMMENTS.PUT.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_COMMENTS.PUT.FAILURE, error)
};

export const removeContestComment = {
  request: (comment: IComment) => action(CONTEST_COMMENTS.DELETE.REQUEST, { comment }),
  success: (payload: any) => action(CONTEST_COMMENTS.DELETE.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_COMMENTS.DELETE.FAILURE, error)
};

export const likeOrUnlikeContest = {
  request: (contestId, userId) => action(CONTEST_LIKE.POST.REQUEST, { contestId, userId }),
  success: (payload: any) => action(CONTEST_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_LIKE.POST.FAILURE, error)
};

export const likeOrUnlikeContestComment = {
  request: (comment: IComment, userId) => action(CONTEST_COMMENT_LIKE.POST.REQUEST, { comment, userId }),
  success: (payload: any) => action(CONTEST_COMMENT_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_COMMENT_LIKE.POST.FAILURE, error)
};

export const addContestsView = {
  request: (contestId) => action(CONTEST_VIEW.POST.REQUEST, { contestId }),
  success: (payload: any) => action(CONTEST_VIEW.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_VIEW.POST.FAILURE, error)
};

export const likeOrUnlikeContestContestApplication = {
  request: (contestApplicationId) => action(CONTEST_APPLICATION_LIKE.POST.REQUEST, { contestApplicationId }),
  success: (payload: any) => action(CONTEST_APPLICATION_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION_LIKE.POST.FAILURE, error)
};

export const likeOrUnlikeContestAction = {
  request: (actionId) => action(CONTEST_ACTION_LIKE.POST.REQUEST, { actionId }),
  success: (payload: any) => action(CONTEST_ACTION_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_ACTION_LIKE.POST.FAILURE, error)
};

export const backContestContestApplication = {
  request: (contestApplicationId, userId, contestId) =>
    action(CONTEST_CONTEST_APPLICATION_VOTE.POST.REQUEST, { contestApplicationId, userId, contestId }),
  success: (payload: any) => action(CONTEST_CONTEST_APPLICATION_VOTE.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_CONTEST_APPLICATION_VOTE.POST.FAILURE, error)
};

export const backContestAction = {
  request: (actionId) => action(CONTEST_ACTION_VOTE.POST.REQUEST, { actionId }),
  success: (payload: any) => action(CONTEST_ACTION_VOTE.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_ACTION_VOTE.POST.FAILURE, error)
};
