import { action, createRequestTypes } from 'utils/ReduxHelpers';

import { GET } from 'utils/Constants';
import { IPersonalProfile, IUser } from 'core/models/Models';

export const USERS = createRequestTypes('USERS', [GET]);
export const USER_PROFILE = createRequestTypes('USER_PROFILE', [GET]);

export const getUsers = {
  request: () => action(USERS.GET.REQUEST),
  success: (payload: Array<IUser>) => action(USERS.GET.SUCCESS, payload),
  failure: (error: any) => action(USERS.GET.FAILURE, error)
};

export const getUserProfile = {
  request: (id: number) => action(USER_PROFILE.GET.REQUEST, { id }),
  success: (payload: IPersonalProfile) => action(USER_PROFILE.GET.SUCCESS, payload),
  failure: (error: any) => action(USER_PROFILE.GET.FAILURE, error)
};
