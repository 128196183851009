export const AppRoutes = {
  init: { link: '/', name: 'Init' },
  home: { link: '/dom', name: 'Dom' },
  login: { link: '/login', name: 'Login' },
  profile: { link: '/licni-profil', name: 'Lični profil' },
  memberProfile: { link: '/profil-clanice/:profileId', name: 'Profil članice' },
  actions: { link: '/akcije', name: 'Akcije' },
  contestApplication: { link: '/prijave/:id', name: 'Prijava na konkurs' },
  createContestApplication: { link: '/nova-prijava', name: 'Nova prijava na konkurs' },
  createAction: { link: '/nova-akcija', name: 'Nova akcija' },
  viewEditAction: { link: '/akcije/:id', name: 'Akcija' },
  calendar: { link: '/kalendar', name: 'Kalendar' },
  resources: { link: '/resursi', name: 'Resursi' },
  contests: { link: '/konkursi', name: 'Konkursi' },
  contest: { link: '/konkursi/:id', name: 'Konkurs' },
  createContest: { link: '/novi-konkurs', name: 'Novi konkurs' },
  createExpertiseResource: { link: '/nova-ekspertiza', name: 'Novi ekspertski profil' },
  expertiseResources: { link: '/ekspertize', name: 'Ekspertski profili' },
  expertiseResource: { link: '/ekspertize/:id', name: 'Ekspertski profil' },
  createToolResource: { link: '/nova-alatka', name: 'Nova alatka' },
  toolResource: { link: '/alatke/:id', name: 'Alatke' },
  createKnowledge: { link: '/novo-znanje', name: 'Novo znanje' },
  knowledges: { link: '/znanje', name: 'Znanja' },
  knowledge: { link: '/znanje/:id', name: 'Znanje' },
  settings: { link: '/podesavanja', name: 'Podešavanja' },
  forum: { link: '/forum', name: 'Forum' },
  logout: { link: '/logout', name: 'Logout' },
  signup: { link: '/signup/:step', name: 'Signup' },
  verification: { link: '/verification', name: 'Verifikacija' },
  newOrganization: { link: '/nova-organizacija', name: 'Nova organizacija' },
  organization: { link: '/organizacija/:id', name: 'Organizacija' },
  noAccount: { link: '/nalog/:action', name: 'Account' },
  forgotPassword: { link: '/zaboravljena-lozinka', name: 'Zaboravljena lozinka' },
  forgotPasswordWithParams: { link: '/zaboravljena-lozinka/:status', name: 'Zaboravljena lozinka' },
  changePassword: { link: '/change-password', name: 'Promena lozinke' },
  addavatar: { link: '/add-avatar', name: 'Dodaj avatar' },
  about: { link: '/o-platformi', name: 'O platformi' },
  privacy: { link: '/polisa-privatnosti', name: 'Polisa privatnosti' },
  rules: { link: '/pravila', name: 'Pravila ponašanja' },
  values: { link: '/vrednosti', name: 'Vrednosti' },
  management: { link: '/upravljanje-platformom', name: 'Upravljanje platformom' },
  questions: { link: '/pitanja', name: 'Često postavljana pitanja' },
  code: { link: '/kod', name: 'Programerski kod' },
  design: { link: '/dizajn', name: 'Dizajnerski fajlovi' },
  admin: { link: 'http://admin.zdruzene.org:81/admin/', name: 'Admin pristup' },
  howto: { link: '/uputstvo', name: 'Uputstvo za korišćenje platforme' },
  search: { link: '/pretraga/:type', name: 'Pretraga' },
  notfound: { link: '/not-found', name: 'Stranica nije nađena' },

  conditions: { link: '/uslovi-koriscenja', name: 'Uslovi korišćenja' },
  transparency: { link: '/transparentnost', name: 'Transparentnost' },
  docs: { link: '/dokumentacija', name: 'Programerska dokumentacija' },
  files: { link: '/fajlovi', name: 'Dizajnerski fajlovi' }
};
