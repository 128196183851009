import {
  ORGANIZATIONS,
  getOrganizations,
  ORGANIZATION,
  createOrganization,
  getOrganization,
  ORGANIZATION_INFO,
  upateOrganizationInfo,
  updateOrganizationSocialNetworks,
  ORGANIZATION_SOCIAL_NETWORKS,
  ORGANIZATION_TAGS,
  updateOrganizationTags,
  ORGANIZATION_MEMBERS,
  updateOrganizationMembers,
  updateOrganizationProject,
  ORGANIZATION_PROJECT,
  createOrganizationProject,
  deleteOrganizationProject,
  ORGANIZATIONS_OPTIMIZED,
  getOptimizedOrganizations
} from '../actions/OrganizationActions';
import { all, put, takeLatest } from 'redux-saga/effects';
import { API, Auth, Storage } from 'aws-amplify';
import { getOrganizationQuery, listOrganizations, listOrganizationsOptimized } from 'api/graphql/queries';
import {
  addNewOrganizationsImage,
  createNewOrganization,
  createOrganizationProjectMutation,
  deleteOrganizationProjectMutation,
  updateMembersMutation,
  updateOrganizationMutation,
  updateOrganizationProjectMutation,
  updateOrganizationSocialNetoworksMutation,
  updateOrganizationTagsMutation
} from 'api/graphql/mutations';
import * as uuid from 'uuid';
import * as Alert from 'utils/Alerts';
import i18n from 'utils/i18n';
import { globalNavigate } from 'utils/global-history';

export function* fetchOrganizationsSaga() {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: listOrganizations()
      }
    };

    const {
      data: { allOrganizations }
    } = yield API.post('OrganizationAPI', '/graphql?query', requestInfo);

    yield put(getOrganizations.success(allOrganizations));
  } catch (e: any) {
    yield put(getOrganizations.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getOrganizations'));
  }
}

export function* fetchOrganizationsOptimizedSaga() {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: listOrganizationsOptimized()
      }
    };

    const {
      data: { allOrganizations }
    } = yield API.post('OrganizationAPI', '/graphql?query', requestInfo);

    yield put(getOptimizedOrganizations.success(allOrganizations));
  } catch (e: any) {
    yield put(getOptimizedOrganizations.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getOrganizations'));
  }
}

export function* createOrganizationSaga({ payload: { organization } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      body: {
        query: createNewOrganization(organization, cognitoId)
      }
    };
    let response = yield API.post('OrganizationAPI', '/graphql', requestInfo);
    if (!response?.data?.createOrganization?.isRequestSuccessful) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('Duplicate entry')) {
        yield Alert.setErrorAlert(i18n.t('api.errors.organizationExists'));
        throw new Error(response);
      }
    }

    let newOrganization = response.data?.createOrganization?.organization;
    const organizationId = newOrganization?.attributes?.id;

    const imageFile = organization?.attributes?.imageFile;
    if (imageFile) {
      const level = 'protected';
      const fileName = getS3Key(imageFile, organizationId);
      yield Storage.put(fileName, imageFile, { level: level });
      const imageUrl = yield Storage.get(fileName, { level: level });
      const requestInfo = {
        queryStringParameters: {
          query: addNewOrganizationsImage(organizationId, imageUrl)
        }
      };
      response = yield API.post('OrganizationAPI', '/graphql?query', requestInfo);
      if (!response?.data?.addNewOrganizationsImage?.isRequestSuccessful) {
        throw new Error(response);
      }
      const organizationAttributes = { ...newOrganization.attributes, imageUrl: imageUrl };
      newOrganization = { ...newOrganization, attributes: organizationAttributes };
    }

    yield put(createOrganization.success(newOrganization));
    globalNavigate(`/organizacija/${organizationId}`);
  } catch (e: any) {
    yield put(createOrganization.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createOrganization'));
  }
}

export function* updateOrganizationSocialNetworksSaga({ payload: { socialNetworks, organizationId } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: updateOrganizationSocialNetoworksMutation(organizationId, socialNetworks)
      }
    };
    const response = yield API.post('OrganizationAPI', '/graphql?query', requestInfo);

    if (response.errors) {
      throw new Error(response.errors);
    }
    const orgId = response.data.createOrUpdateOrganizationSocialNetworks.organizationId;
    const socNetworks = response.data.createOrUpdateOrganizationSocialNetworks.socialNetworks;
    yield put(updateOrganizationSocialNetworks.success(orgId, socNetworks));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateSocialNetworks'));
  } catch (e: any) {
    yield put(updateOrganizationSocialNetworks.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createOrganization'));
  }
}

export function* updateOrganizationInfoSaga({ payload: { data } }) {
  try {
    let newImageUrl = '';
    const imageUrl = data?.imageUrl;
    const imageFile = data?.imageFile;
    if (imageFile) {
      const level = 'protected';
      const imageFile = data?.imageFile;
      const fileName = getS3Key(imageFile, data.pId);
      yield Storage.put(fileName, imageFile, { level: level });
      newImageUrl = yield Storage.get(fileName, { level: level });
      data = { ...data, imageUrl: newImageUrl };
    }
    const requestInfo = {
      body: {
        query: updateOrganizationMutation(data)
      }
    };

    const response = yield API.post('OrganizationAPI', '/graphql', requestInfo);

    if (response.errors) {
      throw new Error(response.errors);
    }

    if (newImageUrl && imageUrl) {
      if (typeof imageUrl === 'string') {
        const fileName = getS3KeyFromUrl(imageUrl, data.pId);
        yield Storage.remove(fileName, { level: 'protected' });
      }
    }

    yield put(upateOrganizationInfo.success(response.data?.updateOrganization?.organization));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateOrganizationProfile'));
  } catch (e: any) {
    yield put(upateOrganizationInfo.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.upateOrganizationInfo'));
  }
}

export function* getOrganizationsSaga({ payload: { id } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getOrganizationQuery(id)
      }
    };
    const response = yield API.post('OrganizationAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }
    yield put(getOrganization.success(response.data?.organization));
  } catch (e: any) {
    yield put(getOrganization.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getOrganization'));
  }
}

export function* updateOrganizationTagsSaga({ payload: { tagIds, organizationId } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: updateOrganizationTagsMutation(organizationId, tagIds)
      }
    };
    const response = yield API.post('OrganizationAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }
    const orgId = response.data?.createOrUpdateOrganizationTags?.organizationId;
    const tags = response.data?.createOrUpdateOrganizationTags?.tags;
    yield put(updateOrganizationTags.success(orgId, tags));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateTags'));
  } catch (e: any) {
    yield put(updateOrganizationTags.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createOrganization'));
  }
}

export function* updateOrganizationMembersSaga({ payload: { members, organizationId } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: updateMembersMutation(members, organizationId)
      }
    };
    const response = yield API.post('OrganizationAPI', '/graphql?query', requestInfo);

    if (response.errors) {
      throw new Error(response.errors);
    }

    const orgId = response.data?.createOrDeleteOrganizationMembers?.organizationId;
    const newMembers = response.data?.createOrDeleteOrganizationMembers?.members;
    yield put(updateOrganizationMembers.success(orgId, newMembers));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateMembers'));
  } catch (e: any) {
    yield put(updateOrganizationMembers.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateMembers'));
  }
}

export function* createOrganizationProjectSaga({ payload: { project, organizationId } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: createOrganizationProjectMutation(project, organizationId)
      }
    };
    const response = yield API.post('OrganizationAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }

    const createdProject = response.data?.createOrganizationProject?.project;
    yield put(createOrganizationProject.success(createdProject, organizationId));
    yield Alert.setSuccessAlert(i18n.t('api.success.createProject'));
  } catch (e: any) {
    yield put(createOrganizationProject.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createProject'));
  }
}

export function* updateOrganizationProjectSaga({ payload: { project, organizationId } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: updateOrganizationProjectMutation(project)
      }
    };
    const response = yield API.post('OrganizationAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }

    const updatedProject = response.data?.updateOrganizationProject?.project;
    yield put(updateOrganizationProject.success(updatedProject, organizationId));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateProject'));
  } catch (e: any) {
    yield put(updateOrganizationProject.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateProject'));
  }
}

export function* deleteOrganizationProjectSaga({ payload: { id, organizationId } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: deleteOrganizationProjectMutation(id)
      }
    };
    const response = yield API.post('OrganizationAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }

    const data = response?.data?.deleteOrganizationProject;
    yield put(deleteOrganizationProject.success(data?.projectId, organizationId));
    yield Alert.setSuccessAlert(i18n.t('api.success.deleteProject'));
  } catch (e: any) {
    yield put(deleteOrganizationProject.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteProject'));
  }
}

function getS3Key(file, organizationId) {
  const fileName = file.name;
  const fileType = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
  const randomId = uuid.v4();
  return `Organizations/${organizationId}/${randomId}${fileType}`;
}

function getS3KeyFromUrl(url, organiaztionId) {
  const name = url.substring(url.lastIndexOf('/') + 1, url.length);
  return `Organizations/${organiaztionId}/${name}`;
}

function* organizationSaga() {
  yield all([
    takeLatest(ORGANIZATIONS.GET.REQUEST, fetchOrganizationsSaga),
    takeLatest(ORGANIZATIONS_OPTIMIZED.GET.REQUEST, fetchOrganizationsOptimizedSaga),
    takeLatest(ORGANIZATION.POST.REQUEST, createOrganizationSaga),
    takeLatest(ORGANIZATION.GET.REQUEST, getOrganizationsSaga),
    takeLatest(ORGANIZATION_INFO.POST.REQUEST, updateOrganizationInfoSaga),
    takeLatest(ORGANIZATION_SOCIAL_NETWORKS.POST.REQUEST, updateOrganizationSocialNetworksSaga),
    takeLatest(ORGANIZATION_TAGS.POST.REQUEST, updateOrganizationTagsSaga),
    takeLatest(ORGANIZATION_MEMBERS.POST.REQUEST, updateOrganizationMembersSaga),
    takeLatest(ORGANIZATION_PROJECT.PUT.REQUEST, updateOrganizationProjectSaga),
    takeLatest(ORGANIZATION_PROJECT.POST.REQUEST, createOrganizationProjectSaga),
    takeLatest(ORGANIZATION_PROJECT.DELETE.REQUEST, deleteOrganizationProjectSaga)
  ]);
}

export default organizationSaga;
