import {
  CALENDAR_EVENT,
  CALENDAR_EVENTS,
  CALENDAR_EVENT_TYPES,
  CALENDAR_PARTICIPANTS
} from 'core/actions/CalendarActions';
import { IActivity, ICalendarEvent, ICalendarEventType } from 'core/models/Models';
import { produce } from 'immer';

export interface ICalendarState {
  calendarEvents: ICalendarEvent[];
  calendarEventTypes: ICalendarEventType[];
}

const initialState: ICalendarState = {
  calendarEvents: [],
  calendarEventTypes: []
};

const calendarReducer = (state = initialState, action: IActivity): ICalendarState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case CALENDAR_EVENTS.GET.SUCCESS:
        draft.calendarEvents = payload?.map((e) => ({
          ...e.attributes,
          start: new Date(Date.parse(e.attributes.start)),
          end: new Date(Date.parse(e.attributes.end)),
          combinedOrganizers: e?.combinedOrganizers,
          tags: e?.tags,
          participants: e?.participants
        }));
        break;
      case CALENDAR_EVENT.POST.SUCCESS:
        {
          const event = {
            ...payload?.attributes,
            start: new Date(Date.parse(payload.attributes.start)),
            end: new Date(Date.parse(payload.attributes.end)),
            combinedOrganizers: payload?.combinedOrganizers,
            tags: payload?.tags,
            participants: payload?.participants
          };
          draft.calendarEvents.push(event);
        }
        break;
      case CALENDAR_EVENT.PUT.SUCCESS:
      case CALENDAR_PARTICIPANTS.POST.SUCCESS:
        draft.calendarEvents = state?.calendarEvents?.map((e) =>
          e?.id === payload?.attributes?.id
            ? {
                ...payload.attributes,
                start: new Date(Date.parse(payload.attributes.start)),
                end: new Date(Date.parse(payload.attributes.end)),
                combinedOrganizers: payload?.combinedOrganizers,
                tags: payload?.tags,
                participants: payload?.participants
              }
            : e
        );
        break;
      case CALENDAR_EVENT.DELETE.SUCCESS:
        draft.calendarEvents = state.calendarEvents.filter((e) => e.id !== payload?.id);
        break;
      case CALENDAR_EVENT_TYPES.GET.SUCCESS:
        draft.calendarEventTypes = payload;
        break;
      default:
        return draft;
    }
  });

export default calendarReducer;
