import dateFormat from 'dateformat';

export const formatDateToMonthAndYear = (dbDateString) => {
  if (dbDateString == null || dbDateString === '') {
    return '';
  }
  const dateObj = dbDateString ? new Date(dbDateString) : new Date();

  const year = dateObj.toLocaleDateString('en', { year: 'numeric' });
  const month = dateObj.toLocaleDateString('en', { month: 'short' });
  return `${month} ${year}`;
};

export const localDatetime = (date) => {
  return new Date(date.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19);
};

export const convertStringToDateObject = (dateStr) => {
  return dateStr ? new Date(dateStr) : null;
};

export const formatDateObject = (dateObj) => {
  return dateObj ? new Date(dateObj).toLocaleDateString('sr-RS') : '';
};

export const formatDateAndTime = (dateStr) => {
  return dateStr ? dateFormat(new Date(dateStr), 'dd.mm.yyyy u H:MM') : '';
};

export const formatDateToDatabaseFormat = (dateObj) => {
  return dateObj ? dateObj.toJSON().slice(0, 10) : null;
};

export function isValidFacebookURL(url) {
  return /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(url);
}

export function isValidTwitterURL(url) {
  return /^(https?:\/\/)?((w{3}\.)?)twitter.com\/.*/i.test(url);
}

export function isValidInstagramURL(url) {
  return /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(url);
}

export function isValidLinkedinURL(url) {
  return /^(https?:\/\/)?((w{3}\.)?)linkedin.com\/.*/i.test(url);
}

export function isValidYoutubeURL(url) {
  return /^(https?:\/\/)?((w{3}\.)?)youtube.com\/.*/i.test(url);
}

export function isValidWebsiteURL(url) {
  return /^http(s*):\/\/(www\.)?([A-z]+)\.([A-z]{2,})/.test(url);
}

export function isValidEmailURL(url) {
  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(url);
}

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!#%&/,><’:;|_~`])\S{8,99}$/;

// export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

export const getRandomArbitrary = (min = 3, max = 20000) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export function decodeBase64(base64String) {
  return Buffer.from(base64String, 'base64').toString();
}

export function encodeBase64(stringToEncode) {
  return Buffer.from(stringToEncode, 'utf8').toString('base64');
}

export function navigateToExternalPage(url) {
  window.location.assign(url);
}

export function sortByDate(items) {
  if (items?.length) {
    const sorted = [...items];
    sorted.sort((item1, item2) => {
      if (item1.toDate === null && item2.toDate !== null) {
        return -1;
      }
      if (item2.toDate === null && item1.toDate !== null) {
        return 1;
      }
      if (item1.fromDate > item2.fromDate) {
        return -1;
      }
      if (item2.fromDate > item1.fromDate) {
        return 1;
      }
      if (item1.toDate < item2.toDate) {
        return -1;
      }
      if (item2.toDate < item1.toDate) {
        return 1;
      }

      return 0;
    });

    return sorted;
  }
  return [];
}

export function splitUrlOnLast(url: string) {
  if (url) {
    const urlSplited = url.split('?')[0].split('/');
    return urlSplited[urlSplited?.length - 1];
  }
  return '';
}

export function splitByFirstSpace(str) {
  const [firstName, ...lastName] = str.split(" ");
  return [firstName, lastName.join(" ")];
}

export function splitS3Url(url) {
  if (url != (null || undefined || '')) {
    const decodedUrl = decodeURIComponent(url);
    const identityRegex = new RegExp('eu-west-1:[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}');
    const identityMatch = decodedUrl.match(identityRegex);
    let key = '';
    let identity = '';
    if (identityMatch != null) {
      key = decodedUrl.split(identityMatch + '/')[1];
      identity = identityMatch[0] + '';
    } else {
      key = decodedUrl.split('public/')[1];
    }
    return [identity, key];
  }
  return ['', ''];
}

export function sortedInsert(array, attribute, element, decreasing) {
  array.splice(sortedIndex(array, attribute, element, decreasing), 0, element);
  return array;
}

function sortedIndex(array, attribute, element, decreasing) {
  let low = 0;
  let high = array.length;
  let elementAttribuValue =
    attribute != null ? (element?.attributes ? element.attributes[attribute] : element[attribute]) : Math.random();
  if (Array.isArray(elementAttribuValue)) {
    elementAttribuValue = elementAttribuValue.length;
  }

  while (low < high) {
    const mid = (low + high) >>> 1;
    let midAttributeValue =
      attribute != null
        ? array[mid]?.attributes
          ? array[mid].attributes[attribute]
          : array[mid][attribute]
        : Math.random();
    if (Array.isArray(midAttributeValue)) {
      midAttributeValue = midAttributeValue.length;
    }
    if (decreasing) {
      if (
        (midAttributeValue > elementAttribuValue && decreasing) ||
        (elementAttribuValue < midAttributeValue && !decreasing)
      )
        low = mid + 1;
      else high = mid;
    }
  }
  return low;
}

export function mergeDicts(dict1: Record<string, any[]>, dict2: Record<string, any[]>): Record<string, any[]> {
  const mergedDict = { ...dict1 };

  Object.keys(dict2).forEach(key => {
      if (Array.isArray(mergedDict[key]) && Array.isArray(dict2[key])) {
          mergedDict[key] = [...mergedDict[key], ...dict2[key]];
      } else {
          mergedDict[key] = dict2[key];
      }
  });

  return mergedDict;
}

export function areAllListsEmpty(obj) {
  return Object.values(obj).every(list => Array.isArray(list) && list.length === 0);
}


type ArrayType = Array<number | string>;
export const arrayDifference = (arr1: ArrayType, arr2: ArrayType): ArrayType => arr1.filter((x) => !arr2.includes(x));
export const arrayIntersection = (arr1: ArrayType, arr2: ArrayType): ArrayType => arr1.filter((x) => arr2.includes(x));
