import { all, put, takeLatest } from 'redux-saga/effects';
import {
  getAllResources as getAllResourcesMutation,
  getKnowledgeQuery,
  getPrimitiveResources as getPrimitiveResourcesQuery,
  searchResourcesQuery,
  getAllResourcesPaginated as getAllResourcesPaginatedQuery,
  getExpertiseResourcesPaginatedQuery,
  getKnowledgeResourcesPaginatedQuery,
  getLinkResourcesPaginatedQuery,
  getToolResourcesPaginatedQuery
} from 'api/graphql/queries';
import { API, Auth, Storage } from 'aws-amplify';
import i18n from 'utils/i18n';
import * as uuid from 'uuid';

import {
  createKnowledge,
  KNOWLEDGE_RESOURCE,
  addExpertiseResourcesView,
  addLinkResourcesView,
  createExpertiseProject,
  createExpertiseResource,
  createLinkResource,
  createToolResource,
  deleteExpertiseProject,
  deleteExpertiseResource,
  deleteLinkResource,
  deleteToolResource,
  EXPERTISE_RESOURCE,
  EXPERTISE_RESOURCE_PROJECT,
  EXPERTISE_RESOURCE_TAGS,
  EXPERTISE_RESOURCE_VIEW,
  getResources,
  LINK_RESOURCE,
  LINK_RESOURCE_TAGS,
  RESOURCES,
  TOOL_RESOURCE,
  TOOL_RESOURCE_TAGS,
  updateExpertiseProject,
  updateExpertiseResource,
  updateExpertiseResourceTags,
  updateLinkResource,
  updateLinkResourceTags,
  updateToolResource,
  updateToolResourceTags,
  updateKnowledge,
  getKnowledge,
  addToolResourcesView,
  TOOL_RESOURCE_VIEW,
  PRIMITIVE_RESOURCES,
  getPrimitiveResources,
  updateToolResourceResources,
  TOOL_RESOURCE_RESOURCES,
  updateKnowledgeTags,
  KNOWLEDGE_RESOURCE_TAGS,
  KNOWLEDGE_RESOURCE_VIEW,
  addKnowledgeResourcesView,
  addToolResourceDocument,
  updateToolResourceDocument,
  removeToolResourceDocument,
  TOOL_RESOURCE_DOCUMENTS,
  LINK_RESOURCE_VIEW,
  deleteKnowledge,
  searchResource,
  SEARCH_RESOURCE,
  RESOURCES_PAGINATED,
  getResourcesPaginated,
  getKnowledgeResourcesPaginated,
  getLinkResourcesPaginated,
  getExpertiseResourcesPaginated,
  getToolResourcesPaginated,
  KNOWLEDGE_RESOURCES_PAGINATED,
  LINK_RESOURCES_PAGINATED,
  EXPERTISE_RESOURCES_PAGINATED,
  TOOL_RESOURCES_PAGINATED
} from 'core/actions/ResourceActions';
import {
  addExpertiseResourcesViewMutation,
  createExpertiseProjectMutation,
  createExpertiseResourceMutation,
  createLinkResourceMutation,
  createOrUpdateExpertiseResourceTagsMutation,
  createOrUpdateLinkResourceTagsMutation,
  createOrUpdateToolResourceTagsMutation,
  createToolResourceMutation,
  deleteExpertiseProjectMutation,
  deleteExpertiseResourceMutation,
  deleteLinkResourceMutation,
  deleteToolResourceMutation,
  updateExpertiseProjectMutation,
  updateExpertiseResourceMutation,
  updateLinkResourceMutation,
  updateToolResourceMutation,
  createKnowledgeResourceMutation,
  deleteKnowledgeResourceMutation,
  updateKnowledgeResourceMutation,
  addToolResourcesViewMutation,
  createOrUpdateToolResourceResourcesMutation,
  createOrUpdateKnowledgeResourceTagsMutation,
  addKnowledgeResourcesViewMutation,
  addToolResourcesDocument,
  updateToolResourcesDocument,
  deleteToolResourcesDocument,
  addToolResourceDocumentsAndImage,
  addLinkResourcesViewMutation
} from 'api/graphql/resourceMutations';
import * as Alert from 'utils/Alerts';
import { stringify } from 'api/graphql/utils';
import { AppRoutes } from 'config/AppRoutes';
import { IDocument, IDocumentType } from 'core/models/Models';
import { ElementAlreadyExistsError } from 'core/models/Exceptions';
import { globalNavigate } from 'utils/global-history';

export function* getAllResourcesSaga() {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getAllResourcesMutation()
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getResources.success(response?.data?.allResources));
  } catch (e: any) {
    yield put(getResources.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getResources'));
  }
}


export function* getAllResourcesPaginatedSaga({payload: { page, number}}) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getAllResourcesPaginatedQuery(page, number)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getResourcesPaginated.success(response?.data?.allResourcesPaginated));
  } catch (e: any) {
    yield put(getResourcesPaginated.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getResources'));
  }
}


export function* getKnowledgeResourcesPaginatedSaga({payload: { page, number}}) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getKnowledgeResourcesPaginatedQuery(page, number)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getKnowledgeResourcesPaginated.success(response?.data?.knowledgeResourcesPaginated));
  } catch (e: any) {
    yield put(getKnowledgeResourcesPaginated.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getResources'));
  }
}


export function* getLinkResourcesPaginatedSaga({payload: { page, number}}) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getLinkResourcesPaginatedQuery(page, number)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getLinkResourcesPaginated.success(response?.data?.linkResourcesPaginated));
  } catch (e: any) {
    yield put(getLinkResourcesPaginated.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getResources'));
  }
}


export function* getExpertiseResourcesPaginatedSaga({payload: { page, number}}) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getExpertiseResourcesPaginatedQuery(page, number)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getExpertiseResourcesPaginated.success(response?.data?.expertiseResourcesPaginated));
  } catch (e: any) {
    yield put(getExpertiseResourcesPaginated.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getResources'));
  }
}


export function* getToolResourcesPaginatedSaga({payload: { page, number}}) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getToolResourcesPaginatedQuery(page, number)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getToolResourcesPaginated.success(response?.data?.toolResourcesPaginated));
  } catch (e: any) {
    yield put(getToolResourcesPaginated.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getResources'));
  }
}





export function* getPrimitiveResourcesSaga({
  payload: { loadExpertisesResources, loadKnowledgeResources, loadLinkResources }
}) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getPrimitiveResourcesQuery(loadExpertisesResources, loadKnowledgeResources, loadLinkResources)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getPrimitiveResources.success(response?.data?.allResources));
  } catch (e: any) {
    yield put(getPrimitiveResources.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getResources'));
  }
}

export function* createExpertiseResourceSaga({ payload: { resource, source } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      body: {
        query: createExpertiseResourceMutation(resource, cognitoId)
      }
    };
    let response = yield API.post('ResourceAPI', '/graphql', requestInfo);
    if (!response?.data?.createExpertiseResource?.isRequestSuccessful) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
      throw new Error(error);
    }

    let newResource = response.data?.createExpertiseResource?.resource;
    const resourceId = newResource?.attributes?.pId;

    const imageFile = resource?.attributes?.imageFile;
    if (imageFile) {
      const level = 'protected';
      const fileName = getS3Key(imageFile, resourceId);
      yield Storage.put(fileName, imageFile, { level: level });
      const imageUrl = yield Storage.get(fileName, { level: level });
      const data = { pId: resourceId, imageUrl: imageUrl };
      const requestInfo = {
        queryStringParameters: {
          query: updateExpertiseResourceMutation(data, cognitoId)
        }
      };
      response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);
      if (!response?.data?.updateExpertiseResource?.isRequestSuccessful) {
        throw new Error(response);
      }
      const resourceAttributes = { ...newResource.attributes, imageUrl: imageUrl };
      newResource = { ...newResource, attributes: resourceAttributes };
    }

    yield put(createExpertiseResource.success(newResource));
    yield Alert.setSuccessAlert(i18n.t('api.success.createResource'));
    globalNavigate(`/ekspertize/${resourceId}`);
  } catch (error: any) {
    yield put(createExpertiseResource.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.resourceExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.createResource'));
    }
  }
}

export function* deleteExpertiseResourceSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteExpertiseResourceMutation(id, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.deleteResource') ?? 'Greska kod brisanja resursa');
    }
    yield put(deleteExpertiseResource.success(id));
    yield Alert.setSuccessAlert(i18n.t('api.success.deleteResource'));
    globalNavigate(AppRoutes.resources.link);
  } catch (e) {
    yield put(deleteExpertiseResource.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteResource'));
  }
}

export function* addExpertiseResourceViewSaga({ payload: { resourceId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addExpertiseResourcesViewMutation(resourceId, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);
    if (response?.errors) {
      throw new Error(response?.errors);
    }

    yield put(addExpertiseResourcesView.success(response?.data?.addExpertiseResourcesView?.resource));
  } catch (e) {
    yield put(addExpertiseResourcesView.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getResources'));
  }
}

export function* updateExpertiseResourceSaga({ payload: { data } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;

    let newImageUrl = null;
    const oldImageUrl = data?.imageFile;
    const level = 'protected';
    if (data?.imageFile) {
      const fileName = getS3Key(data.imageFile, data.pId);
      yield Storage.put(fileName, data.imageFile, { level: level });
      newImageUrl = yield Storage.get(fileName, { level: level });
      data = { ...data, imageUrl: newImageUrl };
    }

    const requestInfo = {
      body: {
        query: updateExpertiseResourceMutation(data, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql', requestInfo);

    if (response?.errors?.length) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
      throw new Error(error);
    }

    if (newImageUrl && oldImageUrl) {
      if (typeof oldImageUrl === 'string') {
        const fileName = getS3KeyFromUrl(oldImageUrl, data.pId);
        yield Storage.remove(fileName, { level: 'protected' });
      }
    }

    yield put(updateExpertiseResource.success(response?.data?.updateExpertiseResource?.resource));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateResource'));
  } catch (error: any) {
    yield put(updateExpertiseResource.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.resourceExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.updateResource'));
    }
  }
}

export function* updateExpertiseResourceTagsSaga({ payload: { tagIds, resourceId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: createOrUpdateExpertiseResourceTagsMutation(resourceId, tagIds, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    const resId = response.data?.createOrUpdateExpertiseResourceTags?.resourceId;
    const tags = response.data?.createOrUpdateExpertiseResourceTags?.tags;
    yield put(updateExpertiseResourceTags.success(resId, tags));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateTags'));
  } catch (e) {
    yield put(updateExpertiseResourceTags.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateTags'));
  }
}

export function* createExpertiseProjectSaga({ payload: { project } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: createExpertiseProjectMutation(project, cognitoId)
      }
    };
    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }

    const createdProject = response.data?.createExpertiseProject?.project;
    yield put(createExpertiseProject.success(createdProject));
    yield Alert.setSuccessAlert(i18n.t('api.success.createProject'));
  } catch (e: any) {
    yield put(createExpertiseProject.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createProject'));
  }
}

export function* updateExpertiseProjectSaga({ payload: { project } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: updateExpertiseProjectMutation(project, cognitoId)
      }
    };
    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }

    const updatedProject = response.data?.updateExpertiseProject?.project;
    yield put(updateExpertiseProject.success(updatedProject));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateProject'));
  } catch (e: any) {
    yield put(updateExpertiseProject.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateProject'));
  }
}

export function* deleteExpertiseProjectSaga({ payload: { id, resourceId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteExpertiseProjectMutation(id, cognitoId)
      }
    };
    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }

    yield put(deleteExpertiseProject.success(id, resourceId));
    yield Alert.setSuccessAlert(i18n.t('api.success.deleteProject'));
  } catch (e: any) {
    yield put(deleteExpertiseProject.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteProject'));
  }
}

export function* createLinkResourceSaga({ payload: { resource, source } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: createLinkResourceMutation(resource, cognitoId)
      }
    };
    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);
    if (!response?.data?.createLinkResource?.isRequestSuccessful) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
      throw new Error(error);
    }

    yield Alert.setSuccessAlert(i18n.t('api.success.createResource'));
    yield put(createLinkResource.success({ ...response.data?.createLinkResource?.resource, source }));
  } catch (error: any) {
    yield put(createLinkResource.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.resourceExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.createResource'));
    }
  }
}

export function* addToolResourceViewSaga({ payload: { resourceId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addToolResourcesViewMutation(resourceId, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(addToolResourcesView.success(response?.data?.addToolResourceView?.resource));
  } catch (e) {
    yield put(addToolResourcesView.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getResources'));
  }
}

export function* updateLinkResourceSaga({ payload: { data } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: updateLinkResourceMutation(data, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
      throw new Error(error);
    }

    yield put(updateLinkResource.success(response?.data?.updateLinkResource?.resource));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateResource'));
  } catch (error: any) {
    yield put(updateLinkResource.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.resourceExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.updateResource'));
    }
  }
}

export function* updateLinkResourceTagsSaga({ payload: { tagIds, resourceId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: createOrUpdateLinkResourceTagsMutation(resourceId, tagIds, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    const resId = response.data?.createOrUpdateLinkResourceTags?.resourceId;
    const tags = response.data?.createOrUpdateLinkResourceTags?.tags;
    yield put(updateLinkResourceTags.success(resId, tags));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateTags'));
  } catch (e) {
    yield put(updateLinkResourceTags.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateTags'));
  }
}

export function* deleteLinkResourceSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteLinkResourceMutation(id, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(deleteLinkResource.success(id));
    yield Alert.setSuccessAlert(i18n.t('api.success.deleteResource'));
  } catch (e) {
    yield put(deleteLinkResource.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteResource'));
  }
}

export function* addLinkResourceViewSaga({ payload: { resourceId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addLinkResourcesViewMutation(resourceId, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);
    if (response?.errors) {
      throw new Error(response?.errors);
    }

    yield put(addLinkResourcesView.success(response?.data?.addLinkResourcesView?.resource));
  } catch (e) {
    yield put(addLinkResourcesView.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getResources'));
  }
}

export function* createKnowledgeSaga({ payload: { knowledge, source } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      body: {
        query: createKnowledgeResourceMutation(knowledge, cognitoId)
      }
    };

    let response = yield API.post('ResourceAPI', '/graphql', requestInfo);

    if (response?.errors) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
      throw new Error(error);
    }

    let {
      data: {
        createKnowledgeResource: { resource }
      }
    } = response;

    const resourceId = resource?.attributes?.pId;
    const document = knowledge?.file;
    const imageFile = knowledge?.imageFile;
    const linkUrl = resource?.attributes?.contentUrl;

    if (document || imageFile || knowledge?.documentType === IDocumentType.PDF) {
      let imageUrl = '';
      const level = 'protected';
      let documentUrl = '';
      let data: any = { pId: resource?.attributes?.pId };
      if (document) {
        const fileName = getS3Key(document, resourceId);
        yield Storage.put(fileName, document, { level: 'protected' });
        documentUrl = yield Storage.get(fileName, { level: 'protected' });
      }
      if (knowledge?.documentType === IDocumentType.PDF && !imageFile && linkUrl) {
        const fileName = getS3KeyFromUrl(linkUrl, resourceId);
        yield Storage.put(fileName, new File([''], linkUrl), { level: 'protected' });
        documentUrl = yield Storage.get(fileName, { level: 'protected' });
        data = { ...data, linkUrl };
      }
      if (imageFile) {
        const fileName = getS3Key(imageFile, resourceId);
        yield Storage.put(fileName, imageFile, { level });
        imageUrl = yield Storage.get(fileName, { level });
        data = { ...data, imageUrl };
      }
      if (documentUrl) {
        data = { ...data, contentUrl: documentUrl };
      }
      const requestInfo = {
        queryStringParameters: {
          query: updateKnowledgeResourceMutation(data, cognitoId)
        }
      };

      response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

      if (response?.errors?.length) {
        yield Alert.setErrorAlert(i18n.t('api.errors.createContestAddDocumentsAndImage'));
      } else {
        resource = response?.data?.updateKnowledgeResource?.knowledgeResource;
      }
    }

    yield put(createKnowledge.success({ ...resource, source: source }));
    yield Alert.setSuccessAlert(i18n.t('api.success.createResource'));
    globalNavigate(`${AppRoutes.knowledges.link}/${resourceId}`);
  } catch (error: any) {
    yield put(createKnowledge.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.resourceExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.createResource'));
    }
  }
}

export function* createToolResourceSaga({ payload: { resource } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: createToolResourceMutation(resource, cognitoId)
      }
    };

    let response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);
    if (!response?.data?.createToolResource?.isRequestSuccessful) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
      throw new Error(error);
    }

    let newResource = response.data?.createToolResource?.resource;
    const resourceId = newResource?.attributes?.pId;

    const imageFile = resource?.attributes?.imageFile;
    const documents = resource?.documents;
    if (documents?.length || imageFile) {
      let documentsStr = '';
      let imageUrl = '';
      const level = 'protected';
      const documentsWithUrl = new Array<IDocument>();
      if (documents?.length) {
        for (let i = 0; i < documents.length; i++) {
          const document = documents[i];
          let fileName = document.file.name;
          const title = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
          fileName = getS3Key(document.file, resourceId);
          yield Storage.put(fileName, document.file, { level: 'protected' });
          const url = yield Storage.get(fileName, { level: 'protected' });
          const cleanUrl = url.substring(0, url.indexOf('?'));
          documentsWithUrl.push({ url: cleanUrl, title: title, elementId: resourceId });
        }
        documentsStr = stringify(documentsWithUrl);
      }
      if (imageFile) {
        const fileName = getS3Key(imageFile, resourceId);
        yield Storage.put(fileName, imageFile, { level: level });
        imageUrl = yield Storage.get(fileName, { level: level });
      }
      const requestInfo = {
        queryStringParameters: {
          query: addToolResourceDocumentsAndImage(resourceId, documentsStr, imageUrl, cognitoId)
        }
      };

      response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

      if (response?.errors?.length) {
        yield Alert.setErrorAlert(i18n.t('api.errors.createToolResourceAddDocumentsAndImage'));
      } else {
        newResource = response?.data?.addNewContestsDocumentsAndImage?.contest;
      }
    }

    yield Alert.setSuccessAlert(i18n.t('api.success.createResource'));
    yield put(createToolResource.success(newResource));
    globalNavigate(`/alatke/${resourceId}`);
  } catch (error: any) {
    yield put(createToolResource.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.resourceExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.createResource'));
    }
  }
}

export function* updateToolResourceSaga({ payload: { data } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    let newImageUrl = null;
    const level = 'protected';
    if (data?.imageFile) {
      const fileName = getS3Key(data.imageFile, data.pId);
      yield Storage.put(fileName, data.imageFile, { level: level });
      newImageUrl = yield Storage.get(fileName, { level: level });
      data = { ...data, imageUrl: newImageUrl };
    }

    const requestInfo = {
      queryStringParameters: {
        query: updateToolResourceMutation(data, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
      throw new Error(error);
    }

    yield Alert.setSuccessAlert(i18n.t('api.success.updateResource'));
    yield put(createToolResource.success(response.data?.updateToolResource?.resource));
  } catch (error: any) {
    yield put(updateToolResource.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.resourceExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.updateResource'));
    }
  }
}

export function* updateToolResourceTagsSaga({ payload: { tagIds, resourceId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: createOrUpdateToolResourceTagsMutation(resourceId, tagIds, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.updateTags') ?? 'Greska azuriranja tagova');
    }
    const resId = response.data?.createOrUpdateToolResourceTags?.resourceId;
    const tags = response.data?.createOrUpdateToolResourceTags?.tags;
    yield put(updateToolResourceTags.success(resId, tags));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateTags'));
  } catch (e) {
    yield put(updateToolResourceTags.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateTags'));
  }
}

export function* updateToolResourceResourcesSaga({ payload: { toolId, knowledgeIds, linkIds, expertiseIds } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: createOrUpdateToolResourceResourcesMutation(toolId, knowledgeIds, linkIds, expertiseIds, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(updateToolResourceResources.success(response.data?.createOrUpdateToolResourceResources?.resource));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateResource'));
  } catch (e) {
    yield put(updateToolResourceResources.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateResource'));
  }
}

export function* deleteToolResourceSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteToolResourceMutation(id, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(deleteToolResource.success(id));
    yield Alert.setSuccessAlert(i18n.t('api.success.deleteResource'));
    globalNavigate(AppRoutes.resources.link);
  } catch (e) {
    yield put(deleteToolResource.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteResource'));
  }
}

export function* deleteKnowledgeSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteKnowledgeResourceMutation(id, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(deleteKnowledge.success({ id }));
    yield Alert.setSuccessAlert(i18n.t('api.success.deleteResource'));
    globalNavigate(AppRoutes.resources.link);
  } catch (error: any) {
    yield put(deleteKnowledge.failure(error));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteResource'));
  }
}

export function* getKnowledgeSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const requestInfo = {
      queryStringParameters: {
        query: getKnowledgeQuery(id)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(getKnowledge.success(response.data.knowledgeResource));
  } catch (error: any) {
    yield put(getKnowledge.failure(error));
    yield Alert.setErrorAlert(i18n.t('api.errors.getResources'));
  }
}

export function* updateKnowledgeResourceSaga({ payload: { knowledge } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;

    let newData = { ...knowledge };
    const document = knowledge?.file;
    const imageFile = knowledge?.imageFile;
    const resourceId = knowledge.pId;
    const linkUrl = knowledge?.contentUrl;
    const imageUrl = knowledge?.imageUrl;

    if (document || imageFile || linkUrl || imageUrl) {
      const level = 'protected';
      let documentUrl = '';
      if (knowledge?.documentType === IDocumentType.PDF && !imageFile && linkUrl) {
        const fileName = getS3KeyFromUrl(linkUrl, resourceId);
        yield Storage.put(fileName, new File([''], linkUrl), { level });
        documentUrl = yield Storage.get(fileName, { level });
        newData = { ...newData, linkUrl };
      }
      if (document) {
        const fileName = getS3Key(document, resourceId);
        yield Storage.put(fileName, document, { level });
        documentUrl = yield Storage.get(fileName, { level });
      }
      if (imageFile) {
        const fileName = getS3Key(imageFile, resourceId);
        yield Storage.put(fileName, imageFile, { level });
        documentUrl = yield Storage.get(fileName, { level });
      }
      if (documentUrl) {
        newData = { ...newData, contentUrl: documentUrl };
      }
      if (imageUrl) {
        const fileName = getS3Key(imageUrl, resourceId);
        yield Storage.put(fileName, imageUrl, { level });
        const url = yield Storage.get(fileName, { level });
        newData = { ...newData, imageUrl: url };
      }
    }

    const requestInfo = {
      body: {
        query: updateKnowledgeResourceMutation(newData, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql', requestInfo);

    if (response?.errors) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
      throw new Error(error);
    }

    yield put(updateKnowledge.success(response?.data?.updateKnowledgeResource?.knowledgeResource));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateResource'));
  } catch (error: any) {
    yield put(updateKnowledge.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.resourceExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.updateResource'));
    }
  }
}

export function* updateKnowledgeTagsSaga({ payload: { id, tags } }) {
  const ids = tags.map((tag) => tag.pId);
  const tagIds = stringify(ids);

  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: createOrUpdateKnowledgeResourceTagsMutation(id, tagIds, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }

    const data = response?.data?.createOrUpdateKnowledgeResourceTags;

    yield put(updateKnowledgeTags.success(data?.knowledgeResourceId, data?.tags));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateTags'));
  } catch (e) {
    yield put(updateKnowledgeTags.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateTags'));
  }
}

export function* addKnowledgeResourceViewSaga({ payload: { resourceId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addKnowledgeResourcesViewMutation(resourceId, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(addKnowledgeResourcesView.success(response?.data?.addKnowledgeResourceView?.resource));
  } catch (e) {
    yield put(addToolResourcesView.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getResources'));
  }
}

export function* addToolResourceDocumentSaga({ payload: { document } }) {
  try {
    Storage.configure({ level: 'protected' });
    let fileName = document.file.name;
    const title = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
    fileName = getS3Key(document.file, document.resourceId);
    yield Storage.put(fileName, document.file);
    const url = yield Storage.get(fileName);

    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addToolResourcesDocument(document.elementId, url, title, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.createDocument'));
    }
    yield put(addToolResourceDocument.success(response?.data?.addToolResourcesDocument?.document));

    yield Alert.setSuccessAlert(i18n.t('api.success.createDocument'));
  } catch (e) {
    yield put(addToolResourceDocument.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createDocument'));
  }
}

export function* updateToolResourceDocumentSaga({ payload: { document } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: updateToolResourcesDocument(document.pId, document.title, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.updateDocument'));
    }
    yield put(updateToolResourceDocument.success(response?.data?.updateToolResourcesDocument?.document));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateDocument'));
  } catch (e) {
    yield put(updateToolResourceDocument.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateDocument'));
  }
}

export function* removeToolResourceDocumentSaga({ payload: { document } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const fileName = getS3KeyFromUrl(document.url, document.elementId);
    yield Storage.remove(fileName, { level: 'protected' });
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteToolResourcesDocument(document.pId, cognitoId)
      }
    };

    const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(removeToolResourceDocument.success(document));

    yield Alert.setSuccessAlert(i18n.t('api.success.deleteDocument'));
  } catch (e) {
    yield put(removeToolResourceDocument.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteDocument'));
  }
}

function getS3Key(file, resourceId) {
  const fileName = file.name;
  const fileType = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
  const randomId = uuid.v4();
  return `Resources/${resourceId}/${randomId}${fileType}`;
}

function getS3KeyFromUrl(url, resourceId) {
  const name = url.substring(url.lastIndexOf('/') + 1, url.length);
  return `Resources/${resourceId}/${name}`;
}

export function* searchResourcesSaga({ payload: { name, resourceType, knowledgeType, tagIds, page, num } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();

    //TODO: pagination is not yet implemented for search
    if (name) {
      const requestInfo = {
        queryStringParameters: {
          query: searchResourcesQuery(name, resourceType, knowledgeType, tagIds)
        }
      };
      const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);
      if (response?.errors) {
        throw new Error(response?.errors);
      }
      yield put(searchResource.success({
        data: response?.data?.searchResources,
        searchParam: name
      }));
    } else {
      const requestInfo = {
        queryStringParameters: {
          query: getAllResourcesPaginatedQuery(page, num)
        }
      };
      const response = yield API.post('ResourceAPI', '/graphql?query', requestInfo);
      if (response?.errors) {
        throw new Error(response?.errors);
      }
      yield put(searchResource.success({
        data: response?.data?.allResourcesPaginated,
        searchParam: name,
      }));
    }
  } catch (e: any) {
    yield put(searchResource.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.search'));
  }
}

function* resourcesSaga() {
  yield all([
    takeLatest(RESOURCES.GET.REQUEST, getAllResourcesSaga),
    takeLatest(RESOURCES_PAGINATED.GET.REQUEST, getAllResourcesPaginatedSaga),
    takeLatest(KNOWLEDGE_RESOURCES_PAGINATED.GET.REQUEST, getKnowledgeResourcesPaginatedSaga),
    takeLatest(LINK_RESOURCES_PAGINATED.GET.REQUEST, getLinkResourcesPaginatedSaga),
    takeLatest(EXPERTISE_RESOURCES_PAGINATED.GET.REQUEST, getExpertiseResourcesPaginatedSaga),
    takeLatest(TOOL_RESOURCES_PAGINATED.GET.REQUEST, getToolResourcesPaginatedSaga),
    takeLatest(PRIMITIVE_RESOURCES.GET.REQUEST, getPrimitiveResourcesSaga),
    takeLatest(EXPERTISE_RESOURCE.POST.REQUEST, createExpertiseResourceSaga),
    takeLatest(EXPERTISE_RESOURCE.PUT.REQUEST, updateExpertiseResourceSaga),
    takeLatest(EXPERTISE_RESOURCE_VIEW.POST.REQUEST, addExpertiseResourceViewSaga),
    takeLatest(EXPERTISE_RESOURCE_TAGS.POST.REQUEST, updateExpertiseResourceTagsSaga),
    takeLatest(EXPERTISE_RESOURCE_PROJECT.POST.REQUEST, createExpertiseProjectSaga),
    takeLatest(EXPERTISE_RESOURCE_PROJECT.PUT.REQUEST, updateExpertiseProjectSaga),
    takeLatest(EXPERTISE_RESOURCE_PROJECT.DELETE.REQUEST, deleteExpertiseProjectSaga),
    takeLatest(EXPERTISE_RESOURCE.DELETE.REQUEST, deleteExpertiseResourceSaga),
    takeLatest(LINK_RESOURCE.DELETE.REQUEST, deleteLinkResourceSaga),
    takeLatest(LINK_RESOURCE.POST.REQUEST, createLinkResourceSaga),
    takeLatest(LINK_RESOURCE.PUT.REQUEST, updateLinkResourceSaga),
    takeLatest(LINK_RESOURCE_VIEW.POST.REQUEST, addLinkResourceViewSaga),
    takeLatest(LINK_RESOURCE_TAGS.POST.REQUEST, updateLinkResourceTagsSaga),
    takeLatest(KNOWLEDGE_RESOURCE.POST.REQUEST, createKnowledgeSaga),
    takeLatest(KNOWLEDGE_RESOURCE.PUT.REQUEST, updateKnowledgeResourceSaga),
    takeLatest(KNOWLEDGE_RESOURCE.DELETE.REQUEST, deleteKnowledgeSaga),
    takeLatest(KNOWLEDGE_RESOURCE.GET.REQUEST, getKnowledgeSaga),
    takeLatest(KNOWLEDGE_RESOURCE_TAGS.POST.REQUEST, updateKnowledgeTagsSaga),
    takeLatest(KNOWLEDGE_RESOURCE_VIEW.POST.REQUEST, addKnowledgeResourceViewSaga),
    takeLatest(TOOL_RESOURCE.DELETE.REQUEST, deleteToolResourceSaga),
    takeLatest(TOOL_RESOURCE.POST.REQUEST, createToolResourceSaga),
    takeLatest(TOOL_RESOURCE.PUT.REQUEST, updateToolResourceSaga),
    takeLatest(TOOL_RESOURCE_VIEW.POST.REQUEST, addToolResourceViewSaga),
    takeLatest(TOOL_RESOURCE_TAGS.POST.REQUEST, updateToolResourceTagsSaga),
    takeLatest(TOOL_RESOURCE_RESOURCES.POST.REQUEST, updateToolResourceResourcesSaga),
    takeLatest(TOOL_RESOURCE_DOCUMENTS.POST.REQUEST, addToolResourceDocumentSaga),
    takeLatest(TOOL_RESOURCE_DOCUMENTS.PUT.REQUEST, updateToolResourceDocumentSaga),
    takeLatest(TOOL_RESOURCE_DOCUMENTS.DELETE.REQUEST, removeToolResourceDocumentSaga),
    takeLatest(SEARCH_RESOURCE.GET.REQUEST, searchResourcesSaga)
  ]);
}

export default resourcesSaga;
