import { all, put, takeLatest } from 'redux-saga/effects';
import { getAllCalendarEvents, getAllEventCategories } from 'api/graphql/queries';
import { API, Auth } from 'aws-amplify';
import * as Alert from 'utils/Alerts';
import i18n from 'utils/i18n';
import {
  CALENDAR_EVENT,
  CALENDAR_EVENTS,
  CALENDAR_EVENT_TYPES,
  CALENDAR_PARTICIPANTS,
  createCalendarEvent,
  deleteCalendarEvent,
  getCalendarEvents,
  getCalendarEventTypes,
  updateCalendarEvent,
  updateCalendarParticipants
} from 'core/actions/CalendarActions';
import {
  addOrRemoveEventParticipantMutation,
  createCalendarEventMutation,
  deleteCalendarMutation,
  updateCalendarEventMutation
} from 'api/graphql/calendarMutations';
import { ElementAlreadyExistsError } from 'core/models/Exceptions';

export function* getCalendarEventsSaga() {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getAllCalendarEvents
      }
    };
    const response = yield API.post('CalendarAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }

    yield put(getCalendarEvents.success(response.data.allEvents));
  } catch (e: any) {
    yield put(getCalendarEvents.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getCalendarEvents'));
  }
}

export function* createCalendarEventSaga({ payload: { event } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: createCalendarEventMutation(event, cognitoId)
      }
    };
    const response = yield API.post('CalendarAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
    }

    yield put(createCalendarEvent.success(response.data?.createCalendarEvent?.calendarEvent));
    yield Alert.setSuccessAlert(i18n.t('api.success.createCalendarEvent'));
  } catch (error: any) {
    yield put(createCalendarEvent.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.calendarEventExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.createCalendarEvent'));
    }
  }
}

export function* updateCalendarEventSaga({ payload: { event } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: updateCalendarEventMutation(event, cognitoId)
      }
    };
    const response = yield API.post('CalendarAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }

    yield put(updateCalendarEvent.success(response.data.updateCalendarEvent.calendarEvent));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateCalendarEvent'));
  } catch (e: any) {
    yield put(updateCalendarEvent.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateCalendarEvent'));
  }
}

export function* deleteCalendarEventSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteCalendarMutation(id, cognitoId)
      }
    };
    const response = yield API.post('CalendarAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }

    yield put(deleteCalendarEvent.success(id));
    yield Alert.setSuccessAlert(i18n.t('api.success.deleteCalendarEvent'));
  } catch (e: any) {
    yield put(deleteCalendarEvent.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteCalendarEvent'));
  }
}

export function* getCalendarEventTypesSaga() {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getAllEventCategories
      }
    };

    const response = yield API.post('CalendarAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }

    yield put(getCalendarEventTypes.success(response.data.allEventTypes));
  } catch (e: any) {
    yield put(getCalendarEventTypes.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getEventTypes'));
  }
}

function* addOrRemoveParticipantSaga({ payload: { eventId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addOrRemoveEventParticipantMutation(eventId, cognitoId)
      }
    };

    const response = yield API.post('CalendarAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      throw new Error(response.errors);
    }

    yield put(updateCalendarParticipants.success(response.data.addOrRemoveEventParticipant.calendarEvent));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateCalendarParticipants'));
  } catch (error) {
    yield put(updateCalendarParticipants.failure(error));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateCalendarParticipants'));
  }
}

function* calendarSaga() {
  yield all([
    //  takeLatest(CALENDAR_EVENT.GET.REQUEST, getCalendarEventSaga),
    takeLatest(CALENDAR_EVENT.DELETE.REQUEST, deleteCalendarEventSaga),
    takeLatest(CALENDAR_EVENT.PUT.REQUEST, updateCalendarEventSaga),
    takeLatest(CALENDAR_EVENT.POST.REQUEST, createCalendarEventSaga),
    takeLatest(CALENDAR_EVENTS.GET.REQUEST, getCalendarEventsSaga),
    takeLatest(CALENDAR_EVENT_TYPES.GET.REQUEST, getCalendarEventTypesSaga),
    takeLatest(CALENDAR_PARTICIPANTS.POST.REQUEST, addOrRemoveParticipantSaga)
  ]);
}

export default calendarSaga;
