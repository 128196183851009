import homePageSvg from 'assets/art/Homepage.svg';
import actionSvg from 'assets/art/Akcija.svg';
import contestsSvg from 'assets/art/Konkursi.svg';
import signupBreakingPrinciples from 'assets/art/Signup-krsenje_principa.svg';
import signupCookies from 'assets/art/Signup-politika_kolacica.svg';
import signupAccept from 'assets/art/Signup-prihvatanje_uslova.svg';
import signupPrinicpleOne from 'assets/art/Signup-princip_briznosti_komunikacije.svg';
import signupPrincipleTwo from 'assets/art/Signup-princip_domacinskog_odnosa_prema_platformi.svg';
import signupPrincipleThree from 'assets/art/Signup-princip_intersekcionalnosti.svg';
import signupPrincipleFour from 'assets/art/Signup-princip_poverljivosti.svg';
import signupPrincipleFive from 'assets/art/Signup-princip_transparentnosti_i_uvazavanja.svg';
import resourcesExpert from 'assets/art/Resursi-ekspertkinja.svg';
import resourcesAudio from 'assets/art/Resursi-audio.svg';
import resourcesVideo from 'assets/art/Resursi-video.svg';
import resourcesBook from 'assets/art/Resursi-knjiga.svg';
import resourcesText from 'assets/art/Resursi-tekst.svg';
import resourcesTool from 'assets/art/Resursi-alatka.svg';
import footerMain from 'assets/art/Footer-ilustracija.svg';
import footerAbout from 'assets/art/Footer-o_platformi.svg';
import footerPrivacy from 'assets/art/Footer-polisa_privatnosti.svg';
import footerRules from 'assets/art/Footer-pravila_ponasanja.svg';
import footerValues from 'assets/art/Footer-vrednosti.svg';
import footerSettings from 'assets/art/Footer-upravljanje_platformom.svg';
import footerFAQ from 'assets/art/Footer-cesta_pitanja.svg';
import iconartProfilePersonalOrg from 'assets/art/Ikonica-Licni_profil-organizacija.svg';
import iconartProfilePersonalEdu from 'assets/art/Ikonica-Licni_profil-obrazovanje.svg';
import iconartProfileOrgProjects from 'assets/art/Ikonica-Profil_organizacije-istaknuti_projekti.svg';

const images = {
  pagesHome: {
    src: homePageSvg,
    alt: 'Ilustracija naslovne strane platforme Združene'
  },
  pagesActions: {
    src: actionSvg,
    alt: 'Ilustracija Akcije'
  },
  pagesCompetition: {
    src: contestsSvg,
    alt: 'Ilustracija Konkursi'
  },
  signupBreakingPrinciples: {
    src: signupBreakingPrinciples,
    alt: 'ilustracija kršenje principa'
  },
  signupCookies: {
    src: signupCookies,
    alt: 'ilustracija politike kolačića'
  },
  signupAccept: {
    src: signupAccept,
    alt: 'ilustracija prihvatanje uslov'
  },
  signupPrinicpleOne: {
    src: signupPrinicpleOne,
    alt: 'ilustracija principa brižnosti komunikacije'
  },
  signupPrincipleTwo: {
    src: signupPrincipleTwo,
    alt: 'ilustracija principa domaćinskog odnosa prema platformi'
  },
  signupPrincipleThree: {
    src: signupPrincipleThree,
    alt: 'ilustracija principa intersekcionalnosti'
  },
  signupPrincipleFour: {
    src: signupPrincipleFour,
    alt: 'ilustracija principa povjerljivosti'
  },
  signupPrincipleFive: {
    src: signupPrincipleFive,
    alt: 'ilustracija principa transparentnosti i uvažavanja'
  },
  resourcesExpert: {
    src: resourcesExpert,
    alt: 'ilustracija ekspertkinje'
  },
  resourcesAudio: {
    src: resourcesAudio,
    alt: 'ilustracija za resurs koji je u audio formatu'
  },
  resourcesVideo: {
    src: resourcesVideo,
    alt: 'ilustracija za resurse koji je u video formatu'
  },
  resourcesBook: {
    src: resourcesBook,
    alt: 'ilustracija za resurs koji je u tekstualnom formatu'
  },
  resourcesText: {
    src: resourcesText,
    alt: 'ilustracija za resurs koji je u tekstualnom formatu'
  },
  resourcesTool: {
    src: resourcesTool,
    alt: 'ilustracija za resurs koji alatka'
  },
  footerMain: {
    src: footerMain,
    alt: 'Ilustracija tri žene koje sarađuju'
  },
  footerAbout: {
    src: footerAbout,
    alt: 'ilustracija o platformi'
  },
  footerPrivacy: {
    src: footerPrivacy,
    alt: 'ilustracija polisa privatnosti'
  },
  footerRules: {
    src: footerRules,
    alt: 'ilustracija pravila ponašanja'
  },
  footerValues: {
    src: footerValues,
    alt: 'ilustracija vrednosti'
  },
  footerSettings: {
    src: footerSettings,
    alt: 'ilustracija upravljanje platformom'
  },
  footerFAQ: {
    src: footerFAQ,
    alt: 'ilustracija česta pitanja'
  },
  iconartProfilePersonalOrg: {
    src: iconartProfilePersonalOrg,
    alt: 'Ilustracija radno iskustvo organizacija'
  },
  iconartProfilePersonalEdu: {
    src: iconartProfilePersonalEdu,
    alt: 'ilustracija obrazovanje'
  },
  iconartProfileOrgProjects: {
    src: iconartProfileOrgProjects,
    alt: 'ilustrcija istaknuti projekti'
  }
};

export default images;
