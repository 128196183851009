import { all, put, takeLatest } from 'redux-saga/effects';
import { getActionsContests, getAllContests, getContestQuery, searchContestsQuery } from 'api/graphql/queries';
import { API, Auth, Storage } from 'aws-amplify';
import * as Alert from 'utils/Alerts';
import i18n from 'utils/i18n';
import {
  CONTEST,
  CONTESTS,
  CONTEST_DOCUMENT,
  getContest,
  createContest,
  updateContest,
  deleteContest,
  addDocument,
  getContests,
  CONTEST_TAGS,
  updateContestTags,
  updateDocument,
  removeDocument,
  updateContestVisibility,
  CONTEST_VISIBILITY,
  backContestContestApplication,
  CONTEST_CONTEST_APPLICATION_VOTE,
  getEligibleContests,
  ELIGIBLE_CONTESTS,
  backContestAction,
  CONTEST_ACTION_VOTE,
  CONTEST_VIEW,
  addContestsView,
  SEARCH_CONTEST,
  searchContests
} from 'core/actions/ContestActions';
import {
  addContestDocumentsAndImage,
  addContestsDocument,
  createContestMutation,
  createOrDeleteContestsTags,
  deleteContestMutation,
  deleteContestsDocument,
  updateContestMutation,
  updateContestsDocument,
  updateContestVisibility as updateContestVisibilityMutation,
  backAction as backActionMutation,
  addContestsView as addContestsViewMutation
} from 'api/graphql/contestMutations';
import { backContestApplication as backContestApplicationMutation } from 'api/graphql/contestApplicationsMutations';
import * as uuid from 'uuid';
import { AppRoutes } from 'config/AppRoutes';
import { stringify } from 'api/graphql/utils';
import { IDocument } from 'core/models/Models';
import { ElementAlreadyExistsError } from 'core/models/Exceptions';
import { globalNavigate } from 'utils/global-history';

export function* fetchContestsSaga() {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: getAllContests(cognitoId)
      }
    };
    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);
    if (response?.errors?.length) {
      throw new Error(i18n.t('api.errors.getContest'));
    }
    yield put(getContests.success(response?.data?.usersContests));
  } catch (e: any) {
    yield put(getContests.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getContest'));
  }
}

export function* fetchContestSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: getContestQuery(id, cognitoId)
      }
    };
    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      const error = response?.errors[0].message;
      if (error.includes('Konkurs nije dostupan')) {
        globalNavigate(AppRoutes.contests.link);
        throw new Error(error);
      }
      throw new Error(i18n.t('api.errors.getContest'));
    }
    yield put(getContest.success(response?.data?.expandedContest));
  } catch (e: any) {
    yield put(getContest.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getContest'));
  }
}

export function* fetchEligibleContestsSaga({ payload: { actionId } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getActionsContests(actionId)
      }
    };
    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.actionsContests'));
    }
    yield put(getEligibleContests.success(response?.data?.actionsContests));
  } catch (e: any) {
    yield put(getEligibleContests.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.actionsContests'));
  }
}

export function* createContestSaga({ payload: { contestData } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      body: {
        query: createContestMutation(contestData)
      }
    };

    let response = yield API.post('ContestAPI', '/graphql', requestInfo);
    if (response?.errors?.length) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
      throw new Error(error);
    }

    let {
      data: {
        createContest: { contest }
      }
    } = response;

    const contestId = contest?.attributes?.pId;
    // upload documents only after contest is created successfully
    // need the id and shouldn't upload if an error occurs
    const documents = contestData?.documents;
    const imageFile = contestData?.imageFile;
    if (documents?.length || imageFile) {
      let documentsStr = '';
      let imageUrl = '';
      const level = 'protected';
      const documentsWithUrl = new Array<IDocument>();
      if (documents?.length) {
        for (let i = 0; i < documents.length; i++) {
          const document = documents[i];
          let fileName = document.file.name;
          const title = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
          fileName = getS3Key(document.file, contestId);
          yield Storage.put(fileName, document.file, { level: 'protected' });
          const url = yield Storage.get(fileName, { level: 'protected' });
          const cleanUrl = url.substring(0, url.indexOf('?'));
          documentsWithUrl.push({ url: cleanUrl, title: title, elementId: contestId });
        }
        documentsStr = stringify(documentsWithUrl);
      }
      if (imageFile) {
        const fileName = getS3Key(imageFile, contestId);
        yield Storage.put(fileName, imageFile, { level: level });
        imageUrl = yield Storage.get(fileName, { level: level });
      }
      const requestInfo = {
        queryStringParameters: {
          query: addContestDocumentsAndImage(contestId, documentsStr, imageUrl, cognitoId)
        }
      };

      response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

      if (response?.errors?.length) {
        yield Alert.setErrorAlert(i18n.t('api.errors.createContestAddDocumentsAndImage'));
      } else {
        contest = response?.data?.addNewContestsDocumentsAndImage?.contest;
      }
    }

    yield put(createContest.success(contest));
    yield Alert.setSuccessAlert(i18n.t('api.success.createContest'));
    globalNavigate(`${AppRoutes.contests.link}/${contest.attributes.pId}`);
  } catch (error: any) {
    yield put(createContest.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.contestExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.createAction'));
    }
  }
}

export function* updateContestSaga({ payload: { data } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;

    const level = 'protected';
    let newData = { ...data };
    const imageFile = data?.imageFile;
    if (imageFile) {
      const fileName = getS3Key(imageFile, data.pId);
      yield Storage.put(fileName, imageFile, { level: level });
      const newImageUrl = yield Storage.get(fileName, { level: level });
      newData = { ...data, imageUrl: newImageUrl };
    }

    const requestInfo = {
      body: {
        query: updateContestMutation(newData, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql', requestInfo);
    if (response?.errors?.length) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
      throw new Error(error);
    }

    yield put(updateContest.success(response?.data?.updateContest?.contest));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateContest'));
  } catch (error: any) {
    yield put(updateContest.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.contestExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.updateContest'));
    }
  }
}

export function* deleteContestSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteContestMutation(id, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors?.length) {
      throw new Error(i18n.t('api.errors.deleteContest'));
    }

    yield put(deleteContest.success(response));
    globalNavigate(AppRoutes.contests.link);
    yield Alert.setSuccessAlert(i18n.t('api.success.deleteContest'));
  } catch (e: any) {
    yield put(deleteContest.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteContest'));
  }
}

export function* updateContestTagsSaga({ payload: { id, tags } }) {
  const ids = tags.map((tag) => tag.pId);
  const tagIds = stringify(ids);
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: createOrDeleteContestsTags(id, tagIds, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.updateTags'));
    }
    yield put(updateContestTags.success(response?.data?.createOrDeleteContestsTags?.contest));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateTags'));
  } catch (e) {
    yield put(updateContestTags.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateTags'));
  }
}

export function* updateContestVisibilitySaga({ payload: { data } }) {
  const organizationIds = data?.organizations?.length ? stringify(data?.organizations?.map((org) => org.pId)) : null;
  const countryIds = data?.countries?.length ? stringify(data?.countries?.map((c) => c.pId)) : null;
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: updateContestVisibilityMutation(data, organizationIds, countryIds, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.updateContest'));
    }
    yield put(updateContestVisibility.success(response?.data?.updateContestsVisibility?.contest));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateContest'));
  } catch (e) {
    yield put(updateContestVisibility.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateContest'));
  }
}

function getS3Key(file, contestId) {
  const fileName = file.name;
  const fileType = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
  const randomId = uuid.v4();
  return `Contests/${contestId}/${randomId}${fileType}`;
}

function getS3KeyFromUrl(url, contestId) {
  const name = url.substring(url.lastIndexOf('/') + 1, url.length);
  return `Contests/${contestId}/${name}`;
}

export function* addDocumentSaga({ payload: { document } }) {
  try {
    Storage.configure({ level: 'protected' });
    let fileName = document.file.name;
    const title = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
    fileName = getS3Key(document.file, document.elementId);
    yield Storage.put(fileName, document.file);
    const url = yield Storage.get(fileName);

    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addContestsDocument(document.elementId, url, title, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.createDocument'));
    }
    yield put(addDocument.success(response?.data?.addContestsDocument?.contest));

    yield Alert.setSuccessAlert(i18n.t('api.success.createDocument'));
  } catch (e) {
    yield put(addDocument.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createDocument'));
  }
}

export function* updateDocumentSaga({ payload: { document } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: updateContestsDocument(document.id, document.title, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.updateDocument'));
    }
    yield put(updateDocument.success(response?.data?.updateContestsDocument?.contest));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateDocument'));
  } catch (e) {
    yield put(updateDocument.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateDocument'));
  }
}

export function* removeDocumentSaga({ payload: { document } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const fileName = getS3KeyFromUrl(document.url, document.actionId);
    yield Storage.remove(fileName, { level: 'protected' });
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteContestsDocument(document.id, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.deleteDocument'));
    }
    yield put(removeDocument.success(response?.data?.deleteContestsDocument?.contest));

    yield Alert.setSuccessAlert(i18n.t('api.success.deleteDocument'));
  } catch (e) {
    yield put(removeDocument.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteDocument'));
  }
}

export function* addContestsViewSaga({ payload: { contestId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addContestsViewMutation(contestId, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      const error = response?.errors[0].message;
      if (error.includes('Konkurs nije dostupan')) {
        globalNavigate(AppRoutes.contests.link);
        throw new Error(error);
      }
      throw new Error(i18n.t('api.errors.getContests'));
    }
    yield put(addContestsView.success(response?.data?.addContestsView?.contest));
  } catch (e) {
    yield put(addContestsView.failure(e));
  }
}

export function* backContestApplicationSaga({ payload: { contestApplicationId, userId, contestId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: backContestApplicationMutation(contestApplicationId, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.backContestApplication'));
    }
    yield put(backContestContestApplication.success({ contestApplicationId, userId, contestId }));
    yield Alert.setSuccessAlert(i18n.t('api.success.backContestApplication'));
  } catch (e) {
    yield put(backContestContestApplication.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.backContestApplication'));
  }
}

export function* backContestActionSaga({ payload: { actionId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: backActionMutation(actionId, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.backContestApplication'));
    }
    yield put(backContestAction.success(response?.data?.backAction?.action));
    yield Alert.setSuccessAlert(i18n.t('api.success.backContestApplication'));
  } catch (e) {
    yield put(backContestAction.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.backContestApplication'));
  }
}

export function* searchContestSaga({ payload: { name, tagIds } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: searchContestsQuery(name, tagIds, cognitoId)
      }
    };
    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);
    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(searchContests.success(response?.data?.searchContests));
  } catch (e) {
    yield put(searchContests.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.search'));
  }
}

function* contestSaga() {
  yield all([
    takeLatest(CONTESTS.GET.REQUEST, fetchContestsSaga),
    takeLatest(ELIGIBLE_CONTESTS.GET.REQUEST, fetchEligibleContestsSaga),
    takeLatest(CONTEST.GET.REQUEST, fetchContestSaga),
    takeLatest(CONTEST.POST.REQUEST, createContestSaga),
    takeLatest(CONTEST.PUT.REQUEST, updateContestSaga),
    takeLatest(CONTEST.DELETE.REQUEST, deleteContestSaga),
    takeLatest(CONTEST_TAGS.POST.REQUEST, updateContestTagsSaga),
    takeLatest(CONTEST_DOCUMENT.POST.REQUEST, addDocumentSaga),
    takeLatest(CONTEST_DOCUMENT.PUT.REQUEST, updateDocumentSaga),
    takeLatest(CONTEST_DOCUMENT.DELETE.REQUEST, removeDocumentSaga),
    takeLatest(CONTEST_VISIBILITY.POST.REQUEST, updateContestVisibilitySaga),
    takeLatest(CONTEST_CONTEST_APPLICATION_VOTE.POST.REQUEST, backContestApplicationSaga),
    takeLatest(CONTEST_ACTION_VOTE.POST.REQUEST, backContestActionSaga),
    takeLatest(CONTEST_VIEW.POST.REQUEST, addContestsViewSaga),
    takeLatest(SEARCH_CONTEST.GET.REQUEST, searchContestSaga)
  ]);
}

export default contestSaga;
