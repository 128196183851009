import { produce } from 'immer';
import {
  HOME_CARD_COMMENTS,
  HOME_PAGE_ACTIVITY,
  HOME_PAGE_COMMENTS,
  HOME_PAGE_CONTENT
} from 'core/actions/HomePageActions';
import { COMMENT, COMMENT_LIKE, ELEMENT_LIKE } from 'core/actions/InteractionActions';
import { IActivity, ICardComments } from 'core/models/Models';

export interface IHomePageState {
  homePageContent: any[];
  homePageActivity: any;
  homePageComments: ICardComments[];
  isFetchingElementComments: number;
}

const initialState: IHomePageState = {
  homePageContent: [],
  homePageActivity: {},
  homePageComments: [],
  isFetchingElementComments: 0
};

const homePageReducer = (state = initialState, action: IActivity): IHomePageState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case HOME_PAGE_CONTENT.GET.SUCCESS: {
        draft.homePageContent = state.homePageContent.concat(payload);
        break;
      }
      case ELEMENT_LIKE.POST.SUCCESS: {
        const { elementId, userId } = payload;
        draft.homePageContent = state.homePageContent.map((e) =>
          e?.pId === elementId
            ? {
                ...e,
                likedByIds: e?.likedByIds.includes(userId)
                  ? e?.likedByIds.filter((id) => id !== userId)
                  : [...e.likedByIds, userId]
              }
            : e
        );
        if (state.homePageActivity?.pId === elementId) {
          draft.homePageActivity = {
            ...state.homePageActivity,
            likedByIds: state.homePageActivity?.likedByIds.includes(userId)
              ? state.homePageActivity?.likedByIds.filter((id) => id !== userId)
              : [...state.homePageActivity.likedByIds, userId]
          };
        }
        break;
      }
      case HOME_PAGE_ACTIVITY.GET.SUCCESS:
        draft.homePageActivity = payload;
        break;
      case HOME_PAGE_COMMENTS.GET.SUCCESS:
        draft.homePageComments = state.homePageComments.concat(payload);
        break;
      case HOME_CARD_COMMENTS.GET.REQUEST:
        draft.isFetchingElementComments = payload.elementId;
        break;
      case COMMENT.PUT.REQUEST:
        draft.isFetchingElementComments = payload?.comment?.pId;
        break;
      case HOME_CARD_COMMENTS.GET.FAILURE:
      case COMMENT.PUT.FAILURE:
        draft.isFetchingElementComments = 0;
        break;
      case HOME_CARD_COMMENTS.GET.SUCCESS:
        {
          draft.isFetchingElementComments = 0;
          draft.homePageComments = state.homePageComments.map((commentData) =>
            commentData?.elementId === payload.elementId ? { ...commentData, comments: payload.comments } : commentData
          );
        }
        break;
      case COMMENT.DELETE.SUCCESS:
        {
          draft.homePageComments = state.homePageComments.map((comData) =>
            comData?.elementId === payload?.comment?.elementId
              ? {
                  ...comData,
                  comments: comData?.comments.filter(
                    (c) => c?.pId !== payload?.comment?.pId && c?.parentId !== payload?.comment.pId
                  )
                }
              : comData
          );
          draft.homePageContent = state.homePageContent.map((el) =>
            el?.pId === payload?.comment?.elementId ? { ...el, commentsCount: el?.commentsCount - 1 } : el
          );
        }
        break;
      case COMMENT.POST.SUCCESS:
        {
          let elementId = 0;
          state.homePageComments.map((comData) => {
            if (comData?.elementId === payload?.comment?.elementId) {
              elementId = comData?.elementId;
              const updatedComments = [...comData.comments];
              updatedComments.push(payload?.comment);
              return { ...comData, comments: updatedComments };
            }
            return comData;
          });
          const updatedComments = [...state.homePageComments];
          if (elementId === 0) {
            updatedComments.push({ elementId: payload?.comment?.elementId, comments: [payload?.comment] });
            draft.homePageContent = state.homePageContent.map((el) =>
              el?.pId === payload?.comment?.elementId ? { ...el, commentsCount: 1 } : el
            );
          }
          draft.homePageComments = updatedComments;
          draft.homePageContent = state.homePageContent.map((el) =>
            el?.pId === payload?.comment?.elementId ? { ...el, commentsCount: el.commentsCount + 1 } : el
          );
        }
        break;
      case COMMENT.PUT.SUCCESS:
      case COMMENT_LIKE.POST.SUCCESS:
        {
          draft.isFetchingElementComments = 0;
          draft.homePageComments = state.homePageComments.map((comData) =>
            comData?.elementId === payload?.comment?.elementId
              ? {
                  ...comData,
                  comments: comData?.comments.map((c) =>
                    c?.pId === payload?.comment?.pId ? { ...payload.comment } : c
                  )
                }
              : comData
          );
        }
        break;
      default:
        return draft;
    }
  });

export default homePageReducer;
