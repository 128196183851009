import { stringify, escapeSingleAndDoubleQuotes, stringToList } from './utils';
import { contestData, actionData } from './queries';

export function createContestMutation(contest) {
  const countryIds = contest?.countries?.length ? `countryIds: ${stringify(contest.countries.map((c) => c.pId))}` : '';
  const organizationIds = contest?.organizations?.length
    ? `organizationIds: ${stringify(contest.organizations.map((c) => c?.pId))}`
    : '';
  const tagIds = stringify(contest.tagIds);
  const description =
    contest?.description != undefined
      ? `description: [${stringToList(escapeSingleAndDoubleQuotes(contest.description))}]`
      : '';
  const link =
    contest?.applicationLink != undefined
      ? `applicationLink: "${escapeSingleAndDoubleQuotes(contest.applicationLink)}"`
      : '';
  const voteNumber = contest?.voteNumber != undefined ? `voteNumber: ${contest?.voteNumber}` : '';
  const votingDeadline = contest?.votingDeadline != undefined ? `votingDeadline: "${contest?.votingDeadline}"` : '';
  const minAge = contest?.minAge != undefined ? `minAge: ${contest?.minAge}` : '';
  const maxAge = contest?.maxAge != undefined ? `maxAge: ${contest?.maxAge}` : '';
  const budget = contest?.budget != undefined ? `budget: ${contest?.budget}` : '';
  const actionBudget = contest?.actionBudget != undefined ? `actionBudget: ${contest?.actionBudget}` : '';
  const groupActionBudget =
    contest?.groupActionBudget != undefined ? `groupActionBudget: ${contest?.groupActionBudget}` : '';
  const createdBy = contest?.createdBy != undefined ? `createdBy: ${contest?.createdBy}` : '';
  const organizerOrganizationId =
    contest?.organizerOrganizationId != undefined ? `organizerOrganizationId: ${contest?.organizerOrganizationId}` : '';
  const currencyId = contest?.currencyId != undefined ? `currencyId: ${contest.currencyId}` : '';

  return `
    mutation{
      createContest(
        contestData: {
          name: "${escapeSingleAndDoubleQuotes(contest.name)}"
          imageUrl: "${contest.imageUrl}"
          allCountries: ${contest.allCountries}
          allOrganizations: ${contest.allOrganizations}
          allIndividuals: ${contest.allIndividuals}
          applicationsDeadline: "${contest.applicationsDeadline}"
          published: ${contest.published}
          tagIds: ${tagIds}
          applicationType: "${contest.applicationType}"
          applicantType: "${contest.applicantType}"
          ${countryIds}
          ${organizationIds}
          ${currencyId}
          ${description}
          ${budget}
          ${actionBudget}
          ${groupActionBudget}
          ${maxAge}
          ${minAge}
          ${createdBy}
          ${votingDeadline}
          ${voteNumber}
          ${organizerOrganizationId}
          ${link}
        }
      ){
        isRequestSuccessful
        contest {
          ${contestData}
        }
      }
    }
  `;
}

export function updateContestMutation(contest, cognitoId) {
  const description =
    contest?.description != undefined
      ? `description: [${stringToList(escapeSingleAndDoubleQuotes(contest.description))}]`
      : '';
  const name = contest?.name != undefined ? `name: "${escapeSingleAndDoubleQuotes(contest.name)}"` : '';
  const applicationsDeadline =
    contest?.applicationsDeadline != undefined
      ? `applicationsDeadline: "${escapeSingleAndDoubleQuotes(contest.applicationsDeadline)}"`
      : '';
  const votingDeadline =
    contest?.votingDeadline != undefined
      ? `votingDeadline: "${escapeSingleAndDoubleQuotes(contest.votingDeadline)}"`
      : '';
  const link =
    contest?.applicationLink != undefined
      ? `applicationLink: "${escapeSingleAndDoubleQuotes(contest.applicationLink)}"`
      : '';
  const voteNumber = contest?.voteNumber != undefined ? `voteNumber: ${contest?.voteNumber}` : '';
  const imageUrl = contest?.imageUrl != undefined ? `imageUrl: "${contest?.imageUrl}"` : '';
  const minAge = contest?.minAge != undefined ? `minAge: ${contest?.minAge}` : '';
  const maxAge = contest?.maxAge != undefined ? `maxAge: ${contest?.maxAge}` : '';
  const budget = contest?.budget != undefined ? `budget: ${contest?.budget}` : '';
  const actionBudget = contest?.actionBudget != undefined ? `actionBudget: ${contest?.actionBudget}` : '';
  const groupActionBudget =
    contest?.groupActionBudget != undefined ? `groupActionBudget: ${contest?.groupActionBudget}` : '';
  const currencyId = contest?.currencyId != undefined ? `currencyId: ${contest.currencyId}` : '';
  const applicationType = contest?.applicationType != undefined ? `applicationType: "${contest.applicationType}"` : '';
  const published = contest?.published != undefined ? `published: ${contest.published}` : '';
  const allCountries = contest?.allCountries != undefined ? `allCountries: ${contest.allCountries}` : '';
  const allOrganizations =
    contest?.allOrganizations != undefined ? `allOrganizations: ${contest.allOrganizations}` : '';
  const allIndividuals = contest?.allIndividuals != undefined ? `allIndividuals: ${contest.allIndividuals}` : '';

  return `
    mutation{
      updateContest(
        contestData: {
          id: ${contest.pId}
          ${name}
          ${description}
          ${link}
          ${voteNumber}
          ${votingDeadline}
          ${minAge}
          ${maxAge}
          ${budget}
          ${actionBudget}
          ${groupActionBudget}
          ${currencyId}
          ${published}
          ${applicationsDeadline}
          ${applicationType}
          ${allCountries}
          ${allOrganizations}
          ${allIndividuals}
          ${imageUrl}
        }
         cognitoId: "${cognitoId}"
      ){
        isRequestSuccessful
        contest {
          ${contestData}
        }
      }
    }
  `;
}

export function deleteContestMutation(contestId, cognitoId) {
  return `
    mutation{
      deleteContest(
        id: ${contestId}
        cognitoId: "${cognitoId}"
      ){
        isRequestSuccessful
        contestId
    }
  }
  `;
}

export function createOrDeleteContestsTags(id, tagIds, cognitoId) {
  return `mutation {
    createOrDeleteContestsTags(
      tagIds: ${tagIds}
      id: ${id}
      cognitoId: "${cognitoId}"
    ) {
        isRequestSuccessful
        contest {
          ${contestData}
        }
      }
    }`;
}

export function addContestsDocument(contestId, url, title, cognitoId) {
  const titleStr = title != undefined ? `title: "${escapeSingleAndDoubleQuotes(title)}"` : '';
  return `mutation {
    addContestsDocument (
      elementId: ${contestId}
      url: "${url}"
      ${titleStr}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      contest {
        ${contestData}
      }
    }
  }`;
}

export function updateContestsDocument(id, title, cognitoId) {
  const titleStr = title != undefined ? `title: "${escapeSingleAndDoubleQuotes(title)}"` : '';
  return `mutation {
    updateContestsDocument (
      id: ${id}
      ${titleStr}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      contest {
        ${contestData}
      }
    }
  }`;
}

export function updateContestVisibility(contest, organizationIds, countryIds, cognitoId) {
  const minAge = contest?.minAge != undefined ? `minAge: ${contest?.minAge}` : '';
  const maxAge = contest?.maxAge != undefined ? `maxAge: ${contest?.maxAge}` : '';
  const allCountries = contest?.allCountries != undefined ? `allCountries: ${contest.allCountries}` : '';
  const allOrganizations =
    contest?.allOrganizations != undefined ? `allOrganizations: ${contest.allOrganizations}` : '';
  const allIndividuals = contest?.allIndividuals != undefined ? `allIndividuals: ${contest.allIndividuals}` : '';
  const countries = countryIds != undefined ? `countryIds: ${countryIds}` : '';
  const organizations = organizationIds != undefined ? `organizationIds: ${organizationIds}` : '';
  return `mutation {
    updateContestsVisibility (
      contestVisibilityInputData: {
        id: ${contest.pId}
        applicantType: "${contest.applicantType}"
        ${minAge}
        ${maxAge}
        ${allCountries}
        ${allOrganizations}
        ${allIndividuals}
        ${countries}
        ${organizations}
      }
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      contest {
        ${contestData}
      }
    }
  }`;
}

export function deleteContestsDocument(id, cognitoId) {
  return `mutation {
    deleteContestsDocument (
      id: ${id}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      contest {
        ${contestData}
      }
    }
  }`;
}

export function addContestDocumentsAndImage(contestId, documents, imageUrl, cognitoId) {
  const documentsStr = documents != undefined && documents ? `documents: ${documents}` : '';
  const imageUrlStr = imageUrl != undefined && imageUrl ? `imageUrl: "${imageUrl}"` : '';
  return `mutation {
    addNewContestsDocumentsAndImage (
      newContestInputData: {
        ${documentsStr}
        ${imageUrlStr}
        id: ${contestId}
      }
    cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      contest {
        ${contestData}
      }
    }
  }`;
}

export function addContestsView(contestId, cognitoId) {
  return `mutation {
    addContestsView (
      id: ${contestId}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      contest {
        ${contestData}
      }
    }
  }`;
}

export function backAction(actionId, cognitoId) {
  return `mutation {
    backAction(cognitoId: "${cognitoId}", id: ${actionId}){
      action {
       ${actionData}
      }
      isRequestSuccessful
    }
  }`;
}
