import { action, createRequestTypes } from 'utils/ReduxHelpers';

import { GET } from 'utils/Constants';
import { ICurrency } from 'core/models/Models';
import { ITag } from 'core/models/Models';

export const TAGS = createRequestTypes('TAGS', [GET]);
export const CURRENCIES = createRequestTypes('CURRENCIES', [GET]);
export const SEARCH_ALL_CONTENT = createRequestTypes('SEARCH_ALL_CONTENT', [GET]);

export const getTags = {
  request: () => action(TAGS.GET.REQUEST),
  success: (payload: Array<ITag>) => action(TAGS.GET.SUCCESS, payload),
  failure: (error: any) => action(TAGS.GET.FAILURE, error)
};

export const getCurrencies = {
  request: () => action(CURRENCIES.GET.REQUEST),
  success: (payload: Array<ICurrency>) => action(CURRENCIES.GET.SUCCESS, payload),
  failure: (error: any) => action(CURRENCIES.GET.FAILURE, error)
};

export const searchAllContent = {
  request: (page: number, num?: number, name?: string | null, fetchingNextPage?: boolean, elementType?: string) =>
    action(SEARCH_ALL_CONTENT.GET.REQUEST, { page, num, name, fetchingNextPage, elementType }),
  success: (payload: any) => action(SEARCH_ALL_CONTENT.GET.SUCCESS, payload),
  failure: (error: any) => action(SEARCH_ALL_CONTENT.GET.FAILURE, error)
};
