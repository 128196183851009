import { all, put, takeLatest } from 'redux-saga/effects';
import {
  addComment,
  removeComment,
  updateComment,
  likeOrUnlikeElement,
  likeOrUnlikeComment,
  COMMENT,
  COMMENT_LIKE,
  ELEMENT_LIKE
} from 'core/actions/InteractionActions';
import {
  addContestApplicationComment,
  updateContestApplicationComment,
  removeContestApplicationComment,
  likeOrUnlikeContestApplication,
  likeOrUnlikeContestApplicationComment
} from 'core/actions/ContestApplicationActions';
import { API, Auth } from 'aws-amplify';

import {
  addComment as addCommentMutation,
  updateComment as updateCommentMutation,
  deleteComment as deleteCommentMutation,
  likeOrUnlikeElement as likeOrUnlikeElementMutation,
  likeOrUnlikeComment as likeOrUnlikeCommentMutation
} from 'api/graphql/interactionsMutations';
import * as Alert from 'utils/Alerts';
import i18n from 'utils/i18n';
import { ElementType } from 'core/models/Models';
import {
  addContestComment,
  likeOrUnlikeContest,
  likeOrUnlikeContestAction,
  likeOrUnlikeContestComment,
  likeOrUnlikeContestContestApplication,
  removeContestComment,
  updateContestComment
} from 'core/actions/ContestActions';
import {
  addExpertiseResourceComment,
  addKnowledgeResourceComment,
  addToolResourceComment,
  likeOrUnlikeTooContainedResource,
  likeOrUnlikExpertiseResource,
  likeOrUnlikExpertiseResourceComment,
  likeOrUnlikKnowledgeResource,
  likeOrUnlikKnowledgeResourceComment,
  likeOrUnlikLinkResource,
  likeOrUnlikToolResource,
  likeOrUnlikToolResourceComment,
  removeExpertiseResourceComment,
  removeToolResourceComment,
  updateExpertiseResourceComment,
  updateKnowledgeResourceComment,
  updateToolResourceComment
} from 'core/actions/ResourceActions';
import { profileLikeOrUnlikeContainedResource } from 'core/actions/PersonalProfileActions';
import { organizationLikeOrUnlikeContainedResource } from 'core/actions/OrganizationActions';
import { likeOrUnlikeAction } from 'core/actions/ActionActions';

export function* addCommentSaga({ payload: { comment, elementType } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addCommentMutation(comment.elementId, comment.parentId, comment.text, cognitoId)
      }
    };

    const response = yield API.post('InteractionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.createComment'));
    }
    const newComment = response?.data?.createComment?.comment;
    if (elementType == ElementType.CONTEST_APPLICATION) {
      yield put(addContestApplicationComment.success(newComment));
    } else if (elementType == ElementType.CONTEST) {
      yield put(addContestComment.success(newComment));
    } else if (elementType == ElementType.EXPERTISE_RESOURCE) {
      yield put(addExpertiseResourceComment.success(newComment));
    } else if (elementType == ElementType.KNOWLEDGE_RESOURCE) {
      yield put(addKnowledgeResourceComment.success(newComment));
    } else if (elementType == ElementType.TOOL_RESOURCE) {
      yield put(addToolResourceComment.success(newComment));
    }
    yield put(addComment.success({ comment: newComment, elementType }));
    yield Alert.setSuccessAlert(i18n.t('api.success.createComment'));
  } catch (e) {
    yield put(addComment.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createComment'));
  }
}

export function* updateCommentSaga({ payload: { comment, elementType } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: updateCommentMutation(comment.pId, comment.text, cognitoId)
      }
    };

    const response = yield API.post('InteractionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    const updatedComment = response?.data?.updateComment?.comment;
    if (elementType == ElementType.CONTEST_APPLICATION) {
      yield put(updateContestApplicationComment.success(updatedComment));
    } else if (elementType == ElementType.CONTEST) {
      yield put(updateContestComment.success(updatedComment));
    } else if (elementType == ElementType.EXPERTISE_RESOURCE) {
      yield put(updateExpertiseResourceComment.success(updatedComment));
    } else if (elementType == ElementType.KNOWLEDGE_RESOURCE) {
      yield put(updateKnowledgeResourceComment.success(updatedComment));
    } else if (elementType == ElementType.TOOL_RESOURCE) {
      yield put(updateToolResourceComment.success(updatedComment));
    }
    yield put(updateComment.success({ comment: updatedComment, elementType }));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateComment'));
  } catch (e) {
    yield put(updateComment.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateComment'));
  }
}

export function* removeCommentSaga({ payload: { comment, elementType } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteCommentMutation(comment.pId, cognitoId)
      }
    };

    const response = yield API.post('InteractionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    if (elementType == ElementType.CONTEST_APPLICATION) {
      yield put(removeContestApplicationComment.success(comment));
    } else if (elementType == ElementType.CONTEST) {
      yield put(removeContestComment.success(comment));
    } else if (elementType == ElementType.EXPERTISE_RESOURCE) {
      yield put(removeExpertiseResourceComment.success(comment));
    } else if (elementType == ElementType.TOOL_RESOURCE) {
      yield put(removeToolResourceComment.success(comment));
    }
    yield put(removeComment.success({ comment, elementType }));

    yield Alert.setSuccessAlert(i18n.t('api.success.deleteComment'));
  } catch (e) {
    yield put(removeComment.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteComment'));
  }
}

export function* likeOrUnlikeElementnSaga({ payload: { elementId, userId, elementType, parentId, source, sourceId } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: likeOrUnlikeElementMutation(elementId, userId)
      }
    };

    const response = yield API.post('InteractionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    if (elementType == ElementType.CONTEST_APPLICATION) {
      yield put(likeOrUnlikeContestApplication.success({ elementId, userId }));
    } else if (elementType == ElementType.CONTEST) {
      yield put(likeOrUnlikeContest.success({ elementId, userId }));
    } else if (elementType == ElementType.CONTEST_CONTEST_APPLICATION) {
      yield put(likeOrUnlikeContestContestApplication.success({ elementId, userId, contestId: parentId }));
    } else if (elementType == ElementType.CONTEST_ACTION) {
      yield put(likeOrUnlikeContestAction.success({ elementId, userId, contestId: parentId }));
    } else if (elementType == ElementType.EXPERTISE_RESOURCE) {
      yield put(likeOrUnlikExpertiseResource.success({ elementId, userId }));
    } else if (elementType == ElementType.LINK_RESOURCE) {
      yield put(likeOrUnlikLinkResource.success({ elementId, userId }));
    } else if (elementType == ElementType.KNOWLEDGE_RESOURCE) {
      yield put(likeOrUnlikKnowledgeResource.success({ elementId, userId }));
    } else if (elementType == ElementType.TOOL_RESOURCE) {
      yield put(likeOrUnlikToolResource.success({ elementId, userId }));
    } else if (elementType === ElementType.ACTION) {
      yield put(likeOrUnlikeAction.success({ actionId: elementId, userId }));
    }
    if (source == 'tool') {
      yield put(likeOrUnlikeTooContainedResource.success({ sourceId, elementId, userId }));
    } else if (source == 'profile') {
      yield put(profileLikeOrUnlikeContainedResource.success({ elementId, userId }));
    } else if (source == 'organization') {
      yield put(organizationLikeOrUnlikeContainedResource.success({ sourceId, elementId, userId }));
    }
    yield put(likeOrUnlikeElement.success({ elementId, userId, elementType }));
  } catch (e) {
    yield put(likeOrUnlikeContestApplication.failure(e));
    yield put(likeOrUnlikeElement.failure(e));
  }
}

export function* likeOrUnlikeCommentSaga({ payload: { comment, userId, elementType } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: likeOrUnlikeCommentMutation(comment?.pId, userId)
      }
    };

    const response = yield API.post('InteractionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }

    const updatedComment = response?.data?.likeOrUnlikeComment?.comment;
    if (elementType == ElementType.CONTEST_APPLICATION) {
      yield put(likeOrUnlikeContestApplicationComment.success(updatedComment));
    } else if (elementType == ElementType.CONTEST) {
      yield put(likeOrUnlikeContestComment.success(updatedComment));
    } else if (elementType == ElementType.EXPERTISE_RESOURCE) {
      yield put(likeOrUnlikExpertiseResourceComment.success(updatedComment));
    } else if (elementType == ElementType.KNOWLEDGE_RESOURCE) {
      yield put(likeOrUnlikKnowledgeResourceComment.success(updatedComment));
    } else if (elementType == ElementType.TOOL_RESOURCE) {
      yield put(likeOrUnlikToolResourceComment.success(updatedComment));
    }
    yield put(likeOrUnlikeComment.success({ comment: updatedComment, userId, elementType }));
  } catch (e) {
    yield put(likeOrUnlikeComment.failure(e));
  }
}

function* interactionSaga() {
  yield all([
    takeLatest(COMMENT.POST.REQUEST, addCommentSaga),
    takeLatest(COMMENT.PUT.REQUEST, updateCommentSaga),
    takeLatest(COMMENT.DELETE.REQUEST, removeCommentSaga),
    takeLatest(ELEMENT_LIKE.POST.REQUEST, likeOrUnlikeElementnSaga),
    takeLatest(COMMENT_LIKE.POST.REQUEST, likeOrUnlikeCommentSaga)
  ]);
}

export default interactionSaga;
