import { IAction, IActivity } from 'core/models/Models';
import { produce } from 'immer';
import {
  ALL_ACTIONS,
  ACTION,
  ACTION_TAGS,
  ACTION_PARTICIPANTS,
  UPDATE_ACTION,
  ACTION_CHECK_LIST_ITEMS,
  ACTION_DOCUMENTS,
  ACTION_COMMENTS,
  ACTION_LIKE,
  ACTION_COMMENT_LIKE,
  ACTION_VIEW,
  UPDATE_NEW_ACTION,
  UPDATE_NEW_ACTION_PARTICIPANTS,
  ADD_NEW_ACTION_CHECK_LIST_ITEM,
  UPDATE_NEW_ACTION_CHECK_LIST_ITEM,
  DELETE_NEW_ACTION_CHECK_LIST_ITEM,
  ADD_NEW_ACTION_DOCUMENT,
  UPDATE_NEW_ACTION_DOCUMENT,
  DELETE_NEW_ACTION_DOCUMENT,
  NEW_ACTION,
  UPDATE_NEW_ACTION_TAGS,
  ACTION_ADD_REMOVE_PARTICIPANT,
  JOIN_ACTION,
  ACTION_REMOVE_PARTICIPANT,
  ACTION_VOTE,
  SEARCH_ACTION
} from 'core/actions/ActionActions';

const emptyAction: IAction = {
  attributes: {
    pId: 0,
    id: '',
    name: '',
    imageUrl: '',
    description: '',
    endDate: '',
    published: false,
    likedByIds: [],
    seenByIds: []
  },
  participants: [],
  tags: [],
  checkListItems: [],
  documents: [],
  comments: [],
  history: []
};

export interface IActionState {
  newAction: IAction;
  actions: IAction[];
  actionsLoaded: boolean;
}

const initialState: IActionState = {
  newAction: emptyAction,
  actions: [],
  actionsLoaded: false
};

const actionReducer = (state = initialState, action: IActivity): IActionState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case ALL_ACTIONS.GET.SUCCESS:
        draft.actionsLoaded = true;
        draft.actions = payload;
        break;
      case SEARCH_ACTION.GET.SUCCESS:
        draft.actions = payload;
        draft.actionsLoaded = false;
        break;
      case ACTION.POST.SUCCESS:
        draft.actions.push(payload);
        draft.newAction = { ...state.newAction, ...emptyAction };
        break;
      case ACTION.DELETE.SUCCESS:
        draft.actions = state.actions?.filter((action) => action.attributes?.pId !== payload?.id);
        break;
      case ACTION.GET.SUCCESS:
      case UPDATE_ACTION.POST.SUCCESS:
      case ACTION_PARTICIPANTS.POST.SUCCESS:
      case ACTION_CHECK_LIST_ITEMS.POST.SUCCESS:
      case ACTION_CHECK_LIST_ITEMS.PUT.SUCCESS:
      case ACTION_CHECK_LIST_ITEMS.DELETE.SUCCESS:
      case ACTION_DOCUMENTS.POST.SUCCESS:
      case ACTION_DOCUMENTS.PUT.SUCCESS:
      case ACTION_DOCUMENTS.DELETE.SUCCESS:
      case ACTION_VIEW.POST.SUCCESS:
      case ACTION_ADD_REMOVE_PARTICIPANT.POST.SUCCESS:
      case ACTION_VOTE.POST.SUCCESS:
      case JOIN_ACTION.POST.SUCCESS:
      case ACTION_REMOVE_PARTICIPANT.POST.SUCCESS:
        if (!state.actions.some((item) => item?.attributes?.id == payload?.attributes?.id)) {
          draft.actions.push(payload);
        } else {
          draft.actions = state.actions.map((item) => {
            return item?.attributes?.id == payload?.attributes?.id ? payload : item;
          });
        }
        break;
      case ACTION_COMMENTS.POST.SUCCESS:
        draft.actions = state.actions.map((action) => {
          if (action.attributes?.pId === payload?.newComment?.elementId) {
            const newComments = [...action.comments];
            const newHistory = [...action.history];
            newComments?.push(payload?.newComment);
            newHistory?.push(payload?.history);
            return { ...action, comments: newComments, history: newHistory };
          }
          return action;
        });
        break;
      case ACTION_COMMENTS.PUT.SUCCESS:
        draft.actions = state.actions.map((action) => {
          if (action.attributes?.pId === payload?.elementId) {
            const newComments = action.comments.map((comment) => {
              return comment.id === payload?.id ? payload : comment;
            });
            return { ...action, comments: newComments };
          }
          return action;
        });
        break;
      case ACTION_COMMENTS.DELETE.SUCCESS:
        draft.actions = state.actions.map((action) => {
          if (action.attributes.pId === payload?.comment?.elementId) {
            const newComments = [...action.comments].filter(
              (c) => c?.id !== payload?.comment?.id && c?.parentId != payload?.comment?.id
            );
            const newHistory = [...action.history];
            newHistory?.push(payload?.history);
            return { ...action, comments: newComments, history: newHistory };
          }
          return action;
        });
        break;
      case ACTION_LIKE.POST.SUCCESS:
        draft.actions = state.actions.map((action) => {
          if (action.attributes?.id === payload?.actionId) {
            const likedByIds = action?.attributes?.likedByIds ? [...action.attributes.likedByIds] : [];
            const pUserId = Number(payload.userId);
            if (likedByIds) {
              const index = likedByIds.indexOf(pUserId);
              if (index > -1) {
                likedByIds.splice(index, 1);
              } else {
                likedByIds.push(pUserId);
              }
            }
            const newAttributes = { ...action?.attributes, likedByIds: likedByIds };
            action = { ...action, attributes: newAttributes };
          }
          return action;
        });
        break;
      case ACTION_COMMENT_LIKE.POST.SUCCESS:
        draft.actions = state.actions.map((action) => {
          if (action.attributes.pId === payload?.actionId) {
            const newComments = action.comments.map((comment) => {
              if (comment?.pId === payload?.commentId) {
                const likedByIds = comment?.likedByIds ? [...comment.likedByIds] : [];
                const pUserId = Number(payload.userId);
                if (likedByIds) {
                  const index = likedByIds.indexOf(pUserId);
                  if (index > -1) {
                    likedByIds.splice(index, 1);
                  } else {
                    likedByIds.push(pUserId);
                  }
                }
                comment = { ...comment, likedByIds: likedByIds };
              }
              return comment;
            });
            action = { ...action, comments: newComments };
          }
          return action;
        });
        break;
      case NEW_ACTION: {
        if (payload?.organizationId === null || payload?.organizationId === undefined) {
          delete payload['organizationId'];
        }
        const newAttributes = {
          ...emptyAction.attributes,
          ...payload
        };
        draft.newAction = { ...emptyAction, attributes: newAttributes };
        break;
      }
      case UPDATE_NEW_ACTION: {
        const payloadWithoutEmptyKeys = Object.entries(payload).reduce(
          (a, [k, v]) => (v === null || v === undefined ? a : ((a[k] = v), a)),
          {}
        );
        draft.newAction.attributes = { ...state.newAction.attributes, ...payloadWithoutEmptyKeys };
        break;
      }
      case UPDATE_NEW_ACTION_PARTICIPANTS:
        draft.newAction.participants = payload;
        break;
      case UPDATE_NEW_ACTION_TAGS:
        draft.newAction.tags = payload;
        break;
      case ADD_NEW_ACTION_CHECK_LIST_ITEM:
        draft.newAction.checkListItems.push(payload);
        break;
      case UPDATE_NEW_ACTION_CHECK_LIST_ITEM:
        draft.newAction.checkListItems = state.newAction.checkListItems.map((item) => {
          return item.id === payload.id ? payload : item;
        });
        break;
      case DELETE_NEW_ACTION_CHECK_LIST_ITEM:
        draft.newAction.checkListItems = state.newAction.checkListItems.filter((item) => {
          return item.id !== payload.id;
        });
        break;
      case ADD_NEW_ACTION_DOCUMENT:
        draft.newAction.documents.push(payload);
        break;
      case UPDATE_NEW_ACTION_DOCUMENT:
        draft.newAction.documents = state.newAction.documents.map((item) => {
          return item.id === payload.id ? payload : item;
        });
        break;
      case DELETE_NEW_ACTION_DOCUMENT:
        draft.newAction.documents = state.newAction.documents.filter((item) => {
          return item.id !== payload.id;
        });
        break;
      case ACTION_TAGS.POST.SUCCESS:
        draft.actions = state.actions.map((action) =>
          action.attributes?.pId === payload.attributes.pId ? payload : action
        );
        break;
      default:
        return draft;
    }
  });

export default actionReducer;
