import {
  CURRENCIES,
  getCurrencies,
  getTags,
  searchAllContent,
  SEARCH_ALL_CONTENT,
  TAGS
} from '../actions/CommonActions';
import { put, all, takeLatest } from 'redux-saga/effects';
import { listCurrencies, listTags, searchAllContentQuery } from 'api/graphql/queries';
import { API, Auth } from 'aws-amplify';
import * as Alert from 'utils/Alerts';
import i18n from 'utils/i18n';

export function* fetchCurrenciesSaga() {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: listCurrencies()
      }
    };

    const {
      data: { currencies }
    } = yield API.post('CommonsAPI', '/graphql?query', requestInfo);

    yield put(getCurrencies.success(currencies));
  } catch (e: any) {
    yield put(getCurrencies.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getCurrencies'));
  }
}

export function* fetchTagsSaga() {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: listTags()
      }
    };

    const {
      data: { tags }
    } = yield API.post('CommonsAPI', '/graphql?query', requestInfo);

    yield put(getTags.success(tags));
  } catch (e: any) {
    yield put(getTags.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getTags'));
  }
}

export function* searchAllContentSaga({ payload: { name, page, num, tagIds, fetchingNextPage, elementType } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: searchAllContentQuery(cognitoId, page, num, name, tagIds, elementType)
      }
    };

    const result = yield API.post('CommonsAPI', '/graphql?query', requestInfo);

    if (result?.errors) {
      throw new Error(result?.errors);
    }

    yield put(searchAllContent.success(
      {
        data: result?.data?.searchAll,
        elementType: elementType,
        searchParam: name,
      }
    ));
  } catch (e: any) {
    yield put(searchAllContent.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getTags'));
  }
}

function* commonSaga() {
  yield all([
    takeLatest(CURRENCIES.GET.REQUEST, fetchCurrenciesSaga),
    takeLatest(TAGS.GET.REQUEST, fetchTagsSaga),
    takeLatest(SEARCH_ALL_CONTENT.GET.REQUEST, searchAllContentSaga)
  ]);
}

export default commonSaga;
