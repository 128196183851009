/* eslint-disable prettier/prettier */
import { Avatar } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useInView } from 'react-cool-inview';
import { Image } from '@aws-amplify/ui-react';
import { Storage } from '@aws-amplify/storage';
import { AccessLevel } from 'core/models/Models';
import { splitS3Url } from 'utils/Helpers';
import { useEffect } from 'react';
import { useStyles } from './S3Avatar.styles';

interface Props {
  imageUrl?: string;
  imgClassName?: any;
  avatarClassName?: any;
  avatarProps?: any;
  avatarVariant?: 'square' | 'circular' | 'rounded' | undefined;
  onClick?: () => void;
}

function S3Avatar({
  onClick,
  imageUrl,
  imgClassName,
  avatarClassName,
  avatarProps,
  avatarVariant
}: Props): ReactElement {
  const classes = useStyles();
  const [url, setUrl] = useState('');
  const { observe, inView } = useInView({ threshold: 0.25, unobserveOnEnter: true, rootMargin: '50px' });

  useEffect(() => {
    const getSignedUrl = async () => {
      const splitUrl = splitS3Url(imageUrl);
      const identity = splitUrl[0];
      const key = splitUrl[1];
      const signedUrl = await Storage.get(key, {
        level: identity ? AccessLevel.Protected : AccessLevel.Public,
        identityId: identity,
        expires: 60 * 60 * 24 * 7
      });
      setUrl(signedUrl);
    };
    if (imageUrl && imageUrl.includes('amazon')) {
      getSignedUrl();
    }
  }, [imageUrl]);

  const renderImage = () => {
    if (url) {
      return (
        <Avatar className={avatarClassName} {...avatarProps} variant={avatarVariant ?? 'circular'} onClick={onClick}>
          <Image alt='avatar' className={`${classes.image} ${imgClassName}`} src={url} />
        </Avatar>
      );
    }
    return (
      <Avatar
        className={avatarClassName}
        src={imageUrl}
        {...avatarProps}
        variant={avatarVariant ?? 'circular'}
        onClick={onClick}
      />
    );
  };

  return (
    <React.Fragment>
      <span ref={observe}></span>
      {inView ? renderImage() : null}
    </React.Fragment>
  );
}

export default S3Avatar;
