import { action, createRequestTypes } from 'utils/ReduxHelpers';

import { GET, POST } from 'utils/Constants';
import { IDiscourseCategory, INotification } from 'core/models/Models';
import { JsonValue } from 'react-use-websocket/dist/lib/types';

export const NOTIFICATIONS = createRequestTypes('NOTIFICATIONS', [GET, POST]);
export const DISCOURSE_CATEGORIES = createRequestTypes('DISCOURSE_CATEGORIES', [GET, POST]);
export const ADD_NEW_NOTIFICATION = 'ADD_NEW_NOTIFICATION';

export const getDiscourseUserNotifications = {
  request: (userId: number) => action(NOTIFICATIONS.GET.REQUEST, { userId }),
  success: (payload: any) => action(NOTIFICATIONS.GET.SUCCESS, payload),
  failure: (error: string) => action(NOTIFICATIONS.GET.FAILURE, error)
};

export const getDiscourseUserCategories = {
  request: (userId: string) => action(DISCOURSE_CATEGORIES.GET.REQUEST, { userId }),
  success: (payload: Array<IDiscourseCategory>) => action(DISCOURSE_CATEGORIES.GET.SUCCESS, payload),
  failure: (error: string) => action(DISCOURSE_CATEGORIES.GET.FAILURE, error)
};

export const updateDiscourseUserCategories = {
  request: (categories: IDiscourseCategory[], discourseUsername: string) =>
    action(DISCOURSE_CATEGORIES.POST.REQUEST, { categories, discourseUsername }),
  success: (categories: IDiscourseCategory[]) => action(DISCOURSE_CATEGORIES.POST.SUCCESS, categories),
  failure: (error: any) => action(DISCOURSE_CATEGORIES.POST.FAILURE, error)
};

export const markAllUsersNotificationsAsRead = {
  request: (userId: string) => action(NOTIFICATIONS.POST.REQUEST, { userId }),
  success: () => action(NOTIFICATIONS.POST.SUCCESS),
  failure: (error: string) => action(NOTIFICATIONS.POST.FAILURE, error)
};

export const addNewNotification = (notification: INotification | JsonValue) =>
  action(ADD_NEW_NOTIFICATION, notification);
