import { action, createRequestTypes } from 'utils/ReduxHelpers';

import { GET, POST, DELETE, PUT } from 'utils/Constants';
import { IMember, IOrganization, IProject, ISocialNetwork, ITag, IUpdateMember } from 'core/models/Models';

export const ORGANIZATIONS = createRequestTypes('ORGANIZATIONS', [GET]);
export const ORGANIZATIONS_OPTIMIZED = createRequestTypes('ORGANIZATIONS_OPTIMIZED', [GET]);
export const ORGANIZATION = createRequestTypes('ORGANIZATION', [GET, POST, DELETE]);
export const ORGANIZATION_SOCIAL_NETWORKS = createRequestTypes('ORGANIZATION_SOCIAL_NETWORKS', [POST]);
export const ORGANIZATION_TAGS = createRequestTypes('ORGANIZATION_TAGS', [POST]);
export const ORGANIZATION_MEMBERS = createRequestTypes('ORGANIZATION_MEMBERS', [POST]);
export const ORGANIZATION_INFO = createRequestTypes('ORGANIZATION_INFO', [POST]);
export const ORGANIZATION_PROJECT = createRequestTypes('ORGANIZATION_PROJECT', [POST, PUT, DELETE]);
export const ORGANIZATION_CONTAINED_RESOURCE_LIKE = createRequestTypes('ORGANIZATION_CONTAINED_RESOURCE_LIKE', [POST]);

export const getOrganization = {
  request: (id: number) => action(ORGANIZATION.GET.REQUEST, { id }),
  success: (payload: IOrganization) => action(ORGANIZATION.GET.SUCCESS, payload),
  failure: (error: string) => action(ORGANIZATION.GET.FAILURE, error)
};

export const getOrganizations = {
  request: () => action(ORGANIZATIONS.GET.REQUEST),
  success: (payload: Array<IOrganization>) => action(ORGANIZATIONS.GET.SUCCESS, payload),
  failure: (error: string) => action(ORGANIZATIONS.GET.FAILURE, error)
};

export const getOptimizedOrganizations = {
  request: () => action(ORGANIZATIONS_OPTIMIZED.GET.REQUEST),
  success: (payload: Array<IOrganization>) => action(ORGANIZATIONS_OPTIMIZED.GET.SUCCESS, payload),
  failure: (error: string) => action(ORGANIZATIONS_OPTIMIZED.GET.FAILURE, error)
};

export const createOrganization = {
  request: (organization: IOrganization) => action(ORGANIZATION.POST.REQUEST, { organization }),
  success: (payload: any) => action(ORGANIZATION.POST.SUCCESS, payload),
  failure: (error: string) => action(ORGANIZATION.POST.FAILURE, error)
};

export const updateOrganizationSocialNetworks = {
  request: (socialNetworks: ISocialNetwork[], organizationId: number) =>
    action(ORGANIZATION_SOCIAL_NETWORKS.POST.REQUEST, { socialNetworks, organizationId }),
  success: (organizationId: number, socialNetworks: ISocialNetwork[]) =>
    action(ORGANIZATION_SOCIAL_NETWORKS.POST.SUCCESS, { organizationId, socialNetworks }),
  failure: (error: string) => action(ORGANIZATION_SOCIAL_NETWORKS.POST.FAILURE, error)
};

export const updateOrganizationTags = {
  request: (tagIds: Array<number>, organizationId: number) =>
    action(ORGANIZATION_TAGS.POST.REQUEST, { tagIds, organizationId }),
  success: (organizationId: number, tags: ITag[]) => action(ORGANIZATION_TAGS.POST.SUCCESS, { organizationId, tags }),
  failure: (error: string) => action(ORGANIZATION_TAGS.POST.FAILURE, error)
};

export const updateOrganizationMembers = {
  request: (members: IUpdateMember[], organizationId: number) =>
    action(ORGANIZATION_MEMBERS.POST.REQUEST, { members, organizationId }),
  success: (organizationId: number, members: IMember[]) =>
    action(ORGANIZATION_MEMBERS.POST.SUCCESS, { organizationId, members }),
  failure: (error: string) => action(ORGANIZATION_MEMBERS.POST.FAILURE, error)
};

export const upateOrganizationInfo = {
  request: (data: any) => action(ORGANIZATION_INFO.POST.REQUEST, { data }),
  success: (payload: any) => action(ORGANIZATION_INFO.POST.SUCCESS, payload),
  failure: (error: string) => action(ORGANIZATION_INFO.POST.FAILURE, error)
};

export const deleteOrganization = {
  request: (id: number) => action(ORGANIZATION.DELETE.REQUEST, { id }),
  success: (payload: any) => action(ORGANIZATION.DELETE.SUCCESS, payload),
  failure: (error: string) => action(ORGANIZATION.DELETE.FAILURE, error)
};

export const updateOrganizationProject = {
  request: (project: IProject, organizationId: number) =>
    action(ORGANIZATION_PROJECT.PUT.REQUEST, { project, organizationId }),
  success: (project: IProject, organizationId: number) =>
    action(ORGANIZATION_PROJECT.PUT.SUCCESS, { project, organizationId }),
  failure: (error: string) => action(ORGANIZATION_PROJECT.PUT.FAILURE, error)
};

export const createOrganizationProject = {
  request: (project: IProject, organizationId: number) =>
    action(ORGANIZATION_PROJECT.POST.REQUEST, { project, organizationId }),
  success: (project: IProject, organizationId: number) =>
    action(ORGANIZATION_PROJECT.POST.SUCCESS, { project, organizationId }),
  failure: (error: string) => action(ORGANIZATION_PROJECT.POST.FAILURE, error)
};

export const deleteOrganizationProject = {
  request: (id: number, organizationId: number) => action(ORGANIZATION_PROJECT.DELETE.REQUEST, { id, organizationId }),
  success: (projectId: number, organizationId: number) =>
    action(ORGANIZATION_PROJECT.DELETE.SUCCESS, { projectId, organizationId }),
  failure: (error: string) => action(ORGANIZATION_PROJECT.DELETE.FAILURE, error)
};

export const organizationLikeOrUnlikeContainedResource = {
  request: (organizationId, resourceId, userId) =>
    action(ORGANIZATION_CONTAINED_RESOURCE_LIKE.POST.REQUEST, { organizationId, resourceId, userId }),
  success: (payload: any) => action(ORGANIZATION_CONTAINED_RESOURCE_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(ORGANIZATION_CONTAINED_RESOURCE_LIKE.POST.FAILURE, error)
};
