import { produce } from 'immer';
import { IActivity, IPersonalProfile, IUser } from 'core/models/Models';
import { USERS, USER_PROFILE } from 'core/actions/UserActions';

export interface IUsersState {
  users: IUser[];
  user: IPersonalProfile;
}

const initialState: IUsersState = {
  users: [],
  user: {} as IPersonalProfile
};

const userReducer = (state = initialState, action: IActivity): IUsersState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case USERS.GET.SUCCESS:
        draft.users = payload;
        break;
      case USER_PROFILE.GET.SUCCESS:
        draft.user = payload;
        draft.user.knowledgeResources = payload?.knowledgeResources?.map((resource) => {
          return { attributes: { ...resource } };
        });
        draft.user.expertiseResources = payload?.expertiseResources?.map((resource) => {
          return { attributes: { ...resource } };
        });
        draft.user.linkResources = payload?.linkResources?.map((resource) => {
          return { attributes: { ...resource } };
        });
        draft.user.toolResources = payload?.toolResources?.map((resource) => {
          return {
            attributes: { ...resource },
            knowledgeResources: resource.toolsKnowledgeResources,
            linkResources: resource.toolsLinkResources,
            expertiseResources: resource.toolsExpertiseResources,
            documents: resource.toolsDocuments
          };
        });
        draft.user.actions = payload?.actions?.map((action) => {
          return { attributes: { ...action } };
        });
        draft.user.contests = payload?.contests?.map((contest) => {
          return { attributes: { ...contest } };
        });
        break;
      default:
        return draft;
    }
  });

export default userReducer;
