import { DELETE, GET, POST, PUT } from 'utils/Constants';
import { action, createRequestTypes } from 'utils/ReduxHelpers';
import { INotificationSettings, IPersonalProfile, ISignupForm, ISocialNetwork, ITag } from 'core/models/Models';
interface FetchPersonalProfileSuccessPayload {
  user: IPersonalProfile;
}

interface IUpdateProfileRequestPayload {
  bio?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  imageFile?: File;
  imageUrl?: string;
}

export const PERSONAL_PROFIL = createRequestTypes('PERSONAL_PROFIL', [GET, POST, PUT]);
export const PROFILE_TAGS = createRequestTypes('PROFILE_TAGS', [POST]);
export const UPDATE_PERSONAL_PROFILE = createRequestTypes('UPDATE_PERSONAL_PROFILE', [POST]);
export const PROFILE_IMAGE = createRequestTypes('PROFILE_IMAGE', [POST]);
export const PROFILE_SOCIAL_NETWORKS = createRequestTypes('PROFILE_SOCIAL_NETWORKS', [POST]);
export const PROFILE_GENERAL_INFO = createRequestTypes('PROFILE_GENERAL_INFO', [POST]);
export const PROFILE_EDUCATION = createRequestTypes('PROFILE_EDUCATION', [POST, PUT, DELETE]);
export const PROFILE_WORK_EXPERIENCE = createRequestTypes('PROFILE_WORK_EXPERIENCE', [POST, PUT, DELETE]);
export const UPLOAD_IMAGE = createRequestTypes('UPLOAD_IMAGE', [POST]);
export const PROFILE_NOTIFICATIONS = createRequestTypes('PROFILE_NOTIFICATIONS', [POST]);
export const PROFILE_CONTAINED_RESOURCE_LIKE = createRequestTypes('PROFILE_CONTAINED_RESOURCE_LIKE', [POST]);

export const getPersonalProfileByCID = {
  request: (id?: string) => action(PERSONAL_PROFIL.GET.REQUEST, { id }),
  success: (payload: FetchPersonalProfileSuccessPayload) => action(PERSONAL_PROFIL.GET.SUCCESS, payload),
  failure: (error: any) => action(PERSONAL_PROFIL.GET.FAILURE, error)
};

export const uploadImage = {
  request: (file: any, data: ISignupForm) => action(UPLOAD_IMAGE.POST.REQUEST, { file, data }),
  success: (payload: ISignupForm) => action(UPLOAD_IMAGE.POST.SUCCESS, payload),
  failure: (payload: any) => action(UPLOAD_IMAGE.POST.FAILURE, payload)
};

export const createPersonalProfile = {
  request: (email: string, password: string) => action(PERSONAL_PROFIL.POST.REQUEST, { email, password }),
  success: (payload: any) => action(PERSONAL_PROFIL.POST.SUCCESS, payload),
  failure: (error: any) => action(PERSONAL_PROFIL.POST.FAILURE, error)
};

export const updateProfileTags = {
  request: (tags: Array<ITag>, data?: any) => action(PROFILE_TAGS.POST.REQUEST, { tags, data }),
  success: (payload: any) => action(PROFILE_TAGS.POST.SUCCESS, payload),
  failure: (error: any) => action(PROFILE_TAGS.POST.FAILURE, error)
};

export const updatePersonalProfile = {
  request: (data: IUpdateProfileRequestPayload) => action(UPDATE_PERSONAL_PROFILE.POST.REQUEST, data),
  success: (payload: any) => action(UPDATE_PERSONAL_PROFILE.POST.SUCCESS, payload),
  failure: (error: any) => action(UPDATE_PERSONAL_PROFILE.POST.FAILURE, error)
};

export const updateProfileImage = {
  request: (imageFile: any, imageUrl: string) => action(PROFILE_IMAGE.POST.REQUEST, { imageFile, imageUrl }),
  success: (payload: any) => action(PROFILE_IMAGE.POST.SUCCESS, payload),
  failure: (error: any) => action(PROFILE_IMAGE.POST.FAILURE, error)
};

export const updateProfileSocialNetworks = {
  request: (socialNetworks: ISocialNetwork[]) => action(PROFILE_SOCIAL_NETWORKS.POST.REQUEST, { socialNetworks }),
  success: (payload: any) => action(PROFILE_SOCIAL_NETWORKS.POST.SUCCESS, payload),
  failure: (error: any) => action(PROFILE_SOCIAL_NETWORKS.POST.FAILURE, error)
};

export const updateProfileGeneralInfo = {
  request: (gneralInfo: any) => action(PROFILE_GENERAL_INFO.POST.REQUEST, gneralInfo),
  success: (payload: any) => action(PROFILE_GENERAL_INFO.POST.SUCCESS, payload),
  failure: (error: any) => action(PROFILE_GENERAL_INFO.POST.FAILURE, error)
};

export const createProfileEducation = {
  request: (education: any) => action(PROFILE_EDUCATION.POST.REQUEST, { education }),
  success: (payload: any) => action(PROFILE_EDUCATION.POST.SUCCESS, payload),
  failure: (error: any) => action(PROFILE_EDUCATION.POST.FAILURE, error)
};

export const updateProfileEducation = {
  request: (education: any) => action(PROFILE_EDUCATION.PUT.REQUEST, { education }),
  success: (payload: any) => action(PROFILE_EDUCATION.PUT.SUCCESS, payload),
  failure: (error: any) => action(PROFILE_EDUCATION.PUT.FAILURE, error)
};

export const deleteProfileEducation = {
  request: (id: string) => action(PROFILE_EDUCATION.DELETE.REQUEST, { id }),
  success: (payload: any) => action(PROFILE_EDUCATION.DELETE.SUCCESS, payload),
  failure: (error: any) => action(PROFILE_EDUCATION.DELETE.FAILURE, error)
};

export const updateProfileWorkExperience = {
  request: (workExperience: any) => action(PROFILE_WORK_EXPERIENCE.PUT.REQUEST, { workExperience }),
  success: (isHeaderWorkBio: boolean, payload: any) =>
    action(PROFILE_WORK_EXPERIENCE.PUT.SUCCESS, { isHeaderWorkBio, ...payload }),
  failure: (error: any) => action(PROFILE_WORK_EXPERIENCE.PUT.FAILURE, error)
};

export const createProfileWorkExperience = {
  request: (workExperience: any) => action(PROFILE_WORK_EXPERIENCE.POST.REQUEST, { workExperience }),
  success: (payload: any) => action(PROFILE_WORK_EXPERIENCE.POST.SUCCESS, payload),
  failure: (error: any) => action(PROFILE_WORK_EXPERIENCE.POST.FAILURE, error)
};

export const deleteProfileWorkExperience = {
  request: (id: string) => action(PROFILE_WORK_EXPERIENCE.DELETE.REQUEST, { id }),
  success: (payload: any) => action(PROFILE_WORK_EXPERIENCE.DELETE.SUCCESS, payload),
  failure: (error: any) => action(PROFILE_WORK_EXPERIENCE.DELETE.FAILURE, error)
};

export const updateUserNotifications = {
  request: (notifications: INotificationSettings, userId: string) =>
    action(PROFILE_NOTIFICATIONS.POST.REQUEST, { notifications, userId }),
  success: (payload: any) => action(PROFILE_NOTIFICATIONS.POST.SUCCESS, payload),
  failure: (error: string) => action(PROFILE_NOTIFICATIONS.POST.FAILURE, error)
};

export const profileLikeOrUnlikeContainedResource = {
  request: (resourceId, userId) => action(PROFILE_CONTAINED_RESOURCE_LIKE.POST.REQUEST, { resourceId, userId }),
  success: (payload: any) => action(PROFILE_CONTAINED_RESOURCE_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(PROFILE_CONTAINED_RESOURCE_LIKE.POST.FAILURE, error)
};
