import { produce } from 'immer';
import { IActivity } from 'core/models/Models';

const loadingReducer = (state = {}, action: IActivity) => {
  return produce(state, (draft) => {
    const { type } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

    // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
    if (!matches) return state;

    const [, requestName, requestState] = matches;

    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    // and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    draft[requestName + '_REQUEST'] = requestState === 'REQUEST';
  });
};

export default loadingReducer;
