import { stringify } from './utils';

const containedResources = `
knowledgeResources {
  id
  pId
  name
  description
  imageUrl
  createdBy
  documentType
  likedByIds
  seenByIds
  createdBy
  commentsCount
  author {
    name
    imageUrl
  }
  creatorOrganization {
    name
    imageUrl
  }
  allTags {
    pId
    id: pId
    name
    type
  }
}
linkResources {
  id
  pId
  name
  description
  createdBy
  likedByIds
  commentsCount
  link
  allTags {
    pId
    name
    type
    id: pId
  }
}
expertiseResources {
  id
  pId
  imageUrl
  name
  likedByIds
  seenByIds
  commentsCount
  expertiseArea
  allTags {
    pId
    name
    type
    id: pId
  }
}
`;

const containedToolResources = `
toolResources {
  id
  pId
  name
  description
  imageUrl
  likedByIds
  seenByIds
  createdBy
  commentsCount
  allTags {
    pId
    name
    type
  }
  toolsKnowledgeResources {
    id
    pId
    name
  }
  toolsLinkResources {
    id
    pId
    name
  }
  toolsExpertiseResources {
    id
    pId
    name
  }
  toolsDocuments {
    id
    pId
    title
  }
}
`;

const userData = `
  userId: id
  firstName
  lastName
  name
  title
  bio
  imageUrl
  birthDate
  headerWorkBioId
  userCountryId: countryId
  city
  email
  pets
  hobby
  organization {
    edges {
      node {
        name
        imageUrl
      }
    }
  }
  workBios {
    edges {
      node {
        id
        position
        workplace
        toDate
        fromDate
        imageUrl
        location
        organizationId
      }
    }
  }
  education {
    edges {
      node {
        id
        schoolOrCourse
        certificate
        toDate
        fromDate
        location
      }
    }
  }
  socialNetwork {
    edges {
      node {
        name
        url
        id
      }
    }
  }
`;

export function getUserByCognitoId(cognitoId) {
  return `{
    user(cognitoId: "${cognitoId}") {
      ${userData}
    }
  }`;
}

export function getUserProfileById(profileId) {
  return `{
    user(userId: ${profileId}){
      attributes {
        pId
        id
        firstName
        lastName
        name
        title
        showCustomTitle
        bio
        imageUrl
        birthDate
        headerWorkBioId
        countryId
        city
        email
        pets
        hobby
        discourseUsername
        discourseId
      }
      tags {
        id
        name
        url
        type
      }
      organizations {
        role
        organization {
          id
          pId
          name
          imageUrl
        }
      }
      workBios {
        id
        position
        workplace
        toDate
        fromDate
        imageUrl
        location
        organizationId
      }
      education {
        id
        schoolOrCourse
        certificate
        toDate
        fromDate
        location
      }
      socialNetworks {
        name
        url
        id
      }
      ${containedResources}
      ${containedToolResources}
      actions {
        ${containedActionData}
      }
      contests {
        ${containedContest}
      }
    }
  }`;
}

export function getPersonalProfileByCognitoId(cognitoId) {
  return `{
    personalProfile(cognitoId: "${cognitoId}") {
      user {
        attributes {
          pId
          id
          firstName
          lastName
          name
          title
          showCustomTitle
          bio
          imageUrl
          birthDate
          headerWorkBioId
          countryId
          city
          email
          pets
          hobby
          discourseUsername
          discourseId
          organizationName
          signature
        }
        tags {
          id
          pId
          name
          url
          type
        }
        organizations {
          role
          organization {
            id
            pId
          	name
          	imageUrl
            countryId
          }
        }
        workBios {
          id
          position
          workplace
          toDate
          fromDate
          imageUrl
          location
          organizationId
        }
        education {
          id
          schoolOrCourse
          certificate
          toDate
          fromDate
          location
        }
        socialNetworks {
          name
          url
          id
        }
        notificationSettings {
          forumEmailOn
          forumToastOn
          actionsEmailOn
          actionsToastOn
          contestsEmailOn
          contestsToastOn
          resourcesEmailOn
          resourcesToastOn
          commentsEmailOn
          commentsToastOn
          calendarEmailOn
          calendarToastOn
          mentionsEmailOn
          mentionsToastOn
        }
      }
      countries {
        pId
        id
        name
      }
      tags {
        pId
        id
        name
        url
        type
      }
      ${containedResources}
      ${containedToolResources}
      actions {
        ${containedActionData}
      }
      contests {
        ${containedContest}
      }
    }
  }`;
}

export function listOrganizations() {
  return `{
    allOrganizations {
      attributes {
        about
        description
        city
        countryId
        address
        phoneNumber
        email
        name
        id
        pId
        imageUrl
      }
      socialNetworks {
        name
        url
        pId
        id
      }
      tags {
        pId
        id
        name
        type
        url
      }
      members {
        member: user {
          name
          pId
          imageUrl
        }
        role
      }
      projects {
        pId
        name
        description
        location
        fromDate
        toDate
        organizationId
      }
    }
  }`;
}
export function listOrganizationsOptimized() {
  return `{
    allOrganizations {
      attributes {
        city
        countryId
        address
        phoneNumber
        email
        name
        id
        pId
        imageUrl
      }
      members {
        member: user {
          name
          pId
          imageUrl
        }
        role
      }
    }
  }`;
}

export function listTags() {
  return `{
    tags {
      pId
      id
      name
      url
      type
    }
  }`;
}

export function listCountries() {
  return `{
    countries {
      pId
      name
      id
    }
  }`;
}

export function listCurrencies() {
  return `{
    currencies {
      pId
      name
      code
      id
    }
  }`;
}

const notificationData = `
  id
  pId
  timestamp
  notificationSource
  text
  read
  userAuthor {
    name
    imageUrl
  }
  organizationAuthor {
    name
    imageUrl
  }
`;

export function listUsersNotifications(userId) {
  return `{
    allUsersNotifications(
      userId: ${userId}
    ){
      platformNotifications{
        ${notificationData}
        actionId
        contestId
        resourceId
        userId
      }
      discourseNotifications{
        ${notificationData}
        url
      }
      unreadNotificationsCount
    }
  }`;
}

export function usersDiscourseCategories(userId) {
  return `{
    usersDiscourseCategories(userId: ${userId}){
      id
      name
      watched
    }
  }`;
}

export function getInvitationByEmailHash(receiverEmailHash) {
  return `{
    invitation(
      payloadHash: "${receiverEmailHash}"
      invitationType: 1
    ) {
      receiverEmail
      id
    }
  }`;
}

export function getSentInvitation(receiverEmail) {
  return `{
    invitation(
      receiverEmail: "${receiverEmail}"
      invitationType: 1
      invitationStatuses: [1, 2]
    ) {
      id
    }
  }`;
}

export const optimizedAction = `
  attributes {
    pId
    id
    name
    imageUrl
    description
    createdBy
    endDate
    published
    backedPercentage
    organizerOrganizationId
    likedByIds
    seenByIds
    backedByIds
    commentsCount
    contestId
    isGroupApplication
    allUserParticipantsIds
    isBacked
    isVotingActive
    organizerUser{
      id
      firstName
      lastName
      name
      imageUrl
    }
    organizerOrganization{
      id
      name
      imageUrl
    }
    contest {
      pId
      supportType
      createdBy
      budget
    }
  }
  tags {
    pId
    id
    name
    url
    type
  }
  participants: combinedParticipants {
    name,
    memberType,
    pId
  }
`;

export const actionData = `attributes {
    pId
    id
    name
    imageUrl
    description
    createdBy
    endDate
    published
    backedPercentage
    organizerOrganizationId
    likedByIds
    seenByIds
    backedByIds
    contestId
    isGroupApplication
    allUserParticipantsIds
    isBacked
    isVotingActive
    organizerUser{
      id
      firstName
      lastName
      name
      imageUrl
    }
    organizerOrganization{
      id
      name
      imageUrl
      members {
        user {
          pId
        }
        role
      }
    }
    contest {
      id
      pId
      name
      supportType
      budget
      remainingBudget
      actionBudget
      groupActionBudget
      organizerOrganizationId
      createdBy
      voteNumber
      organizerOrganization {
        name
        imageUrl
      }
      currency {
        code
      }
    }
  }
  tags {
    pId
    id
    name
    url
    type
  },
  participants: combinedParticipants {
    name,
    imageUrl,
    memberType,
    pId
  }
  checkListItems {
    id
    text
    actionId
    done
  }
  documents {
    id
    url
    title
    actionId: elementId
  }
  comments {
    id
    pId
    elementId
    userId
    text
    parentId
    timestamp
    likedByIds
    author {
      id
      pId
      firstName
      lastName
      name
      imageUrl
      signature
    }
  }
  history {
    text
    id
    author: combinedAuthor {
      pId
      imageUrl
      name
    }
    timestamp
  }
  `;

export const containedActionData = `
  pId
  id
  name
  imageUrl
  description
  endDate
  backedPercentage
  organizerOrganizationId
  likedByIds
  seenByIds
  backedByIds
  isBacked
  isVotingActive
  commentsCount
  allTags {
    pId
    name
    type
  }
  organizerUser{
    id
    firstName
    lastName
    name
    imageUrl
  }
  organizerOrganization{
    id
    name
    imageUrl
    members {
      user {
        pId
      }
      role
    }
  }
`;

export function getAllActions(cognitoId) {
  return `
  {
    usersActions(cognitoId: "${cognitoId}") {
      ${optimizedAction}
    }
  }`;
}

export function getAction(id, cognitoId) {
  return `
  {
    expandedAction(
      id: ${id}
      cognitoId: ${cognitoId}
    ) {
      ${actionData}
    }
  }`;
}

export function getUsers() {
  return `
  {
    users {
      pId
      id
      firstName
      lastName
      name
      imageUrl
      organizations {
        role
        organization {
          id
          pId
          name
          imageUrl
        }
      }
    }
  }`;
}

export function getOrganizationQuery(id) {
  return `
    {
      organization(id: ${id}) {
        attributes {
          about
          description
          city
          countryId
          address
          phoneNumber
          imageUrl
          email
          name
          id
          pId
        }
        socialNetworks {
          name
          url
          pId
          id
        }
        tags {
          pId
          id
          name
          type
          url
        }
        members {
          member: user {
            name
            pId
            imageUrl
          }
          role
        }
        projects {
          pId
          name
          description
          location
          fromDate
          toDate
          organizationId
        }
        ${containedResources}
        ${containedToolResources}
        actions {
          ${containedActionData}
        }
        contests {
          ${containedContest}
        }
      }
    }
  `;
}

export const contestData = `
  attributes {
    pId
    id
    name
    imageUrl
    budget
    remainingBudget
    actionBudget
    groupActionBudget
    description
    createdBy
    applicationsDeadline
    votingDeadline
    published
    organizerOrganizationId
    applicationType
    applicationLink
    applicantType
    minAge
    maxAge
    allCountries
    allOrganizations
    allIndividuals
    supportType
    participation
    currencyId
    isVotingActive
    isApplyActive
    likedByIds
    seenByIds
    organizerOrganization {
      id
      name
      imageUrl
      members {
        user {
          pId
        }
        role
      }
    }
    organizerUser{
      id
      firstName
      lastName
      name
      imageUrl
    }
  }
  tags {
    pId
    id
    name
    type
    url
  }
  documents {
    id
    url
    title
    elementId
  }
  countries {
    id
    pId
    name
  }
  organizations {
    id
    pId
    name
    imageUrl
  }
  comments {
    id
    pId
    elementId
    userId
    text
    parentId
    timestamp
    likedByIds
    author {
      id
      pId
      firstName
      lastName
      name
      imageUrl
      signature
    }
  }
  platformApplications {
    id
    pId
    createdBy
    name
    likedByIds
    seenByIds
    commentsCount
    backedByIds
    contestId
    isVotingActive
    organizationId
    user {
      firstName
      lastName
      name
      imageUrl
    }
    organization {
      name
      imageUrl
      pId
    }
  }
  actionApplications {
    id
    pId
    createdBy
    name
    imageUrl
    likedByIds
    seenByIds
    commentsCount
    backedByIds
    contestId
    isVotingActive
    allUserParticipantsIds
    organizerOrganizationId
    isBacked
    backedPercentage
    organizerUser {
      firstName
      lastName
      name
      imageUrl
    }
    organizerOrganization {
      name
      imageUrl
      pId
    }
    allTags {
      pId
      name
      type
      id: pId
    }
  }
`;

const containedContest = `
  pId
  id
  name
  imageUrl
  createdBy
  applicationsDeadline
  votingDeadline
  published
  organizerOrganizationId
  applicationType
  applicationLink
  minAge
  maxAge
  allCountries
  allOrganizations
  allIndividuals
  supportType
  participation
  seenByIds
  likedByIds
  allTags {
    pId
    id: pId
    name
    type
  }
  organizerOrganization {
    id
    name
    imageUrl
    members {
      user {
        pId
      }
      role
    }
  }
  organizerUser{
    id
    firstName
    lastName
    name
    imageUrl
  }
`;

export const optimizedContest = `
  attributes {
    pId
    id
    name
    imageUrl
    createdBy
    applicationsDeadline
    votingDeadline
    published
    organizerOrganizationId
    supportType
    participation
    seenByIds
    likedByIds
    commentsCount
    organizerOrganization {
      id
      name
      imageUrl
    }
    organizerUser{
      id
      firstName
      lastName
      name
      imageUrl
    }
  }
  tags {
    pId
    id
    name
    type
    url
  }
`;

export const calendarEventData = `
  attributes {
    id: pId
    start: startTime
    end: endTime
    desc: description
    title: name
    locationType
    eventType
    countryId
    createdBy
    address
    link
   }
  tags {
    pId
    id
    name
    type
  }
   participants {
     id: pId
     name
     imageUrl
   }
   combinedOrganizers {
     id: pId
     name
     imageUrl
     memberType
   }`;

export function getContestQuery(id, cognitoId) {
  return `{
      expandedContest(
        id: ${id}
        cognitoId: "${cognitoId}"
      ) {
        ${contestData}
      }
    }
  `;
}

export function getAllContests(cognitoId) {
  return `
  {
    usersContests(cognitoId: "${cognitoId}") {
      ${optimizedContest}
    }
  }`;
}

export const contestApplicationData = `
  attributes {
    id
    pId
    content
    organizationId
    createdBy
    contestId
    likedByIds
    seenByIds
    backedByIds
    isVotingActive
    knowledgeResourceIds
    linkResourceIds
    expertiseResourceIds
    toolResourceIds
    organization{
      members {
      user {
        pId
      }
        role
      }
      name
      imageUrl
    }
    contest {
      id
      pId
      name
      budget
      createdBy
      organizerOrganizationId
    }
    allContestBackers{
      applicationId
      backers
    }
  }
  documents {
    id
    pId
    url
    title
    elementId
  }
  comments {
    id
    pId
    elementId
    userId
    text
    parentId
    timestamp
    likedByIds
    author {
      id
      pId
      firstName
      lastName
      name
      imageUrl
      signature
    }
  }
`;

export function getContestApplication(id, cognitoId) {
  return `{
    contestApplication(
      id: ${id}
      cognitoId: ${cognitoId}) {
      ${contestApplicationData}
    }
  }`;
}

export function getActionsContests(actionId) {
  return `{
    actionsContests(actionId: ${actionId}) {
      pId
      id
      name
      budget
      actionBudget
      remainingBudget
      groupActionBudget
      voteNumber
      organizerOrganization {
        id
        name
        imageUrl
      }
      currency {
        code
      }
    }
 }`;
}

export const optimizedExpertiseResourceData = `
  attributes {
    id
    pId
    imageUrl
    name
    expertiseArea
    likedByIds
    seenByIds
    commentsCount
    timestamp
    resourceType
    creatorOrganizationId
    creatorOrganization {
      name
      imageUrl
    }
    author {
      name
      imageUrl
    }
  }
  tags {
    pId
    id
    name
    type
  }`;

export const optimizedKnowledgeResourceData = `
  attributes {
    id
    pId
    name
    description
    createdBy
    imageUrl
    documentType
    contentUrl
    seenByIds
    likedByIds
    commentsCount
    timestamp
    resourceType
    creatorOrganizationId
    author {
      id
      pId
      name
      imageUrl
      city
      countryId
    }
    creatorOrganization {
      name
      imageUrl
      moderatorIds
    }
  }
  tags {
    id
    pId
    name
    type
  }
`;

export const optimizedLinkResourceData = `
  attributes {
    id
    pId
    name
    link
    description
    timestamp
    resourceType
    creatorOrganizationId
    creatorOrganization {
      moderatorIds
      name
      imageUrl
    }
    seenByIds
    likedByIds
    author {
      id
      pId
      name
      imageUrl
      city
      countryId
    }
  }
  toolResources {
    name
  }
  tags {
    id
    pId
    name
    type
  }
`;

export const optimizedToolResourceData = `
  attributes {
    id
    pId
    name
    description
    imageUrl
    likedByIds
    seenByIds
    createdBy
    commentsCount
    timestamp
    resourceType
    creatorOrganizationId
    author {
      name
      imageUrl
    }
    creatorOrganization {
      name
      imageUrl
    }
  }
  tags {
    name
    type
    id
    pId
  }
  knowledgeResources {
    id
    pId
    name
  }
  linkResources {
    id
    pId
    name
  }
  expertiseResources {
    id
    pId
    name
    imageUrl
  }
  documents {
    id
    pId
    url
    title
    elementId
  }
`;

export const expertiseResourceData = `
  attributes {
    id
    pId
    name
    description
    createdBy
    imageUrl
    expertiseArea
    expertiseLocation
    city
    countryId
    email
    likedByIds
    seenByIds
    creatorOrganizationId
    resourceType
  }
  tags {
    id
    pId
    name
    type
  }
  projects {
    id
    pId
    project
    organizationId
    description
    projectLink
    workplace
    linkId
    knowledgeId
  }
  toolResources {
    id
    pId
    name
  }
  comments {
    id
    pId
    elementId
    userId
    text
    parentId
    timestamp
    likedByIds
    author {
      id
      pId
      firstName
      lastName
      name
      imageUrl
      signature
    }
  }
`;

export const linkResourceData = `
  attributes {
    id
    pId
    name
    description
    link
    likedByIds
    seenByIds
    createdBy
    resourceType
    creatorOrganizationId
    creatorOrganization{
      name
      imageUrl
      moderatorIds
    }
  }
  toolResources {
    id
    pId
    name
  }
  tags {
    name
    type
    id
    pId
  }
`;

export const knowledgeResourceData = `
  attributes {
    id
    pId
    name
    description
    contentUrl
    imageUrl
    documentType
    authorRights
    createdBy
    seenByIds
    likedByIds
    resourceType
    author {
      name
      imageUrl
    }
    creatorOrganizationId
    creatorOrganization {
      name
      imageUrl
    }
  }
  comments {
    id
    pId
    elementId
    userId
    text
    parentId
    timestamp
    likedByIds
    author {
      id
      pId
      firstName
      lastName
      name
      imageUrl
      signature
   }
 }
  toolResources {
    id
    pId
    name
  }
  tags {
    pId
    id
    name
    type
  }
`;

export const toolResourceData = `
  attributes {
    id
    pId
    name
    description
    imageUrl
    likedByIds
    seenByIds
    createdBy
    resourceType
    author {
      name
      imageUrl
    }
    creatorOrganization {
      name
      imageUrl
      moderatorIds
    }
  }
  tags {
    name
    type
    id
    pId
  }
  documents {
    id
    pId
    url
    title
    elementId
  }
  comments {
    id
    pId
    elementId
    userId
    text
    parentId
    timestamp
    likedByIds
    author {
      id
      pId
      firstName
      lastName
      name
      imageUrl
      signature
    }
  }
  ${containedResources}
`;

export const optimizedCalendarEventData = `
  attributes {
    pId
    id: pId
    start: startTime
    end: endTime
    desc: description
    title: name
    locationType
    eventType
    countryId
    createdBy
    address
    link
  }
  organizers {
    id: pId
    name
    imageUrl
  }
`;

export function getAllResources() {
  return `
  {
    allResources{
      expertiseResources {
        ${optimizedExpertiseResourceData}
      }
      knowledgeResources {
        ${optimizedKnowledgeResourceData}
      }
      linkResources {
        ${linkResourceData}
      }
      toolResources {
        ${optimizedToolResourceData}
      }
    }
   }
  `;
}


export function getAllResourcesPaginated(page, number) {
  return `
  {
    allResourcesPaginated(
      page: ${page}
      number: ${number}
    )
    {
      isFinalPage
      expertiseResources {
        ${optimizedExpertiseResourceData}
      }
      knowledgeResources {
        ${optimizedKnowledgeResourceData}
      }
      linkResources {
        ${linkResourceData}
      }
      toolResources {
        ${optimizedToolResourceData}
      }
    }
   }
  `;
}

export function getKnowledgeResourcesPaginatedQuery(page, number) {
  return `
  {
    knowledgeResourcesPaginated(
      page: ${page}
      number: ${number}
    ){
      ${optimizedKnowledgeResourceData}
      }
    }
  `;
}


export function getExpertiseResourcesPaginatedQuery(page, number) {
  return `
  {
    expertiseResourcesPaginated(
      page: ${page}
      number: ${number}
    )
    {
      ${optimizedExpertiseResourceData}
      }
    }
  `;
}


export function getLinkResourcesPaginatedQuery(page, number) {
  return `
  {
    linkResourcesPaginated(
      page: ${page}
      number: ${number}
    )
    {
      ${optimizedLinkResourceData}
      }
    }
  `;
}


export function getToolResourcesPaginatedQuery(page, number) {
  return `
  {
    toolResourcesPaginated(
      page: ${page}
      number: ${number}
    )
    {
      ${optimizedToolResourceData}
      }
    }
  `;
}



export function getPrimitiveResources(loadExpertisesResources, loadKnowledgeResources, loadLinkResources) {
  const expertiseStr = loadExpertisesResources
    ? `expertiseResources {
        ${optimizedExpertiseResourceData}
      }`
    : '';
  const knowledgeStr = loadKnowledgeResources
    ? `knowledgeResources {
        ${optimizedKnowledgeResourceData}
      }`
    : '';
  const linkStr = loadLinkResources
    ? `linkResources {
        ${optimizedLinkResourceData}
      }`
    : '';
  return `
  {
    allResources{
      ${expertiseStr}
      ${knowledgeStr}
      ${linkStr}
    }
   }
  `;
}

export function getKnowledgeQuery(id) {
  return `{
    knowledgeResource(
      id: ${id}
    ) {
      ${knowledgeResourceData}
    }
  }`;
}

export const getAllCalendarEvents = `
  {
    allEvents {
      ${calendarEventData}
    }
  }`;

export const getAllEventCategories = `
  {
    allEventTypes {
      name
      id: pId
    }
  }
`;

export function getHomePageContentQuery(cognitoId, nbElements, page) {
  return `{
    usersHomeCards(
      cognitoId: "${cognitoId}"
      number: ${nbElements}
      page: ${page}
    ) {
      actions {
        ${optimizedAction}
      }
      contests {
        ${optimizedContest}
      }
      knowledgeResources {
        ${optimizedKnowledgeResourceData}
      }
      linkResources {
         ${optimizedLinkResourceData}
      }
      expertiseResources {
        ${optimizedExpertiseResourceData}
      }
      toolResources {
        ${optimizedToolResourceData}
      }
      calendarEvents {
        attributes {
          pId
          id: pId
          start: startTime
          end: endTime
          desc: description
          title: name
          locationType
          eventType
          countryId
          createdBy
          address
          link
        }
        organizers: combinedOrganizers {
          id: pId
          name
          imageUrl
        }
      }
    }
  }
`;
}

export const getHomeHighlightActivity = (cognitoId) => `
  {
  usersHighlightedElement(cognitoId: "${cognitoId}"){
    contest {
      ${optimizedContest}
    }
    action {
      ${optimizedAction}
    }
    linkResource {
      ${optimizedLinkResourceData}     
    }
    knowledgeResource {
      ${optimizedKnowledgeResourceData}
    }
    expertiseResource {
      ${optimizedExpertiseResourceData}
    }
  }
}
`;

export const getHomePageCommentsQuery = (elementIds, number, page) => `
  {
    comments (
      elementIds: ${stringify(elementIds)}
      number: ${number}
      page: ${page}
    ){
      elementId
      comments {
        id
        pId
        elementId
        userId
        text
        parentId
        timestamp
        likedByIds
        author {
          id
          pId
          name
          imageUrl
        }
      }
    }
  }
`;

export const searchActionsQuery = (name, tagIds, cognitoId) => {
  const tags = tagIds?.length ? `tagIds: ${stringify(tagIds)}` : '';
  return `
    {
      searchActions(
        cognitoId: "${cognitoId}"
        ${tags}
        nameLike: "${name}"
      ) {
        ${optimizedAction}
      }
    }
  `;
};

export const searchContestsQuery = (name, tagIds, cognitoId) => {
  const tags = tagIds?.length ? `tagIds: ${stringify(tagIds)}` : '';
  return `
    {
      searchContests(
        cognitoId: "${cognitoId}"
        ${tags}
        nameLike: "${name}"
      ) {
        ${optimizedContest}
      }
    }
  `;
};

export const searchResourcesQuery = (name, resourceType, knowledgeType, tagIds) => {
  const tags = tagIds?.length ? `tagIds: ${stringify(tagIds)}` : '';
  const res = resourceType ? `resourceType: "${resourceType}"` : '';
  const kt = knowledgeType ? `knowledgeType: "${knowledgeType}"` : '';
  return `
    {
      searchResources(
        nameLike: "${name}"
        ${tags}
        ${res}
        ${kt}
      ) {
        expertiseResources {
          ${optimizedExpertiseResourceData}
        }
        linkResources {
          ${optimizedLinkResourceData}
        }
        knowledgeResources {
          ${optimizedKnowledgeResourceData}
        }
        toolResources {
          ${optimizedToolResourceData}
        }
      }
    }
  `;
};

export const searchAllContentQuery = (cognitoId, page, number, name, tagIds, elementType) => {
  const tags = tagIds?.length ? `tagIds: ${stringify(tagIds)}` : '';
  const nameLike = name != undefined ? `nameLike: "${name}"` : '';
  const elType = elementType ? `elementType: "${elementType}"` : '';
  const num = number ? `number: ${number}` : '';

  return `{
    searchAll(
      cognitoId: "${cognitoId}"
      page: ${page}
      ${num}
      ${tags}
      ${nameLike}
      ${elType}
    ) {
      users {
        pId
        id
        firstName
        lastName
        name
        imageUrl
        title
        signature
        deactivated
        organizations {
          role
          organization {
            id
            pId
            name
            imageUrl
          }
        }
      } 
      organizations {
        attributes {
          about
          description
          city
          countryId
          address
          phoneNumber
          email
          name
          id
          pId
          imageUrl
        }
        tags {
          pId
          id
          name
          type
          url
        }
      }
      actions {
        ${optimizedAction}
      } 
      contests {
        ${optimizedContest}
      }
      knowledgeResources {
        ${optimizedKnowledgeResourceData}
      } 
      linkResources {
        ${optimizedLinkResourceData}
      }
      expertiseResources {
         ${optimizedExpertiseResourceData}
      } 
      toolResources {
        ${optimizedToolResourceData}
      }
      isFinalPage
    }
  }`;
};
