import { produce } from 'immer';
import { ElementKeysToTYpe, IActivity, ICurrency, ITag } from 'core/models/Models';
import { CURRENCIES, TAGS, SEARCH_ALL_CONTENT } from 'core/actions/CommonActions';
import { SEARCH_ACTION } from 'core/actions/ActionActions';
import { SEARCH_CONTEST } from 'core/actions/ContestActions';
import { SEARCH_RESOURCE } from 'core/actions/ResourceActions';
import { ELEMENT_LIKE } from 'core/actions/InteractionActions';
import { mergeDicts } from 'utils/Helpers';

export interface ICommonState {
  currencies: ICurrency[];
  tags: ITag[];
  lastSearchParam: {
    users: string;
    organizations: string;
    all: string;
    actions: string;
    contests: string;
    resources: string;
  };
  searchContent: {
    users: any[];
    organizations: any[];
    all: any;
    actions: any[];
    contests: any[];
    expertiseResources: any[];
    knowledgeResources: any[];
    linkResources: any[];
    toolResources: any[];
  };
  isFinalPage: {
    users: boolean;
    organizations: boolean;
    all: boolean;
    resources: boolean;
  };
}

const initialState: ICommonState = {
  currencies: [],
  tags: [],
  lastSearchParam: {
    users: '',
    organizations: '',
    all: '',
    actions: '',
    contests: '',
    resources: '',
  },
  searchContent: {
    users: [],
    organizations: [],
    all: {},
    actions: [],
    contests: [],
    expertiseResources: [],
    knowledgeResources: [],
    linkResources: [],
    toolResources: [],
  },
  isFinalPage: {
    users: false,
    organizations: false,
    all: false,
    resources: false,
  },
};

const commonReducer = (state = initialState, action: IActivity): ICommonState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case CURRENCIES.GET.SUCCESS:
        draft.currencies = payload;
        break;
      case TAGS.GET.SUCCESS:
        draft.tags = payload;
        break;
      case SEARCH_ALL_CONTENT.GET.SUCCESS: {
        const elementType = payload.elementType;

        // Check if search parameters have changed, and replace or append content based on elementType
        if (elementType === 'organization') {
          const searchParamChanged = payload.searchParam !== state.lastSearchParam.organizations && (payload.searchParam || state.lastSearchParam.organizations);
          draft.searchContent.organizations = searchParamChanged
            ? payload.data.organizations
            : [...state.searchContent.organizations, ...payload.data.organizations];
          draft.isFinalPage = {...state.isFinalPage, organizations: payload.data.isFinalPage}
          draft.lastSearchParam = {...state.lastSearchParam, organizations: payload.searchParam}
        } else if (elementType === 'user') {
          const searchParamChanged = payload.searchParam !== state.lastSearchParam.users && (payload.searchParam || state.lastSearchParam.users);
          draft.searchContent.users = searchParamChanged
            ? payload.data.users
            : [...state.searchContent.users, ...payload.data.users];
            draft.isFinalPage = {...state.isFinalPage, users: payload.data.isFinalPage}
            draft.lastSearchParam = {...state.lastSearchParam, users: payload.searchParam}
        } else {
          // Assuming 'all' includes combined data of organizations, users, etc.
          const searchParamChanged = payload.searchParam !== state.lastSearchParam.all && (payload.searchParam || state.lastSearchParam.all);
          draft.searchContent.all = searchParamChanged
            ? payload.data
            : mergeDicts(draft.searchContent.all, payload.data);
          draft.isFinalPage = {...state.isFinalPage, all: payload.data.isFinalPage}
          draft.lastSearchParam = {...state.lastSearchParam, all: payload.searchParam}
        }
        break;
      }
      case SEARCH_ACTION.GET.SUCCESS:
        draft.searchContent = { ...state.searchContent, actions: payload };
        break;
      case SEARCH_CONTEST.GET.SUCCESS:
        draft.searchContent = { ...state.searchContent, contests: payload };
        break;
      case SEARCH_RESOURCE.GET.SUCCESS: {
        const searchParamChanged = payload.searchParam !== state.lastSearchParam.resources && (payload.searchParam || state.lastSearchParam.resources);
        if (!searchParamChanged) {
          draft.searchContent = {
            ...state.searchContent,
            expertiseResources: [...(state.searchContent.expertiseResources || []), ...(payload.data.expertiseResources || [])],
            knowledgeResources: [...(state.searchContent.knowledgeResources || []), ...(payload.data.knowledgeResources || [])],
            linkResources: [...(state.searchContent.linkResources || []), ...(payload.data.linkResources || [])],
            toolResources: [...(state.searchContent.toolResources || []), ...(payload.data.toolResources || [])],
          };
          draft.isFinalPage = {...state.isFinalPage, resources: payload.data.isFinalPage}
        } else {
          draft.searchContent = {
            ...state.searchContent,
            expertiseResources: payload.data.expertiseResources,
            knowledgeResources: payload.data.knowledgeResources,
            linkResources: payload.data.linkResources,
            toolResources: payload.data.toolResources,
          }
          draft.isFinalPage = {...state.isFinalPage, resources: true}
        }
        draft.lastSearchParam = {...state.lastSearchParam, resources: payload.searchParam}

        break;
      }
      case ELEMENT_LIKE.POST.SUCCESS: {
        const { elementId, userId, elementType } = payload;
        draft.searchContent[ElementKeysToTYpe[elementType]] = state.searchContent[ElementKeysToTYpe[elementType]]?.map(
          (e) =>
            e?.attributes?.pId === elementId
              ? {
                  ...e,
                  attributes: {
                    ...e?.attributes,
                    likedByIds: e?.attributes?.likedByIds.includes(userId)
                      ? e?.attributes?.likedByIds.filter((id) => id !== userId)
                      : [...e.attributes.likedByIds, userId]
                  }
                }
              : e
        );
        break;
      }
      default:
        return draft;
    }
  });

export default commonReducer;
