import { produce } from 'immer';
import { IContestApplication, IActivity } from 'core/models/Models';
import {
  CONTEST_APPLICATION,
  CONTEST_APPLICATION_COMMENTS,
  CONTEST_APPLICATION_COMMENT_LIKE,
  CONTEST_APPLICATION_DOCUMENTS,
  CONTEST_APPLICATION_LIKE,
  CONTEST_APPLICATION_RESOURCES,
  CONTEST_APPLICATION_VIEW,
  CONTEST_APPLICATION_VOTE
} from 'core/actions/ContestApplicationActions';

const emptyContestApplication: IContestApplication = {
  attributes: {
    pId: 0,
    id: '',
    content: '',
    createdBy: 0,
    contestId: 0
  },
  documents: [],
  comments: [],
  knowledgeResources: [],
  linkResources: [],
  expertiseResources: [],
  toolResources: []
};

export interface IContestApplicationState {
  contestApplications: IContestApplication[];
  newContestApplication: IContestApplication;
}

const initialState: IContestApplicationState = {
  contestApplications: [],
  newContestApplication: emptyContestApplication
};

const contestApplicationReducer = (state = initialState, action: IActivity): IContestApplicationState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case CONTEST_APPLICATION_VIEW.POST.SUCCESS:
      case CONTEST_APPLICATION.POST.SUCCESS:
      case CONTEST_APPLICATION_RESOURCES.POST.SUCCESS:
        if (!state.contestApplications.some((item) => item?.attributes?.id == payload?.attributes?.id)) {
          draft.contestApplications.push(payload);
        } else {
          draft.contestApplications = state.contestApplications.map((item) => {
            return item?.attributes?.id == payload?.attributes?.id ? payload : item;
          });
        }
        break;
      case CONTEST_APPLICATION.PUT.SUCCESS:
        draft.contestApplications = state.contestApplications.map((item) => {
          if (item?.attributes?.pId == payload?.contestApplicationId) {
            const attributes = { ...item.attributes, content: payload.content };
            return { ...item, attributes: attributes };
          }
          return item;
        });
        break;
      case CONTEST_APPLICATION_LIKE.POST.SUCCESS:
        draft.contestApplications = state.contestApplications.map((item) => {
          if (item?.attributes?.pId == payload?.elementId) {
            const userId = payload?.userId;
            const likedByIds = item?.attributes?.likedByIds ?? [];
            const index = likedByIds?.indexOf(userId) ?? -1;
            const newElementsById = [...likedByIds];
            if (index > -1) {
              newElementsById.splice(index, 1);
              const attributes = { ...item.attributes, likedByIds: newElementsById };
              return { ...item, attributes: attributes };
            } else {
              newElementsById.push(userId);
              const attributes = { ...item.attributes, likedByIds: newElementsById };
              return { ...item, attributes: attributes };
            }
          }
          return item;
        });
        break;
      case CONTEST_APPLICATION_VOTE.POST.SUCCESS:
        draft.contestApplications = state.contestApplications.map((item) => {
          if (item?.attributes?.pId == payload?.contestApplicationId) {
            const userId = payload?.userId;
            const backedByIds = item?.attributes?.backedByIds ?? [];
            const index = backedByIds?.indexOf(userId) ?? -1;
            const newElementsById = [...backedByIds];
            if (index > -1) {
              newElementsById.splice(index, 1);
              const attributes = { ...item.attributes, backedByIds: newElementsById };
              return { ...item, attributes: attributes };
            } else {
              newElementsById.push(userId);
              const attributes = { ...item.attributes, backedByIds: newElementsById };
              return { ...item, attributes: attributes };
            }
          }
          return item;
        });
        break;
      case CONTEST_APPLICATION_DOCUMENTS.PUT.SUCCESS:
        draft.contestApplications = state.contestApplications.map((contestApplication) =>
          contestApplication.attributes.pId === payload.elementId
            ? {
                ...contestApplication,
                documents: contestApplication.documents?.map((d) => (d.pId === payload.pId ? payload : d))
              }
            : contestApplication
        );
        break;
      case CONTEST_APPLICATION_DOCUMENTS.POST.SUCCESS:
        draft.contestApplications = state.contestApplications.map((contestApplication) => {
          if (contestApplication.attributes?.pId === payload.elementId) {
            const newDocuments = [...contestApplication.documents];
            newDocuments?.push(payload);
            return { ...contestApplication, documents: newDocuments };
          }
          return contestApplication;
        });
        break;
      case CONTEST_APPLICATION_DOCUMENTS.DELETE.SUCCESS:
        draft.contestApplications = state.contestApplications.map((contestApplication) => {
          if (contestApplication.attributes.pId === payload.elementId) {
            const documents = [...contestApplication.documents].filter((d) => d.pId !== payload.pId);
            return { ...contestApplication, documents };
          }
          return contestApplication;
        });
        break;
      case CONTEST_APPLICATION_COMMENTS.PUT.SUCCESS:
      case CONTEST_APPLICATION_COMMENT_LIKE.POST.SUCCESS:
        draft.contestApplications = state.contestApplications.map((contestApplication) =>
          contestApplication.attributes.pId === payload.elementId
            ? {
                ...contestApplication,
                comments: contestApplication.comments?.map((c) => (c.pId === payload.pId ? payload : c))
              }
            : contestApplication
        );
        break;
      case CONTEST_APPLICATION_COMMENTS.POST.SUCCESS:
        draft.contestApplications = state.contestApplications.map((contestApplication) => {
          if (contestApplication.attributes?.pId === payload.elementId) {
            const newComments = [...contestApplication.comments];
            newComments?.push(payload);
            return { ...contestApplication, comments: newComments };
          }
          return contestApplication;
        });
        break;
      case CONTEST_APPLICATION_COMMENTS.DELETE.SUCCESS:
        draft.contestApplications = state.contestApplications.map((contestApplication) => {
          if (contestApplication.attributes.pId === payload.elementId) {
            const comments = [...contestApplication.comments].filter(
              (c) => c.pId !== payload.pId && c?.parentId !== payload.pId
            );
            return { ...contestApplication, comments };
          }
          return contestApplication;
        });
        break;
      case CONTEST_APPLICATION.DELETE.SUCCESS:
        draft.contestApplications = state.contestApplications?.filter(
          (contestApplication) => contestApplication.attributes?.pId !== payload?.id
        );
        break;
      default:
        return draft;
    }
  });

export default contestApplicationReducer;
