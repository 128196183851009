import {
  SIGN_IN,
  SIGN_OUT,
  USER,
  VERIFY_TOKEN,
  UPDATE_SIGNUP_DATA,
  SIGN_UP,
  CONFIRM_SIGN_UP,
  CURRENT_AUTH_USER,
  SEND_INVITATION,
  COGNITO_USER_EMAIL_VERIFICATION,
  FORGOT_PASSWORD,
  RESET_FORGOT_EMAIL_MESSAGE,
  RESEND_EMAIL_CONFIRMATION_CODE
} from 'core/actions/AuthActions';
import { PERSONAL_PROFIL, UPLOAD_IMAGE } from 'core/actions/PersonalProfileActions';

import { IActivity, ICognitoUser, ISignupForm, ISignupState } from 'core/models/Models';
import { produce } from 'immer';

export interface IAuthState {
  isAuthenticated: boolean;
  isShowResetPasswordMessage: boolean;
  cognitoUser: ICognitoUser;
  signupForm: ISignupForm;
  invitationErrorMessage: string;
  cognitoAttributesVerification: {
    attributes?: any;
    confirmationCodeSent: boolean;
    errorMessage?: string;
    isVerified: boolean;
  };
}

const initialState: IAuthState = {
  isAuthenticated: false,
  isShowResetPasswordMessage: false,
  cognitoUser: {} as ICognitoUser,
  signupForm: {
    firstname: '',
    lastname: '',
    email: '',
    username: '',
    position: '',
    organizationId: undefined,
    organizationName: '',
    imageUrl: '',
    confirmedEmail: false,
    isContinuingSignUp: false,
    state: ISignupState.Roules
  },
  invitationErrorMessage: '',
  cognitoAttributesVerification: {
    attributes: {},
    confirmationCodeSent: false,
    errorMessage: '',
    isVerified: false
  },
};

const authReducer = (state = initialState, action: IActivity): IAuthState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case USER.GET.SUCCESS:
        draft.cognitoUser = payload;
        break;
      case USER.POST.SUCCESS:
        draft.cognitoAttributesVerification.attributes = payload.attributes;
        if (payload?.attributes?.email) {
          draft.cognitoAttributesVerification.confirmationCodeSent = true;
        }
        break;
      case USER.POST.FAILURE:
        draft.cognitoAttributesVerification.attributes = payload.attribute;
        draft.cognitoAttributesVerification.confirmationCodeSent = false;
        draft.cognitoAttributesVerification.errorMessage = payload.error;
        break;
      case COGNITO_USER_EMAIL_VERIFICATION.POST.SUCCESS: {
        draft.cognitoUser.attributes.email = payload;
        const attributes = { ...state.cognitoAttributesVerification.attributes };
        delete attributes['email'];
        draft.cognitoAttributesVerification.attributes = attributes;
        draft.cognitoAttributesVerification.confirmationCodeSent = false;
        draft.cognitoAttributesVerification.errorMessage = '';
        draft.cognitoAttributesVerification.isVerified = true;
        return draft;
      }
      case COGNITO_USER_EMAIL_VERIFICATION.POST.FAILURE:
        draft.cognitoAttributesVerification.errorMessage = 'Kod nije uspesno proveren.';
        draft.cognitoAttributesVerification.confirmationCodeSent = false;
        break;
      case RESEND_EMAIL_CONFIRMATION_CODE.POST.SUCCESS:
        draft.signupForm = {
          ...state.signupForm,
          isContinuingSignUp: true,
          state: ISignupState.EmailVerify,
          email: payload.email,
          username: payload.username,
        };
        break;
      case SIGN_IN.POST.SUCCESS:
      case CURRENT_AUTH_USER.GET.SUCCESS:
        draft.cognitoUser = payload;
        draft.isAuthenticated = true;
        break;
      case SIGN_UP.GET.SUCCESS:
        draft.signupForm = { ...state.signupForm, ...payload };
        break;
      case SIGN_UP.POST.SUCCESS:
        draft.cognitoUser = payload;
        break;
      // case SIGN_OUT.POST.SUCCESS:
      //   draft.isShowResetPasswordMessage = false;
      //   draft.cognitoUser = {} as ICognitoUser;
      //   draft.isAuthenticated = false;
      //   break;
      case CURRENT_AUTH_USER.GET.FAILURE:
      case PERSONAL_PROFIL.GET.FAILURE:
        draft.cognitoUser = {} as ICognitoUser;
        draft.isAuthenticated = false;
        break;
      case VERIFY_TOKEN.POST.SUCCESS:
        draft.signupForm = { ...state.signupForm, email: payload.receiverEmail, invitationId: payload.id };
        break;
      case UPDATE_SIGNUP_DATA:
      case UPLOAD_IMAGE.POST.SUCCESS:
      case CONFIRM_SIGN_UP.POST.SUCCESS:
        draft.signupForm = { ...state.signupForm, ...payload, state: state.signupForm.state + 1 };
        if (state.signupForm.state === ISignupState.Theme) {
          draft.isAuthenticated = true;
        }
        break;
      case SEND_INVITATION.POST.FAILURE:
        draft.invitationErrorMessage = payload;
        break;
      case SEND_INVITATION.POST.REQUEST:
        draft.invitationErrorMessage = '';
        break;
      case FORGOT_PASSWORD.POST.SUCCESS:
        draft.isShowResetPasswordMessage = true;
        break;
      case RESET_FORGOT_EMAIL_MESSAGE:
        draft.isShowResetPasswordMessage = false;
        break;
      default:
        return draft;
    }
  });

export default authReducer;
