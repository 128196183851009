import ToastNotification from 'components/Notifications/ToastNotification';
import { addNewNotification } from 'core/actions/NotificationActions';
import { INotification, IPersonalProfile } from 'core/models/Models';
import { getPersonalProfileSelector } from 'core/selectors/PersonalProfileSelectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { JsonValue } from 'react-use-websocket/dist/lib/types';
import * as Alert from 'utils/Alerts';

const socketUrl = process.env.REACT_APP_WEBSOCKET_API ?? '';
interface Props {
  children: any;
}

type MessageType = INotification & JsonValue;

export default function WebSocketWrapper({ children }: Props) {
  const dispatch = useDispatch();
  const [notifcationData, setNotificationData] = useState<INotification | null>(null);

  const personalProfile: IPersonalProfile = useSelector(getPersonalProfileSelector);
  const { lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: () => true
  });

  const content = () => {
    const lastMessage: any = { ...lastJsonMessage };
    const authorName = lastMessage?.organizationAuthor
      ? `Organizacija ${lastMessage.organizationAuthor.name}`
      : lastMessage?.userAuthor?.name;
    const authorImageUrl = lastMessage.organizationAuthor
      ? lastMessage.organizationAuthor?.imageUrl
      : lastMessage?.userAuthor?.imageUrl;
    const elementImageUrl = lastMessage?.elementData?.imageUrl;
    const notificationSource = lastMessage?.notificationSource;
    return {
      ...lastJsonMessage,
      authorName: authorName,
      authorImageUrl: authorImageUrl,
      elementImageUrl: elementImageUrl,
      notificationSource: notificationSource
    } as MessageType;
  };

  useEffect(() => {
    if (lastJsonMessage) {
      const allReceivers = (lastJsonMessage as MessageType)?.allReceivers;
      const toastReceivers = (lastJsonMessage as MessageType)?.toastReceivers;
      const userId = personalProfile?.attributes?.pId;
      if (allReceivers?.includes(userId)) {
        dispatch(addNewNotification(lastJsonMessage));
      }
      if (toastReceivers?.includes(userId)) {
        setNotificationData(content());
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (notifcationData) Alert.setToastNotification(() => <ToastNotification notification={notifcationData} />);
  }, [notifcationData]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState];

  return <div>{children}</div>;
}
