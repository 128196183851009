import { all, put, takeLatest } from 'redux-saga/effects';
import {
  ALL_ACTIONS,
  ACTION,
  UPDATE_ACTION,
  ACTION_TAGS,
  ACTION_PARTICIPANTS,
  ACTION_CHECK_LIST_ITEMS,
  ACTION_DOCUMENTS,
  getAllActions,
  getAction,
  createAction,
  updateAction,
  updateActionTags,
  updateActionParticipants,
  addCheckListItem,
  updateCheckListItem,
  removeCheckListItem,
  addDocument,
  updateDocument,
  removeDocument,
  ACTION_COMMENTS,
  addComment,
  removeComment,
  updateComment,
  likeOrUnlikeAction,
  likeOrUnlikeActionComment,
  ACTION_LIKE,
  ACTION_COMMENT_LIKE,
  ACTION_VIEW,
  deleteAction,
  addRemoveActionParticipant,
  ACTION_ADD_REMOVE_PARTICIPANT,
  sendActionInvitation,
  SEND_ACTION_INVITATION,
  addActionView,
  joinAction,
  JOIN_ACTION,
  ACTION_REMOVE_PARTICIPANT,
  removeActionParticipant,
  backAction,
  ACTION_VOTE,
  SEARCH_ACTION,
  searchAction
} from 'core/actions/ActionActions';
import { API, Auth, Storage } from 'aws-amplify';
import {
  getAllActions as getAllActionsQuery,
  getAction as getActionQuery,
  searchActionsQuery
} from 'api/graphql/queries';
import {
  createAction as createActionMutation,
  updateAction as updateActionMutation,
  deleteAction as deleteActionMutation,
  createOrDeleteActionsTags,
  createOrDeleteActionsParticipants,
  addActionsCheckListItem,
  updateActionsCheckListItem,
  deleteActionsCheckListItem,
  addActionsDocument,
  updateActionsDocument,
  deleteActionsDocument,
  addActionView as addActionViewMutation,
  addNewActionsDocumentsAndImage,
  addOrRemoveActionsParticipant as addOrRemoveActionsParticipantMutation,
  joinAction as joinActionMutation,
  removeActionsParticipant as removeActionsParticipantMutation
} from 'api/graphql/actionsMutations';
import {
  addComment as addCommentMutation,
  updateComment as updateCommentMutation,
  deleteComment as deleteCommentMutation,
  likeOrUnlikeElement as likeOrUnlikeElementMutation,
  likeOrUnlikeComment as likeOrUnlikeCommentMutation
} from 'api/graphql/interactionsMutations';
import { backAction as backActionMutation } from 'api/graphql/contestMutations';
//import { markNotificationsAsRead, updateUserCategoriesWatchStatus } from 'api/graphql/mutations';
import { stringify } from 'api/graphql/utils';
import * as Alert from 'utils/Alerts';
import i18n from 'utils/i18n';
import * as uuid from 'uuid';
import { IDocument } from 'core/models/Models';
import { AppRoutes } from 'config/AppRoutes';
import { ElementAlreadyExistsError } from 'core/models/Exceptions';
import { globalNavigate } from 'utils/global-history';

export function* getAllActionsSaga() {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;

    const requestInfo = {
      queryStringParameters: {
        query: getAllActionsQuery(cognitoId)
      }
    };

    const { data } = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (data?.errors) {
      throw new Error(i18n.t('api.errors.getActions'));
    }
    yield put(getAllActions.success(data?.usersActions));
  } catch (e: any) {
    yield put(getAllActions.failure(e));
  }
}

export function* getActionSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;

    const requestInfo = {
      queryStringParameters: {
        query: getActionQuery(id, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      const error = response?.errors[0].message;
      if (error.includes('Akcija nije dostupna')) {
        globalNavigate(AppRoutes.actions.link);
        throw new Error(error);
      }
      throw new Error(i18n.t('api.errors.getActions'));
    }

    yield put(getAction.success(response?.data?.expandedAction));
  } catch (e: any) {
    yield put(getAction.failure(e));
  }
}

export function* createActionSaga({ payload: { data } }) {
  try {
    // TODO initial tags, participants, check list items and documents
    let tagIds = '';
    let participantIds = '';
    const checkListItems = data?.checkListItems ? stringify(data?.checkListItems) : '';

    if (data?.tags?.length) {
      const ids = data.tags.map((tag) => tag.pId);
      tagIds = stringify(ids);
    }
    if (data?.participants?.length) {
      const ids = data.participants.map((participant) => participant.pId);
      participantIds = stringify(ids);
    }

    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      body: {
        query: createActionMutation(
          data.attributes.name,
          data.attributes.imageUrl,
          data.attributes.description,
          data.attributes?.endDate,
          tagIds,
          data.attributes?.organizerOrganizationId,
          checkListItems,
          participantIds,
          data.attributes.published,
          data.attributes.createdBy,
          data.attributes?.contestId
        )
      }
    };

    let response = yield API.post('ActionsAPI', '/graphql', requestInfo);

    if (response?.errors?.length) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
      throw new Error(error);
    }

    let action = response?.data?.createAction?.action;
    const actionId = response?.data?.createAction?.action?.attributes?.id;
    // upload documents only after action is created successfully
    // need the id and shouldn't upload if an error occurs
    const documents = data?.documents;
    const imageFile = data?.attributes?.imageFile;
    if (documents?.length || imageFile) {
      let documentsStr = '';
      let imageUrl = '';
      const level = 'protected';
      const documentsWithUrl = new Array<IDocument>();
      if (documents?.length) {
        for (let i = 0; i < documents.length; i++) {
          const document = documents[i];
          let fileName = document.file.name;
          const title = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
          fileName = getS3Key(document.file, actionId);
          yield Storage.put(fileName, document.file, { level: 'protected' });
          const url = yield Storage.get(fileName, { level: 'protected' });
          const cleanUrl = url.substring(0, url.indexOf('?'));
          documentsWithUrl.push({ url: cleanUrl, title: title, elementId: actionId });
        }
        documentsStr = stringify(documentsWithUrl);
      }
      if (imageFile) {
        const fileName = getS3Key(imageFile, actionId);
        yield Storage.put(fileName, imageFile, { level: level });
        imageUrl = yield Storage.get(fileName, { level: level });
      }
      const requestInfo = {
        queryStringParameters: {
          query: addNewActionsDocumentsAndImage(actionId, documentsStr, imageUrl, cognitoId)
        }
      };

      response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);
      if (response?.errors?.length) {
        throw new Error(i18n.t('api.errors.createActionAddDocumentsAndImage'));
      }
      action = response?.data?.addNewActionsDocumentsAndImage?.action;
    }

    yield put(createAction.success(action));
    globalNavigate(AppRoutes.actions.link + '/' + action?.attributes?.id);
    yield Alert.setSuccessAlert(i18n.t('api.success.createAction'));
  } catch (error: any) {
    yield put(createAction.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.actionExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.createAction'));
    }
  }
}

export function* updateActionSaga({
  payload: { id, name, imageFile, description, endDate, published, imageUrl, contestId, isGroupApplication }
}) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;

    let newImageUrl = null;
    const level = 'protected';
    if (imageFile) {
      const fileName = getS3Key(imageFile, id);
      yield Storage.put(fileName, imageFile, { level: level });
      newImageUrl = yield Storage.get(fileName, { level: level });
    }

    const requestInfo = {
      body: {
        query: updateActionMutation(
          id,
          name,
          newImageUrl,
          description,
          endDate,
          published,
          cognitoId,
          contestId,
          isGroupApplication
        )
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql', requestInfo);

    if (response?.errors?.length) {
      const error = response?.errors[0]?.message;
      if (error && error.includes('already exists')) {
        throw new ElementAlreadyExistsError(error);
      }
      throw new Error(error);
    }

    if (newImageUrl && imageUrl) {
      if (typeof imageUrl === 'string') {
        const fileName = getS3KeyFromUrl(imageUrl, id);
        yield Storage.remove(fileName, { level: 'protected' });
      }
    }

    yield put(updateAction.success(response?.data?.updateAction?.action));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateAction'));
  } catch (error: any) {
    yield put(updateAction.failure(error));
    if (error instanceof ElementAlreadyExistsError) {
      yield Alert.setErrorAlert(i18n.t('api.errors.actionExists'));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.updateAction'));
    }
  }
}

export function* updateActionTagsSaga({ payload: { id, tags } }) {
  const ids = tags.map((tag) => tag.pId);
  const tagIds = stringify(ids);
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: createOrDeleteActionsTags(id, tagIds, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.updateTags'));
    }
    yield put(updateActionTags.success(response?.data?.createOrDeleteActionsTags?.action));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateTags'));
  } catch (e) {
    yield put(updateActionTags.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateTags'));
  }
}

export function* addRemoveActionsParticipantSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addOrRemoveActionsParticipantMutation(id, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.addRemoveParticipant'));
    }
    yield put(addRemoveActionParticipant.success(response?.data?.addOrRemoveActionsParticipant?.action));

    yield Alert.setSuccessAlert(i18n.t('api.success.addRemoveParticipant'));
  } catch (e) {
    yield put(addRemoveActionParticipant.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.addRemoveParticipant'));
  }
}

export function* removeActionsParticipantSaga({ payload: { id, member } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: removeActionsParticipantMutation(id, member, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.removeActionsParticipant'));
    }
    yield put(removeActionParticipant.success(response?.data?.removeActionsParticipant?.action));

    yield Alert.setSuccessAlert(i18n.t('api.success.removeActionsParticipant'));
  } catch (e) {
    yield put(removeActionParticipant.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.removeActionsParticipant'));
  }
}

export function* updateActionParticipantsSaga({ payload: { id, participants } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: createOrDeleteActionsParticipants(id, participants)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.updateActionsParticipatns'));
    }
    yield put(updateActionParticipants.success(response?.data?.createOrDeleteActionsParticipants?.action));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateActionsParticipants'));
  } catch (e) {
    yield put(updateActionParticipants.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateActionsParticipants'));
  }
}

export function* addCheckListItemSaga({ payload: { checkListItem } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addActionsCheckListItem(checkListItem.actionId, checkListItem.text, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.createCheckListItem'));
    }
    yield put(addCheckListItem.success(response?.data?.addActionsCheckListItem?.action));

    yield Alert.setSuccessAlert(i18n.t('api.success.createCheckListItem'));
  } catch (e) {
    yield put(addCheckListItem.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createCheckListItem'));
  }
}

export function* updateCheckListItemSaga({ payload: { checkListItem } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: updateActionsCheckListItem(checkListItem.id, checkListItem.text, checkListItem?.done, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.console.errors);
    }
    yield put(updateCheckListItem.success(response?.data?.updateActionsCheckListItem?.action));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateCheckListItem'));
  } catch (e) {
    yield put(updateCheckListItem.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateCheckListItem'));
  }
}

export function* removeCheckListItemSaga({ payload: { checkListItem } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteActionsCheckListItem(checkListItem.id, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.deleteCheckListItem'));
    }
    yield put(removeCheckListItem.success(response?.data?.deleteActionsCheckListItem?.action));

    yield Alert.setSuccessAlert(i18n.t('api.success.deleteCheckListItem'));
  } catch (e) {
    yield put(removeCheckListItem.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteCheckListItem'));
  }
}

export function* addDocumentSaga({ payload: { document } }) {
  try {
    Storage.configure({ level: 'protected' });
    let fileName = document.file.name;
    const title = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
    fileName = getS3Key(document.file, document.elementId);
    yield Storage.put(fileName, document.file);
    const url = yield Storage.get(fileName);

    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addActionsDocument(document.elementId, url, title, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.createDocument'));
    }
    yield put(addDocument.success(response?.data?.addActionsDocument?.action));

    yield Alert.setSuccessAlert(i18n.t('api.success.createDocument'));
  } catch (e) {
    yield put(addDocument.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createDocument'));
  }
}

export function* updateDocumentSaga({ payload: { document } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: updateActionsDocument(document.id, document.title, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.updateDocument'));
    }
    yield put(updateDocument.success(response?.data?.updateActionsDocument?.action));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateDocument'));
  } catch (e) {
    yield put(updateDocument.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateDocument'));
  }
}

export function* removeDocumentSaga({ payload: { document } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const fileName = getS3KeyFromUrl(document.url, document.actionId);
    yield Storage.remove(fileName, { level: 'protected' });
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteActionsDocument(document.id, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.deleteDocument'));
    }
    yield put(removeDocument.success(response?.data?.deleteActionsDocument?.action));

    yield Alert.setSuccessAlert(i18n.t('api.success.deleteDocument'));
  } catch (e) {
    yield put(removeDocument.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteDocument'));
  }
}

export function* addCommentSaga({ payload: { comment } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addCommentMutation(comment.elementId, comment.parentId, comment.text, cognitoId)
      }
    };

    const response = yield API.post('InteractionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.createComment'));
    }
    const newComment = response?.data?.createComment?.comment;
    const history = response?.data?.createComment?.history;
    yield put(addComment.success({ newComment, history }));

    yield Alert.setSuccessAlert(i18n.t('api.success.createComment'));
  } catch (e) {
    yield put(addComment.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createComment'));
  }
}

export function* updateCommentSaga({ payload: { comment } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: updateCommentMutation(comment.id, comment.text, cognitoId)
      }
    };

    const response = yield API.post('InteractionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.updateComment'));
    }
    const updatedComment = response?.data?.updateComment?.comment;
    yield put(updateComment.success(updatedComment));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateComment'));
  } catch (e) {
    yield put(updateComment.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateComment'));
  }
}

export function* removeCommentSaga({ payload: { comment } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteCommentMutation(comment.pId, cognitoId)
      }
    };

    const response = yield API.post('InteractionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.deleteComment'));
    }
    const history = response?.data?.deleteComment?.history;
    yield put(removeComment.success({ comment, history }));

    yield Alert.setSuccessAlert(i18n.t('api.success.deleteComment'));
  } catch (e) {
    yield put(removeComment.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteComment'));
  }
}

export function* likeOrUnlikeActionSaga({ payload: { actionId, userId } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: likeOrUnlikeElementMutation(actionId, userId)
      }
    };

    const response = yield API.post('InteractionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.like'));
    }
    yield put(likeOrUnlikeAction.success({ actionId, userId }));
  } catch (e) {
    yield put(likeOrUnlikeAction.failure(e));
  }
}

export function* likeOrUnlikeActionCommentSaga({ payload: { actionId, commentId, userId } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: likeOrUnlikeCommentMutation(commentId, userId)
      }
    };

    const response = yield API.post('InteractionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.like'));
    }
    yield put(likeOrUnlikeActionComment.success({ actionId, commentId, userId }));
  } catch (e) {
    yield put(likeOrUnlikeActionComment.failure(e));
  }
}

export function* addActionViewSaga({ payload: { actionId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addActionViewMutation(actionId, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      const error = response?.errors[0].message;
      if (error.includes('Akcija nije dostupna')) {
        globalNavigate(AppRoutes.actions.link);
        throw new Error(error);
      }
      throw new Error(i18n.t('api.errors.getAction'));
    }
    yield put(addActionView.success(response?.data?.addActionsView?.action));
  } catch (e) {
    yield put(addActionView.failure(e));
  }
}

function getS3Key(file, actionId) {
  const fileName = file.name;
  const fileType = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
  const randomId = uuid.v4();
  return `Actions/${actionId}/${randomId}${fileType}`;
}

function getS3KeyFromUrl(url, actionId) {
  const name = url.substring(url.lastIndexOf('/') + 1, url.length);
  return `Actions/${actionId}/${name}`;
}

export function* deleteActionSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteActionMutation(id, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.deleteAction'));
    }
    yield put(deleteAction.success(response?.data?.deleteAction?.actionId));
    yield Alert.setSuccessAlert(i18n.t('api.success.deleteAction'));
    globalNavigate(AppRoutes.actions.link);
  } catch (e) {
    yield put(deleteAction.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteAction'));
  }
}

export function* sendActionInvitationSaga({
  payload: { userId, actionId, usersParticipantsIds, organizationsParticipantsIds }
}) {
  try {
    const requestInfo = {
      body: {
        sender_id: userId,
        receivers: usersParticipantsIds,
        action_id: actionId,
        receiver_organizations: organizationsParticipantsIds
      }
    };
    const data = yield API.post('EmailAPI', '/send-action-invitation', requestInfo);

    yield put(sendActionInvitation.success(data));
    yield Alert.setSuccessAlert(i18n.t('api.success.sendActionInvitation'));
  } catch (error: any) {
    yield put(sendActionInvitation.failure(error?.response?.data?.message));
    yield Alert.setErrorAlert(i18n.t('api.errors.sendActionInvitation'));
  }
}

export function* joinActionSaga({ payload: { actionId, organizationId, payloadHash } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: joinActionMutation(actionId, organizationId, payloadHash, cognitoId)
      }
    };

    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);
    if (response?.errors) {
      const message = response.errors[0].message;
      if (message.includes('Ne postoji trenutno aktivna pozivnica')) {
        if (organizationId == null) {
          yield Alert.setErrorAlert(i18n.t('api.errors.noInvitationUser'));
        } else {
          yield Alert.setErrorAlert(i18n.t('api.errors.noInvitationOrganization'));
        }
      } else {
        throw new Error(i18n.t('api.errors.joinAction'));
      }
    } else {
      yield put(joinAction.success(response?.data?.joinAction?.action));
      yield Alert.setSuccessAlert(i18n.t('api.success.joinAction'));
    }
    globalNavigate(AppRoutes.actions.link + '/' + actionId);
  } catch (error: any) {
    yield put(sendActionInvitation.failure(error?.response?.data?.message));
    yield Alert.setErrorAlert(i18n.t('api.errors.joinAction'));
  }
}

export function* backActionSaga({ payload: { actionId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: backActionMutation(actionId, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(backAction.success(response?.data?.backAction?.action));
    yield Alert.setSuccessAlert(i18n.t('api.success.backContestApplication'));
  } catch (e) {
    yield put(backAction.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.backContestApplication'));
  }
}

export function* searchActionSaga({ payload: { name, tagIds } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: searchActionsQuery(name, tagIds, cognitoId)
      }
    };
    const response = yield API.post('ActionsAPI', '/graphql?query', requestInfo);
    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(searchAction.success(response?.data?.searchActions));
  } catch (e) {
    yield put(searchAction.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.search'));
  }
}

function* actionsSaga() {
  yield all([
    takeLatest(ALL_ACTIONS.GET.REQUEST, getAllActionsSaga),
    takeLatest(ACTION.GET.REQUEST, getActionSaga),
    takeLatest(ACTION.POST.REQUEST, createActionSaga),
    takeLatest(ACTION.DELETE.REQUEST, deleteActionSaga),
    takeLatest(UPDATE_ACTION.POST.REQUEST, updateActionSaga),
    takeLatest(ACTION_TAGS.POST.REQUEST, updateActionTagsSaga),
    takeLatest(ACTION_PARTICIPANTS.POST.REQUEST, updateActionParticipantsSaga),
    takeLatest(ACTION_CHECK_LIST_ITEMS.POST.REQUEST, addCheckListItemSaga),
    takeLatest(ACTION_CHECK_LIST_ITEMS.PUT.REQUEST, updateCheckListItemSaga),
    takeLatest(ACTION_CHECK_LIST_ITEMS.DELETE.REQUEST, removeCheckListItemSaga),
    takeLatest(ACTION_DOCUMENTS.POST.REQUEST, addDocumentSaga),
    takeLatest(ACTION_DOCUMENTS.PUT.REQUEST, updateDocumentSaga),
    takeLatest(ACTION_DOCUMENTS.DELETE.REQUEST, removeDocumentSaga),
    takeLatest(ACTION_COMMENTS.POST.REQUEST, addCommentSaga),
    takeLatest(ACTION_COMMENTS.PUT.REQUEST, updateCommentSaga),
    takeLatest(ACTION_COMMENTS.DELETE.REQUEST, removeCommentSaga),
    takeLatest(ACTION_LIKE.POST.REQUEST, likeOrUnlikeActionSaga),
    takeLatest(ACTION_COMMENT_LIKE.POST.REQUEST, likeOrUnlikeActionCommentSaga),
    takeLatest(ACTION_VIEW.POST.REQUEST, addActionViewSaga),
    takeLatest(ACTION_ADD_REMOVE_PARTICIPANT.POST.REQUEST, addRemoveActionsParticipantSaga),
    takeLatest(SEND_ACTION_INVITATION.POST.REQUEST, sendActionInvitationSaga),
    takeLatest(JOIN_ACTION.POST.REQUEST, joinActionSaga),
    takeLatest(ACTION_REMOVE_PARTICIPANT.POST.REQUEST, removeActionsParticipantSaga),
    takeLatest(ACTION_VOTE.POST.REQUEST, backActionSaga),
    takeLatest(SEARCH_ACTION.GET.REQUEST, searchActionSaga)
  ]);
}

export default actionsSaga;
