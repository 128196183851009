import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  notification: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #eee',
    borderRadius: '3px',
    padding: '20px 5px 20px 20px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderLeft: '8px solid #FA7268',
    '&:hover': {
      backgroundColor: '#eee'
    }
  },
  avatar: {
    marginRight: '10px',
    height: '35px',
    width: '35px'
  },
  notificationText: {
    fontSize: '12px'
  }
}));
