import { calendarEventData } from './queries.js';
import { stringify, escapeSingleAndDoubleQuotes, stringToList } from './utils';

export function createCalendarEventMutation(event, cognitoId) {
  const description =
    event?.desc != undefined ? `description: [${stringToList(escapeSingleAndDoubleQuotes(event.desc))}]` : '';
  const addr = event?.address ? `address: "${escapeSingleAndDoubleQuotes(event.address)}"` : '';
  const link = event?.link ? `link: "${escapeSingleAndDoubleQuotes(event.link)}"` : '';
  const countryId = event?.countryId ? `countryId: ${event.countryId}` : '';
  const organizerIds = stringify(event?.organizerIds);
  const tagIds = stringify(event?.tagIds);
  const orgOrganizerIds = stringify(event?.organizationOrganizerIds);

  return `mutation {
    createCalendarEvent(
      calendarEventInputData: {
        startTime: "${event.start}"
        endTime: "${event.end}"
        name: "${event.title}"
        locationType: "${event.locationType}"
        eventType: ${event.eventType}
        organizerIds: ${organizerIds}
        organizationOrganizerIds: ${orgOrganizerIds}
        tagIds: ${tagIds}
        ${addr}
        ${link}
        ${countryId}
        ${description}
      }
      cognitoId: "${cognitoId}"
    ) {
      isRequestSuccessful
      calendarEvent {
        ${calendarEventData}
      }
    }
  }`;
}

export function updateCalendarEventMutation(data, cognitoId) {
  const description =
    data?.desc != undefined ? `description: [${stringToList(escapeSingleAndDoubleQuotes(data.desc))}]` : '';
  const countryId = data?.countryId != undefined ? `countryId: ${data.countryId}` : '';
  const startTime = data?.start != undefined ? `startTime: "${data.start}"` : '';
  const endTime = data?.end != undefined ? `endTime: "${data.end}"` : '';
  const name = data?.title != undefined ? `name: "${data.title}"` : '';
  const locationType = data?.locationType != undefined ? `locationType: "${data.locationType}"` : '';
  const eventType = data?.eventType != undefined ? `eventType: ${data.eventType}` : '';
  const organizerIds = stringify(data?.organizerIds);
  const organizationOrganizerIds = stringify(data?.organizationOrganizerIds);

  const addr = data?.address ? `address: "${escapeSingleAndDoubleQuotes(data.address)}"` : '';
  const link = data?.link ? `link: "${escapeSingleAndDoubleQuotes(data.link)}"` : '';
  const tagIds = stringify(data?.tagIds);

  return `mutation {
    updateCalendarEvent(
      calendarEventInputData: {
        id: ${data.id}
        ${description}
        ${countryId}
        ${startTime}
        ${endTime}
        ${name}
        ${locationType}
        ${eventType}
        ${addr}
        ${link}
        organizationOrganizerIds: ${organizationOrganizerIds}
        tagIds: ${tagIds}
        organizerIds: ${organizerIds}
      }
      cognitoId: "${cognitoId}"
    ) {
      isRequestSuccessful
      calendarEvent {
        ${calendarEventData}
      }
    }
  }`;
}

export function createOrDeleteOrganizersMutation(eventId, organizerIds, cognitoId) {
  const orgIds = stringify(organizerIds);
  return `
    mutation {
      createOrDeleteOrganizers(
        id: ${eventId}
        organizerIds: ${orgIds}
        cognitoId: "${cognitoId}"
      ) {
        isRequestSuccessful
        calendarEvent {
          ${calendarEventData}
        }
      }
    }
  `;
}

export function deleteCalendarMutation(eventId, cognitoId) {
  return `mutation {
    deleteCalendarEvent(
      id: ${eventId}
      cognitoId: "${cognitoId}"
    ) {
      isRequestSuccessful
    }
  }
  `;
}

export function addOrRemoveEventParticipantMutation(eventId, cognitoId) {
  return `mutation {
    addOrRemoveEventParticipant(
      id: ${eventId}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      calendarEvent {
        ${calendarEventData}
      }
    }
  }`;
}
