import { contestApplicationData } from './queries.js';
import { stringify, escapeSingleAndDoubleQuotes, stringToList } from './utils';

export function createContestApplication(contestApplication, cognitoId) {
  const organizationId = contestApplication.attributes.organizationId;
  const content = contestApplication.attributes.content;
  const contestId = contestApplication.attributes.contestId;
  const organizationIdStr = organizationId != undefined ? `organizationId: ${organizationId}` : '';
  const contentStr = content != undefined ? `content: [${stringToList(escapeSingleAndDoubleQuotes(content))}]` : '';
  const knowledgeIds = stringify(contestApplication.knowledgeIds);
  const linkIds = stringify(contestApplication.linkIds);
  const expertiseIds = stringify(contestApplication.expertiseIds);
  const toolIds = stringify(contestApplication.toolIds);
  return `mutation {
      createContestApplication (
        contestApplicationData: {
          ${organizationIdStr}
          contestId: ${contestId}
          knowledgeIds: ${knowledgeIds}
          linkIds: ${linkIds}
          expertiseIds: ${expertiseIds}
          toolIds: ${toolIds}
          ${contentStr}
        }
        cognitoId: "${cognitoId}"
      ){
        contestApplication {
          ${contestApplicationData}
        }
        isRequestSuccessful
      }
    }`;
}

export function updateContestApplication(id, content, cognitoId) {
  const contentStr = content != undefined ? `content: [${stringToList(escapeSingleAndDoubleQuotes(content))}]` : '';
  return `mutation {
    updateContestApplication(
      updateContestApplicationInputData: {
        id: ${id}
        ${contentStr}
      }
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
    }
  }`;
}

export function deleteContestApplication(id, cognitoId) {
  return `
    mutation {
      deleteContestApplication(
        id: ${id}
        cognitoId: "${cognitoId}"
      ){
        isRequestSuccessful
      }
    }`;
}

export function addNewContestApplicationsDocuments(documents, cognitoId) {
  return `mutation {  
    addNewContestApplicationsDocuments(
      documents: ${documents}
      cognitoId: "${cognitoId}"
    ){
      contestApplication {
        ${contestApplicationData}
      }
      isRequestSuccessful
    }
  }`;
}

export function addContestApplicationsDocument(document, cognitoId) {
  const titleStr = document.title != undefined ? `title: "${escapeSingleAndDoubleQuotes(document.title)}"` : '';
  return `mutation {
    addContestApplicationsDocument(
      documentInput: {
        ${titleStr}
        url: "${document.url}"
        elementId: ${document.elementId}
      }
      cognitoId: "${cognitoId}"
    ){
      document {
        pId
        title
        url
        elementId
      }
      isRequestSuccessful
    }
  }`;
}

export function updateContestApplicationsDocument(document, cognitoId) {
  const titleStr = document.title != undefined ? `title: "${escapeSingleAndDoubleQuotes(document.title)}"` : '';
  return `mutation {
    updateContestApplicationsDocument(
      id: ${document.pId}
      ${titleStr}
      cognitoId: "${cognitoId}"
    ){
      document {
        pId
        title
        url
        elementId
      }
      isRequestSuccessful
    }
  }`;
}

export function deleteContestApplicationsDocument(document, cognitoId) {
  return `mutation {
    deleteContestApplicationsDocument(
      id:  ${document.pId}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
    }
  }`;
}

export function addContestApplicationsView(id, cognitoId) {
  return `mutation {
    addContestApplicationsView(
      id: ${id}
      cognitoId: "${cognitoId}"
    ){
      contestApplication {
        ${contestApplicationData}
      }
      isRequestSuccessful
    }
  }`;
}

export function backContestApplication(id, cognitoId) {
  return `mutation {
    backContestApplication(cognitoId: "${cognitoId}", id:${id}) {
      isRequestSuccessful
    }
  }`;
}

export function createOrUpdateContestApplicationResourcesMutation(
  contestApplicationId,
  knowledgeIds,
  linkIds,
  expertiseIds,
  toolIds,
  cognitoId
) {
  const knowledgeIdsStr = stringify(knowledgeIds);
  const linkIdsStr = stringify(linkIds);
  const expertiseIdsStr = stringify(expertiseIds);
  const toolIdsStr = stringify(toolIds);
  return `mutation {
    createOrDeleteContestApplicationResources (
    resourcesData: {
      id: ${contestApplicationId}
      knowledgeIds: ${knowledgeIdsStr}
      linkIds: ${linkIdsStr}
      expertiseIds: ${expertiseIdsStr}
      toolIds: ${toolIdsStr}
    }
    cognitoId: "${cognitoId}"
  ){
    isRequestSuccessful
    contestApplication {
      ${contestApplicationData}
    }
  }
}`;
}
