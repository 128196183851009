import { action, createRequestTypes } from 'utils/ReduxHelpers';

import { GET, POST, PUT, DELETE } from 'utils/Constants';
import {
  IComment,
  ILinkResource,
  IExpertiseResource,
  ITag,
  IExpertiseResourceProject,
  IKnowledgeResource,
  IAuthorApprovalType,
  IDocumentType,
  IToolResource,
  IDocument
} from 'core/models/Models';

interface IKnowledgePayload {
  name: string;
  description: string;
  tags: ITag[];
  imageUrl: string;
  contentUrl: string;
  file?: any;
  authorRights: IAuthorApprovalType;
  documentType: IDocumentType;
  creatorOrganizationId?: number;
}

export const RESOURCES = createRequestTypes('RESOURCES', [GET]);
export const RESOURCES_PAGINATED = createRequestTypes('RESOURCES_PAGINATED', [GET]);
export const KNOWLEDGE_RESOURCES_PAGINATED = createRequestTypes('KNOWLEDGE_RESOURCES_PAGINATED', [GET]);
export const LINK_RESOURCES_PAGINATED = createRequestTypes('LINK_RESOURCES_PAGINATED', [GET]);
export const EXPERTISE_RESOURCES_PAGINATED = createRequestTypes('EXPERTISE_RESOURCES_PAGINATED', [GET]);
export const TOOL_RESOURCES_PAGINATED = createRequestTypes('TOOL_RESOURCES_PAGINATED', [GET]);

export const PRIMITIVE_RESOURCES = createRequestTypes('PRIMITIVE_RESOURCES', [GET]);
export const EXPERTISE_RESOURCE = createRequestTypes('EXPERTISE_RESOURCE', [GET, POST, PUT, DELETE]);
export const EXPERTISE_RESOURCE_LIKE = createRequestTypes('EXPERTISE_RESOURCE_LIKE', [POST]);
export const EXPERTISE_RESOURCE_PROJECT = createRequestTypes('EXPERTISE_RESOURCE_PROJECT', [POST, PUT, DELETE]);
export const EXPERTISE_RESOURCE_VIEW = createRequestTypes('EXPERTISE_RESOURCE_VIEW', [POST]);
export const EXPERTISE_RESOURCE_TAGS = createRequestTypes('EXPERTISE_RESOURCE_TAGS', [POST]);
export const EXPERTISE_RESOURCE_COMMENTS = createRequestTypes('EXPERTISE_RESOURCE_COMMENTS', [POST, PUT, DELETE]);
export const EXPERTISE_RESOURCE_COMMENTS_LIKE = createRequestTypes('EXPERTISE_RESOURCE_COMMENTS_LIKE', [POST]);

export const LINK_RESOURCE = createRequestTypes('LINK_RESOURCE', [GET, POST, PUT, DELETE]);
export const LINK_RESOURCE_LIKE = createRequestTypes('LINK_RESOURCE_LIKE', [POST]);
export const LINK_RESOURCE_VIEW = createRequestTypes('LINK_RESOURCE_VIEW', [POST]);
export const LINK_RESOURCE_TAGS = createRequestTypes('LINK_RESOURCE_TAGS', [POST]);

export const TOOL_RESOURCE = createRequestTypes('TOOL_RESOURCE', [POST, PUT, DELETE]);
export const TOOL_RESOURCE_LIKE = createRequestTypes('TOOL_RESOURCE_LIKE', [POST]);
export const TOOL_RESOURCE_VIEW = createRequestTypes('TOOL_RESOURCE_VIEW', [POST]);
export const TOOL_RESOURCE_TAGS = createRequestTypes('TOOL_RESOURCE_TAGS', [POST]);
export const TOOL_RESOURCE_RESOURCES = createRequestTypes('TOOL_RESOURCE_RESOURCES', [POST]);
export const TOOL_RESOURCE_COMMENTS = createRequestTypes('TOOL_RESOURCE_COMMENTS', [POST, PUT, DELETE]);
export const TOOL_RESOURCE_COMMENTS_LIKE = createRequestTypes('TOOL_RESOURCE_COMMENTS_LIKE', [POST]);
export const TOOL_CONTAINED_RESOURCE_LIKE = createRequestTypes('TOOL_CONTAINED_RESOURCE_LIKE', [POST]);
export const TOOL_RESOURCE_DOCUMENTS = createRequestTypes('TOOL_RESOURCE_DOCUMENTS', [DELETE, POST, PUT]);

export const KNOWLEDGE_RESOURCE = createRequestTypes('KNOWLEDGE_RESOURCE', [GET, POST, PUT, DELETE]);
export const KNOWLEDGE_RESOURCE_TAGS = createRequestTypes('KNOWLEDGE_RESOURCE_TAGS', [POST]);
export const KNOWLEDGE_RESOURCE_LIKE = createRequestTypes('KNOWLEDGE_RESOURCE_LIKE', [POST]);
export const KNOWLEDGE_RESOURCE_VIEW = createRequestTypes('KNOWLEDGE_RESOURCE_VIEW', [POST]);
export const KNOWLEDGE_RESOURCE_COMMENTS = createRequestTypes('KNOWLEDGE_RESOURCE_COMMENTS', [POST, PUT, DELETE]);
export const KNOWLEDGE_RESOURCE_COMMENTS_LIKE = createRequestTypes('KNOWLEDGE_RESOURCE_COMMENTS_LIKE', [POST]);

export const SEARCH_RESOURCE = createRequestTypes('SEARCH_RESOURCE', [GET]);

export const UPDATE_EXPERTISE_LINKED_RESOURCES = 'UPDATE_EXPERTISE_LINKED_RESOURCES';
export const UPDATE_TOOL_LINKED_RESOURCES = 'UPDATE_TOOL_LINKED_RESOURCES';

export const updateExpertiseLinkedResources = (knowledgeResource: IKnowledgeResource, linkResource: ILinkResource) =>
  action(UPDATE_EXPERTISE_LINKED_RESOURCES, { knowledgeResource, linkResource });

export const updateToolLinkedResources = (
  expertiseResources: IExpertiseResource[],
  knowledgeResources: IKnowledgeResource[],
  linkResources: ILinkResource[]
) => action(UPDATE_EXPERTISE_LINKED_RESOURCES, { expertiseResources, knowledgeResources, linkResources });

export const searchResource = {
  request: (name?: string | null, resourceType?: string, knowledgeType?: string, tagIds?: number[], page?: number, num?: number) =>
    action(SEARCH_RESOURCE.GET.REQUEST, { page, num, name, tagIds, resourceType, knowledgeType }),
  success: (payload: any) => action(SEARCH_RESOURCE.GET.SUCCESS, payload),
  failure: (error: string) => action(SEARCH_RESOURCE.GET.FAILURE, error)
};

export const createKnowledge = {
  request: (knowledge: IKnowledgePayload, source: string) =>
    action(KNOWLEDGE_RESOURCE.POST.REQUEST, { knowledge, source }),
  success: (payload: any) => action(KNOWLEDGE_RESOURCE.POST.SUCCESS, payload),
  failure: (error: string) => action(KNOWLEDGE_RESOURCE.POST.FAILURE, error)
};

export const getKnowledge = {
  request: (id: number) => action(KNOWLEDGE_RESOURCE.GET.REQUEST, { id }),
  success: (payload: any) => action(KNOWLEDGE_RESOURCE.GET.SUCCESS, payload),
  failure: (error: string) => action(KNOWLEDGE_RESOURCE.GET.FAILURE, error)
};

export const deleteKnowledge = {
  request: (id: number) => action(KNOWLEDGE_RESOURCE.DELETE.REQUEST, { id }),
  success: (payload: any) => action(KNOWLEDGE_RESOURCE.DELETE.SUCCESS, payload),
  failure: (error: string) => action(KNOWLEDGE_RESOURCE.DELETE.FAILURE, error)
};

export const updateKnowledge = {
  request: (knowledge: any) => action(KNOWLEDGE_RESOURCE.PUT.REQUEST, { knowledge }),
  success: (payload: any) => action(KNOWLEDGE_RESOURCE.PUT.SUCCESS, payload),
  failure: (error: string) => action(KNOWLEDGE_RESOURCE.PUT.FAILURE, error)
};

export const updateKnowledgeTags = {
  request: (id: number, tags: Array<ITag>) => action(KNOWLEDGE_RESOURCE_TAGS.POST.REQUEST, { id, tags }),
  success: (id: number, tags: ITag[]) => action(KNOWLEDGE_RESOURCE_TAGS.POST.SUCCESS, { id, tags }),
  failure: (error: any) => action(KNOWLEDGE_RESOURCE_TAGS.POST.FAILURE, error)
};

export const getResources = {
  request: () => action(RESOURCES.GET.REQUEST),
  success: (payload: any) => action(RESOURCES.GET.SUCCESS, payload),
  failure: (error: string) => action(RESOURCES.GET.FAILURE, error)
};

export const getResourcesPaginated = {
  request: (page: number, number?: number) => action(RESOURCES_PAGINATED.GET.REQUEST, {page, number}),
  success: (payload: any) => action(RESOURCES_PAGINATED.GET.SUCCESS, payload),
  failure: (error: string) => action(RESOURCES_PAGINATED.GET.FAILURE, error)
};

export const getKnowledgeResourcesPaginated = {
  request: (page: number, number?: number) => action(KNOWLEDGE_RESOURCES_PAGINATED.GET.REQUEST, {page, number}),
  success: (payload: any) => action(KNOWLEDGE_RESOURCES_PAGINATED.GET.SUCCESS, payload),
  failure: (error: string) => action(KNOWLEDGE_RESOURCES_PAGINATED.GET.FAILURE, error)
};

export const getLinkResourcesPaginated = {
  request: (page: number, number?: number) => action(LINK_RESOURCES_PAGINATED.GET.REQUEST, {page, number}),
  success: (payload: any) => action(LINK_RESOURCES_PAGINATED.GET.SUCCESS, payload),
  failure: (error: string) => action(LINK_RESOURCES_PAGINATED.GET.FAILURE, error)
};

export const getExpertiseResourcesPaginated = {
  request: (page: number, number?: number) => action(EXPERTISE_RESOURCES_PAGINATED.GET.REQUEST, {page, number}),
  success: (payload: any) => action(EXPERTISE_RESOURCES_PAGINATED.GET.SUCCESS, payload),
  failure: (error: string) => action(EXPERTISE_RESOURCES_PAGINATED.GET.FAILURE, error)
};

export const getToolResourcesPaginated = {
  request: (page: number, number?: number) => action(TOOL_RESOURCES_PAGINATED.GET.REQUEST, {page, number}),
  success: (payload: any) => action(TOOL_RESOURCES_PAGINATED.GET.SUCCESS, payload),
  failure: (error: string) => action(TOOL_RESOURCES_PAGINATED.GET.FAILURE, error)
};

export const getPrimitiveResources = {
  request: (loadExpertisesResources, loadKnowledgeResources, loadLinkResources) =>
    action(PRIMITIVE_RESOURCES.GET.REQUEST, { loadExpertisesResources, loadKnowledgeResources, loadLinkResources }),
  success: (payload: any) => action(PRIMITIVE_RESOURCES.GET.SUCCESS, payload),
  failure: (error: string) => action(PRIMITIVE_RESOURCES.GET.FAILURE, error)
};

export const getExpertiseResource = {
  request: (id) => action(EXPERTISE_RESOURCE.GET.REQUEST, id),
  success: (payload: any) => action(EXPERTISE_RESOURCE.GET.SUCCESS, payload),
  failure: (error: string) => action(EXPERTISE_RESOURCE.GET.FAILURE, error)
};

export const updateExpertiseResource = {
  request: (data: any) => action(EXPERTISE_RESOURCE.PUT.REQUEST, { data }),
  success: (payload: any) => action(EXPERTISE_RESOURCE.PUT.SUCCESS, payload),
  failure: (error: string) => action(EXPERTISE_RESOURCE.PUT.FAILURE, error)
};

export const createExpertiseResource = {
  request: (resource: IExpertiseResource, source: string) =>
    action(EXPERTISE_RESOURCE.POST.REQUEST, { resource, source }),
  success: (payload: any) => action(EXPERTISE_RESOURCE.POST.SUCCESS, payload),
  failure: (error: string) => action(EXPERTISE_RESOURCE.POST.FAILURE, error)
};

export const deleteExpertiseResource = {
  request: (id: number) => action(EXPERTISE_RESOURCE.DELETE.REQUEST, { id }),
  success: (payload: any) => action(EXPERTISE_RESOURCE.DELETE.SUCCESS, payload),
  failure: (error: any) => action(EXPERTISE_RESOURCE.DELETE.FAILURE, error)
};

export const addExpertiseResourcesView = {
  request: (resourceId) => action(EXPERTISE_RESOURCE_VIEW.POST.REQUEST, { resourceId }),
  success: (payload: any) => action(EXPERTISE_RESOURCE_VIEW.POST.SUCCESS, payload),
  failure: (error: any) => action(EXPERTISE_RESOURCE_VIEW.POST.FAILURE, error)
};

export const updateExpertiseResourceTags = {
  request: (tagIds: Array<number>, resourceId: number) =>
    action(EXPERTISE_RESOURCE_TAGS.POST.REQUEST, { tagIds, resourceId }),
  success: (resourceId: number, tags: ITag[]) => action(EXPERTISE_RESOURCE_TAGS.POST.SUCCESS, { resourceId, tags }),
  failure: (error: any) => action(EXPERTISE_RESOURCE_TAGS.POST.FAILURE, error)
};

export const updateExpertiseProject = {
  request: (project: IExpertiseResourceProject) => action(EXPERTISE_RESOURCE_PROJECT.PUT.REQUEST, { project }),
  success: (project: IExpertiseResourceProject) => action(EXPERTISE_RESOURCE_PROJECT.PUT.SUCCESS, { project }),
  failure: (error: any) => action(EXPERTISE_RESOURCE_PROJECT.PUT.FAILURE, error)
};

export const createExpertiseProject = {
  request: (project: IExpertiseResourceProject) => action(EXPERTISE_RESOURCE_PROJECT.POST.REQUEST, { project }),
  success: (project: IExpertiseResourceProject) => action(EXPERTISE_RESOURCE_PROJECT.POST.SUCCESS, { project }),
  failure: (error: any) => action(EXPERTISE_RESOURCE_PROJECT.POST.FAILURE, error)
};

export const deleteExpertiseProject = {
  request: (id: number, resourceId: number) => action(EXPERTISE_RESOURCE_PROJECT.DELETE.REQUEST, { id, resourceId }),
  success: (projectId: number, resourceId: number) =>
    action(EXPERTISE_RESOURCE_PROJECT.DELETE.SUCCESS, { projectId, resourceId }),
  failure: (error: any) => action(EXPERTISE_RESOURCE_PROJECT.DELETE.FAILURE, error)
};

export const addExpertiseResourceComment = {
  request: (comment: IComment) => action(EXPERTISE_RESOURCE_COMMENTS.POST.REQUEST, { comment }),
  success: (payload: any) => action(EXPERTISE_RESOURCE_COMMENTS.POST.SUCCESS, payload),
  failure: (error: any) => action(EXPERTISE_RESOURCE_COMMENTS.POST.FAILURE, error)
};

export const updateExpertiseResourceComment = {
  request: (comment: IComment) => action(EXPERTISE_RESOURCE_COMMENTS.PUT.REQUEST, { comment }),
  success: (payload: any) => action(EXPERTISE_RESOURCE_COMMENTS.PUT.SUCCESS, payload),
  failure: (error: any) => action(EXPERTISE_RESOURCE_COMMENTS.PUT.FAILURE, error)
};

export const removeExpertiseResourceComment = {
  request: (comment: IComment) => action(EXPERTISE_RESOURCE_COMMENTS.DELETE.REQUEST, { comment }),
  success: (payload: any) => action(EXPERTISE_RESOURCE_COMMENTS.DELETE.SUCCESS, payload),
  failure: (error: any) => action(EXPERTISE_RESOURCE_COMMENTS.DELETE.FAILURE, error)
};

export const likeOrUnlikExpertiseResource = {
  request: (resourceId, userId) => action(EXPERTISE_RESOURCE_LIKE.POST.REQUEST, { resourceId, userId }),
  success: (payload: any) => action(EXPERTISE_RESOURCE_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(EXPERTISE_RESOURCE_LIKE.POST.FAILURE, error)
};

export const likeOrUnlikExpertiseResourceComment = {
  request: (comment: IComment, userId) => action(EXPERTISE_RESOURCE_COMMENTS_LIKE.POST.REQUEST, { comment, userId }),
  success: (payload: any) => action(EXPERTISE_RESOURCE_COMMENTS_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(EXPERTISE_RESOURCE_COMMENTS_LIKE.POST.FAILURE, error)
};

export const getLinkResource = {
  request: (id) => action(LINK_RESOURCE.GET.REQUEST, id),
  success: (payload: any) => action(LINK_RESOURCE.GET.SUCCESS, payload),
  failure: (error: string) => action(LINK_RESOURCE.GET.FAILURE, error)
};

export const updateLinkResource = {
  request: (data: any) => action(LINK_RESOURCE.PUT.REQUEST, { data }),
  success: (payload: any) => action(LINK_RESOURCE.PUT.SUCCESS, payload),
  failure: (error: string) => action(LINK_RESOURCE.PUT.FAILURE, error)
};

export const createLinkResource = {
  request: (resource: ILinkResource, source: string) => action(LINK_RESOURCE.POST.REQUEST, { resource, source }),
  success: (payload: any) => action(LINK_RESOURCE.POST.SUCCESS, payload),
  failure: (error: string) => action(LINK_RESOURCE.POST.FAILURE, error)
};

export const addLinkResourcesView = {
  request: (resourceId) => action(LINK_RESOURCE_VIEW.POST.REQUEST, { resourceId }),
  success: (payload: any) => action(LINK_RESOURCE_VIEW.POST.SUCCESS, payload),
  failure: (error: any) => action(LINK_RESOURCE_VIEW.POST.FAILURE, error)
};

export const updateLinkResourceTags = {
  request: (tagIds: Array<number>, resourceId: number) =>
    action(LINK_RESOURCE_TAGS.POST.REQUEST, { tagIds, resourceId }),
  success: (resourceId: number, tags: ITag[]) => action(LINK_RESOURCE_TAGS.POST.SUCCESS, { resourceId, tags }),
  failure: (error: any) => action(LINK_RESOURCE_TAGS.POST.FAILURE, error)
};

export const likeOrUnlikLinkResource = {
  request: (resourceId, userId) => action(LINK_RESOURCE_LIKE.POST.REQUEST, { resourceId, userId }),
  success: (payload: any) => action(LINK_RESOURCE_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(LINK_RESOURCE_LIKE.POST.FAILURE, error)
};

export const deleteLinkResource = {
  request: (id: number) => action(LINK_RESOURCE.DELETE.REQUEST, { id }),
  success: (payload: any) => action(LINK_RESOURCE.DELETE.SUCCESS, payload),
  failure: (error: any) => action(LINK_RESOURCE.DELETE.FAILURE, error)
};

export const updateToolResource = {
  request: (data: any) => action(TOOL_RESOURCE.PUT.REQUEST, { data }),
  success: (payload: any) => action(TOOL_RESOURCE.PUT.SUCCESS, payload),
  failure: (error: string) => action(TOOL_RESOURCE.PUT.FAILURE, error)
};

export const createToolResource = {
  request: (resource: IToolResource) => action(TOOL_RESOURCE.POST.REQUEST, { resource }),
  success: (payload: any) => action(TOOL_RESOURCE.POST.SUCCESS, payload),
  failure: (error: string) => action(TOOL_RESOURCE.POST.FAILURE, error)
};

export const addToolResourcesView = {
  request: (resourceId) => action(TOOL_RESOURCE_VIEW.POST.REQUEST, { resourceId }),
  success: (payload: any) => action(TOOL_RESOURCE_VIEW.POST.SUCCESS, payload),
  failure: (error: any) => action(TOOL_RESOURCE_VIEW.POST.FAILURE, error)
};

export const updateToolResourceTags = {
  request: (tagIds: Array<number>, resourceId: number) =>
    action(TOOL_RESOURCE_TAGS.POST.REQUEST, { tagIds, resourceId }),
  success: (resourceId: number, tags: ITag[]) => action(TOOL_RESOURCE_TAGS.POST.SUCCESS, { resourceId, tags }),
  failure: (error: any) => action(TOOL_RESOURCE_TAGS.POST.FAILURE, error)
};

export const updateToolResourceResources = {
  request: (toolId: number, knowledgeIds: Array<number>, linkIds: Array<number>, expertiseIds: Array<number>) =>
    action(TOOL_RESOURCE_RESOURCES.POST.REQUEST, { toolId, knowledgeIds, linkIds, expertiseIds }),
  success: (payload: any) => action(TOOL_RESOURCE_RESOURCES.POST.SUCCESS, payload),
  failure: (error: any) => action(TOOL_RESOURCE_RESOURCES.POST.FAILURE, error)
};

export const deleteToolResource = {
  request: (id: number) => action(TOOL_RESOURCE.DELETE.REQUEST, { id }),
  success: (payload: any) => action(TOOL_RESOURCE.DELETE.SUCCESS, payload),
  failure: (error: any) => action(TOOL_RESOURCE.DELETE.FAILURE, error)
};

export const addKnowledgeResourceComment = {
  request: (comment: IComment) => action(KNOWLEDGE_RESOURCE_COMMENTS.POST.REQUEST, { comment }),
  success: (payload: any) => action(KNOWLEDGE_RESOURCE_COMMENTS.POST.SUCCESS, payload),
  failure: (error: any) => action(KNOWLEDGE_RESOURCE_COMMENTS.POST.FAILURE, error)
};

export const updateKnowledgeResourceComment = {
  request: (comment: IComment) => action(KNOWLEDGE_RESOURCE_COMMENTS.PUT.REQUEST, { comment }),
  success: (payload: any) => action(KNOWLEDGE_RESOURCE_COMMENTS.PUT.SUCCESS, payload),
  failure: (error: any) => action(KNOWLEDGE_RESOURCE_COMMENTS.PUT.FAILURE, error)
};

export const removeKnowledgeResourceComment = {
  request: (comment: IComment) => action(KNOWLEDGE_RESOURCE_COMMENTS.DELETE.REQUEST, { comment }),
  success: (payload: any) => action(KNOWLEDGE_RESOURCE_COMMENTS.DELETE.SUCCESS, payload),
  failure: (error: any) => action(KNOWLEDGE_RESOURCE_COMMENTS.DELETE.FAILURE, error)
};

export const likeOrUnlikKnowledgeResource = {
  request: (resourceId, userId) => action(KNOWLEDGE_RESOURCE_LIKE.POST.REQUEST, { resourceId, userId }),
  success: (payload: any) => action(KNOWLEDGE_RESOURCE_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(KNOWLEDGE_RESOURCE_LIKE.POST.FAILURE, error)
};

export const likeOrUnlikKnowledgeResourceComment = {
  request: (comment: IComment, userId) => action(KNOWLEDGE_RESOURCE_COMMENTS_LIKE.POST.REQUEST, { comment, userId }),
  success: (payload: any) => action(KNOWLEDGE_RESOURCE_COMMENTS_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(KNOWLEDGE_RESOURCE_COMMENTS_LIKE.POST.FAILURE, error)
};

export const addKnowledgeResourcesView = {
  request: (resourceId) => action(KNOWLEDGE_RESOURCE_VIEW.POST.REQUEST, { resourceId }),
  success: (payload: any) => action(KNOWLEDGE_RESOURCE_VIEW.POST.SUCCESS, payload),
  failure: (error: any) => action(KNOWLEDGE_RESOURCE_VIEW.POST.FAILURE, error)
};

export const addToolResourceComment = {
  request: (comment: IComment) => action(TOOL_RESOURCE_COMMENTS.POST.REQUEST, { comment }),
  success: (payload: any) => action(TOOL_RESOURCE_COMMENTS.POST.SUCCESS, payload),
  failure: (error: any) => action(TOOL_RESOURCE_COMMENTS.POST.FAILURE, error)
};

export const updateToolResourceComment = {
  request: (comment: IComment) => action(TOOL_RESOURCE_COMMENTS.PUT.REQUEST, { comment }),
  success: (payload: any) => action(TOOL_RESOURCE_COMMENTS.PUT.SUCCESS, payload),
  failure: (error: any) => action(TOOL_RESOURCE_COMMENTS.PUT.FAILURE, error)
};

export const removeToolResourceComment = {
  request: (comment: IComment) => action(TOOL_RESOURCE_COMMENTS.DELETE.REQUEST, { comment }),
  success: (payload: any) => action(TOOL_RESOURCE_COMMENTS.DELETE.SUCCESS, payload),
  failure: (error: any) => action(TOOL_RESOURCE_COMMENTS.DELETE.FAILURE, error)
};

export const likeOrUnlikToolResource = {
  request: (resourceId, userId) => action(TOOL_RESOURCE_LIKE.POST.REQUEST, { resourceId, userId }),
  success: (payload: any) => action(TOOL_RESOURCE_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(TOOL_RESOURCE_LIKE.POST.FAILURE, error)
};

export const likeOrUnlikToolResourceComment = {
  request: (comment: IComment, userId) => action(TOOL_RESOURCE_COMMENTS_LIKE.POST.REQUEST, { comment, userId }),
  success: (payload: any) => action(TOOL_RESOURCE_COMMENTS_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(TOOL_RESOURCE_COMMENTS_LIKE.POST.FAILURE, error)
};

export const likeOrUnlikeTooContainedResource = {
  request: (toolId, resourceId, userId) =>
    action(TOOL_CONTAINED_RESOURCE_LIKE.POST.REQUEST, { toolId, resourceId, userId }),
  success: (payload: any) => action(TOOL_CONTAINED_RESOURCE_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(TOOL_CONTAINED_RESOURCE_LIKE.POST.FAILURE, error)
};

export const addToolResourceDocument = {
  request: (document: IDocument) => action(TOOL_RESOURCE_DOCUMENTS.POST.REQUEST, { document }),
  success: (payload: any) => action(TOOL_RESOURCE_DOCUMENTS.POST.SUCCESS, payload),
  failure: (error: any) => action(TOOL_RESOURCE_DOCUMENTS.POST.FAILURE, error)
};

export const updateToolResourceDocument = {
  request: (document: IDocument) => action(TOOL_RESOURCE_DOCUMENTS.PUT.REQUEST, { document }),
  success: (payload: any) => action(TOOL_RESOURCE_DOCUMENTS.PUT.SUCCESS, payload),
  failure: (error: any) => action(TOOL_RESOURCE_DOCUMENTS.PUT.FAILURE, error)
};

export const removeToolResourceDocument = {
  request: (document: IDocument) => action(TOOL_RESOURCE_DOCUMENTS.DELETE.REQUEST, { document }),
  success: (payload: any) => action(TOOL_RESOURCE_DOCUMENTS.DELETE.SUCCESS, payload),
  failure: (error: any) => action(TOOL_RESOURCE_DOCUMENTS.DELETE.FAILURE, error)
};
