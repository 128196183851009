import { action, createRequestTypes } from 'utils/ReduxHelpers';

import { GET } from 'utils/Constants';
import { ICountry } from 'core/models/Models';

export const COUNTRY = createRequestTypes('COUNTRY', [GET]);

export const getCountries = {
  request: () => action(COUNTRY.GET.REQUEST),
  success: (payload: Array<ICountry>) => action(COUNTRY.GET.SUCCESS, payload),
  failure: (error: string) => action(COUNTRY.GET.FAILURE, error)
};
