import React, { Suspense, useEffect, useState, lazy } from 'react';
import { Route, Navigate, useLocation, useSearchParams, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppRoutes } from 'config/AppRoutes';
import { getCurrentAuthUser, signInForumUser } from 'core/actions/AuthActions';
import { getPersonalProfileByCID } from 'core/actions/PersonalProfileActions';
import { getIsAuthenticatedSelector, getUserSelector } from 'core/selectors/AuthSelectors';
import { getPersonalProfileSelector } from 'core/selectors/PersonalProfileSelectors';

import LoadingPage from 'containers/LoadingPage';
import { SignupRoutes } from 'containers/SignupContainer';

const ForgotPasswordContainer = lazy(() => import('containers/ForgotPasswordContainer'));
const LandingPageContainer = lazy(() => import('containers/LandingPageContainer'));
const NoAccountContainer = lazy(() => import('containers/NoAccountContainer'));
const SignupContainer = lazy(() => import('containers/SignupContainer'));
const NotFound404 = lazy(() => import('components/ErrorPages/NotFound404'));
const About = lazy(() => import('components/StaticPages/About'));
const Rules = lazy(() => import('components/StaticPages/Rules'));
const Values = lazy(() => import('components/StaticPages/Values'));
const Privacy = lazy(() => import('components/StaticPages/Privacy'));
const ActionContainer = lazy(() => import('containers/ActionContainer'));
const ActionsContainer = lazy(() => import('containers/ActionsContainer'));
const CalendarContainer = lazy(() => import('containers/CalendarContainer'));
const ContestsContainer = lazy(() => import('containers/ContestsContainer'));
const Layout = lazy(() => import('containers/Layout'));
const PersonalProfileContainer = lazy(() => import('containers/PersonalProfileContainer'));
const ResourcesContainer = lazy(() => import('containers/ResourcesContainer'));
const ProtectedRoute = lazy(() => import('utils/ProtectedRoute'));
const ActionCreateContainer = lazy(() => import('containers/ActionCreateContainer'));
const ContestApplicationContainer = lazy(() => import('containers/ContestApplicationContainer'));
const ContestApplicationCreateContainer = lazy(() => import('containers/ContestApplicationCreateContainer'));
const CreateOrganizationContainer = lazy(() => import('containers/CreateOrganizationContainer'));
const MembersProfileContainer = lazy(() => import('containers/MembersProfileContainer'));
const CreateContestContainer = lazy(() => import('containers/CreateContestContainer'));
const OrganizationContainer = lazy(() => import('containers/OrganizationContainer'));
const SettingsContainer = lazy(() => import('containers/SettingsContainer'));
const ContestContainer = lazy(() => import('containers/ContestContainer'));
const CreateExpertiseResourceContainer = lazy(() => import('containers/CreateExpertiseResourceContainer'));
const ExpertiseResourceContainer = lazy(() => import('containers/ExpertiseResourceContainer'));
const CreateToolResourceContainer = lazy(() => import('containers/CreateToolResourceContainer'));
const CreateKnowledgeContainer = lazy(() => import('containers/CreateKnowledgeContainer'));
const KnowledgeContainer = lazy(() => import('containers/KnowledgeContainer'));
const ResourceToolContainer = lazy(() => import('containers/ResourceToolContainer'));
const HomePageContainer = lazy(() => import('containers/HomePageContainer'));
const SearchContainer = lazy(() => import('containers/SearchContainer'));
const HowTo = lazy(() => import('components/StaticPages/HowTo'));
const Questions = lazy(() => import('components/StaticPages/Questions'));

const routes = [
  { path: AppRoutes.home.link, Component: HomePageContainer },
  { path: AppRoutes.actions.link, Component: ActionsContainer },
  { path: AppRoutes.createAction.link, Component: ActionCreateContainer },
  { path: AppRoutes.createContestApplication.link, Component: ContestApplicationCreateContainer },
  { path: AppRoutes.contestApplication.link, Component: ContestApplicationContainer },
  { path: AppRoutes.viewEditAction.link, Component: ActionContainer },
  { path: AppRoutes.resources.link, Component: ResourcesContainer },
  { path: AppRoutes.contests.link, Component: ContestsContainer },
  { path: AppRoutes.createContest.link, Component: CreateContestContainer },
  { path: AppRoutes.createExpertiseResource.link, Component: CreateExpertiseResourceContainer },
  { path: AppRoutes.contest.link, Component: ContestContainer },
  { path: AppRoutes.calendar.link, Component: CalendarContainer },
  { path: AppRoutes.settings.link, Component: SettingsContainer },
  { path: AppRoutes.newOrganization.link, Component: CreateOrganizationContainer },
  { path: AppRoutes.organization.link, Component: OrganizationContainer },
  { path: AppRoutes.memberProfile.link, Component: MembersProfileContainer },
  { path: AppRoutes.profile.link, Component: PersonalProfileContainer },
  { path: AppRoutes.about.link, Component: About },
  { path: AppRoutes.values.link, Component: Values },
  { path: AppRoutes.rules.link, Component: Rules },
  { path: AppRoutes.privacy.link, Component: Privacy },
  { path: AppRoutes.howto.link, Component: HowTo },
  { path: AppRoutes.questions.link, Component: Questions },
  { path: AppRoutes.expertiseResource.link, Component: ExpertiseResourceContainer },
  { path: AppRoutes.createToolResource.link, Component: CreateToolResourceContainer },
  { path: AppRoutes.createKnowledge.link, Component: CreateKnowledgeContainer },
  { path: AppRoutes.knowledge.link, Component: KnowledgeContainer },
  { path: AppRoutes.toolResource.link, Component: ResourceToolContainer },
  { path: AppRoutes.search.link, Component: SearchContainer }
];

const App = () => {
  const dispatch = useDispatch();
  const [query] = useSearchParams();
  const sso = query.get('sso');
  const sig = query.get('sig');
  const { state, pathname } = useLocation();
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const cognitoUser = useSelector(getUserSelector);
  const personalProfile = useSelector(getPersonalProfileSelector);
  const { from }: any = state || { from: { pathname: AppRoutes.home.link } };
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  useEffect(() => {
    if (!pathname.startsWith('/signup') && !pathname.startsWith('/zaboravljena-lozinka')) {
     dispatch(getCurrentAuthUser.request());
    }
  }, []);


  useEffect(() => {
    if (cognitoUser?.attributes?.sub && Object.keys(personalProfile).length === 0 && !pathname.includes('signup')) {
      dispatch(getPersonalProfileByCID.request(cognitoUser?.attributes?.sub));
    }
  }, [cognitoUser?.attributes?.sub, pathname]);

  useEffect(() => {
    if (isAuthenticated) {
      setRedirectToReferrer(true);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const cognitoEmail = cognitoUser?.attributes?.email;
    const cognitoId = cognitoUser?.attributes?.sub;
    if (cognitoEmail && cognitoId && sig && sso) {
      dispatch(signInForumUser.request(cognitoEmail, cognitoId, sso, sig));
    }
  }, [cognitoUser?.attributes?.sub, sso, sig]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path='/'>
          <Route index element={<Navigate to={AppRoutes.login.link} replace />} />
          <Route path={AppRoutes.forgotPasswordWithParams.link} element={<ForgotPasswordContainer />} />
          <Route path={AppRoutes.noAccount.link} element={<NoAccountContainer />} />
          <Route
            path='/signup'
            element={
              isAuthenticated ? (
                <Navigate to={AppRoutes.home.link} replace />
              ) : (
                <Navigate to={AppRoutes.login.link} replace />
              )
            }
          />
          <Route path={AppRoutes.signup.link} element={<SignupContainer />} />
          <Route
            path={AppRoutes.login.link}
            element={
              isAuthenticated && redirectToReferrer ? (
                <Navigate to={from} replace />
              ) : (
                <LandingPageContainer sso={sso} sig={sig} />
              )
            }
          />
          <Route path='/pretraga' element={<Navigate to='/pretraga/sve' />} />
          {routes.map(({ path, Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRoute isAuthorized={isAuthenticated}>
                  <Layout>
                    <Component />
                  </Layout>
                </ProtectedRoute>
              }
            />
          ))}
          <Route path='*' element={<NotFound404 />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
