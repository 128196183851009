import { DELETE, POST, PUT } from 'utils/Constants';
import { action, createRequestTypes } from 'utils/ReduxHelpers';
import { IComment, ElementType } from 'core/models/Models';

export const COMMENT = createRequestTypes('COMMENT', [DELETE, POST, PUT]);
export const ELEMENT_LIKE = createRequestTypes('ELEMENT_LIKE', [POST]);
export const COMMENT_LIKE = createRequestTypes('COMMENT_LIKE', [POST]);

export const addComment = {
  request: (comment: IComment, elementType: ElementType) => action(COMMENT.POST.REQUEST, { comment, elementType }),
  success: (payload: any) => action(COMMENT.POST.SUCCESS, payload),
  failure: (error: any) => action(COMMENT.POST.FAILURE, error)
};

export const updateComment = {
  request: (comment: IComment, elementType: ElementType) => action(COMMENT.PUT.REQUEST, { comment, elementType }),
  success: (payload: any) => action(COMMENT.PUT.SUCCESS, payload),
  failure: (error: any) => action(COMMENT.PUT.FAILURE, error)
};

export const removeComment = {
  request: (comment: IComment, elementType: ElementType) => action(COMMENT.DELETE.REQUEST, { comment, elementType }),
  success: (payload: any) => action(COMMENT.DELETE.SUCCESS, payload),
  failure: (error: any) => action(COMMENT.DELETE.FAILURE, error)
};

export const likeOrUnlikeElement = {
  request: (elementId, userId, elementType: ElementType, parentId?: number, source?: string, sourceId?: number) =>
    action(ELEMENT_LIKE.POST.REQUEST, { elementId, userId, elementType, parentId, source, sourceId }),
  success: (payload: any) => action(ELEMENT_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(ELEMENT_LIKE.POST.FAILURE, error)
};

export const likeOrUnlikeComment = {
  request: (comment: IComment, userId, elementType: ElementType) =>
    action(COMMENT_LIKE.POST.REQUEST, { comment, userId, elementType }),
  success: (payload: any) => action(COMMENT_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(COMMENT_LIKE.POST.FAILURE, error)
};
