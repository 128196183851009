import { produce } from 'immer';
import { IActivity, IOrganization } from 'core/models/Models';
import {
  ORGANIZATION,
  ORGANIZATIONS,
  ORGANIZATIONS_OPTIMIZED,
  ORGANIZATION_CONTAINED_RESOURCE_LIKE,
  ORGANIZATION_INFO,
  ORGANIZATION_MEMBERS,
  ORGANIZATION_PROJECT,
  ORGANIZATION_SOCIAL_NETWORKS,
  ORGANIZATION_TAGS
} from 'core/actions/OrganizationActions';

export interface IOrganizationState {
  organizations: Array<IOrganization>;
  optimizedOrganizations: Array<IOrganization>;
}

const initialState: IOrganizationState = {
  organizations: [],
  optimizedOrganizations: []
};

const organizationReducer = (state = initialState, action: IActivity): IOrganizationState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case ORGANIZATION.GET.SUCCESS: {
        const updatedOrgs = state.organizations.filter((org) => org.attributes.pId !== payload.attributes.pId);
        const knowledgeResources = payload?.knowledgeResources?.map((resource) => {
          return { attributes: { ...resource } };
        });
        const expertiseResources = payload?.expertiseResources?.map((resource) => {
          return { attributes: { ...resource } };
        });
        const linkResources = payload?.linkResources?.map((resource) => {
          return { attributes: { ...resource } };
        });
        const toolResources = payload?.toolResources?.map((resource) => {
          return {
            attributes: { ...resource },
            knowledgeResources: resource.toolsKnowledgeResources,
            linkResources: resource.toolsLinkResources,
            expertiseResources: resource.toolsExpertiseResources,
            documents: resource.toolsDocuments
          };
        });
        const actions = payload?.actions?.map((action) => {
          return { attributes: { ...action } };
        });
        const contests = payload?.contests?.map((contest) => {
          return { attributes: { ...contest } };
        });
        updatedOrgs.push({
          ...payload,
          actions: actions,
          contests: contests,
          knowledgeResources: knowledgeResources,
          expertiseResources: expertiseResources,
          linkResources: linkResources,
          toolResources: toolResources
        });
        draft.organizations = updatedOrgs;
        break;
      }
      case ORGANIZATIONS.GET.SUCCESS: {
        const loadedOrgsIds = state.organizations.map((org) => org?.attributes?.pId);
        const newOrgs = payload?.filter((org) => !loadedOrgsIds?.includes(org?.attributes?.pId));
        draft.organizations = [...state.organizations].concat(newOrgs);
        break;
      }
      case ORGANIZATIONS_OPTIMIZED.GET.SUCCESS: {
        draft.optimizedOrganizations = payload;
        break;
      }
      case ORGANIZATION.POST.SUCCESS:
        draft.organizations.push(payload);
        break;
      case ORGANIZATION_INFO.POST.SUCCESS:
        draft.organizations = state.organizations.map((org) =>
          org.attributes?.pId !== payload?.pId ? org : { ...org, attributes: { ...org.attributes, ...payload } }
        );
        break;
      case ORGANIZATION_SOCIAL_NETWORKS.POST.SUCCESS:
        draft.organizations = state.organizations.map((org) =>
          org.attributes.pId === payload.organizationId ? { ...org, socialNetworks: payload.socialNetworks } : org
        );
        break;
      case ORGANIZATION_TAGS.POST.SUCCESS:
        draft.organizations = state.organizations.map((org) =>
          org.attributes.pId === payload.organizationId ? { ...org, tags: payload.tags } : org
        );
        break;
      case ORGANIZATION_MEMBERS.POST.SUCCESS:
        draft.organizations = state.organizations.map((org) =>
          org.attributes.pId === payload.organizationId ? { ...org, members: payload.members } : org
        );
        break;
      case ORGANIZATION_PROJECT.PUT.SUCCESS:
        draft.organizations = state.organizations.map((org) =>
          org.attributes.pId === payload.organizationId
            ? { ...org, projects: org.projects?.map((p) => (p.pId === payload.project.pId ? payload.project : p)) }
            : org
        );
        break;
      case ORGANIZATION_PROJECT.POST.SUCCESS:
        draft.organizations = state.organizations.map((org) => {
          if (org.attributes?.pId === payload.organizationId) {
            const newProjects = [...org.projects];
            newProjects?.push(payload.project);
            return { ...org, projects: newProjects };
          }
          return org;
        });
        break;
      case ORGANIZATION_PROJECT.DELETE.SUCCESS:
        draft.organizations = state.organizations.map((org) => {
          if (org.attributes.pId === payload.organizationId) {
            const projects = [...org.projects].filter((p) => p.pId !== payload.projectId);
            return { ...org, projects };
          }
          return org;
        });
        break;
      case ORGANIZATION_CONTAINED_RESOURCE_LIKE.POST.SUCCESS: {
        const userId = payload?.userId;
        draft.organizations = state.organizations.map((item) => {
          if (item?.attributes?.pId == payload?.sourceId) {
            const newActions = item?.actions?.map((action) => {
              if (action?.attributes?.pId == payload?.elementId) {
                return containedItemWithUpdatedLikedBy(userId, action);
              }
              return action;
            });
            const newContests = item?.contests?.map((contest) => {
              if (contest?.attributes?.pId == payload?.elementId) {
                return containedItemWithUpdatedLikedBy(userId, contest);
              }
              return contest;
            });
            return { ...item, actions: newActions, contests: newContests };
          }
          return item;
        });
        break;
      }
      default:
        return draft;
    }
  });

const containedItemWithUpdatedLikedBy = (userId, item) => {
  const likedByIds = item?.attributes?.likedByIds ?? [];
  const index = likedByIds?.indexOf(userId) ?? -1;
  const newElementsById = [...likedByIds];
  if (index > -1) {
    newElementsById.splice(index, 1);
    const attributes = { ...item.attributes, likedByIds: newElementsById };
    return { ...item, attributes: attributes };
  } else {
    newElementsById.push(userId);
    const attributes = { ...item.attributes, likedByIds: newElementsById };
    return { ...item, attributes: attributes };
  }
};

export default organizationReducer;
