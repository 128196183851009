import { produce } from 'immer';
import { IActivity, IDiscourseCategory, INotification } from 'core/models/Models';
import { NOTIFICATIONS, DISCOURSE_CATEGORIES, ADD_NEW_NOTIFICATION } from 'core/actions/NotificationActions';
import { sortedInsert } from 'utils/Helpers';

export interface INotificationsState {
  notifications: INotification[];
  unreadNotificationsCount: number;
  discourseCategories: IDiscourseCategory[];
}

const initialState: INotificationsState = {
  notifications: [],
  discourseCategories: [],
  unreadNotificationsCount: 0
};

const createPlatformNotification = (notification) => {
  let link = '';
  if (notification.notificationSource == 'ACTION') {
    link = `/akcije/${notification.actionId}`;
  } else if (notification.notificationSource == 'CONTEST') {
    link = `/konkursi/${notification.contestId}`;
  } else if (notification.notificationSource == 'KNOWLEDGE_RESOURCE') {
    link = `/znanje/${notification.resourceId}`;
  } else if (notification.notificationSource == 'LINK_RESOURCE') {
    link = `/resursi`;
  } else if (notification.notificationSource == 'EXPERTISE_RESOURCE') {
    link = `/ekspertize/${notification.resourceId}`;
  } else if (notification.notificationSource == 'TOOL_RESOURCE') {
    link = `/alatke/${notification.resourceId}`;
  } else if (notification.notificationSource == 'CALENDAR') {
    link = '/kalendar';
  } else if (notification.notificationSource == 'USER') {
    link = `/profil-clanice/${notification.userId}`;
  }
  const authorName = notification?.organizationAuthor
    ? `Organizacija ${notification.organizationAuthor.name}`
    : notification?.userAuthor?.name ?? '';
  const authorImageUrl = notification?.organizationAuthor
    ? notification.organizationAuthor?.imageUrl
    : notification?.userAuthor?.imageUrl;
  const elementImageUrl = notification?.elementData?.imageUrl;
  return {
    notificationSource: notification.notificationSource,
    pId: notification.pId,
    authorImageUrl: authorImageUrl,
    authorName: authorName,
    elementImageUrl: elementImageUrl,
    relativeUrl: link,
    text: notification.text,
    read: notification.read,
    timestamp: notification.timestamp
  };
};

const createDiscourseNotification = (notification) => {
  return {
    pId: notification.pId,
    authorImageUrl: notification.userAuthor.imageUrl,
    authorName: notification.userAuthor.name,
    absoluteUrl: notification.url,
    text: notification.text,
    read: notification.read,
    timestamp: notification.timestamp
  };
};

const notificationReducer = (state = initialState, action: IActivity): INotificationsState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case NOTIFICATIONS.GET.SUCCESS: {
        const allNotifications = Array<INotification>();
        const platformNotifications = payload.platformNotifications.map((notification) => {
          return createPlatformNotification(notification);
        });
        platformNotifications.forEach((element) => sortedInsert(allNotifications, 'timestamp', element, true));
        const discourseNotifications = payload.discourseNotifications.map((notification) => {
          return createDiscourseNotification(notification);
        });
        discourseNotifications.forEach((element) => sortedInsert(allNotifications, 'timestamp', element, true));
        draft.notifications = allNotifications;
        draft.unreadNotificationsCount = parseInt(payload?.unreadNotificationsCount) || 0;
        break;
      }
      case NOTIFICATIONS.POST.SUCCESS:
        draft.unreadNotificationsCount = 0;
        break;
      case DISCOURSE_CATEGORIES.GET.SUCCESS:
        draft.discourseCategories = payload;
        break;
      case DISCOURSE_CATEGORIES.POST.SUCCESS:
        draft.discourseCategories = payload;
        break;
      case ADD_NEW_NOTIFICATION:
        if (payload.notificationSource == 'FORUM') {
          draft.notifications.unshift(createDiscourseNotification(payload));
        } else {
          draft.notifications.unshift(createPlatformNotification(payload));
        }
        draft.unreadNotificationsCount = state.unreadNotificationsCount + 1;
        break;
      default:
        return draft;
    }
  });

export default notificationReducer;
