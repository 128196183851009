import { action, createRequestTypes } from 'utils/ReduxHelpers';

import { GET } from 'utils/Constants';

export const HOME_PAGE_CONTENT = createRequestTypes('HOME_PAGE_CONTENT', [GET]);

export const getHomePageContent = {
  request: (nbElements: number, page: number) => action(HOME_PAGE_CONTENT.GET.REQUEST, { nbElements, page }),
  success: (payload: any) => action(HOME_PAGE_CONTENT.GET.SUCCESS, payload),
  failure: (error: any) => action(HOME_PAGE_CONTENT.GET.FAILURE, error)
};

export const HOME_PAGE_ACTIVITY = createRequestTypes('HOME_PAGE_ACTIVITY', [GET]);

export const getHomePageActivity = {
  request: () => action(HOME_PAGE_ACTIVITY.GET.REQUEST),
  success: (payload: any) => action(HOME_PAGE_ACTIVITY.GET.SUCCESS, payload),
  failure: (error: any) => action(HOME_PAGE_ACTIVITY.GET.FAILURE, error)
};

export const HOME_PAGE_COMMENTS = createRequestTypes('HOME_PAGE_COMMENTS', [GET]);

export const getHomePageComments = {
  request: (elementIds, number, page) => action(HOME_PAGE_COMMENTS.GET.REQUEST, { elementIds, number, page }),
  success: (payload: any) => action(HOME_PAGE_COMMENTS.GET.SUCCESS, payload),
  failure: (error: any) => action(HOME_PAGE_COMMENTS.GET.FAILURE, error)
};
export const HOME_CARD_COMMENTS = createRequestTypes('HOME_CARD_COMMENTS', [GET]);

export const getHomeCardComments = {
  request: (elementId, number, page) => action(HOME_CARD_COMMENTS.GET.REQUEST, { elementId, number, page }),
  success: (payload: any) => action(HOME_CARD_COMMENTS.GET.SUCCESS, payload),
  failure: (error: any) => action(HOME_CARD_COMMENTS.GET.FAILURE, error)
};
