import { DELETE, GET, POST, PUT } from 'utils/Constants';
import { action, createRequestTypes } from 'utils/ReduxHelpers';
import { IDocument, IContestApplication, IComment } from 'core/models/Models';

// export const ALL_CONTEST_APPLICATIONS = createRequestTypes('ALL_CONTEST_APPLICATIONS', [GET]);
export const CONTEST_APPLICATION = createRequestTypes('CONTEST_APPLICATION', [GET, DELETE, POST, PUT]);
export const CONTEST_APPLICATION_DOCUMENTS = createRequestTypes('CONTEST_APPLICATION_DOCUMENTS', [DELETE, POST, PUT]);
export const CONTEST_APPLICATION_COMMENTS = createRequestTypes('CONTEST_APPLICATION_COMMENTS', [DELETE, POST, PUT]);
export const CONTEST_APPLICATION_LIKE = createRequestTypes('CONTEST_APPLICATION_LIKE', [POST]);
export const CONTEST_APPLICATION_COMMENT_LIKE = createRequestTypes('CONTEST_APPLICATION_COMMENT_LIKE', [POST]);
export const CONTEST_APPLICATION_VIEW = createRequestTypes('CONTEST_APPLICATION_VIEW', [POST]);
export const CONTEST_APPLICATION_VOTE = createRequestTypes('CONTEST_APPLICATION_VOTE', [POST]);
export const CONTEST_APPLICATION_RESOURCES = createRequestTypes('CONTEST_APPLICATION_RESOURCES', [POST]);

interface ICreateContestApplicationPayload {
  contestApplicationId: number;
  content: string;
}

export const getContestApplication = {
  request: (id) => action(CONTEST_APPLICATION.GET.REQUEST, { id }),
  success: (payload: IContestApplication) => action(CONTEST_APPLICATION.GET.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION.GET.FAILURE, error)
};

export const createContestApplication = {
  request: (data: IContestApplication) => action(CONTEST_APPLICATION.POST.REQUEST, { data }),
  success: (payload: any) => action(CONTEST_APPLICATION.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION.POST.FAILURE, error)
};

export const updateContestApplication = {
  request: (data: ICreateContestApplicationPayload) => action(CONTEST_APPLICATION.PUT.REQUEST, { data }),
  success: (payload: any) => action(CONTEST_APPLICATION.PUT.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION.PUT.FAILURE, error)
};

export const deleteContestApplication = {
  request: (id: number) => action(CONTEST_APPLICATION.DELETE.REQUEST, { id }),
  success: (id: number) => action(CONTEST_APPLICATION.DELETE.SUCCESS, { id }),
  failure: (error: any) => action(CONTEST_APPLICATION.DELETE.FAILURE, error)
};

export const addDocument = {
  request: (document: IDocument) => action(CONTEST_APPLICATION_DOCUMENTS.POST.REQUEST, { document }),
  success: (payload: any) => action(CONTEST_APPLICATION_DOCUMENTS.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION_DOCUMENTS.POST.FAILURE, error)
};

export const updateDocument = {
  request: (document: IDocument) => action(CONTEST_APPLICATION_DOCUMENTS.PUT.REQUEST, { document }),
  success: (payload: any) => action(CONTEST_APPLICATION_DOCUMENTS.PUT.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION_DOCUMENTS.PUT.FAILURE, error)
};

export const removeDocument = {
  request: (document: IDocument) => action(CONTEST_APPLICATION_DOCUMENTS.DELETE.REQUEST, { document }),
  success: (payload: any) => action(CONTEST_APPLICATION_DOCUMENTS.DELETE.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION_DOCUMENTS.DELETE.FAILURE, error)
};

export const addContestApplicationComment = {
  request: (comment: IComment) => action(CONTEST_APPLICATION_COMMENTS.POST.REQUEST, { comment }),
  success: (payload: any) => action(CONTEST_APPLICATION_COMMENTS.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION_COMMENTS.POST.FAILURE, error)
};

export const updateContestApplicationComment = {
  request: (comment: IComment) => action(CONTEST_APPLICATION_COMMENTS.PUT.REQUEST, { comment }),
  success: (payload: any) => action(CONTEST_APPLICATION_COMMENTS.PUT.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION_COMMENTS.PUT.FAILURE, error)
};

export const removeContestApplicationComment = {
  request: (comment: IComment) => action(CONTEST_APPLICATION_COMMENTS.DELETE.REQUEST, { comment }),
  success: (payload: any) => action(CONTEST_APPLICATION_COMMENTS.DELETE.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION_COMMENTS.DELETE.FAILURE, error)
};

export const updateContestApplicationResources = {
  request: (
    contestApplicationId: number,
    knowledgeIds: Array<number>,
    linkIds: Array<number>,
    expertiseIds: Array<number>,
    toolIds: Array<number>
  ) =>
    action(CONTEST_APPLICATION_RESOURCES.POST.REQUEST, {
      contestApplicationId,
      knowledgeIds,
      linkIds,
      expertiseIds,
      toolIds
    }),
  success: (payload: any) => action(CONTEST_APPLICATION_RESOURCES.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION_RESOURCES.POST.FAILURE, error)
};

export const likeOrUnlikeContestApplication = {
  request: (contestApplicationId, userId) =>
    action(CONTEST_APPLICATION_LIKE.POST.REQUEST, { contestApplicationId, userId }),
  success: (payload: any) => action(CONTEST_APPLICATION_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION_LIKE.POST.FAILURE, error)
};

export const likeOrUnlikeContestApplicationComment = {
  request: (comment: IComment, userId) => action(CONTEST_APPLICATION_COMMENT_LIKE.POST.REQUEST, { comment, userId }),
  success: (payload: any) => action(CONTEST_APPLICATION_COMMENT_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION_COMMENT_LIKE.POST.FAILURE, error)
};

export const addContestApplicationView = {
  request: (contestApplicationId) => action(CONTEST_APPLICATION_VIEW.POST.REQUEST, { contestApplicationId }),
  success: (payload: any) => action(CONTEST_APPLICATION_VIEW.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION_VIEW.POST.FAILURE, error)
};

export const backContestApplication = {
  request: (contestApplicationId, userId) =>
    action(CONTEST_APPLICATION_VOTE.POST.REQUEST, { contestApplicationId, userId }),
  success: (payload: any) => action(CONTEST_APPLICATION_VOTE.POST.SUCCESS, payload),
  failure: (error: any) => action(CONTEST_APPLICATION_VOTE.POST.FAILURE, error)
};
