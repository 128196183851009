import { COUNTRY, getCountries } from '../actions/CountryActions';
import { put, takeLatest } from 'redux-saga/effects';
import { listCountries } from 'api/graphql/queries';
import { API } from 'aws-amplify';
import * as Alert from 'utils/Alerts';
import i18n from 'utils/i18n';

export function* fetchCountriesSaga() {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: listCountries()
      }
    };

    const {
      data: { countries }
    } = yield API.post('CommonsAPI', '/graphql?query', requestInfo);

    yield put(getCountries.success(countries));
  } catch (e: any) {
    yield put(getCountries.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getCountries'));
  }
}

function* countrySaga() {
  yield takeLatest(COUNTRY.GET.REQUEST, fetchCountriesSaga);
}

export default countrySaga;
