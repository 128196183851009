import { produce } from 'immer';
import { COMMENT } from 'core/actions/InteractionActions';
import { IActivity, IComment } from 'core/models/Models';

export interface IInteractionState {
  isLoadingElementComments: boolean | IComment;
}

const initialState: IInteractionState = {
  isLoadingElementComments: false
};

const interactionReducer = (state = initialState, action: IActivity): IInteractionState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case COMMENT.POST.REQUEST:
      case COMMENT.DELETE.REQUEST:
      case COMMENT.PUT.REQUEST:
        draft.isLoadingElementComments = payload;
        break;
      case COMMENT.POST.SUCCESS:
      case COMMENT.DELETE.SUCCESS:
      case COMMENT.PUT.SUCCESS:
      case COMMENT.POST.FAILURE:
      case COMMENT.DELETE.FAILURE:
      case COMMENT.PUT.FAILURE:
        draft.isLoadingElementComments = false;
        break;
      default:
        return draft;
    }
  });

export default interactionReducer;
