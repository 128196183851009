import { iNotification, Store } from 'react-notifications-component';
import i18n from 'utils/i18n';

const notification = {
  title: `${i18n.t('alerts.info')}`,
  message: 'Obavestenje Platforma Zdruzene',
  type: 'info',
  insert: 'bottom',
  container: 'bottom-left',
  animationIn: ['animate__animated animate__fadeIn'],
  animationOut: ['animate__animated animate__fadeOut'],
  dismiss: {
    duration: 3000,
    onScreen: true,
    pauseOnHover: true
  }
};

export const setSuccessAlert = (message: string) =>
  Store.addNotification({
    ...notification,
    title: `${i18n.t('alerts.success')}`,
    message,
    type: 'success'
  } as iNotification);

export const setInfoAlert = (message: string) =>
  Store.addNotification({
    ...notification,
    message
  } as iNotification);

export const setErrorAlert = (message: string) =>
  Store.addNotification({
    ...notification,
    title: `${i18n.t('alerts.danger')}`,
    message,
    type: 'danger'
  } as iNotification);

export const setWarningAlert = (message: string) =>
  Store.addNotification({
    ...notification,
    title: `${i18n.t('alerts.warning')}`,
    message,
    type: 'warning'
  } as iNotification);

export const setToastNotification = (contentElement: any) => {
  Store.addNotification({
    ...notification,
    content: contentElement
  } as iNotification);
};
