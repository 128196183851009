import { all, put, takeLatest } from 'redux-saga/effects';
import {
  getContestApplication,
  createContestApplication,
  updateContestApplication,
  addDocument,
  updateDocument,
  removeDocument,
  deleteContestApplication,
  addContestApplicationView,
  CONTEST_APPLICATION,
  CONTEST_APPLICATION_DOCUMENTS,
  CONTEST_APPLICATION_VIEW,
  backContestApplication,
  CONTEST_APPLICATION_VOTE,
  CONTEST_APPLICATION_RESOURCES,
  updateContestApplicationResources
} from 'core/actions/ContestApplicationActions';
import { API, Auth, Storage } from 'aws-amplify';
import { getContestApplication as getContestApplicationQuery } from 'api/graphql/queries';
import {
  createContestApplication as createContestApplicationMutation,
  updateContestApplication as updateContestApplicationMutation,
  deleteContestApplication as deleteContestApplicationMutation,
  addContestApplicationsDocument,
  updateContestApplicationsDocument,
  deleteContestApplicationsDocument,
  addContestApplicationsView as addContestApplicationsViewMutation,
  addNewContestApplicationsDocuments,
  backContestApplication as backContestApplicationMutation,
  createOrUpdateContestApplicationResourcesMutation
} from 'api/graphql/contestApplicationsMutations';
//import { markNotificationsAsRead, updateUserCategoriesWatchStatus } from 'api/graphql/mutations';
import { stringify } from 'api/graphql/utils';
import * as Alert from 'utils/Alerts';
import i18n from 'utils/i18n';
import * as uuid from 'uuid';
import { IDocument } from 'core/models/Models';
import { AppRoutes } from 'config/AppRoutes';
import { globalNavigate } from 'utils/global-history';

export function* getContestApplicationSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;

    const requestInfo = {
      queryStringParameters: {
        query: getContestApplicationQuery(id, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      const error = response?.errors[0].message;
      if (error.includes('Prijava nije dostupna')) {
        globalNavigate(AppRoutes.contests.link);
        throw new Error(error);
      }
      throw new Error(i18n.t('api.errors.getContestApplications'));
    }

    yield put(getContestApplication.success(response?.data?.contestApplication));
  } catch (e: any) {
    yield put(getContestApplication.failure(e));
  }
}

export function* createContestApplicationSaga({ payload: { data } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      body: {
        query: createContestApplicationMutation(data, cognitoId)
      }
    };

    let response = yield API.post('ContestAPI', '/graphql', requestInfo);

    if (response?.errors?.length) {
      const error = response?.errors[0].message;
      if (error.includes('prijavila')) {
        throw new Error(error);
      } else {
        throw new Error(i18n.t('api.errors.createContestApplicationAddDocuments'));
      }
    }

    let contestApplication = response?.data?.createContestApplication?.contestApplication;
    const contestApplicationId = response?.data?.createContestApplication?.contestApplication?.attributes?.pId;
    // upload documents only after contestApplication is created successfully
    // need the id and shouldn't upload if an error occurs
    const documents = data?.documents;
    if (documents?.length) {
      let documentsStr = '';
      const documentsWithUrl = new Array<IDocument>();
      if (documents?.length) {
        for (let i = 0; i < documents.length; i++) {
          const document = documents[i];
          let fileName = document.file.name;
          const title = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
          fileName = getS3Key(document.file, contestApplicationId);
          yield Storage.put(fileName, document.file, { level: 'protected' });
          const url = yield Storage.get(fileName, { level: 'protected' });
          documentsWithUrl.push({ url: url, title: title, elementId: contestApplicationId });
        }
        documentsStr = stringify(documentsWithUrl);
      }

      const requestInfo = {
        queryStringParameters: {
          query: addNewContestApplicationsDocuments(documentsStr, cognitoId)
        }
      };

      response = yield API.post('ContestAPI', '/graphql?query', requestInfo);
      if (response?.errors?.length) {
        throw new Error(i18n.t('api.errors.createContestApplicationAddDocuments'));
      }
      contestApplication = response?.data?.addNewContestApplicationsDocumentsAndImage?.contestApplication;
    }

    yield put(createContestApplication.success(contestApplication));
    globalNavigate('prijave/' + contestApplicationId);
    yield Alert.setSuccessAlert(i18n.t('api.success.createContestApplication'));
  } catch (e: any) {
    yield put(createContestApplication.failure(e));
    yield Alert.setErrorAlert(e.message);
  }
}

export function* updateContestApplicationSaga({ payload: { data } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;

    const requestInfo = {
      queryStringParameters: {
        query: updateContestApplicationMutation(data.contestApplicationId, data.content, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors?.length) {
      throw new Error(i18n.t('api.errors.updateContestApplication'));
    }

    yield put(updateContestApplication.success(data));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateContestApplication'));
  } catch (e: any) {
    yield put(updateContestApplication.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateContestApplication'));
  }
}

export function* updateContestApplicationResourcesSaga({
  payload: { contestApplicationId, knowledgeIds, linkIds, expertiseIds, toolIds }
}) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      body: {
        query: createOrUpdateContestApplicationResourcesMutation(
          contestApplicationId,
          knowledgeIds,
          linkIds,
          expertiseIds,
          toolIds,
          cognitoId
        )
      }
    };

    const response = yield API.post('ContestAPI', '/graphql', requestInfo);

    if (response?.errors) {
      throw new Error(response?.errors);
    }
    yield put(
      updateContestApplicationResources.success(
        response.data?.createOrDeleteContestApplicationResources?.contestApplication
      )
    );

    yield Alert.setSuccessAlert(i18n.t('api.success.updateResource'));
  } catch (e) {
    yield put(updateContestApplicationResources.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateResource'));
  }
}

export function* addDocumentSaga({ payload: { document } }) {
  try {
    Storage.configure({ level: 'protected' });
    let fileName = document.file.name;
    const title = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
    fileName = getS3Key(document.file, document.contestApplicationId);
    yield Storage.put(fileName, document.file);
    const url = yield Storage.get(fileName);

    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addContestApplicationsDocument({ elementId: document.elementId, url: url, title: title }, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.createDocument'));
    }
    yield put(addDocument.success(response?.data?.addContestApplicationsDocument?.document));

    yield Alert.setSuccessAlert(i18n.t('api.success.createDocument'));
  } catch (e) {
    yield put(addDocument.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createDocument'));
  }
}

export function* updateDocumentSaga({ payload: { document } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: updateContestApplicationsDocument(document, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.updateDocument'));
    }
    yield put(updateDocument.success(response?.data?.updateContestApplicationsDocument?.document));

    yield Alert.setSuccessAlert(i18n.t('api.success.updateDocument'));
  } catch (e) {
    yield put(updateDocument.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateDocument'));
  }
}

export function* removeDocumentSaga({ payload: { document } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const fileName = getS3KeyFromUrl(document.url, document.contestApplicationId);
    yield Storage.remove(fileName, { level: 'protected' });
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteContestApplicationsDocument(document, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.deleteDocument'));
    }
    yield put(removeDocument.success(document));

    yield Alert.setSuccessAlert(i18n.t('api.success.deleteDocument'));
  } catch (e) {
    yield put(removeDocument.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteDocument'));
  }
}

export function* addContestApplicationViewSaga({ payload: { contestApplicationId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: addContestApplicationsViewMutation(contestApplicationId, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.like'));
    }
    yield put(addContestApplicationView.success(response?.data?.addContestApplicationsView?.contestApplication));
  } catch (e) {
    yield put(addContestApplicationView.failure(e));
  }
}

function getS3Key(file, contestApplicationId) {
  const fileName = file.name;
  const fileType = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
  const randomId = uuid.v4();
  return `ContestApplications/${contestApplicationId}/${randomId}${fileType}`;
}

function getS3KeyFromUrl(url, contestApplicationId) {
  const name = url.substring(url.lastIndexOf('/') + 1, url.length);
  return `ContestApplications/${contestApplicationId}/${name}`;
}

export function* deleteContestApplicationSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: deleteContestApplicationMutation(id, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.deleteContestApplication'));
    }
    yield put(deleteContestApplication.success(id));
    yield Alert.setSuccessAlert(i18n.t('api.success.deleteContestApplication'));
    globalNavigate(AppRoutes.contests.link);
  } catch (e) {
    yield put(deleteContestApplication.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteContestApplication'));
  }
}

export function* backContestApplicationSaga({ payload: { contestApplicationId, userId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: backContestApplicationMutation(contestApplicationId, cognitoId)
      }
    };

    const response = yield API.post('ContestAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.backContestApplication'));
    }
    yield put(backContestApplication.success({ contestApplicationId, userId }));
    yield Alert.setSuccessAlert(i18n.t('api.success.backContestApplication'));
  } catch (e) {
    yield put(backContestApplication.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.backContestApplication'));
  }
}

function* contestApplicationsSaga() {
  yield all([
    takeLatest(CONTEST_APPLICATION.GET.REQUEST, getContestApplicationSaga),
    takeLatest(CONTEST_APPLICATION.POST.REQUEST, createContestApplicationSaga),
    takeLatest(CONTEST_APPLICATION.PUT.REQUEST, updateContestApplicationSaga),
    takeLatest(CONTEST_APPLICATION.DELETE.REQUEST, deleteContestApplicationSaga),
    takeLatest(CONTEST_APPLICATION_DOCUMENTS.POST.REQUEST, addDocumentSaga),
    takeLatest(CONTEST_APPLICATION_DOCUMENTS.PUT.REQUEST, updateDocumentSaga),
    takeLatest(CONTEST_APPLICATION_DOCUMENTS.DELETE.REQUEST, removeDocumentSaga),
    takeLatest(CONTEST_APPLICATION_VIEW.POST.REQUEST, addContestApplicationViewSaga),
    takeLatest(CONTEST_APPLICATION_VOTE.POST.REQUEST, backContestApplicationSaga),
    takeLatest(CONTEST_APPLICATION_RESOURCES.POST.REQUEST, updateContestApplicationResourcesSaga)
  ]);
}

export default contestApplicationsSaga;
