import { escapeSingleAndDoubleQuotes } from './utils';

const commentData = `
    comment {
        id
        pId
        elementId
        userId
        text
        parentId
        timestamp
        likedByIds
        author {
          id
          pId
          firstName
          lastName
          name
          imageUrl
          signature
        }
      }
`;
const historyData = `
    history {
      text
      id
      pId
      author {
        id
        pId
        imageUrl
        firstName
        lastName
        name
      }
      timestamp
    }
`;

export function addComment(elementId, parentId, text, cognitoId) {
  const parentIdStr = parentId != undefined ? `parentId: ${parentId}` : '';
  const textStr = text != undefined ? `text: "${escapeSingleAndDoubleQuotes(text)}"` : '';
  return `mutation {
      createComment (
        addCommentData: {
          ${textStr}
          elementId: ${elementId}
          ${parentIdStr}
        }
        cognitoId: "${cognitoId}"
      ){
        isRequestSuccessful
        ${commentData}
        ${historyData}
      }
    }`;
}

export function updateComment(id, text, cognitoId) {
  const textStr = text != undefined ? `text: "${escapeSingleAndDoubleQuotes(text)}"` : '';
  return `mutation {
      updateComment (
        id: ${id}
        ${textStr}
        cognitoId: "${cognitoId}"
      ){
        isRequestSuccessful
        ${commentData}
      }
    }`;
}

export function deleteComment(id, cognitoId) {
  return `mutation {
      deleteComment (
        id: ${id}
        cognitoId: "${cognitoId}"
      ){
        isRequestSuccessful
        ${historyData}
      }
    }`;
}

export function likeOrUnlikeElement(elementId, userId) {
  return `mutation {
      likeOrUnlikeElement (
        id: ${elementId}
        userId: ${userId}
      ){
        isRequestSuccessful
      }
    }`;
}

export function likeOrUnlikeComment(commentId, userId) {
  return `mutation {
      likeOrUnlikeComment(
        id: ${commentId}
        userId: ${userId}
      ){
        isRequestSuccessful
        ${commentData}
      }
    }`;
}
