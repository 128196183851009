import { all, put, takeLatest } from 'redux-saga/effects';
import {
  NOTIFICATIONS,
  getDiscourseUserNotifications,
  DISCOURSE_CATEGORIES,
  getDiscourseUserCategories,
  updateDiscourseUserCategories,
  markAllUsersNotificationsAsRead
} from 'core/actions/NotificationActions';
import { API, Auth } from 'aws-amplify';
import { listUsersNotifications, usersDiscourseCategories } from 'api/graphql/queries';
import { markNotificationsAsRead, updateUserCategoriesWatchStatus } from 'api/graphql/mutations';
import { stringify } from 'api/graphql/utils';
import * as Alert from 'utils/Alerts';
import i18n from 'utils/i18n';

export function* fetchNotificationsSaga({ payload: { userId } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: listUsersNotifications(userId)
      }
    };

    const { data } = yield API.post('NotificationsAPI', '/graphql?query', requestInfo);

    if (data?.errors) {
      throw new Error('Error: Notifications not fetched');
    }
    yield put(getDiscourseUserNotifications.success(data?.allUsersNotifications));
  } catch (e: any) {
    yield put(getDiscourseUserNotifications.failure(e));
  }
}

export function* markAllUsersNotificationsAsReadSaga({ payload: { userId } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: markNotificationsAsRead(userId)
      }
    };

    const { data } = yield API.post('NotificationsAPI', '/graphql?query', requestInfo);

    if (data?.errors) {
      throw new Error('Error: could not mark notfications as read');
    }

    yield put(markAllUsersNotificationsAsRead.success());
  } catch (e: any) {
    yield put(markAllUsersNotificationsAsRead.failure(e));
  }
}

export function* fetchDiscourseCategoriesSaga({ payload: { userId } }) {
  const id = Number(userId);
  try {
    const requestInfo = {
      queryStringParameters: {
        query: usersDiscourseCategories(id)
      }
    };
    const { data } = yield API.post('NotificationsAPI', '/graphql?query', requestInfo);

    if (data?.errors) {
      throw new Error('Error: Fetch discourse categories failed.');
    }

    yield put(getDiscourseUserCategories.success(data?.usersDiscourseCategories));
  } catch (e: any) {
    yield put(getDiscourseUserCategories.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getDiscourseCategories'));
  }
}

export function* updateDiscourseCategoriesSaga({ payload: { categories, discourseUsername } }) {
  const stringifyCategories = stringify(categories);
  try {
    const requestInfo = {
      queryStringParameters: {
        query: updateUserCategoriesWatchStatus(discourseUsername, stringifyCategories)
      }
    };
    const { data } = yield API.post('NotificationsAPI', '/graphql?query', requestInfo);

    if (data?.errors) {
      throw new Error('Error: Update discourse categories failed.');
    }

    yield put(updateDiscourseUserCategories.success(categories));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateDiscourseCategories'));
  } catch (e: any) {
    yield put(updateDiscourseUserCategories.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateDiscourseCategories'));
  }
}

function* notificationsSaga() {
  yield all([
    takeLatest(NOTIFICATIONS.GET.REQUEST, fetchNotificationsSaga),
    takeLatest(NOTIFICATIONS.POST.REQUEST, markAllUsersNotificationsAsReadSaga),
    takeLatest(DISCOURSE_CATEGORIES.GET.REQUEST, fetchDiscourseCategoriesSaga),
    takeLatest(DISCOURSE_CATEGORIES.POST.REQUEST, updateDiscourseCategoriesSaga)
  ]);
}

export default notificationsSaga;
