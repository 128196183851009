import { produce } from 'immer';
import { IActivity, ICountry } from 'core/models/Models';
import { COUNTRY } from 'core/actions/CountryActions';

export interface ICountryState {
  countries: ICountry[];
}

const initialState: ICountryState = {
  countries: []
};

const countryReducer = (state = initialState, action: IActivity): ICountryState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case COUNTRY.GET.SUCCESS:
        draft.countries = payload;
        break;
      default:
        return draft;
    }
  });

export default countryReducer;
