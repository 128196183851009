// preferred way to import (from `v4`). Uses `animate__` prefix.
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { ReactNotifications } from 'react-notifications-component';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Authorizer from 'config/Authorizer';
import theme_zdr from 'utils/Theme';
import store from './core/store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import WebSocketWrapper from 'components/WebSocket/WebSocketWrapper';
import { GlobalHistory } from 'utils/global-history';
import './utils/i18n';
import './index.css';
import 'animate.css/animate.min.css';
import 'react-notifications-component/dist/theme.css';

// import { history } from './core/reducers/RootReducer';
// import reportWebVitals from './reportWebVitals';

// TODO: remove this when react-scripts fixes the issue with Buffer in Webpack v5
import * as buffer from 'buffer';
window.Buffer = buffer.Buffer;

Authorizer();

const AppWrapper = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme_zdr}>
      <StyledEngineProvider injectFirst>
        <div className='app-container'>
          <ReactNotifications />
          <WebSocketWrapper>
            <BrowserRouter>
              <GlobalHistory />
              <App />
            </BrowserRouter>
          </WebSocketWrapper>
        </div>
      </StyledEngineProvider>
    </ThemeProvider>
  </Provider>
);

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container as Element);

root.render(<AppWrapper />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// if (process.env.NODE_ENV === 'development') {
//   reportWebVitals(console.log);
// }
