import { put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { API, Auth, Storage } from 'aws-amplify';
import * as uuid from 'uuid';

import {
  PERSONAL_PROFIL,
  getPersonalProfileByCID,
  UPLOAD_IMAGE,
  uploadImage,
  createPersonalProfile,
  PROFILE_TAGS,
  updateProfileTags,
  UPDATE_PERSONAL_PROFILE,
  updatePersonalProfile,
  PROFILE_SOCIAL_NETWORKS,
  updateProfileSocialNetworks,
  PROFILE_WORK_EXPERIENCE,
  updateProfileGeneralInfo,
  PROFILE_GENERAL_INFO,
  createProfileWorkExperience,
  updateProfileWorkExperience,
  deleteProfileWorkExperience,
  createProfileEducation,
  updateProfileEducation,
  deleteProfileEducation,
  PROFILE_EDUCATION,
  PROFILE_NOTIFICATIONS,
  updateUserNotifications,
  PROFILE_IMAGE,
  updateProfileImage
} from 'core/actions/PersonalProfileActions';

import {
  createOrUpdateUsersSocialNetworks,
  createUser,
  createUserEducation,
  createOrDeleteUsersTags,
  createUsersWorkBio,
  deleteUserEducaton,
  deleteUsersWorkBio,
  updateNotificationSettings,
  updateUser,
  updateUserEducation,
  updateUsersGeneralInformation,
  updateUsersWorkBio,
  updateUsersImage as updateUsersImageMutation
} from 'api/graphql/mutations';
import { getPersonalProfileByCognitoId } from 'api/graphql/queries';
import { stringify } from 'api/graphql/utils';
import { updateCognitoUser, updateSignupData } from 'core/actions/AuthActions';
import { getTags } from 'core/actions/CommonActions';
import { getCountries } from 'core/actions/CountryActions';
import { AppRoutes } from 'config/AppRoutes';
import * as Alert from 'utils/Alerts';
import i18n from 'utils/i18n';
import { resetDb, upsertSignupData } from 'api/indexedDB';
import { globalNavigate } from 'utils/global-history';
import { ISignupState } from 'core/models/Models';
import { splitByFirstSpace } from 'utils/Helpers';

export function* fetchPersonalProfileSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const requestInfo = {
      queryStringParameters: {
        query: getPersonalProfileByCognitoId(id || user?.attributes?.sub)
      }
    };
    const {
      data: { personalProfile }
    } = yield API.post('UserAPI', '/graphql?query', requestInfo);
    if (!personalProfile && user) {
      // cognito user exists, but profile not created
      // load information from the cognito user and redirect to signup page
      let firstName = user?.attributes?.given_name;
      let lastName = user?.attributes?.family_name;
      if (!firstName) {
        const fullName = user.attributes.name;
        [firstName, lastName] = splitByFirstSpace(fullName);
      }
      const position =  user.attributes['custom:title'] ?? 'Aktivistkinja'
      const organization =  user.attributes['custom:organization'] ?? 'Aktivistkinja'
      let organizationId;
      let organizationName = '';

      if (!isNaN(+organization)) {
          organizationId = +organization;
      } else {
          organizationName = organization;
      }

      const signupForm = {
        firstname: firstName,
        lastname: lastName,
        email: user.attributes.email,
        username: '',
        invitationId: '',
        position: position,
        organizationId: organizationId,
        organizationName: organizationName,
        imageUrl: '',
        confirmedEmail: true,
        isContinuingSignUp: false,
        tagIds: [],
        state: ISignupState.Avatar,
      };
      yield upsertSignupData(signupForm);
      globalNavigate('signup/add-avatar');
    } else if (personalProfile) {
      // find header work bio if set by headerWorkBioId
      const headerWorkBioId = personalProfile.user.attributes.headerWorkBioId;
      if (headerWorkBioId) {
        const headerWorkBio = personalProfile.user?.workBios.find((workBio) => parseInt(workBio.id) === headerWorkBioId);
        personalProfile.user.attributes.headerWorkBio = headerWorkBio;
      }
      yield put(getPersonalProfileByCID.success(personalProfile));
      yield put(getTags.success(personalProfile.tags));
      yield put(getCountries.success(personalProfile.countries));
    }
  } catch (e) {
    yield put(getPersonalProfileByCID.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getPersonalProfile'));
    globalNavigate(AppRoutes.login.link);
  }
}

export function* updateProfileTagsSaga({ payload: { tags, data } }) {
  const ids = tags.map((tag) => tag.pId);
  const tagIds = stringify(ids);

  try {
    const user = yield Auth.currentAuthenticatedUser();
    const requestInfo = {
      queryStringParameters: {
        query: createOrDeleteUsersTags(user?.attributes?.sub, tagIds)
      }
    };

    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);

    if (response?.errors) {
      throw new Error(i18n.t('api.errors.updateTags'));
    }
    yield put(updateProfileTags.success(tags));

    if (data && Object.keys(data).length) {
      yield put(updateSignupData(data));
    } else {
      yield Alert.setSuccessAlert(i18n.t('api.success.updateTags'));
    }
    yield resetDb();
  } catch (e) {
    yield put(updateProfileTags.failure(e));
    if (!data) {
      yield Alert.setErrorAlert(i18n.t('api.errors.updateTags'));
    } else {
      globalNavigate(AppRoutes.profile.link);
    }
  }
}

export function* createPersonalProfileSaga({ payload: { file, data } }) {
  try {
    let userUrl = '';
    if (file) {
      const randomId = uuid.v4();
      const fileName = randomId + '.' + file.name.split('.')[1];
      const res = yield Storage.put(fileName, file, { level: 'protected' });

      const url = yield Storage.get(fileName, { level: 'protected' });
      const cleanUrl = url.substring(0, url.indexOf('?'));
      userUrl = cleanUrl;
    }

    const user = yield Auth.currentAuthenticatedUser();
    const requestInfo = {
      queryStringParameters: {
        query: createUser(
          user?.attributes?.sub,
          data?.lastname,
          data?.firstname,
          userUrl,
          data?.position,
          data?.organizationId,
          data?.organizationName,
          data?.email
        )
      }
    };
    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);
    if (response.errors) {
      if (response.errors[0].message ==  'Discourse user could not be created') {
        yield Alert.setErrorAlert(i18n.t('api.errors.discorseAccount'));
      } else {
        yield Alert.setErrorAlert(i18n.t('api.errors.createProfile'));
      }
      throw new Error(response.errors);
    }
    yield put(createPersonalProfile.success(response));
    const signupform = { ...data, imageUrl: userUrl };
    yield put(uploadImage.success(signupform));
  } catch (e) {
    yield put(uploadImage.failure(e));

  }
}

export function* updatePersonalProfileSaga({
  payload: { bio, firstName, lastName, title, addFromBio, imageFile, imageUrl }
}) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const level = 'protected';

    let newImageUrl = '';
    if (imageFile) {
      const fileName = getS3Key(imageFile);
      yield Storage.put(fileName, imageFile, { level });
      newImageUrl = yield Storage.get(fileName, { level });
    }
    const requestInfo = {
      body: {
        query: updateUser(user?.attributes?.sub, bio, firstName, lastName, title, addFromBio, newImageUrl)
      }
    };
    const response = yield API.post('UserAPI', '/graphql', requestInfo);
    const signature = response.data?.updateUser?.signature;
    if (firstName && lastName) {
      yield Auth.updateUserAttributes(user, { name: `${firstName} ${lastName}` });
      yield put(updateCognitoUser.success({ attributes: { name: `${firstName} ${lastName}` } }));
    }
    if (response?.errors) {
      throw new Error(response.errors);
    }
    if (newImageUrl) {
      newImageUrl = newImageUrl.substring(0, newImageUrl.indexOf('?'));
    }
    if (newImageUrl && imageUrl) {
      if (typeof imageUrl === 'string') {
        const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1, imageUrl.length);
        yield Storage.remove(fileName, { level: 'protected' });
      }
    }
    if (newImageUrl != '') {
      yield put(
        updatePersonalProfile.success({
          bio,
          firstName,
          lastName,
          title,
          signature,
          showCustomTitle: !addFromBio,
          imageUrl: newImageUrl
        })
      );
    } else {
      yield put(
        updatePersonalProfile.success({ bio, firstName, lastName, title, signature, showCustomTitle: !addFromBio })
      );
    }

    yield Alert.setSuccessAlert(i18n.t('api.success.updateProfile'));
  } catch (e) {
    yield put(updatePersonalProfile.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateProfile'));
  }
}

export function* updateProfileImageSaga({ payload: { imageFile, imageUrl } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const level = 'protected';

    let newImageUrl = '';
    const fileName = getS3Key(imageFile);
    yield Storage.put(fileName, imageFile, { level });
    newImageUrl = yield Storage.get(fileName, { level });
    const requestInfo = {
      queryStringParameters: {
        query: updateUsersImageMutation(user?.attributes?.sub, newImageUrl)
      }
    };
    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);
    if (response?.errors) {
      throw new Error(response.errors);
    }
    newImageUrl = newImageUrl.substring(0, newImageUrl.indexOf('?'));
    if (newImageUrl != imageUrl && typeof imageUrl === 'string') {
      const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1, imageUrl.length);
      yield Storage.remove(fileName, { level: 'protected' });
    }
    yield put(updatePersonalProfile.success({ imageUrl: newImageUrl }));
    yield put(updateProfileImage.success({ imageUrl: newImageUrl }));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateProfileImage'));
  } catch (e) {
    yield put(updateProfileImage.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateProfileImage'));
  }
}

export function* updateProfleSocialNetworksSaga({ payload: { socialNetworks } }) {
  const socialNetworksStringified = stringify(socialNetworks);

  try {
    const user = yield Auth.currentAuthenticatedUser();
    const requestInfo = {
      queryStringParameters: {
        query: createOrUpdateUsersSocialNetworks(user?.attributes?.sub, socialNetworksStringified)
      }
    };
    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);
    if (response?.errors) {
      throw new Error(response.errors);
    }
    yield put(updateProfileSocialNetworks.success(response?.data?.createOrUpdateUsersSocialNetworks?.socialNetworks));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateSocialNetworks'));
  } catch (e) {
    yield put(updateProfileSocialNetworks.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateSocialNetworks'));
  }
}

export function* updateProfileGeneralInfoSaga({ payload: { birthDate, countryId, city, email, hobby, pets } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const requestInfo = {
      queryStringParameters: {
        query: updateUsersGeneralInformation(user.attributes.sub, birthDate, countryId, city, email, hobby, pets)
      }
    };
    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);
    if (response?.errors) {
      throw new Error(response.errors);
    }
    yield put(
      updateProfileGeneralInfo.success({
        birthDate,
        countryId,
        city,
        email,
        hobby,
        pets
      })
    );
    yield Alert.setSuccessAlert(i18n.t('api.success.updateGeneralInfo'));
  } catch (e) {
    yield put(updateProfileGeneralInfo.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateGeneralInfo'));
  }
}

export function* createProfileWorkExperienceSaga({ payload: { workExperience } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const requestInfo = {
      queryStringParameters: {
        query: createUsersWorkBio(
          user.attributes.sub,
          workExperience.position,
          workExperience.location,
          workExperience.workplace,
          workExperience.organizationId,
          workExperience.fromDate,
          workExperience.toDate,
          workExperience.isHeaderWorkBio
        )
      }
    };
    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);

    if (response?.errors?.length) {
      throw new Error(response.errors);
    }
    yield put(createProfileWorkExperience.success(response.data.createUsersWorkBio));
    yield Alert.setSuccessAlert(i18n.t('api.success.createWorkExperience'));
  } catch (e) {
    yield put(createProfileWorkExperience.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.createWorkExperience'));
  }
}

export function* updateProfileWorkExperienceSaga({ payload: { workExperience } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const requestInfo = {
      queryStringParameters: {
        query: updateUsersWorkBio(
          user.attributes.sub,
          workExperience.id,
          workExperience.position,
          workExperience.location,
          workExperience.workplace,
          workExperience.organizationId,
          workExperience.fromDate,
          workExperience.toDate,
          workExperience.isHeaderWorkBio
        )
      }
    };
    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);

    if (response?.errors?.length) {
      throw new Error(response.errors);
    }

    yield put(updateProfileWorkExperience.success(workExperience.isHeaderWorkBio, response.data.updateUsersWorkBio));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateWorkExperience'));
  } catch (e) {
    yield put(updateProfileWorkExperience.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateWorkExperience'));
  }
}

export function* deleteProfileWorkExperienceSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const requestInfo = {
      queryStringParameters: {
        query: deleteUsersWorkBio(id, user.attributes.sub)
      }
    };
    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);

    if (response?.errors?.length) {
      throw new Error(response.errors);
    }

    yield put(deleteProfileWorkExperience.success(response.data.deleteUsersWorkBio));
    yield Alert.setSuccessAlert(i18n.t('api.success.deleteWorkExperience'));
  } catch (e) {
    yield put(deleteProfileWorkExperience.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteWorkExperience'));
  }
}

export function* createProfileEducationSaga({ payload: { education } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const requestInfo = {
      queryStringParameters: {
        query: createUserEducation(
          user.attributes.sub,
          education.schoolOrCourse,
          education.certificate,
          education.fromDate,
          education.toDate,
          education.location
        )
      }
    };
    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);

    if (response?.errors?.length) {
      throw new Error(response.errors);
    }

    yield put(createProfileEducation.success(response?.data?.createUsersEducationEntry?.educationEntry));
    yield Alert.setSuccessAlert(i18n.t('api.success.createEducation'));
  } catch (error) {
    yield put(createProfileEducation.failure(error));
    yield Alert.setErrorAlert(i18n.t('api.errors.createEducation'));
  }
}

export function* updateProfileEducationSaga({ payload: { education } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: updateUserEducation(
          education.id,
          education.schoolOrCourse,
          education.certificate,
          education.fromDate,
          education.toDate,
          education.location
        )
      }
    };
    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);

    if (response?.errors?.length) {
      throw new Error(response.errors);
    }

    yield put(updateProfileEducation.success(education));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateEducation'));
  } catch (error) {
    yield put(updateProfileEducation.failure(error));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateEducation'));
  }
}

export function* deleteProfileEducationSaga({ payload: { id } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: deleteUserEducaton(id)
      }
    };
    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);

    if (response?.errors?.length) {
      throw new Error(response.errors);
    }

    yield put(deleteProfileEducation.success(id));
    yield Alert.setSuccessAlert(i18n.t('api.success.deleteEducation'));
  } catch (error) {
    yield put(deleteProfileEducation.failure(error));
    yield Alert.setErrorAlert(i18n.t('api.errors.deleteEducation'));
  }
}

export function* updateUserNotificationSettingsSaga({ payload: { notifications, userId } }) {
  const id = Number(userId);
  try {
    const requestInfo = {
      queryStringParameters: {
        query: updateNotificationSettings(id, notifications)
      }
    };
    const result = yield API.post('UserAPI', '/graphql?query', requestInfo);

    if (result?.errors) {
      throw new Error('Error: Forum notifications fail to update.');
    }

    yield put(updateUserNotifications.success(notifications));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateNotifications'));
  } catch (e: any) {
    yield put(updateUserNotifications.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.updateNotifications'));
  }
}

function getS3Key(file) {
  const fileName = file.name;
  const fileType = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
  const randomId = uuid.v4();
  return `${randomId}${fileType}`;
}

function* personalProfileSaga() {
  yield all([
    takeLatest(PERSONAL_PROFIL.GET.REQUEST, fetchPersonalProfileSaga),
    takeLatest(UPLOAD_IMAGE.POST.REQUEST, createPersonalProfileSaga),
    takeLatest(PROFILE_TAGS.POST.REQUEST, updateProfileTagsSaga),
    takeLatest(UPDATE_PERSONAL_PROFILE.POST.REQUEST, updatePersonalProfileSaga),
    takeLatest(PROFILE_IMAGE.POST.REQUEST, updateProfileImageSaga),
    takeLatest(PROFILE_SOCIAL_NETWORKS.POST.REQUEST, updateProfleSocialNetworksSaga),
    takeLatest(PROFILE_GENERAL_INFO.POST.REQUEST, updateProfileGeneralInfoSaga),
    takeLatest(PROFILE_WORK_EXPERIENCE.POST.REQUEST, createProfileWorkExperienceSaga),
    takeLatest(PROFILE_WORK_EXPERIENCE.PUT.REQUEST, updateProfileWorkExperienceSaga),
    takeLatest(PROFILE_WORK_EXPERIENCE.DELETE.REQUEST, deleteProfileWorkExperienceSaga),
    takeLatest(PROFILE_EDUCATION.POST.REQUEST, createProfileEducationSaga),
    takeLatest(PROFILE_EDUCATION.PUT.REQUEST, updateProfileEducationSaga),
    takeLatest(PROFILE_EDUCATION.DELETE.REQUEST, deleteProfileEducationSaga),
    takeEvery(PROFILE_NOTIFICATIONS.POST.REQUEST, updateUserNotificationSettingsSaga)
  ]);
}

export default personalProfileSaga;
