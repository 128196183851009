import { action, createRequestTypes } from 'utils/ReduxHelpers';
import { GET, POST, PUT, DELETE } from 'utils/Constants';

export const CALENDAR_EVENTS = createRequestTypes('CALENDAR_EVENTS', [GET]);

export const getCalendarEvents = {
  request: () => action(CALENDAR_EVENTS.GET.REQUEST),
  success: (payload: any) => action(CALENDAR_EVENTS.GET.SUCCESS, payload),
  failure: (error: any) => action(CALENDAR_EVENTS.GET.FAILURE, error)
};

export const CALENDAR_EVENT = createRequestTypes('CALENDAR_EVENT', [GET, POST, PUT, DELETE]);

export const getCalendarEvent = {
  request: (id: number) => action(CALENDAR_EVENT.GET.REQUEST, { id }),
  success: (payload: any) => action(CALENDAR_EVENT.GET.SUCCESS, payload),
  failure: (error: any) => action(CALENDAR_EVENT.GET.FAILURE, error)
};

export const createCalendarEvent = {
  request: (event: any) => action(CALENDAR_EVENT.POST.REQUEST, { event }),
  success: (payload: any) => action(CALENDAR_EVENT.POST.SUCCESS, payload),
  failure: (error: any) => action(CALENDAR_EVENT.POST.FAILURE, error)
};

export const updateCalendarEvent = {
  request: (event: any) => action(CALENDAR_EVENT.PUT.REQUEST, { event }),
  success: (payload: any) => action(CALENDAR_EVENT.PUT.SUCCESS, payload),
  failure: (error: any) => action(CALENDAR_EVENT.PUT.FAILURE, error)
};

export const deleteCalendarEvent = {
  request: (id: any) => action(CALENDAR_EVENT.DELETE.REQUEST, { id }),
  success: (id: any) => action(CALENDAR_EVENT.DELETE.SUCCESS, { id }),
  failure: (error: any) => action(CALENDAR_EVENT.DELETE.FAILURE, error)
};

export const CALENDAR_EVENT_TYPES = createRequestTypes('CALENDAR_EVENT_TYPES', [GET]);

export const getCalendarEventTypes = {
  request: () => action(CALENDAR_EVENT_TYPES.GET.REQUEST),
  success: (payload: any) => action(CALENDAR_EVENT_TYPES.GET.SUCCESS, payload),
  failure: (error: any) => action(CALENDAR_EVENT_TYPES.GET.FAILURE, error)
};

export const CALENDAR_EVENT_ORGANIZERS = createRequestTypes('CALENDAR_EVENT_ORGANIZERS', [POST]);

export const upsertCalendarOrganizers = {
  request: (eventId: number, orgIds: number[]) => action(CALENDAR_EVENT_ORGANIZERS.POST.REQUEST, { eventId, orgIds }),
  success: (payload: any) => action(CALENDAR_EVENT_ORGANIZERS.POST.SUCCESS, payload),
  failure: (error: any) => action(CALENDAR_EVENT_ORGANIZERS.POST.FAILURE, error)
};

export const CALENDAR_PARTICIPANTS = createRequestTypes('CALENDAR_PARTICIPANTS', [POST]);
export const updateCalendarParticipants = {
  request: (eventId: any) => action(CALENDAR_PARTICIPANTS.POST.REQUEST, { eventId }),
  success: (payload: any) => action(CALENDAR_PARTICIPANTS.POST.SUCCESS, payload),
  failure: (error: any) => action(CALENDAR_PARTICIPANTS.POST.FAILURE, error)
};
