import { USERS, getUsers, USER_PROFILE, getUserProfile } from '../actions/UserActions';
import { all, put, takeLatest } from 'redux-saga/effects';
import { getUserProfileById, getUsers as getUsersQuery } from 'api/graphql/queries';
import { API, Auth } from 'aws-amplify';
import * as Alert from 'utils/Alerts';
import i18n from 'utils/i18n';
import { AppRoutes } from 'config/AppRoutes';
import { globalNavigate } from 'utils/global-history';

export function* getUsersSaga() {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: getUsersQuery()
      }
    };

    const {
      data: { users }
    } = yield API.post('UserAPI', '/graphql?query', requestInfo);

    yield put(getUsers.success(users));
  } catch (e: any) {
    yield put(getUsers.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getUsers'));
  }
}

export function* getUserSaga({ payload: { id } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        query: getUserProfileById(id)
      }
    };

    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);

    if (response.errors) {
      throw new Error(i18n.t('api.errors.pgetUserProfile'));
    }

    yield put(getUserProfile.success(response?.data?.user));
  } catch (e: any) {
    yield put(getUserProfile.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getUser'));
    globalNavigate(AppRoutes.profile.link);
  }
}

function* userSaga() {
  yield all([
    yield takeLatest(USERS.GET.REQUEST, getUsersSaga),
    yield takeLatest(USER_PROFILE.GET.REQUEST, getUserSaga)
  ]);
}

export default userSaga;
