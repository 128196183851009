import authReducer from './AuthReducer';
import { combineReducers } from 'redux';
import errorReducer from './ErrorReducer';
import loadingReducer from './LoadingReducer';
import organizationReducer from './OrganizationReducer';
import personalProfileReducer from './PersonalProfileReducer';
import countryReducer from './CountryReducer';
import notificationReducer from './NotificationsReducer';
import actionReducer from './ActionReducer';
import userReducer from './UserReducer';
import contestApplicationReducer from './ContestApplicationReducer';
import contestReducer from './ContestReducer';
import commonReducer from './CommonReducer';
import resourceReducer from './ResourceReducer';
import calendarReducer from './CalendarReducer';
import homePageReducer from './HomePageReducer';
import interactionReducer from './InteractionReducer';
import { SIGN_OUT } from 'core/actions/AuthActions';

const appReducer = combineReducers({
  // router: connectRouter(history),
  auth: authReducer,
  loading: loadingReducer,
  error: errorReducer,
  organization: organizationReducer,
  personalProfile: personalProfileReducer,
  country: countryReducer,
  notifications: notificationReducer,
  actions: actionReducer,
  users: userReducer,
  contestApplications: contestApplicationReducer,
  contest: contestReducer,
  commons: commonReducer,
  resources: resourceReducer,
  calendar: calendarReducer,
  homePage: homePageReducer,
  interactions: interactionReducer
});

export type AppState = ReturnType<typeof appReducer>;

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT.POST.SUCCESS) {
    state = undefined; // Reset the state
  }

  return appReducer(state, action);
};

export default rootReducer;
