import { DELETE, GET, POST, PUT } from 'utils/Constants';
import { action, createRequestTypes } from 'utils/ReduxHelpers';
import { ITag, IDocument, ICheckListItem, IAction, IUser, IComment, IGenericMember } from 'core/models/Models';
interface FetchAllActionsSuccessPayload {
  actions: IAction[];
}

interface IUpdateActionPayload {
  name?: string;
  endDate?: string;
  description?: string;
  published?: string;
  imageFile?: File;
  id: string;
  imageUrl?: string;
  isGroupApplication?: boolean;
}

interface INewActionUpdatePayload {
  name?: string;
  endDate?: string;
  description?: string;
}

export const ALL_ACTIONS = createRequestTypes('ALL_ACTIONS', [GET]);
export const ACTION = createRequestTypes('ACTION', [GET, DELETE, POST, PUT]);
export const ACTION_TAGS = createRequestTypes('ACTION_TAGS', [POST]);
export const ACTION_PARTICIPANTS = createRequestTypes('ACTION_PARTICIPANTS', [POST]);
export const ACTION_ADD_REMOVE_PARTICIPANT = createRequestTypes('ACTION_ADD_REMOVE_PARTICIPANT', [POST]);
export const ACTION_REMOVE_PARTICIPANT = createRequestTypes('ACTION_REMOVE_PARTICIPANT', [POST]);
export const UPDATE_ACTION = createRequestTypes('UPDATE_ACTION', [POST]);
export const ACTION_CHECK_LIST_ITEMS = createRequestTypes('ACTION_CHECK_LIST_ITEMS', [DELETE, POST, PUT]);
export const ACTION_DOCUMENTS = createRequestTypes('ACTION_DOCUMENTS', [DELETE, POST, PUT]);
export const ACTION_COMMENTS = createRequestTypes('ACTION_COMMENTS', [DELETE, POST, PUT]);
export const ACTION_UPLOAD_IMAGE = createRequestTypes('ACTION_UPLOAD_IMAGE', [POST]);
export const ACTION_LIKE = createRequestTypes('ACTION_LIKE', [POST]);
export const ACTION_COMMENT_LIKE = createRequestTypes('ACTION_COMMENT_LIKE', [POST]);
export const ACTION_VIEW = createRequestTypes('ACTION_VIEW', [POST]);
export const SEND_ACTION_INVITATION = createRequestTypes('SEND_ACTION_INVITATION', [POST]);
export const JOIN_ACTION = createRequestTypes('JOIN_ACTION', [POST]);
export const ACTION_VOTE = createRequestTypes('ACTION_VOTE', [POST]);
export const SEARCH_ACTION = createRequestTypes('SEARCH_ACTION', [GET]);
export const UPDATE_NEW_ACTION = 'UPDATE_NEW_ACTION';
export const UPDATE_NEW_ACTION_TAGS = 'UPDATE_NEW_ACTION_TAGS';
export const UPDATE_NEW_ACTION_PARTICIPANTS = 'UPDATE_NEW_ACTION_PARTICIPANTS';
export const ADD_NEW_ACTION_CHECK_LIST_ITEM = 'ADD_NEW_ACTION_CHECK_LIST_ITEM';
export const UPDATE_NEW_ACTION_CHECK_LIST_ITEM = 'UPDATE_NEW_ACTION_CHECK_LIST_ITEM';
export const DELETE_NEW_ACTION_CHECK_LIST_ITEM = 'DELETE_NEW_ACTION_CHECK_LIST_ITEM';
export const ADD_NEW_ACTION_DOCUMENT = 'ADD_NEW_ACTION_DOCUMENT';
export const UPDATE_NEW_ACTION_DOCUMENT = 'UPDATE_NEW_ACTION_DOCUMENT';
export const DELETE_NEW_ACTION_DOCUMENT = 'DELETE_NEW_ACTION_DOCUMENT';
export const NEW_ACTION = 'NEW_ACTION';

export const updateNewAction = (data: INewActionUpdatePayload) => action(UPDATE_NEW_ACTION, data);
export const updateNewActionTags = (tags: Array<ITag>) => action(UPDATE_NEW_ACTION_TAGS, tags);
export const updateNewActionParticipants = (participants: IUser[]) =>
  action(UPDATE_NEW_ACTION_PARTICIPANTS, participants);
export const addNewActionCheckListItem = (checkListItem: ICheckListItem) =>
  action(ADD_NEW_ACTION_CHECK_LIST_ITEM, checkListItem);
export const updateNewActionCheckListItem = (checkListItem: ICheckListItem) =>
  action(UPDATE_NEW_ACTION_CHECK_LIST_ITEM, checkListItem);
export const deleteNewActionCheckListItem = (checkListItem: ICheckListItem) =>
  action(DELETE_NEW_ACTION_CHECK_LIST_ITEM, checkListItem);
export const addNewActionDocument = (document: IDocument) => action(ADD_NEW_ACTION_DOCUMENT, document);
export const updateNewActionDocument = (document: IDocument) => action(UPDATE_NEW_ACTION_DOCUMENT, document);
export const deleteNewActionDocument = (document: IDocument) => action(DELETE_NEW_ACTION_DOCUMENT, document);
export const newAction = (createdBy: string, organizerOrganizationId?: number, contestId?: number) =>
  action(NEW_ACTION, { createdBy, organizerOrganizationId, contestId });

export const searchAction = {
  request: (name: string, tagIds?: Array<number>) => action(SEARCH_ACTION.GET.REQUEST, { name, tagIds }),
  success: (payload: any) => action(SEARCH_ACTION.GET.SUCCESS, payload),
  failure: (error: any) => action(SEARCH_ACTION.GET.FAILURE, error)
};

export const getAllActions = {
  request: () => action(ALL_ACTIONS.GET.REQUEST, {}),
  success: (payload: FetchAllActionsSuccessPayload) => action(ALL_ACTIONS.GET.SUCCESS, payload),
  failure: (error: any) => action(ALL_ACTIONS.GET.FAILURE, error)
};

export const getAction = {
  request: (id) => action(ACTION.GET.REQUEST, { id }),
  success: (payload: IAction) => action(ACTION.GET.SUCCESS, payload),
  failure: (error: any) => action(ALL_ACTIONS.GET.FAILURE, error)
};

export const uploadImage = {
  request: (file: any) => action(ACTION_UPLOAD_IMAGE.POST.REQUEST, { file }),
  success: (payload: any) => action(ACTION_UPLOAD_IMAGE.POST.SUCCESS, payload),
  failure: (payload: any) => action(ACTION_UPLOAD_IMAGE.POST.FAILURE, payload)
};

export const createAction = {
  request: (data: IAction) => action(ACTION.POST.REQUEST, { data }),
  success: (payload: any) => action(ACTION.POST.SUCCESS, payload),
  failure: (error: any) => action(ACTION.POST.FAILURE, error)
};

export const updateAction = {
  request: (data: IUpdateActionPayload) => action(UPDATE_ACTION.POST.REQUEST, data),
  success: (payload: any) => action(UPDATE_ACTION.POST.SUCCESS, payload),
  failure: (error: any) => action(UPDATE_ACTION.POST.FAILURE, error)
};

export const deleteAction = {
  request: (id: number) => action(ACTION.DELETE.REQUEST, { id }),
  success: (id: number) => action(ACTION.DELETE.SUCCESS, { id }),
  failure: (error: any) => action(ACTION.DELETE.FAILURE, error)
};

export const updateActionTags = {
  request: (id: number, tags: Array<ITag>) => action(ACTION_TAGS.POST.REQUEST, { id, tags }),
  success: (payload: any) => action(ACTION_TAGS.POST.SUCCESS, payload),
  failure: (error: any) => action(ACTION_TAGS.POST.FAILURE, error)
};

export const updateActionParticipants = {
  request: (id: string, participants: number[]) => action(ACTION_PARTICIPANTS.POST.REQUEST, { id, participants }),
  success: (payload: any) => action(ACTION_PARTICIPANTS.POST.SUCCESS, payload),
  failure: (error: any) => action(ACTION_PARTICIPANTS.POST.FAILURE, error)
};

export const addRemoveActionParticipant = {
  request: (id: string) => action(ACTION_ADD_REMOVE_PARTICIPANT.POST.REQUEST, { id }),
  success: (payload: any) => action(ACTION_ADD_REMOVE_PARTICIPANT.POST.SUCCESS, payload),
  failure: (error: any) => action(ACTION_ADD_REMOVE_PARTICIPANT.POST.FAILURE, error)
};

export const removeActionParticipant = {
  request: (id: number, member: IGenericMember) => action(ACTION_REMOVE_PARTICIPANT.POST.REQUEST, { id, member }),
  success: (payload: any) => action(ACTION_REMOVE_PARTICIPANT.POST.SUCCESS, payload),
  failure: (error: any) => action(ACTION_REMOVE_PARTICIPANT.POST.FAILURE, error)
};

export const addCheckListItem = {
  request: (checkListItem: ICheckListItem) => action(ACTION_CHECK_LIST_ITEMS.POST.REQUEST, { checkListItem }),
  success: (payload: any) => action(ACTION_CHECK_LIST_ITEMS.POST.SUCCESS, payload),
  failure: (error: any) => action(ACTION_CHECK_LIST_ITEMS.POST.FAILURE, error)
};

export const updateCheckListItem = {
  request: (checkListItem: ICheckListItem) => action(ACTION_CHECK_LIST_ITEMS.PUT.REQUEST, { checkListItem }),
  success: (payload: any) => action(ACTION_CHECK_LIST_ITEMS.PUT.SUCCESS, payload),
  failure: (error: any) => action(ACTION_CHECK_LIST_ITEMS.PUT.FAILURE, error)
};

export const removeCheckListItem = {
  request: (checkListItem: ICheckListItem) => action(ACTION_CHECK_LIST_ITEMS.DELETE.REQUEST, { checkListItem }),
  success: (payload: any) => action(ACTION_CHECK_LIST_ITEMS.DELETE.SUCCESS, payload),
  failure: (error: any) => action(ACTION_CHECK_LIST_ITEMS.DELETE.FAILURE, error)
};

export const addDocument = {
  request: (document: IDocument) => action(ACTION_DOCUMENTS.POST.REQUEST, { document }),
  success: (payload: any) => action(ACTION_DOCUMENTS.POST.SUCCESS, payload),
  failure: (error: any) => action(ACTION_DOCUMENTS.POST.FAILURE, error)
};

export const updateDocument = {
  request: (document: IDocument) => action(ACTION_DOCUMENTS.PUT.REQUEST, { document }),
  success: (payload: any) => action(ACTION_DOCUMENTS.PUT.SUCCESS, payload),
  failure: (error: any) => action(ACTION_DOCUMENTS.PUT.FAILURE, error)
};

export const removeDocument = {
  request: (document: IDocument) => action(ACTION_DOCUMENTS.DELETE.REQUEST, { document }),
  success: (payload: any) => action(ACTION_DOCUMENTS.DELETE.SUCCESS, payload),
  failure: (error: any) => action(ACTION_DOCUMENTS.DELETE.FAILURE, error)
};

export const addComment = {
  request: (comment: IComment) => action(ACTION_COMMENTS.POST.REQUEST, { comment }),
  success: (payload: any) => action(ACTION_COMMENTS.POST.SUCCESS, payload),
  failure: (error: any) => action(ACTION_COMMENTS.POST.FAILURE, error)
};

export const updateComment = {
  request: (comment: IComment) => action(ACTION_COMMENTS.PUT.REQUEST, { comment }),
  success: (payload: any) => action(ACTION_COMMENTS.PUT.SUCCESS, payload),
  failure: (error: any) => action(ACTION_COMMENTS.PUT.FAILURE, error)
};

export const removeComment = {
  request: (comment: IComment) => action(ACTION_COMMENTS.DELETE.REQUEST, { comment }),
  success: (payload: any) => action(ACTION_COMMENTS.DELETE.SUCCESS, payload),
  failure: (error: any) => action(ACTION_COMMENTS.DELETE.FAILURE, error)
};

export const likeOrUnlikeAction = {
  request: (actionId, userId) => action(ACTION_LIKE.POST.REQUEST, { actionId, userId }),
  success: (payload: any) => action(ACTION_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(ACTION_LIKE.POST.FAILURE, error)
};

export const likeOrUnlikeActionComment = {
  request: (actionId, commentId, userId) => action(ACTION_COMMENT_LIKE.POST.REQUEST, { actionId, commentId, userId }),
  success: (payload: any) => action(ACTION_COMMENT_LIKE.POST.SUCCESS, payload),
  failure: (error: any) => action(ACTION_COMMENT_LIKE.POST.FAILURE, error)
};

export const addActionView = {
  request: (actionId) => action(ACTION_VIEW.POST.REQUEST, { actionId }),
  success: (payload: any) => action(ACTION_VIEW.POST.SUCCESS, payload),
  failure: (error: any) => action(ACTION_VIEW.POST.FAILURE, error)
};

export const sendActionInvitation = {
  request: (
    userId: number,
    actionId: number,
    usersParticipantsIds: Array<number>,
    organizationsParticipantsIds: Array<number>
  ) =>
    action(SEND_ACTION_INVITATION.POST.REQUEST, {
      userId,
      actionId,
      usersParticipantsIds,
      organizationsParticipantsIds
    }),
  success: (payload: any) => action(SEND_ACTION_INVITATION.POST.SUCCESS, payload),
  failure: (error: any) => action(SEND_ACTION_INVITATION.POST.FAILURE, error)
};

export const joinAction = {
  request: (actionId, organizationId, payloadHash) =>
    action(JOIN_ACTION.POST.REQUEST, { actionId, organizationId, payloadHash }),
  success: (payload: any) => action(JOIN_ACTION.POST.SUCCESS, payload),
  failure: (error: any) => action(JOIN_ACTION.POST.FAILURE, error)
};

export const backAction = {
  request: (actionId, userId) => action(ACTION_VOTE.POST.REQUEST, { actionId, userId }),
  success: (payload: any) => action(ACTION_VOTE.POST.SUCCESS, payload),
  failure: (error: any) => action(ACTION_VOTE.POST.FAILURE, error)
};
