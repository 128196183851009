import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  image: {
    minHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
    zoom: '125%'
  },
  container: {
    height: '100%',
    width: 'auto',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
