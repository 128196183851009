import { all, put, takeLatest } from 'redux-saga/effects';
import { getHomeHighlightActivity, getHomePageCommentsQuery, getHomePageContentQuery } from 'api/graphql/queries';
import { API, Auth } from 'aws-amplify';
import * as Alert from 'utils/Alerts';
import i18n from 'utils/i18n';
import {
  getHomePageActivity,
  getHomePageComments,
  getHomePageContent,
  HOME_PAGE_ACTIVITY,
  HOME_PAGE_CONTENT,
  HOME_PAGE_COMMENTS,
  HOME_CARD_COMMENTS,
  getHomeCardComments
} from 'core/actions/HomePageActions';

export function* fetchHomePageContentSaga({ payload: { nbElements, page } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: getHomePageContentQuery(cognitoId, nbElements, page)
      }
    };

    const result = yield API.post('CommonsAPI', '/graphql?query', requestInfo);

    if (result?.errors) {
      throw new Error(result?.errors);
    }

    const payload = result?.data?.usersHomeCards;
    let tempData: Array<any> = [];
    const keys = Object.keys(payload);

    keys.forEach((k) => {
      if (payload[k].length > 0) {
        const content = payload[k].map((element) => ({
          key: k,
          ...element.attributes,
          organizers: element?.organizers,
          tags: element.tags,
          knowledgeResources: element?.knowledgeResources,
          linkResources: element?.linkResources,
          expertiseResources: element?.expertiseResources,
          documents: element?.documents
        }));
        tempData = tempData.concat(content);
      }
    });

    tempData.sort((a, b) => b.pId - a.pId);

    yield put(getHomePageContent.success(tempData));
  } catch (e: any) {
    yield put(getHomePageContent.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getHomePageContent'));
  }
}

export function* fetchHomePageActivity() {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const cognitoId = user?.attributes?.sub;
    const requestInfo = {
      queryStringParameters: {
        query: getHomeHighlightActivity(cognitoId)
      }
    };

    const result = yield API.post('CommonsAPI', '/graphql?query', requestInfo);

    if (result?.errors) {
      throw new Error(result?.errors);
    }
    let activityElement = null;
    const data = result?.data?.usersHighlightedElement;
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        activityElement = { ...data[key]?.attributes, tags: data[key].tags, key };
      }
    });

    yield put(getHomePageActivity.success(activityElement));
  } catch (e: any) {
    yield put(getHomePageActivity.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getHomePageActivity'));
  }
}

export function* fetchHomePageComments({ payload: { elementIds, number, page } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getHomePageCommentsQuery(elementIds, number, page)
      }
    };

    const result = yield API.post('InteractionsAPI', '/graphql?query', requestInfo);

    if (result?.errors) {
      throw new Error(result?.errors);
    }

    yield put(getHomePageComments.success(result?.data?.comments));
  } catch (e: any) {
    yield put(getHomePageComments.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getHomePageComments'));
  }
}

export function* fetchHomeCardComments({ payload: { elementId, number, page } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: getHomePageCommentsQuery([elementId], number, page)
      }
    };

    const result = yield API.post('InteractionsAPI', '/graphql?query', requestInfo);

    if (result?.errors) {
      throw new Error(result?.errors);
    }

    yield put(getHomeCardComments.success(result?.data?.comments[0]));
  } catch (e: any) {
    yield put(getHomeCardComments.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.getHomePageComments'));
  }
}

function* homePageSaga() {
  yield all([
    takeLatest(HOME_PAGE_CONTENT.GET.REQUEST, fetchHomePageContentSaga),
    takeLatest(HOME_PAGE_ACTIVITY.GET.REQUEST, fetchHomePageActivity),
    takeLatest(HOME_PAGE_COMMENTS.GET.REQUEST, fetchHomePageComments),
    takeLatest(HOME_CARD_COMMENTS.GET.REQUEST, fetchHomeCardComments)
  ]);
}

export default homePageSaga;
