import { stringify, escapeSingleAndDoubleQuotes, stringToList } from './utils';

// #region Users repository
export function createUser(cognitoId, lastName, firstName, imageUrl, title, organizationId, organizationName, email) {
  const ln = escapeSingleAndDoubleQuotes(lastName);
  const fn = escapeSingleAndDoubleQuotes(firstName);
  const titleEscaped = escapeSingleAndDoubleQuotes(title);
  const organizationIdstr = organizationId != undefined ? `organizationId: ${organizationId}` : '';
  const organizationNamestr =
    organizationName !== '' ? `organizationName: "${escapeSingleAndDoubleQuotes(organizationName)}"` : '';
  const url = imageUrl !== '' ? `imageUrl : "${imageUrl}"` : '';

  return `
    mutation {
        createUser(
          userData: {
            cognitoId: "${cognitoId}"
            lastName: "${ln}"
            firstName: "${fn}"
            title: "${titleEscaped}"
            ${url}
            ${organizationIdstr}
            ${organizationNamestr}
            email: "${email}"
          }) {
          isRequestSuccessful
        }
    }`;
}

export function deactivateUser(cognitoId) {
  return `mutation {
    deactivateUser(cognitoId: "${cognitoId}") {
      isRequestSuccessful
    }
  }
  `;
}

export function updateUser(cognitoId, bio, firstname, lastname, title, addFromBio, imageUrl) {
  const userbio = bio != undefined ? `bio: [${stringToList(bio)}]` : '';
  const firstName = firstname != undefined ? `firstName: "${escapeSingleAndDoubleQuotes(firstname)}"` : '';
  const lastName = lastname != undefined ? `lastName: "${escapeSingleAndDoubleQuotes(lastname)}"` : '';
  const userTitle = title != undefined ? `title: "${escapeSingleAndDoubleQuotes(title)}"` : '';
  const showCustomTitle = addFromBio != undefined ? `showCustomTitle: ${!addFromBio}` : '';
  const imageUrlStr = imageUrl != (undefined || '') ? `imageUrl: "${imageUrl}"` : '';
  return `mutation {
    updateUser(userData: {
      cognitoId: "${cognitoId}"
      ${userbio}
      ${firstName}
      ${lastName}
      ${userTitle}
      ${showCustomTitle}
      ${imageUrlStr}
    }) {
      signature
      isRequestSuccessful
    }
  }
  `;
}

export function updateUsersImage(cognitoId, imageUrl) {
  return `mutation {
    updateUser(userData: {
      cognitoId: "${cognitoId}"
      imageUrl: "${imageUrl}"
    }) {
      isRequestSuccessful
    }
  }
  `;
}

export function updateUsersDiscourseEmail(cognitoId, previousEmail, email) {
  return `mutation {
    updateUsersDiscourseEmail(
      cognitoId: "${cognitoId}",
      newEmail: "${escapeSingleAndDoubleQuotes(email)}"
      previousEmail: "${escapeSingleAndDoubleQuotes(previousEmail)}"
    ) {
      isRequestSuccessful
    }
  }
  `;
}

export function signOutFromDiscourse(discourseId) {
  return `mutation {
    logOutFromDiscourse(discourseId: ${discourseId}) {
      isRequestSuccessful
    }
  }
  `;
}

export function updateUsersImageUrl(cognitoId, imageUrl) {
  return `mutation {
    updateUser(userData: {cognitoId: "${cognitoId}", imageUrl: "${imageUrl}"}) {
      isRequestSuccessful
    }
  }
  `;
}

export function updateUsersGeneralInformation(cognitoId, birthDate, country, city, email, hobby, pets) {
  const cityStr = city != undefined ? `city: "${escapeSingleAndDoubleQuotes(city)}"` : '';
  const emailStr = email != undefined ? `email: "${escapeSingleAndDoubleQuotes(email)}"` : '';
  const hobbyStr = hobby != undefined ? `hobby: "${escapeSingleAndDoubleQuotes(hobby)}"` : '';
  const petsStr = pets != undefined ? `pets: "${escapeSingleAndDoubleQuotes(pets)}"` : '';

  return `mutation {
    updateUser(userData: {
      cognitoId: "${cognitoId}"
      birthDate: "${birthDate}"
      countryId: ${country}
      ${cityStr}
      ${emailStr}
      ${hobbyStr}
      ${petsStr}
     }) {
      isRequestSuccessful
    }
  }
  `;
}

// #endregion

// #region Social Networks repository
export function createOrUpdateUsersSocialNetworks(cognitoId, socialNetworksData) {
  return `mutation {
    createOrUpdateUsersSocialNetworks(
      socialNetworksData: ${socialNetworksData}
      cognitoId: "${cognitoId}"
      ){
        isRequestSuccessful
        socialNetworks{
          name,
          url,
          id
        }
      }
    }`;
}

export function updateUsersSocialNetwork(socialNetworkId, socialNetworkUrl) {
  return `mutation {
        updateUsersSocialNetwork(
              socialNetworkData: {
                  id: "${socialNetworkId}"
                  url: "${socialNetworkUrl}"
              }
            ) {
              isRequestSuccessful
            }
      }
      `;
}

export function deleteUsersSocialNetwork(socialNetworkId) {
  return `mutation {
    deleteUsersSocialNetwork(
          socialNetworkData: {
              id: "${socialNetworkId}"
          }
        ) {
          isRequestSuccessful
        }
  }
  `;
}
// #endregion

// #region tags
export function createOrDeleteUsersTags(cognitoId, tagIds) {
  return `mutation {
    createOrDeleteUsersTags(
      tagIds: ${tagIds}
      cognitoId: "${cognitoId}") {
        isRequestSuccessful
      }
    }`;
}

// #endregion

// #region work bio repository
export function createUsersWorkBio(
  cognitoId,
  position,
  location,
  workplace,
  organizationId,
  fromDate,
  toDate,
  isHeaderWorkBio
) {
  const toDateStr = toDate ? `toDate: "${toDate}"` : '';
  const organizationIdStr = organizationId ? `organizationId: ${organizationId}` : '';
  return `mutation {
    createUsersWorkBio(
      workBioData: {
        cognitoId: "${cognitoId}"
        position: "${escapeSingleAndDoubleQuotes(position)}"
        location: "${escapeSingleAndDoubleQuotes(location)}"
        workplace: "${escapeSingleAndDoubleQuotes(workplace)}"
        ${organizationIdStr}
        fromDate: "${fromDate}"
        isHeaderWorkBio: ${isHeaderWorkBio}
        ${toDateStr}
      }) {
        headerWorkBio {
          pId
          id
          position
          workplace
          toDate
          fromDate
          imageUrl
          location
          organizationId
        }
        workBio {
          id
          pId
          position
          workplace
          toDate
          fromDate
          imageUrl
          location
          organizationId
        }
        signature
        isRequestSuccessful
      }
    }`;
}

export function updateUsersWorkBio(
  cognitoId,
  id,
  position,
  location,
  workplace,
  organizationId,
  fromDate,
  toDate,
  isHeaderWorkBio
) {
  const organizationIdStr = organizationId ? `organizationId: ${organizationId}` : '';
  const toDateStr = toDate === null ? '' : `toDate: "${toDate}"`;
  return `mutation {
    updateUsersWorkBio(
      cognitoId: "${cognitoId}"
      workBioData: {
        id: ${id}
        position: "${escapeSingleAndDoubleQuotes(position)}"
        location: "${escapeSingleAndDoubleQuotes(location)}"
        workplace: "${escapeSingleAndDoubleQuotes(workplace)}"
        ${organizationIdStr}
        fromDate: "${fromDate}"
        ${toDateStr}
        isHeaderWorkBio: ${isHeaderWorkBio}
      }) {
        headerWorkBio {
          pId
          id
          position
          workplace
          toDate
          fromDate
          imageUrl
          location
          organizationId
        }
        workBio {
          id
          pId
          position
          workplace
          toDate
          fromDate
          imageUrl
          location
          organizationId
        }
        signature
        isRequestSuccessful
    }
  }`;
}

export function deleteUsersWorkBio(id, cognitoId) {
  return `mutation {
    deleteUsersWorkBio(
      id: ${id}
      cognitoId: "${cognitoId}"
    ) {
      headerWorkBio {
        pId
        id
      }
      workBioId
      signature
      isRequestSuccessful
    }
  }`;
}

export function createUserEducation(cognitoId, school, certificate, fromDate, toDate, location) {
  const toDateStr = toDate ? `toDate: "${toDate}"` : '';
  return `mutation {
    createUsersEducationEntry(
      educationEntryData: {
        cognitoId: "${cognitoId}"
        schoolOrCourse: "${escapeSingleAndDoubleQuotes(school)}"
        certificate: "${escapeSingleAndDoubleQuotes(certificate)}"
        fromDate: "${fromDate}"
        ${toDateStr}
        location: "${escapeSingleAndDoubleQuotes(location)}"
      }
    ) {
        isRequestSuccessful
        educationEntry {
          id
          schoolOrCourse
          certificate
          fromDate
          toDate
          location
        }
    }
  }`;
}

export function updateUserEducation(id, school, certificate, fromDate, toDate, location) {
  const toDateStr = toDate ? `toDate: "${toDate}"` : '';
  return `mutation {
    updateUsersEducationEntry(
      educationEntryData: {
        id: ${id}
        schoolOrCourse: "${escapeSingleAndDoubleQuotes(school)}"
        certificate: "${escapeSingleAndDoubleQuotes(certificate)}"
        fromDate: "${fromDate}"
        ${toDateStr}
        location: "${escapeSingleAndDoubleQuotes(location)}"
      }
    ) {
        isRequestSuccessful
        educationEntry {
          id
          schoolOrCourse
          certificate
          fromDate
          toDate
          location
        }
    }
  }`;
}

export function deleteUserEducaton(id) {
  return `mutation {
    deleteUsersEducationEntry(id: ${id}) {
      isRequestSuccessful
    }
  }`;
}

export function acceptInvitation(id) {
  return `mutation {
    acceptInvitation(id: ${id}){
      isRequestSuccessful
    }
  }`;
}

export function updateUserCategoriesWatchStatus(discourseUsername, categories) {
  return `mutation {
    updateUsersCategoriesWatchStatus(
      categoriesWithWatchStatus: ${categories}
      discourseUsername: "${discourseUsername}"
    ){
    isRequestSuccessful
    }
  }`;
}

export function updateNotificationSettings(userId, settings) {
  const forumEmailOn = settings?.forumEmailOn != (null || undefined) ? `forumEmailOn: ${settings.forumEmailOn}` : '';
  const forumToastOn = settings?.forumToastOn != (null || undefined) ? `forumToastOn: ${settings.forumToastOn}` : '';
  const actionsEmailOn =
    settings?.actionsEmailOn != (null || undefined) ? `actionsEmailOn: ${settings.actionsEmailOn}` : '';
  const actionsToastOn =
    settings?.actionsToastOn != (null || undefined) ? `actionsToastOn: ${settings.actionsToastOn}` : '';
  const contestsEmailOn =
    settings?.contestsEmailOn != (null || undefined) ? `contestsEmailOn: ${settings.contestsEmailOn}` : '';
  const contestsToastOn =
    settings?.contestsToastOn != (null || undefined) ? `contestsToastOn: ${settings.contestsToastOn}` : '';
  const resourcesEmailOn =
    settings?.resourcesEmailOn != (null || undefined) ? `resourcesEmailOn: ${settings.resourcesEmailOn}` : '';
  const resourcesToastOn =
    settings?.resourcesToastOn != (null || undefined) ? `resourcesToastOn: ${settings.resourcesToastOn}` : '';
  const calendarEmailOn =
    settings?.calendarEmailOn != (null || undefined) ? `calendarEmailOn: ${settings.calendarEmailOn}` : '';
  const calendarToastOn =
    settings?.calendarToastOn != (null || undefined) ? `calendarToastOn: ${settings.calendarToastOn}` : '';
  const commentsEmailOn =
    settings?.commentsEmailOn != (null || undefined) ? `commentsEmailOn: ${settings.commentsEmailOn}` : '';
  const commentsToastOn =
    settings?.commentsToastOn != (null || undefined) ? `commentsToastOn: ${settings.commentsToastOn}` : '';
  const mentionsEmailOn =
    settings?.mentionsEmailOn != (null || undefined) ? `mentionsEmailOn: ${settings.mentionsEmailOn}` : '';
  const mentionsToastOn =
    settings?.mentionsToastOn != (null || undefined) ? `mentionsToastOn: ${settings.mentionsToastOn}` : '';

  return `mutation {
    updateNotificationSettings(updateNotificationSettingsData: {
      ${forumEmailOn}
      ${forumToastOn}
      ${actionsEmailOn}
      ${actionsToastOn}
      ${contestsEmailOn}
      ${contestsToastOn}
      ${resourcesEmailOn}
      ${resourcesToastOn}
      ${commentsEmailOn}
      ${commentsToastOn}
      ${calendarEmailOn}
      ${calendarToastOn}
      ${mentionsEmailOn}
      ${mentionsToastOn}
      userId: ${userId}
    }
    ){
    isRequestSuccessful
    }
  }`;
}

export function markNotificationsAsRead(id) {
  return `mutation {
    markAllUsersNotificationsAsRead(userId: ${id}) {
      isRequestSuccessful
    }
  }`;
}

export function updateOrganizationSocialNetoworksMutation(organizationId, socialNetworks) {
  const socNetworks = stringify(socialNetworks);
  return `mutation {
    createOrUpdateOrganizationSocialNetworks (
      id: ${organizationId}
      socialNetworksData: ${socNetworks}
    ){
        socialNetworks {
          name
          url
        }
        organizationId
      }
  }`;
}

export function updateOrganizationProjectMutation(project) {
  const name = project?.name != undefined ? `name: "${escapeSingleAndDoubleQuotes(project.name)}"` : '';
  const fromDate = project?.fromDate != undefined ? `fromDate: "${project.fromDate}"` : '';
  const toDate = project?.toDate != undefined ? `toDate: "${project.toDate}"` : '';
  const location = project?.location != undefined ? `location: "${escapeSingleAndDoubleQuotes(project.location)}"` : '';
  const description =
    project?.description != undefined ? `description: "${escapeSingleAndDoubleQuotes(project.description)}"` : '';

  return `mutation {
    updateOrganizationProject (
      projectData: {
        id: ${project.pId}
        pId: ${project.pId}
        ${name}
        ${fromDate}
        ${toDate}
        ${location}
        ${description}
      }
    ){
      project {
        id
        pId
        name
        fromDate
        toDate
        location
        description
      }

    }
  }`;
}

export function createOrganizationProjectMutation(project, organizationId) {
  const name = project?.name != undefined ? `name: "${escapeSingleAndDoubleQuotes(project.name)}"` : '';
  const fromDate = project?.fromDate != undefined ? `fromDate: "${project.fromDate}"` : '';
  const toDate = project?.toDate != undefined ? `toDate: "${project.toDate}"` : '';
  const location = project?.location != undefined ? `location: "${escapeSingleAndDoubleQuotes(project.location)}"` : '';
  const description =
    project?.description != undefined ? `description: "${escapeSingleAndDoubleQuotes(project.description)}"` : '';

  return `mutation {
    createOrganizationProject (
      projectData: {
        ${name}
        ${fromDate}
        ${toDate}
        ${location}
        ${description}
        organizationId: ${organizationId}
      }
    ){
      project {
        id
        pId
        name
        fromDate
        toDate
        location
        description
      }

    }
  }`;
}

export function deleteOrganizationProjectMutation(pId) {
  return `mutation {
    deleteOrganizationProject (
        id: ${pId}
    ){
      projectId
    }
  }`;
}

export function updateOrganizationTagsMutation(organizationId, tagIds) {
  const ids = stringify(tagIds);
  return `mutation {
    createOrUpdateOrganizationTags (
      id: ${organizationId}
      tagIds: ${ids}
    ){
      tags {
        name
        id
        pId
        type
        url
      }
      organizationId
    }
  }`;
}

export function updateMembersMutation(members, organizationId) {
  const stringifyMembers = stringify(members);
  return `mutation {
    createOrDeleteOrganizationMembers(
      membersData: ${stringifyMembers}
      id: ${organizationId}
    ){
      isRequestSuccessful
      members {
        member: user {
          name
          imageUrl
          pId
        }
        role
      }
      organizationId
    }
  }`;
}

export function updateOrganizationMutation(data) {
  const name = data?.name != undefined ? `name: "${escapeSingleAndDoubleQuotes(data.name)}"` : '';
  const email = data?.email != undefined ? `email: "${escapeSingleAndDoubleQuotes(data.email)}"` : '';
  const about = data?.about != undefined ? `about: [${stringToList(data.about)}]` : '';
  const description =
    data?.description != undefined ? `description: "${escapeSingleAndDoubleQuotes(data.description)}"` : '';
  const city = data?.city != undefined ? `city: "${escapeSingleAndDoubleQuotes(data.city)}"` : '';
  const countryId = data?.countryId != undefined ? `countryId: ${data.countryId}` : '';
  const address = data?.address != undefined ? `address: "${escapeSingleAndDoubleQuotes(data.address)}"` : '';
  const phoneNumber =
    data?.phoneNumber != undefined ? `phoneNumber: "${escapeSingleAndDoubleQuotes(data.phoneNumber)}"` : '';
  const imageUrl = data?.imageUrl != undefined ? `imageUrl: "${data.imageUrl}"` : '';

  return `mutation {
    updateOrganization (
      organizationData: {
        id: ${data.pId}
        ${name}
        ${email}
        ${about}
        ${description}
        ${city}
        ${countryId}
        ${address}
        ${phoneNumber}
        ${imageUrl}
      }
    ){
        organization {
          about
          description
          email
          address
          phoneNumber
          countryId
          city
          name
          id
          pId
          imageUrl
        }
    }
  }`;
}

export function createNewOrganization(organization, cognitoId) {
  const about =
    organization.attributes.about != undefined ? `about: [${stringToList(organization.attributes.about)}]` : '';
  const tagIds = stringify(organization.tagIds);
  const members = stringify(organization.members);
  const projects = stringify(organization.projects);
  const socNetworks = stringify(organization.socialNetworks);

  return `mutation {
    createOrganization(
      organizationData: {
        name: "${escapeSingleAndDoubleQuotes(organization.attributes.name)}"
        ${about}
        description: "${escapeSingleAndDoubleQuotes(organization.attributes.description)}"
        email: "${escapeSingleAndDoubleQuotes(organization.attributes.email)}"
        address: "${escapeSingleAndDoubleQuotes(organization.attributes.address)}"
        phoneNumber: "${escapeSingleAndDoubleQuotes(organization.attributes.phoneNumber)}"
        countryId: ${organization.attributes.countryId}
        city: "${escapeSingleAndDoubleQuotes(organization.attributes.city)}"
        tagIds: ${tagIds}
        socialNetworks: ${socNetworks}
        members: ${members}
        projects: ${projects}
        imageUrl: "${organization.attributes.imageUrl}"
      }
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      organization {
        attributes {
          about
          description
          email
          address
          phoneNumber
          countryId
          city
          name
          id
          pId
        }
        members {
          member: user {
            name
            pId
          }
          role
        }
        socialNetworks {
          name
          id
          pId
          url
        }
        tags {
          name
          pId
          id
          type
          url
        }
        projects {
          name
          location
          description
          pId
          fromDate
          toDate
          organizationId
        }
      }
    }
  }`;
}

export function addNewOrganizationsImage(organizationId, imageUrl) {
  return `mutation {
    addNewOrganizationsImage(id: ${organizationId}, imageUrl: "${imageUrl}") {
      imageUrl
      isRequestSuccessful
    }
  }`;
}

export function deleteUser(cognitoId) {
  return `mutation {
    deleteUser(
      cognitoId: "${cognitoId}") {
        isRequestSuccessful
      }
    }`;
}
