import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/styles' {
  type DefaultTheme = Theme;
}

const zdruzenePurple = '#6E5DD8';
const zdruzenePurpleLight = '#A698FF';
const zdruzenePurpleDark = '#4A38B9';

const zdruzeneCoral = '#FA7268';
const zdruzeneCoralLight = '#FFA59E';
const zdruzeneCoralDark = '#D56860';
const zdruzeneCoralInactive = '#D46860';

const zdruzeneBlack = '#1B1B1B';
const zdruzeneGrayDark = '#828282';
const zdruzeneGray = '#DADADA';
const zdruzeneGrayInactive = '#C4C4C4';
const zdruzeneGrayLight = '#F6F6F6';
const zdruzeneGrayLight1 = '#F1F1F1';
const zdruzeneGrayLighter = '#E0E0E0';

const zdruzeneGreen = '#1F8B24';
const zdruzeneRed = '#DA100B';
const zdruzeneBlue = '#0034FF';
const zdruzeneBlueDark = '#0029CB';
const zdruzeneWhite = '#FFFFFF';

/**
 * Tags colors
 */
const zdruzeneTag1Color = '#AA6276';
const zdruzeneTag2Color = '#159497';
const zdruzeneTag3Color = '#C4C4C4';

/**
 * Fonts
 */
const zdruzenePrimaryFont = 'Montserrat';
const zdruzeneSecondaryFont = 'Open Sans';
const zdruzeneTertiaryFont = 'Roboto';

const theme_zdr = createTheme({
  palette: {
    text: {
      primary: zdruzeneTag1Color,
      secondary: zdruzeneTag2Color,
      disabled: zdruzeneTag3Color
    },
    common: {
      black: zdruzeneBlack,
      white: zdruzeneWhite
    },
    primary: {
      light: zdruzenePurpleLight,
      main: zdruzenePurple,
      dark: zdruzenePurpleDark
    },
    secondary: {
      main: zdruzeneCoral,
      dark: zdruzeneCoralDark,
      light: zdruzeneCoralLight,
      contrastText: zdruzeneCoralInactive
    },
    error: {
      main: zdruzeneRed
    },
    success: {
      main: zdruzeneGreen
    },
    info: {
      main: zdruzeneBlue,
      dark: zdruzeneBlueDark
    },
    divider: zdruzeneGrayLighter,
    grey: {
      200: zdruzeneGrayLighter,
      400: zdruzeneGrayLight,
      500: zdruzeneGrayLight1,
      600: zdruzeneGray,
      800: zdruzeneGrayDark,
      A400: zdruzeneGrayInactive
    }
  },
  typography: {
    h1: {
      fontFamily: `${zdruzenePrimaryFont}`,
      fontSize: '2.357rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '2.7rem',
      letterSpacing: '0em'
    },
    h2: {
      fontFamily: `${zdruzenePrimaryFont}`,
      fontSize: '2.333rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '2.833rem',
      letterSpacing: '0em'
    },
    h3: {
      fontFamily: `${zdruzenePrimaryFont}`,
      fontSize: '2rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '2.416rem',
      letterSpacing: '0em'
    },
    subtitle1: {
      fontFamily: `${zdruzenePrimaryFont}`,
      fontSize: '2.33rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '3.083rem',
      letterSpacing: '0em'
    },
    subtitle2: {
      fontFamily: `${zdruzeneTertiaryFont}`,
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '1.188rem',
      letterSpacing: '0em'
    },
    button: {
      fontFamily: `${zdruzenePrimaryFont}`,
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '1.833rem',
      letterSpacing: '0em'
    },
    body1: {
      fontFamily: `${zdruzeneSecondaryFont}`,
      fontSize: '1.333rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '1.833rem',
      letterSpacing: '0em'
    },
    body2: {
      fontFamily: `${zdruzeneSecondaryFont}`,
      fontSize: '1.166rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '1.583rem',
      letterSpacing: '0em'
    }
  },
  mixins: {
    toolbar: {
      minHeight: 70,
      maxHeight: 70
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: { root: { color: `${zdruzeneBlack}` } }
    }
  }
});

export default theme_zdr;
