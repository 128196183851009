import images from 'assets/images';
import S3Avatar from 'components/Shared/S3Image/S3Avatar';
import { INotification } from 'core/models/Models';
import React, { useEffect, useState } from 'react';
import { useStyles } from './ToastNotification.styles';
interface IProps {
  notification: INotification;
}

export default function ToastNotification({ notification }: IProps): any {
  const classes = useStyles();
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 100);
    return () => clearInterval(interval);
  }, [seconds]);

  const toastContent = () => (
    <div className={classes.notification}>
      {notification?.authorImageUrl ? (
        <S3Avatar imageUrl={notification.authorImageUrl} avatarClassName={classes.avatar} />
      ) : notification?.elementImageUrl ? (
        <S3Avatar imageUrl={notification.elementImageUrl} avatarClassName={classes.avatar} />
      ) : (
        <S3Avatar imageUrl={images.pagesActions.sr} avatarClassName={classes.avatar} />
      )}
      <div className={classes.notificationText}>
        {notification.authorName ? (
          <>
            <b>{notification.authorName} </b>
            {notification.text}
          </>
        ) : (
          <b> {notification.text}</b>
        )}
      </div>
    </div>
  );

  return seconds < 100 ? toastContent() : null;
}
