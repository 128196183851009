import { produce } from 'immer';
import { IActivity, IContest, IElegibleContests } from 'core/models/Models';
import {
  CONTEST,
  CONTESTS,
  CONTEST_APPLICATION_LIKE,
  CONTEST_CONTEST_APPLICATION_VOTE,
  CONTEST_COMMENTS,
  CONTEST_COMMENT_LIKE,
  CONTEST_DOCUMENT,
  CONTEST_LIKE,
  CONTEST_TAGS,
  CONTEST_VISIBILITY,
  ELIGIBLE_CONTESTS,
  CONTEST_ACTION_VOTE,
  CONTEST_ACTION_LIKE,
  CONTEST_VIEW,
  SEARCH_CONTEST
} from 'core/actions/ContestActions';

export interface IContestState {
  contests: IContest[];
  eligibleContests: IElegibleContests;
  contestsLoaded: boolean;
}

const initialState: IContestState = {
  contests: [],
  eligibleContests: {
    contests: [],
    count: -1
  },
  contestsLoaded: false
};

const contestReducer = (state = initialState, action: IActivity): IContestState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case CONTESTS.GET.SUCCESS:
        draft.contestsLoaded = true;
        draft.contests = payload;
        break;
      case SEARCH_CONTEST.GET.SUCCESS:
        draft.contests = payload;
        draft.contestsLoaded = false;
        break;
      case ELIGIBLE_CONTESTS.GET.SUCCESS:
        draft.eligibleContests = {
          contests: payload,
          count: payload?.length ?? -1
        };
        break;
      case CONTEST.GET.SUCCESS: {
        if (state.contests.length === 0) {
          draft.contests.push(payload);
        } else {
          const updatedContests = state.contests.filter(
            (contest) => contest.attributes.pId !== payload.attributes?.pId
          );
          updatedContests.push(payload);
          draft.contests = updatedContests;
        }
        break;
      }
      case CONTEST.POST.SUCCESS:
        draft.contests.push(payload);
        break;
      case CONTEST_VIEW.POST.SUCCESS:
        if (!state.contests.some((item) => item?.attributes?.pId == payload?.attributes?.pId)) {
          draft.contests.push(payload);
        } else {
          draft.contests = state.contests.map((item) => {
            return item?.attributes?.pId == payload?.attributes?.pId ? payload : item;
          });
        }
        break;
      case CONTEST.PUT.SUCCESS:
      case CONTEST_TAGS.POST.SUCCESS:
      case CONTEST_DOCUMENT.POST.SUCCESS:
      case CONTEST_DOCUMENT.PUT.SUCCESS:
      case CONTEST_DOCUMENT.DELETE.SUCCESS:
      case CONTEST_VISIBILITY.POST.SUCCESS:
        draft.contests = state.contests.map((c) => (c.attributes.pId === payload.attributes?.pId ? payload : c));
        break;
      case CONTEST.DELETE.SUCCESS:
        draft.contests = state.contests.filter((c) => c.attributes.pId !== payload.id);
        break;
      case CONTEST_LIKE.POST.SUCCESS:
        draft.contests = state.contests.map((item) => {
          if (item?.attributes?.pId == payload?.elementId) {
            const userId = payload?.userId;
            const likedByIds = item?.attributes?.likedByIds ?? [];
            const index = likedByIds?.indexOf(userId) ?? -1;
            const newElementsById = [...likedByIds];
            if (index > -1) {
              newElementsById.splice(index, 1);
              const attributes = { ...item.attributes, likedByIds: newElementsById };
              return { ...item, attributes: attributes };
            } else {
              newElementsById.push(userId);
              const attributes = { ...item.attributes, likedByIds: newElementsById };
              return { ...item, attributes: attributes };
            }
          }
          return item;
        });
        break;
      case CONTEST_COMMENTS.PUT.SUCCESS:
      case CONTEST_COMMENT_LIKE.POST.SUCCESS:
        draft.contests = state.contests.map((contest) =>
          contest.attributes.pId === payload.elementId
            ? { ...contest, comments: contest.comments?.map((c) => (c.pId === payload.pId ? payload : c)) }
            : contest
        );
        break;
      case CONTEST_COMMENTS.POST.SUCCESS:
        draft.contests = state.contests.map((contest) => {
          if (contest.attributes?.pId === payload.elementId) {
            const newComments = [...contest.comments];
            newComments?.push(payload);
            return { ...contest, comments: newComments };
          }
          return contest;
        });
        break;
      case CONTEST_COMMENTS.DELETE.SUCCESS:
        draft.contests = state.contests.map((contest) => {
          if (contest.attributes.pId === payload.elementId) {
            const comments = [...contest.comments].filter((c) => c.pId !== payload.pId && c?.parentId !== payload.pId);
            return { ...contest, comments };
          }
          return contest;
        });
        break;
      case CONTEST_APPLICATION_LIKE.POST.SUCCESS:
        draft.contests = state.contests.map((item) => {
          if (item?.attributes?.pId == payload?.contestId) {
            const platformApplications = item.platformApplications.map((application) => {
              if (application?.pId == payload?.elementId) {
                const userId = payload?.userId;
                const likedByIds = application?.likedByIds ?? [];
                const index = likedByIds?.indexOf(userId) ?? -1;
                const newElementsById = [...likedByIds];
                if (index > -1) {
                  newElementsById.splice(index, 1);
                  return { ...application, likedByIds: newElementsById };
                } else {
                  newElementsById.push(userId);
                  return { ...application, likedByIds: newElementsById };
                }
              }
              return application;
            });
            return { ...item, platformApplications: platformApplications };
          }
          return item;
        });
        break;
      case CONTEST_CONTEST_APPLICATION_VOTE.POST.SUCCESS:
        draft.contests = state.contests.map((item) => {
          if (item?.attributes?.pId == payload?.contestId) {
            const platformApplications = item.platformApplications.map((application) => {
              if (application?.pId == payload?.contestApplicationId) {
                const userId = payload?.userId;
                const backedByIds = application?.backedByIds ?? [];
                const index = backedByIds?.indexOf(userId) ?? -1;
                const newElementsById = [...backedByIds];
                if (index > -1) {
                  newElementsById.splice(index, 1);
                  return { ...application, backedByIds: newElementsById };
                } else {
                  newElementsById.push(userId);
                  return { ...application, backedByIds: newElementsById };
                }
              }
              return application;
            });
            return { ...item, platformApplications: platformApplications };
          }
          return item;
        });
        break;
      case CONTEST_ACTION_LIKE.POST.SUCCESS:
        draft.contests = state.contests.map((item) => {
          if (item?.attributes?.pId == payload?.contestId) {
            const actionApplications = item.actionApplications.map((application) => {
              if (application?.pId == payload?.elementId) {
                const userId = payload?.userId;
                const likedByIds = application?.likedByIds ?? [];
                const index = likedByIds?.indexOf(userId) ?? -1;
                const newElementsById = [...likedByIds];
                if (index > -1) {
                  newElementsById.splice(index, 1);
                  return { ...application, likedByIds: newElementsById };
                } else {
                  newElementsById.push(userId);
                  return { ...application, likedByIds: newElementsById };
                }
              }
              return application;
            });
            return { ...item, actionApplications: actionApplications };
          }
          return item;
        });
        break;
      case CONTEST_ACTION_VOTE.POST.SUCCESS:
        draft.contests = state.contests.map((item) => {
          if (item?.attributes?.pId == payload?.attributes?.contestId) {
            const actionApplications = item.actionApplications.map((application) => {
              if (application?.pId == payload?.attributes?.pId) {
                return payload?.attributes;
              }
              return application;
            });
            return { ...item, actionApplications: actionApplications };
          }
          return item;
        });
        break;
      default:
        return draft;
    }
  });

export default contestReducer;
