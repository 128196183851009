export function extractNodesFromEdges(edgesList) {
  const ret = [];
  for (const index in edgesList) {
    if (Object.prototype.hasOwnProperty.call(edgesList, index)) {
      const element = edgesList[index];
      if (Object.prototype.hasOwnProperty.call(element, 'node')) {
        ret.push(element['node']);
      }
    }
  }
  return ret;
}

export function extractNodesNamesFromEdgesUsingMap(edgesList) {
  return edgesList.map((el) => el.node?.name);
}

export function extractNodesFromEdgesUsingMap(edgesList) {
  return edgesList.map((el) => el?.node);
}

export function stringify(jsonObj) {
  // input is json object: [{ "name": "facebook", "url": "testFB" }]
  // output should be like this: [{name:"facebook",url:"testFB"}]
  const json = JSON.stringify(jsonObj);
  const unquoted = json.replace(/"([^"]+)":/g, '$1:');
  return unquoted;
}

export function escapeSingleAndDoubleQuotes(str) {
  return str.replace(/\\([\s\S])|(")/g, '\\$1$2');
}

export function stringToList(multilineStirng) {
  const escapedString = escapeSingleAndDoubleQuotes(multilineStirng);
  const split = escapedString?.split(/\r?\n/);
  return split?.map((splitString) => `"${splitString}"`);
}
