import { actionData } from './queries.js';
import { stringify, escapeSingleAndDoubleQuotes, stringToList } from './utils';

export function createAction(
  name,
  imageUrl,
  description,
  endDate,
  tagIds,
  organizerOrganizationId,
  checkListItems,
  participantIds,
  published,
  createdBy,
  contestId,
  isGroupApplication
) {
  const nameStr = name != undefined ? `name: "${escapeSingleAndDoubleQuotes(name)}"` : '';
  const descriptionStr =
    description != undefined ? `description: [${stringToList(escapeSingleAndDoubleQuotes(description))}]` : '';
  const endDateStr = endDate != undefined && endDate != '' ? `endDate: "${endDate}"` : '';
  const organizerOrganizationIdStr =
    organizerOrganizationId != undefined ? `organizerOrganizationId: ${organizerOrganizationId}` : '';
  const checkListItemsStr =
    checkListItems != undefined && checkListItems != '' ? `checkListItems: ${checkListItems}` : '';
  const participantIdsStr =
    participantIds != undefined && participantIds != '' ? `participantIds: ${participantIds}` : '';
  const contestIdStr = contestId != undefined ? `contestId: ${contestId}` : '';
  const isGroupApplicationStr = isGroupApplication != undefined ? `isGroupApplication: ${isGroupApplication}` : '';

  return `mutation {
    createAction(
      actionData: {
        ${nameStr}
        imageUrl: "${imageUrl}"
        ${descriptionStr}
        ${endDateStr}
        tagIds: ${tagIds}
        ${organizerOrganizationIdStr}
        ${checkListItemsStr}
        published: ${published}
        ${participantIdsStr}
        createdBy: ${createdBy}
        ${contestIdStr}
        ${isGroupApplicationStr}
      }
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }
`;
}

export function updateAction(
  id,
  name,
  imageUrl,
  description,
  endDate,
  published,
  cognitoId,
  contestId,
  isGroupApplication
) {
  const nameStr = name != undefined ? `name: "${escapeSingleAndDoubleQuotes(name)}"` : '';
  const imageUrlStr = imageUrl != undefined ? `imageUrl: "${imageUrl}"` : '';
  const descriptionStr = description != undefined ? `description: [${stringToList(description)}]` : '';
  const endDateStr = endDate != undefined && endDate != '' ? `endDate: "${endDate}"` : '';
  const publishedStr = published != undefined ? `published: ${published}` : '';
  const contestIdStr = contestId != undefined ? `contestId: ${contestId}` : '';
  const isGroupApplicationStr = isGroupApplication != undefined ? `isGroupApplication: ${isGroupApplication}` : '';

  return `mutation {
    updateAction(
      actionData: {
        id: ${id}
        ${nameStr}
        ${imageUrlStr}
        ${descriptionStr}
        ${endDateStr}
        ${publishedStr}
        ${contestIdStr}
        ${isGroupApplicationStr}
      }
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }
`;
}

export function createOrDeleteActionsTags(id, tagIds, cognitoId) {
  return `mutation {
    createOrDeleteActionsTags(
      tagIds: ${tagIds}
      id: ${id}
      cognitoId: "${cognitoId}"
    ) {
        isRequestSuccessful
        action {
          ${actionData}
        }
      }
    }`;
}

export function createOrDeleteActionsParticipants(id, participantIds) {
  const ids = stringify(participantIds);
  return `mutation {
    createOrDeleteActionsParticipants(
      participantsIds: ${ids}
      id: ${id}
    ) {
        isRequestSuccessful
        action {
          ${actionData}
        }
      }
    }`;
}

export function addOrRemoveActionsParticipant(id, cognitoId) {
  return `mutation {
    addOrRemoveActionsParticipant(
      id: ${id}
      cognitoId: "${cognitoId}"
    ) {
        isRequestSuccessful
        action {
          ${actionData}
        }
      }
    }`;
}

export function addActionsCheckListItem(actionsId, text, cognitoId) {
  return `mutation {
    addActionsCheckListItem (
      actionId: ${actionsId}
      text: "${text}"
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }`;
}

export function updateActionsCheckListItem(id, text, done, cognitoId) {
  const textStr = text != undefined ? `text: "${escapeSingleAndDoubleQuotes(text)}"` : '';
  const doneStr = done != undefined ? `done: ${done}` : '';
  return `mutation {
    updateActionsCheckListItem (
      updateActionChecklistData: {
        id: ${id}
        ${textStr}
        ${doneStr}
      }
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }`;
}

export function deleteActionsCheckListItem(id, cognitoId) {
  return `mutation {
    deleteActionsCheckListItem (
      id: ${id}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }`;
}

export function addActionsDocument(actionsId, url, title, cognitoId) {
  const titleStr = title != undefined ? `title: "${escapeSingleAndDoubleQuotes(title)}"` : '';
  return `mutation {
    addActionsDocument (
      elementId: ${actionsId}
      url: "${url}"
      ${titleStr}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }`;
}

export function addNewActionsDocumentsAndImage(actionId, documents, imageUrl, cognitoId) {
  const documentsStr = documents != undefined && documents ? `documents: ${documents}` : '';
  const imageUrlStr = imageUrl != undefined && imageUrl ? `imageUrl: "${imageUrl}"` : '';
  return `mutation {
    addNewActionsDocumentsAndImage (
      newActionInputData: {
        ${documentsStr}
        ${imageUrlStr}
        id: ${actionId}
      }
    cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }`;
}

export function updateActionsDocument(id, title, cognitoId) {
  const titleStr = title != undefined ? `title: "${escapeSingleAndDoubleQuotes(title)}"` : '';
  return `mutation {
    updateActionsDocument (
      id: ${id}
      ${titleStr}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }`;
}

export function deleteActionsDocument(id, cognitoId) {
  return `mutation {
    deleteActionsDocument (
      id: ${id}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }`;
}

export function backAction(actionId, userId) {
  return `mutation {
    backAction (
      actionId: ${actionId}
      userId: ${userId}
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }`;
}

export function addActionView(actionId, cognitoId) {
  return `mutation {
    addActionsView (
      id: ${actionId}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }`;
}

export function deleteAction(actionId, cognitoId) {
  return `mutation {
    deleteAction (
      id: ${actionId}
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      actionId
    }
  }`;
}

export function joinAction(actionId, organizationId, payloadHash, cognitoId) {
  const organizationIdStr = organizationId != null ? `organizationId: ${organizationId}` : '';
  return `mutation {
    joinAction (
      joinActionData: {
        actionId: ${actionId}
        ${organizationIdStr}
        payloadHash: "${payloadHash}"
      }
    cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }`;
}

export function removeActionsParticipant(actionId, member, cognitoId) {
  return `mutation {
    removeActionsParticipant(
      removeParticipantInput: {
        actionId: ${actionId}
        participantId: ${member.pId}
        participantType: "${member.memberType ?? 'USER'}"
      }
      cognitoId: "${cognitoId}"
    ){
      isRequestSuccessful
      action {
        ${actionData}
      }
    }
  }`;
}
