import { produce } from 'immer';
import { IActivity, IPersonalProfile } from 'core/models/Models';
import {
  PERSONAL_PROFIL,
  UPDATE_PERSONAL_PROFILE,
  PROFILE_EDUCATION,
  PROFILE_GENERAL_INFO,
  PROFILE_SOCIAL_NETWORKS,
  PROFILE_TAGS,
  PROFILE_WORK_EXPERIENCE,
  PROFILE_NOTIFICATIONS,
  PROFILE_CONTAINED_RESOURCE_LIKE
} from 'core/actions/PersonalProfileActions';
export interface IPersonalProfileState {
  personalProfile: IPersonalProfile;
  isProfileCreated: boolean;
}

const initialState: IPersonalProfileState = {
  personalProfile: {} as IPersonalProfile,
  isProfileCreated: false
};

const personalProfileReducer = (state = initialState, action: IActivity): IPersonalProfileState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case PERSONAL_PROFIL.GET.SUCCESS: {
        draft.personalProfile = payload.user;
        draft.personalProfile.knowledgeResources = payload?.knowledgeResources?.map((resource) => ({
          attributes: { ...resource }
        }));
        draft.personalProfile.expertiseResources = payload?.expertiseResources?.map((resource) => {
          return { attributes: { ...resource } };
        });
        draft.personalProfile.linkResources = payload?.linkResources?.map((resource) => {
          return { attributes: { ...resource } };
        });
        draft.personalProfile.toolResources = payload?.toolResources?.map((resource) => {
          return {
            attributes: { ...resource },
            knowledgeResources: resource.toolsKnowledgeResources,
            linkResources: resource.toolsLinkResources,
            expertiseResources: resource.toolsExpertiseResources,
            documents: resource.toolsDocuments
          };
        });
        draft.personalProfile.actions = payload?.actions?.map((action) => {
          return { attributes: { ...action } };
        });
        draft.personalProfile.contests = payload?.contests?.map((contest) => {
          return { attributes: { ...contest } };
        });
        break;
      }
      case PERSONAL_PROFIL.POST.SUCCESS:
        draft.isProfileCreated = true;
        break;
      case UPDATE_PERSONAL_PROFILE.POST.SUCCESS: {
        const payloadWithoutEmptyKeys = Object.entries(payload).reduce(
          (a, [k, v]) => (v === null || v === undefined ? a : ((a[k] = v), a)),
          {}
        );
        draft.personalProfile.attributes = { ...state.personalProfile.attributes, ...payloadWithoutEmptyKeys };
        break;
      }
      case PROFILE_SOCIAL_NETWORKS.POST.SUCCESS:
        draft.personalProfile.socialNetworks = payload;
        break;
      case PROFILE_GENERAL_INFO.POST.SUCCESS:
        draft.personalProfile.attributes = { ...draft.personalProfile.attributes, ...payload };
        break;
      case PROFILE_WORK_EXPERIENCE.POST.SUCCESS: {
        const headerWorkBio = payload.headerWorkBio;
        if (headerWorkBio != null) {
          draft.personalProfile.attributes.headerWorkBio = headerWorkBio;
          draft.personalProfile.attributes.headerWorkBioId = headerWorkBio.pId;
          draft.personalProfile.attributes.showCustomTitle = false;
        } else {
          draft.personalProfile.attributes.showCustomTitle = true;
        }
        draft.personalProfile.attributes.signature = payload.signature;
        draft.personalProfile.workBios.push(payload.workBio);
        break;
      }
      case PROFILE_WORK_EXPERIENCE.PUT.SUCCESS: {
        const headerWorkBio = payload.headerWorkBio;
        if (payload.isHeaderWorkBio && headerWorkBio != null) {
          draft.personalProfile.attributes.headerWorkBio = headerWorkBio;
          draft.personalProfile.attributes.headerWorkBioId = headerWorkBio.pId;
          draft.personalProfile.attributes.showCustomTitle = false;
        } else {
          draft.personalProfile.attributes.showCustomTitle = true;
        }
        draft.personalProfile.attributes.signature = payload.signature;
        draft.personalProfile.workBios = state.personalProfile.workBios.map((item) =>
          item.id === payload.workBio.id ? payload.workBio : item
        );
        break;
      }
      case PROFILE_WORK_EXPERIENCE.DELETE.SUCCESS: {
        const headerWorkBio = payload.headerWorkBio;
        if (headerWorkBio == null) {
          draft.personalProfile.attributes.showCustomTitle = true;
        }
        draft.personalProfile.attributes.signature = payload.signature;
        draft.personalProfile.workBios = state.personalProfile.workBios.filter(
          (item) => item.id !== payload.workBioId.toString()
        );
        break;
      }
      case PROFILE_EDUCATION.POST.SUCCESS:
        draft.personalProfile.education.push(payload);
        break;
      case PROFILE_EDUCATION.PUT.SUCCESS:
        draft.personalProfile.education = draft.personalProfile.education.map((item) =>
          item.id === payload.id ? payload : item
        );
        break;
      case PROFILE_EDUCATION.DELETE.SUCCESS:
        draft.personalProfile.education = state.personalProfile.education.filter((item) => item.id !== payload);
        break;
      case PROFILE_TAGS.POST.SUCCESS:
        draft.personalProfile.tags = payload;
        break;
      case PROFILE_NOTIFICATIONS.POST.SUCCESS:
        draft.personalProfile.notificationSettings = payload;
        break;
      case PROFILE_CONTAINED_RESOURCE_LIKE.POST.SUCCESS: {
        const userId = payload?.userId;
        const newKnowledgeResources = state.personalProfile?.knowledgeResources?.map((resource) => {
          if (resource?.attributes?.pId == payload?.elementId) {
            return containedItemWithUpdatedLikedBy(userId, resource);
          }
          return resource;
        });
        const newExpertiseResources = state.personalProfile?.expertiseResources?.map((resource) => {
          if (resource?.attributes?.pId == payload?.elementId) {
            return containedItemWithUpdatedLikedBy(userId, resource);
          }
          return resource;
        });
        const newLinkResources = state.personalProfile?.linkResources?.map((resource) => {
          if (resource?.attributes?.pId == payload?.elementId) {
            return containedItemWithUpdatedLikedBy(userId, resource);
          }
          return resource;
        });
        const newToolResources = state.personalProfile?.toolResources?.map((resource) => {
          if (resource?.attributes?.pId == payload?.elementId) {
            return containedItemWithUpdatedLikedBy(userId, resource);
          }
          return resource;
        });
        const newActions = state.personalProfile?.actions?.map((action) => {
          if (action?.attributes?.pId == payload?.elementId) {
            return containedItemWithUpdatedLikedBy(userId, action);
          }
          return action;
        });
        const newContests = state.personalProfile?.contests?.map((contest) => {
          if (contest?.attributes?.pId == payload?.elementId) {
            return containedItemWithUpdatedLikedBy(userId, contest);
          }
          return contest;
        });
        draft.personalProfile = {
          ...state.personalProfile,
          knowledgeResources: newKnowledgeResources,
          expertiseResources: newExpertiseResources,
          linkResources: newLinkResources,
          toolResources: newToolResources,
          actions: newActions,
          contests: newContests
        };
        break;
      }
      default:
        return draft;
    }
  });

const containedItemWithUpdatedLikedBy = (userId, item) => {
  const likedByIds = item?.attributes?.likedByIds ?? [];
  const index = likedByIds?.indexOf(userId) ?? -1;
  const newElementsById = [...likedByIds];
  if (index > -1) {
    newElementsById.splice(index, 1);
    const attributes = { ...item.attributes, likedByIds: newElementsById };
    return { ...item, attributes: attributes };
  } else {
    newElementsById.push(userId);
    const attributes = { ...item.attributes, likedByIds: newElementsById };
    return { ...item, attributes: attributes };
  }
};

export default personalProfileReducer;
