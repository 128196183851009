import { all, spawn } from 'redux-saga/effects';

import authSaga from './AuthSaga';
import notificationsSaga from './NotificationsSaga';
import organizationSaga from './OrganizationSaga';
import personalProfileSaga from './PersonalProfileSaga';
import actionsSaga from './ActionSaga';
import userSaga from './UserSaga';
import countrySaga from './CountrySaga';
import contestApplicationSaga from './ContestApplicationSaga';
import interactionSaga from './InteractionSaga';
import contestSaga from './ContestSaga';
import commonSaga from './CommonSaga';
import resourcesSaga from './ResourceSaga';
import calendarSaga from './CalendarSaga';
import homePageSaga from './HomePageSaga';

export function* startWatchers() {
  yield all([
    spawn(authSaga),
    spawn(organizationSaga),
    spawn(personalProfileSaga),
    spawn(notificationsSaga),
    spawn(actionsSaga),
    spawn(userSaga),
    spawn(countrySaga),
    spawn(contestApplicationSaga),
    spawn(interactionSaga),
    spawn(contestSaga),
    spawn(commonSaga),
    spawn(resourcesSaga),
    spawn(calendarSaga),
    spawn(homePageSaga)
  ]);
}

export default function* rootSaga() {
  yield all([startWatchers()]);
}
