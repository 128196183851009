import {
  CONFIRM_SIGN_UP,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CONFIRMATION,
  SEND_INVITATION,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  VERIFY_TOKEN,
  changeCognitoPassword,
  confirmSignup,
  forgotPassword,
  forgotPasswordConfirmation,
  sendInvitation,
  signInUser,
  signOutUser,
  signUpUser,
  signInForumUser,
  verifyToken,
  updateSignupData,
  RESEND_CONFIRMATION_CODE,
  resendConfirmationCode,
  getCurrentAuthUser,
  CURRENT_AUTH_USER,
  acceptInvitation as acceptInvitationAction,
  ACCEPT_INVITATION,
  updateCognitoUser,
  USER,
  verifyUserEmail,
  COGNITO_USER_EMAIL_VERIFICATION,
  CHANGE_COGNITO_PASSWORD,
  FORUM_SIGN_IN,
  DEACTIVATE_USERS_PROFILE,
  deactivateUsersProfile,
  deleteUserProfile,
  DELETE_USER_PROFILE,
  loadSignUpDataFromIDB,
  UPDATE_SIGNUP_DATA,
  RESEND_EMAIL_CONFIRMATION_CODE,
  resendEmailConfirmationCode
} from 'core/actions/AuthActions';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { AppRoutes } from 'config/AppRoutes';
import { API, Auth } from 'aws-amplify';
import { getInvitationByEmailHash, getPersonalProfileByCognitoId, getSentInvitation } from 'api/graphql/queries';
import {
  acceptInvitation,
  signOutFromDiscourse,
  updateUsersDiscourseEmail,
  deactivateUser,
  deleteUser
} from 'api/graphql/mutations';
import { decodeBase64, encodeBase64, navigateToExternalPage } from 'utils/Helpers';
import i18n from 'utils/i18n';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import * as Alert from 'utils/Alerts';
import { getSignupData, resetDb, upsertSignupData } from 'api/indexedDB';
import { ISignupState } from 'core/models/Models';
import { globalNavigate } from 'utils/global-history';


const discourseConnectSecret = process.env.REACT_APP_DISCOURSE_CONNECT_SECRET;
const forumURL = process.env.REACT_APP_FORUM_URL;

export function* getCurrentAuthUserSaga() {
  try {
    const data = yield Auth.currentAuthenticatedUser();
    yield put(getCurrentAuthUser.success(data));
  } catch (e) {
    yield put(getCurrentAuthUser.failure(e));
  }
}

export function* updateCognitoUserSaga({ payload }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    yield Auth.updateUserAttributes(user, {
      ...payload
    });
    yield put(updateCognitoUser.success({ attributes: payload }));
    yield Alert.setSuccessAlert(i18n.t('api.success.updateUserData'));
  } catch (e: any) {
    yield put(updateCognitoUser.failure({ error: e }));
    if (e.code === 'NotAuthorizedException') {
      const data = yield Auth.signOut();
      yield put(signOutUser.success(data));
      globalNavigate(AppRoutes.login.link);
    }
    if (e?.code && i18n.t(`aws.errors.${e.code}`)) {
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e.code}`));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.updateUserData'));
    }
  }
}

export function* verifyCognitoUserAttributSaga({ payload: { previousEmail, email, code, isSignup } }) {
  try {
    yield Auth.verifyCurrentUserAttributeSubmit('email', code);
    if (!isSignup) {
      const user = yield Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      const requestInfo = {
        headers: {
          Authorization: token
        },
        queryStringParameters: {
          query: updateUsersDiscourseEmail(user?.attributes?.sub, previousEmail, email)
        }
      };
      const res = yield API.post('UserAPI', '/graphql?query', requestInfo);
      if (res?.errors) {
        throw new Error('Error: Discourse mail nije mogao biti ažuriran.');
      }
    }
    yield put(verifyUserEmail.success({ email }));
    yield Alert.setSuccessAlert(i18n.t('api.success.verifyEmail'));
  } catch (e: any) {
    yield put(verifyUserEmail.failure({ email, error: e }));
    if (i18n.t(`aws.errors.${e?.code}`)) {
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.userVerification'));
    }
  }
}

export function* signInUserSaga({ payload: { email, password, sso, sig } }) {
  try {
    const user = yield Auth.signIn(email, password);
    if (user?.attributes?.email_verified === true) {
      const currentUser = yield Auth.currentAuthenticatedUser();
      if (currentUser?.attributes?.sub) {
        const requestInfo = {
          queryStringParameters: {
            query: getPersonalProfileByCognitoId(currentUser?.attributes?.sub)
          }
        };
        yield API.post('UserAPI', '/graphql?query', requestInfo);
        if (sso && sig) {
          const [returnPayload, returnSig] = signInToForum(email, currentUser?.attributes?.sub, sso, sig);
          redirectToForum(returnPayload, returnSig);
        }
        yield put(signInUser.success(user));
      } else {
        throw new Error('Error: currentUser nije pronađen.');
      }
    }
    if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      globalNavigate(AppRoutes.changePassword.link);
    }
  } catch (e: any) {
    if (e?.code && i18n.t(`aws.errors.${e?.code}`)) {
      if (e.code === 'PasswordResetRequiredException') {
        yield Auth.forgotPassword(email);
        globalNavigate(`${AppRoutes.forgotPassword.link}/verification`);
      }
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.signIn'));
    }
    yield put(signInUser.failure(e));
  }
}

export function* forumSignInSaga({ payload: { email, cognitoId, sso, sig } }) {
  try {
    const [returnPayload, returnSig] = signInToForum(email, cognitoId, sso, sig);
    yield put(signInForumUser.success());
    redirectToForum(returnPayload, returnSig);
  } catch (e) {
    yield put(signInForumUser.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.forumSignIn'));
  }
}

function signInToForum(email, cognitoId, sso, sig) {
  const payloadSignature = HmacSHA256(sso, discourseConnectSecret).toString();
  if (payloadSignature != sig) {
    throw new Error('Invalid discourse sso payload');
  }
  // decode sso
  const raw_payload = decodeBase64(sso);
  const nonce = raw_payload.split('&')[0];
  let returnPayload = `${nonce}&email=${email}&external_id=${cognitoId}&suppress_welcome_message=true`;
  returnPayload = encodeBase64(returnPayload);
  const returnSig = HmacSHA256(returnPayload, discourseConnectSecret).toString();
  return [returnPayload, returnSig];
}

function redirectToForum(payload, sig) {
  const searchParams = new URLSearchParams();
  searchParams.append('sso', payload);
  searchParams.append('sig', sig);
  const redirectURL = `${forumURL}/session/sso_login?${searchParams.toString()}`;
  navigateToExternalPage(redirectURL);
}

export function* signOutUserSaga({ payload: { discourseId } }) {
  try {
    // sign out from platform and discourse
    if (discourseId) {
      const requestInfo = {
        queryStringParameters: {
          query: signOutFromDiscourse(discourseId)
        }
      };
      const result = yield API.post('UserAPI', '/graphql?query', requestInfo);
      if (result?.errors) {
        throw new Error('Error: sign out from discourse failed');
      }
    }
    const data = yield Auth.signOut();
    yield put(signOutUser.success(data));
    globalNavigate(AppRoutes.login.link);
  } catch (e: any) {
    yield put(signOutUser.failure(e));
    if (i18n.t(`aws.errors.${e?.code}`)) {
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.signOut'));
    }
  }
}

function* loadSignupDataSaga() {
  try {
    const data = yield getSignupData();
    yield put(loadSignUpDataFromIDB.success(data[0]));
  } catch (err) {
    yield put(loadSignUpDataFromIDB.failure(err));
  }
}

export function* signUpUserSaga({ payload }) {
  const username = payload.email.split('@').join('_');
  try {
    const data = {
      username,
      password: payload?.password,
      attributes: {
        email: payload.email,
        name: `${payload.firstname} ${payload.lastname}`,
        given_name: payload.firstname,
        family_name: payload.lastname,
        [`custom:organization`]: payload.organizationId ? String(payload.organizationId) : payload.organizationName,
        [`custom:title`]: payload.position
      },
      autoSignIn: {
        enabled: true
      }
    };

    const { user } = yield Auth.signUp(data);

    yield put(signUpUser.success(user));
    yield put(updateSignupData({ ...payload, username: user.username }));
    yield put(acceptInvitationAction.request(payload?.invitationId));
  } catch (e: any) {
    yield put(signUpUser.failure(e));
    if (i18n.t(`aws.errors.${e?.code}`)) {
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.signup'));
    }
  }
}

export function* updateSignupIndexedDBDataSaga(data) {
  try {
    if (data?.payload?.state !== ISignupState.EmailVerify) {
      yield upsertSignupData(data?.payload);
    } else if (data?.payload?.state === ISignupState.EmailVerify && data?.payload?.confirmedEmail) {
      yield upsertSignupData({ ...data?.payload, state: ISignupState.Avatar });
    } else {
      yield upsertSignupData({ ...data?.payload, state: ISignupState.EmailVerify });
    }
  } catch (err) {
    console.info('err', err);
  }
}

export function* confirmSignupSaga({ payload: { username, authCode, signupForm} }) {
  try {
    yield Auth.confirmSignUp(username, authCode);
    if (!signupForm.cognitoUser) {
      yield Alert.setSuccessAlert(i18n.t('api.success.emailVerification'));
      globalNavigate(AppRoutes.login.link);
    }
    // yield put(signInUser.request(signupForm?.email, signupForm?.password, '', ''));
    // yield delay(3000);
    // set autosignin to true
    yield put(updateSignupData({ ...signupForm, confirmedEmail: true }));
  } catch (e: any) {
    yield put(confirmSignup.failure(e));
    if (i18n.t(`aws.errors.${e?.code}`)) {
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.emailVerification'));
    }
  }
}

export function* resendEmailConfirmationCodeSaga({ payload: { email } }) {
  try {
    const username = email.split('@').join('_');
    const data = yield Auth.resendSignUp(username);
    yield put(resendEmailConfirmationCode.success({email: email, username: username}));
    yield Alert.setSuccessAlert(i18n.t('api.success.resendCode'));
    globalNavigate('/signup/email-verifikacija');
  } catch (e: any) {
    yield put(resendEmailConfirmationCode.failure(e));
    if (i18n.t(`aws.errors.${e?.code}`)) {
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.resendCode'));
    }
  }
}

export function* resendConfirmationCodeSaga({ payload: { username } }) {
  try {
    const data = yield Auth.resendSignUp(username);
    yield put(resendConfirmationCode.success(data));
    yield Alert.setSuccessAlert(i18n.t('api.success.resendCode'));
  } catch (e: any) {
    yield put(resendConfirmationCode.failure(e));
    if (i18n.t(`aws.errors.${e?.code}`)) {
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.resendCode'));
    }
  }
}

export function* changePasswordSaga({ payload: { oldPassword, newPassword } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const data = yield Auth.changePassword(user, oldPassword, newPassword);
    yield put(changeCognitoPassword.success(data));
    yield Alert.setSuccessAlert(i18n.t('pages.passwordChange.success.title'));
  } catch (e: any) {
    yield put(changeCognitoPassword.failure(e));
    if (i18n.t(`aws.errors.${e?.code}`)) {
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.changePassword'));
    }
  }
}

export function* forgotPasswordSaga({ payload: { email } }) {
  try {
    yield Auth.forgotPassword(email);
    yield put(forgotPassword.success('forgotPasswordSubmit'));
  } catch (e: any) {
    yield put(forgotPassword.failure(e));
    if (i18n.t(`aws.errors.${e?.code}`)) {
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.forgotPassword'));
    }
  }
}

export function* forgotPasswordConfirmationSaga({ payload: { username, code, newPassword } }) {
  try {
    const data = yield Auth.forgotPasswordSubmit(username, code, newPassword);
    yield put(forgotPasswordConfirmation.success(data));
    globalNavigate(`${AppRoutes.forgotPassword.link}/success`);
  } catch (e: any) {
    yield put(forgotPasswordConfirmation.failure(e));
    globalNavigate(`${AppRoutes.forgotPassword.link}/failure`);
    if (i18n.t(`aws.errors.${e?.code}`)) {
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
    }
  }
}

export function* verifyTokenSaga({ payload: { token } }) {
  // ako je pozivnica prihvacena i cognito korisnik je kreiran
  // ali jos nisu uneti podaci i nije kreiran korisnik
  // verifikacija kaze da je vec prihvacena pozivnica, ali profile ne moze da se pronadje
  // jer korisnik nije unet u bazu
  try {
    yield resetDb();
    const requestInfo = {
      queryStringParameters: {
        query: getInvitationByEmailHash(token)
      }
    };

    const response = yield API.post('InvitationAPI', '/graphql?query', requestInfo);
    const invitation = response?.data?.invitation;

    if (!invitation) {
      throw new Error(i18n.t('api.errors.verifyToken'));
    }
    yield put(verifyToken.success(invitation));
  } catch (e) {
    yield put(verifyToken.failure(e));
  }
}

export function* sendInvitationSaga({ payload: { email, userId } }) {
  try {
    // check if invitation already sent
    const getInvitationQuery = {
      queryStringParameters: {
        query: getSentInvitation(email)
      }
    };

    const {
      data: { invitation }
    } = yield API.post('InvitationAPI', '/graphql?query', getInvitationQuery);

    if (invitation) {
      throw new Error(i18n.t('api.errors.invitationSent'));
    }

    const requestInfo = {
      body: {
        sender_id: userId,
        receiver_email: email
      }
    };
    const data = yield API.post('EmailAPI', '/send-invitation', requestInfo);

    yield put(sendInvitation.success(data));
  } catch (error: any) {
    yield put(sendInvitation.failure(error?.message));
  }
}

export function* acceptInvitationSaga({ payload: { id } }) {
  try {
    const requestInfo = {
      queryStringParameters: {
        query: acceptInvitation(Number(id))
      }
    };
    const response = yield API.post('InvitationAPI', '/graphql?query', requestInfo);

    yield put(acceptInvitationAction.success(response));
  } catch (e) {
    yield put(acceptInvitationAction.failure(e));
  }
}

export function* deactivateUserSaga({ payload: { discourseId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    const requestInfo = {
      queryStringParameters: {
        query: deactivateUser(user?.attributes?.sub)
      }
    };
    const response = yield API.post('UserAPI', '/graphql?query', requestInfo);
    globalNavigate('/nalog/deaktiviran');
    yield call(signOutUserSaga, { payload: { discourseId } });
    yield put(deactivateUsersProfile.success(response));
  } catch (e) {
    yield put(deactivateUsersProfile.failure(e));
    yield Alert.setErrorAlert(i18n.t('api.errors.deactivateAccount'));
  }
}

export function* deleteUserSaga({ payload: { discourseId } }) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    user.deleteUser((error, data) => {
      if (error) {
        throw new Error(error);
      }
    });

    const requestInfo = {
      queryStringParameters: {
        query: deleteUser(user?.attributes?.sub)
      }
    };
    yield API.post('UserAPI', '/graphql?query', requestInfo);

    globalNavigate('/nalog/obrisan');
    yield call(signOutUserSaga, { payload: { discourseId } });
    yield put(deleteUserProfile.success());
  } catch (e: any) {
    yield put(deleteUserProfile.failure(e));
    if (i18n.t(`aws.errors.${e?.code}`)) {
      yield Alert.setErrorAlert(i18n.t(`aws.errors.${e?.code}`));
    } else {
      yield Alert.setErrorAlert(i18n.t('api.errors.deleteAccount'));
    }
  }
}

function* authSaga() {
  yield all([
    takeLatest(CURRENT_AUTH_USER.GET.REQUEST, getCurrentAuthUserSaga),
    takeLatest(SIGN_IN.POST.REQUEST, signInUserSaga),
    takeLatest(SIGN_OUT.POST.REQUEST, signOutUserSaga),
    takeLatest(CURRENT_AUTH_USER.GET.FAILURE, signOutUserSaga),
    takeLatest(SIGN_UP.POST.REQUEST, signUpUserSaga),
    takeLatest(SIGN_UP.GET.REQUEST, loadSignupDataSaga),
    takeLatest(UPDATE_SIGNUP_DATA, updateSignupIndexedDBDataSaga),
    takeLatest(CONFIRM_SIGN_UP.POST.REQUEST, confirmSignupSaga),
    takeLatest(CHANGE_COGNITO_PASSWORD.POST.REQUEST, changePasswordSaga),
    takeLatest(FORGOT_PASSWORD.POST.REQUEST, forgotPasswordSaga),
    takeLatest(FORGOT_PASSWORD_CONFIRMATION.POST.REQUEST, forgotPasswordConfirmationSaga),
    takeLatest(VERIFY_TOKEN.POST.REQUEST, verifyTokenSaga),
    takeLatest(SEND_INVITATION.POST.REQUEST, sendInvitationSaga),
    takeLatest(RESEND_CONFIRMATION_CODE.POST.REQUEST, resendConfirmationCodeSaga),
    takeLatest(RESEND_EMAIL_CONFIRMATION_CODE.POST.REQUEST, resendEmailConfirmationCodeSaga),
    takeLatest(ACCEPT_INVITATION.POST.REQUEST, acceptInvitationSaga),
    takeLatest(USER.POST.REQUEST, updateCognitoUserSaga),
    takeLatest(COGNITO_USER_EMAIL_VERIFICATION.POST.REQUEST, verifyCognitoUserAttributSaga),
    takeLatest(FORUM_SIGN_IN.POST.REQUEST, forumSignInSaga),
    takeLatest(DEACTIVATE_USERS_PROFILE.POST.REQUEST, deactivateUserSaga),
    takeLatest(DELETE_USER_PROFILE.DELETE.REQUEST, deleteUserSaga)

  ]);
}

export default authSaga;
